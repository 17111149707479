import React from "react";

import Flex from "common/components/Flex";
import { FlexPart } from "common/components/Flex/FlexPart";
import { AbsLabel } from "common/components/Layout/Form/Label";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import SearchIcon from "common/icons/SearchIcon";
import { theme } from "common/theme/theme";
import { GroupUser } from "store/tasker/models/GroupUser";
import styled from "styled-components";

export const SearchBar = styled.input`
  border: 1px solid #eceeef;
  padding-left: 16px;
  border-radius: 8px;
  color: ${theme.color.mediumGray}
  outline: none;
  height: 40px;
  width: 388px;
  &:focus {
    outline: none;
  }
`;

interface TopBarProps {
  assignee: GroupUser | null;
  handleChangeInputValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TopBar({
  handleChangeInputValue,
  assignee,
}: TopBarProps) {
  return (
    <Flex height="40px" mt="24px" justify="center">
      <Flex width="403px">
        <RelativeContainer>
          <SearchBar
            placeholder="Szukaj..."
            onChange={handleChangeInputValue}
          />
          <AbsLabel right="15px" top="11px">
            <SearchIcon
              width="24px"
              height="24px"
              color={theme.color.mediumGray}
            />
          </AbsLabel>
        </RelativeContainer>
      </Flex>
      <FlexPart flex="1">
        <Flex width="100%" height="100%" align="center" justify="center">
          <Text
            color={theme.color.mediumGray}
            size="14px"
            data-testid="user-counter"
          >
            {assignee && `wybrano (${assignee.user?.first_name})`}
            {!assignee && `nie wybrano nikogo`}
          </Text>
        </Flex>
      </FlexPart>
    </Flex>
  );
}
