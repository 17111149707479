import React from "react";

import Flex from "common/components/Flex";
import { PlusSvgSign } from "common/components/SvgIcons/PlusSvgSign";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

const FlexCreateTaskButton = styled(Flex)`
  background-color: ${theme.color.basicBlack};
  color: white;
  border-radius: 40px;
  cursor: pointer;
`;

export interface TaskListProps {
  handleOpenNewTask: () => void;
}

export default function CreateTaskButton({ handleOpenNewTask }: TaskListProps) {
  return (
    <FlexCreateTaskButton
      align="center"
      justify="center"
      p="8px 20px"
      onClick={handleOpenNewTask}
    >
      <PlusSvgSign color="black" />
      <Text
        ml="7px"
        size="14px"
        weight="normal"
        font="primary"
        color={theme.color.basicWhite}
      >
        Utwórz zadanie
      </Text>
    </FlexCreateTaskButton>
  );
}
