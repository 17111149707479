import { shiftDate } from "common/utils/dateUtils";
import { ITaskInForm } from "components/TaskerPage/NewTaskView/NewTaskView";
import { getSub } from "store/auth/authUtils";

import { apiRequest } from "../config/axiosConfig";

export interface TaskerCreateTaskPayload {
  task_title: string;
  group_id: string;
  assignee: string | null;
  created_by: string;
  description: string;
  date: string; // realisationDate
  reminder_date: string;
  priority?: boolean;
  category_ids?: string[];
  list_id: string;
  attachments?: string[];
}

export async function taskerCreateTaskRq(
  data: ITaskInForm,
  groupId: string
): Promise<string> {
  let reminder_date: string | null = null;
  if (data.reminder_date === "dayBefore") {
    reminder_date = shiftDate(data.realisationDate, -1).toISOString();
  }
  const dto: TaskerCreateTaskPayload = {
    task_title: data.task_title,
    description: data.description,
    date: data.realisationDate.toISOString(),
    reminder_date: reminder_date,
    assignee: data.assignee,
    list_id: data.listName,
    priority: data.priority,
    created_by: getSub(),
    group_id: groupId,
  };

  if (data.category_ids?.length > 0) {
    dto.category_ids = data.category_ids.map((l) => l.id);
  }

  console.log(dto);
  try {
    const response = await apiRequest<string, TaskerCreateTaskPayload>(
      "POST",
      "TASK",
      "/create",
      {
        data: dto,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(`CreateTask returned with status ${err.response.status}`);
    }
  }
}
