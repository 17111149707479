import styled from "styled-components/macro";

export const GroupPostPanelWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: white;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const GroupPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
`;
