import React from "react";

import IconButton from "common/components/IconButton/IconButton";
import Image from "common/components/Image/Image";
import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import { PostPublicationType } from "common/types/PostPublicationType";

import {
  PostModalUserRightTable,
  PostModalUserWrapper,
  VisibilityDropdown,
} from "./PostModalUser.styled";

interface PostModalUserProps {
  goToPublicationMode?: () => void;
  userAvatar: string;
  username: string;
  displayPublication: boolean;
  publicationType: PostPublicationType;
}

const PostModalUser = ({
  goToPublicationMode,
  userAvatar,
  username,
  publicationType,
  displayPublication,
}: PostModalUserProps) => {
  return (
    <PostModalUserWrapper>
      <IconButton icon={userAvatar} width={40} height={40} radius="100%" />
      <PostModalUserRightTable>
        <Text
          size="14px"
          weight="bold"
          align="left"
          color={theme.color.basicBlack}
        >
          {username}
        </Text>
        {displayPublication && (
          <VisibilityDropdown onClick={goToPublicationMode}>
            <Image
              width={16}
              height={16}
              src={`/icons/${
                publicationType === "Prywatny" ? "lock" : "globe"
              }.svg`}
            />
            <Text size="10px" color={theme.color.basicBlack}>
              {publicationType.toString()}
            </Text>
            <Image src="/icons/small_arrow_down.svg" width={8} height={4.72} />
          </VisibilityDropdown>
        )}
      </PostModalUserRightTable>
    </PostModalUserWrapper>
  );
};

export default PostModalUser;
