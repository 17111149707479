import React from "react";

import IconButton from "common/components/IconButton/IconButton";
import Image from "common/components/Image";
import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import { GroupPostPanelWrapper } from "components/Group/GroupPosts/GroupPostsPanel/GroupPostPanel.styled";

import {
  AddPostButtonIconWrapper,
  PostAreaTextWrapper,
} from "./PostTextArea.styled";

interface PostTextAreaWrapper {
  turnOnModal: () => void;
  userAvatar: string;
}

const PostTextArea = ({ turnOnModal, userAvatar }: PostTextAreaWrapper) => {
  return (
    <GroupPostPanelWrapper>
      <IconButton
        width={40}
        height={40}
        radius="100%"
        icon={userAvatar}
        backgroundColor="white"
        marginLeft="32px"
      />
      <PostAreaTextWrapper onClick={turnOnModal} marginRight="27px">
        <Text size="16px" ml="8px" color={theme.color.mediumGray}>
          O czym chcesz napisać?
        </Text>
        <AddPostButtonIconWrapper>
          <Image
            src="/icons/at-sign.svg"
            alt="Logo Acorn"
            width={20}
            height={20}
          />
          <Image
            src="/icons/paperclip.svg"
            alt="Logo Acorn"
            width={20}
            height={20}
          />
        </AddPostButtonIconWrapper>
      </PostAreaTextWrapper>
    </GroupPostPanelWrapper>
  );
};

export default PostTextArea;
