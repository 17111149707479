import {
  hasCapitalLetterRegex,
  hasLowerCaseLetterRegex,
  hasNumberRegex,
  languageValidation,
  onlyLettersAndDashRegex,
  onlyLettersRegex,
  specialCharRegex,
} from "common/constants/regex";
import { subYears } from "date-fns";
import * as yup from "yup";

const passwordError = "Wprowadzone hasło nie spełnia wszystkich wymagań.";

const schema = yup.object().shape({
  email: yup
    .string()
    .email(
      "Nieprawidłowy format adresu e-mail. Podaj go według wzoru: nazwa@domena.com"
    )
    .required("Wymagany adres email"),
  password: yup
    .string()
    .required("Wymagane pole")
    .test("minLength", passwordError, (v) => v.length >= 8)
    .test("bigLetter", passwordError, (v) => hasCapitalLetterRegex.test(v))
    .test("smallLetter", passwordError, (v) => hasLowerCaseLetterRegex.test(v))
    .test("digit", passwordError, (v) => hasNumberRegex.test(v))
    .test("specialChar", passwordError, (v) => specialCharRegex.test(v)),
  // ^^^ Test's here generate custom error types. Do not replace them with .matches(...).
  firstName: yup
    .string()
    .required("Imię jest wymagane")
    .matches(onlyLettersRegex, "Imię może zawierać tylko litery"),
  lastName: yup
    .string()
    .required("Nazwisko jest wymagane")
    .matches(
      onlyLettersAndDashRegex,
      "Nazwisko może zawierać tylko litery i myślniki."
    ),
  language: yup.object().shape({
    text: yup
      .string()
      .required("Wybierz język z listy.")
      .matches(
        languageValidation,
        "Wpisany język nie jest dostępny. Wybierz język z listy."
      ),
  }),
  dateOfBirth: yup
    .date()
    .typeError("Wpisana data nie jest poprawna")
    .min(subYears(new Date(), 100), "Naprawdę masz więcej, niż 100 lat?")
    .max(
      subYears(new Date(), 13),
      "Aby zarejestrować się na platformie, musisz mieć ukończone 13 lat. "
    )
    .nullable()
    .required("Data urodzenia jest wymagana"),
});

export default schema;
