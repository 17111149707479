import React, { useMemo } from "react";

import Image from "common/components/Image";
import { theme } from "common/theme/theme";

import { postCreationConfig } from "../../postCreationConfig";

import {
  DeleteFileWrapper,
  FileImage,
  FileItemText,
  PostModalFileWrapper,
} from "./PostModalFile.styled";

interface PostModalFileComponentProps {
  file: File;
  handleFileDeletion: (file: File) => void;
}

const PostModalFileComponent = ({
  file,
  handleFileDeletion,
}: PostModalFileComponentProps) => {
  const isDocx = useMemo(() => {
    return file.type.includes(
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );
  }, [file.type]);
  const image = useMemo(() => {
    if (["jpg", "jpeg", "png"].some((type) => file.type.includes(type))) {
      return URL.createObjectURL(file);
    }
    if (file.type.includes("pdf")) {
      return "/icons/files/pdf.svg";
    }
    if (file.type.includes("application/msword")) {
      return "/icons/files/doc.svg";
    }

    if (isDocx) {
      return "/icons/files/docx.svg";
    }

    if (file.type.includes("text/plain")) {
      return "/icons/files/txt.svg";
    }
  }, [file, isDocx]);
  const fileName = useMemo(() => {
    const extension = isDocx ? "docx" : `${file.type.split("/")[1]}`;

    return file.name.length <= postCreationConfig.maxLengthWithoutTruncate
      ? file.name
      : file.name.slice(0, postCreationConfig.lengthWithTruncation) +
          "..." +
          extension;
  }, [file, isDocx]);
  return (
    <PostModalFileWrapper>
      <FileImage image={image}>
        <DeleteFileWrapper onClick={() => handleFileDeletion(file)}>
          <Image width={7} height={7} src="/icons/close_big.svg" />
        </DeleteFileWrapper>
      </FileImage>
      <FileItemText
        size="14px"
        weight="semibold"
        align="center"
        color={theme.color.basicBlack}
      >
        {fileName}
      </FileItemText>
    </PostModalFileWrapper>
  );
};

export default PostModalFileComponent;
