import React from "react";
import { useNavigate } from "react-router-dom";

import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";

import { Stepper } from "./Stepper";
import { ProfileCreationCongrats } from "./Steps/ProfileCreationCongrats";
import { ProfileCreationEducation } from "./Steps/ProfileCreationEducation";
import { ProfileCreationGreetings } from "./Steps/ProfileCreationGreetings";
import { ProfileCreationInformation } from "./Steps/ProfileCreationInformation";
import { ProfileCreationWork } from "./Steps/ProfileCreationWork";

export const ProfileCreation = () => {
  const steps = ["Wykształcenie", "Praca", "Informacje"];
  const { showModal: showModalCongrats, modalProps: congratsModalProps } =
    useModal();
  const navigate = useNavigate();

  function onCongratsModalClose() {
    navigate("/");
    congratsModalProps.closeModal();
  }

  return (
    <>
      <Stepper steps={steps}>
        <Stepper.Header withInitialStep />
        <Stepper.Step>
          <ProfileCreationGreetings />
        </Stepper.Step>
        <Stepper.Step>
          <ProfileCreationEducation />
        </Stepper.Step>
        <Stepper.Step>
          <ProfileCreationWork />
        </Stepper.Step>
        <Stepper.Step>
          <ProfileCreationInformation onOpenModal={showModalCongrats} />
        </Stepper.Step>
      </Stepper>
      <ModalPortal {...congratsModalProps}>
        <ProfileCreationCongrats onClose={onCongratsModalClose} />
      </ModalPortal>
    </>
  );
};
