import React from "react";

import { ButtonsContainer } from "../AvatarChange/shared/style";
import { BackgroundPreview } from "../Background/BackgroundPreview";
import GhostButton from "../GhostButton/GhostButton";
import SmallButton from "../SmallButton/SmallButton";

import {
  BackgroundChangeContainer,
  BackgroundChangeSubtitle,
  BackgroundChangeTitle,
} from "./BackgroundChange.styled";

type BackgroundEditProps = {
  imageDefault: string;
  backgroundImage: string;
  onEdit: () => void;
  onRemove: () => void;
  subtitle: string;
  title: string;
};

export const BackgroundEdit = ({
  imageDefault,
  backgroundImage,
  onEdit,
  onRemove,
  subtitle,
  title,
}: BackgroundEditProps) => {
  return (
    <BackgroundChangeContainer>
      <BackgroundChangeTitle>{title}</BackgroundChangeTitle>
      <BackgroundChangeSubtitle>{subtitle}</BackgroundChangeSubtitle>
      <BackgroundPreview backgroundImage={backgroundImage || imageDefault} />
      <ButtonsContainer>
        {backgroundImage && (
          <GhostButton onClick={onRemove}>Usuń zdjęcie</GhostButton>
        )}
        <SmallButton onClick={onEdit}>
          {backgroundImage ? "Edytuj zdjęcie" : "Dodaj zdjęcie"}
        </SmallButton>
      </ButtonsContainer>
    </BackgroundChangeContainer>
  );
};
