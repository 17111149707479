import React from "react";

import Flex from "common/components/Flex";
import Spinner from "common/components/Spinner";

export default function MainViewLoader() {
  return (
    <Flex
      direction="row"
      justify="center"
      mt="15px"
      gap="12px"
      p="0 0 15px 0"
      overFlowX="auto"
    >
      <Spinner border="3px" radius="20px" />
    </Flex>
  );
}
