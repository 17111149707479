import { Task } from "./../models/Task";

export function updateAllTaskFields(task: Task, fields: Task): void {
  task.attachments = fields.attachments;
  task.task_title = fields.task_title;
  task.description = fields.description;
  task.completed = fields.completed;
  task.priority = fields.priority;
  task.date = fields.date;
  task.category_ids = fields.category_ids;
  task.list_id = fields.list_id;
  task.reminder_date = fields.reminder_date;
  task.assignee = fields.assignee;
}
