import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { ChangeBackground } from "common/components/ChangeBackground/ChangeBackground";
import { imageBase64Decode } from "common/utils/imageBase64Decode";
import { useGetGroupInformationQuery } from "store/group/groupApi";
import {
  fetchGroupMembers,
  fetchGroupsList,
  groupActions,
} from "store/groupSlice";
import { useAppDispatch } from "store/hooks";
import { store } from "store/store";

import { GroupPageContentWrapper, GroupPageWrapper } from "./Group.styled";
import { groupConfig } from "./groupConfig";
import GroupDiscussion from "./GroupDiscussion/GroupDiscussion";
import GroupHeading from "./GroupHeading/GroupHeading";
import { GroupInformation } from "./GroupInformation/GroupInformation";
import { GroupMembers } from "./GroupMembers/GroupMembers";

const Group = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get("activeTab");
  const [currentLogo, setCurrentLogo] = useState("");
  const [backgroundEdit, setBackgroundEdit] = useState(false);

  const { data: currentGroup } = useGetGroupInformationQuery(
    { groupId: params.id },
    { skip: params.id === null || params.id === undefined }
  );
  useEffect(() => {
    currentGroup?.logo?.data
      ? setCurrentLogo(imageBase64Decode(currentGroup.logo.data))
      : setCurrentLogo(groupConfig.defaultImage);
  }, [currentGroup]);

  useEffect(() => {
    store.dispatch(fetchGroupsList());
  }, []);

  useEffect(() => {
    if (currentGroup) {
      dispatch(groupActions.setChosenGroup(currentGroup));
      dispatch(fetchGroupMembers(currentGroup.groupId));
    }
  }, [currentGroup, dispatch]);

  const turnOnBackgroundEdit = () => {
    setBackgroundEdit(true);
  };

  const turnOffBackgroundEdit = () => {
    setBackgroundEdit(false);
  };

  return (
    <>
      <GroupPageWrapper
        backgroundUrl="/images/background-default-image.svg"
        backgroundEditable={true}
        onBackgroundEdit={turnOnBackgroundEdit}
      >
        <GroupHeading group={currentGroup} currentLogo={currentLogo} />
        <GroupPageContentWrapper>
          {activeTab === "info" ? (
            <GroupInformation information={currentGroup?.information} />
          ) : activeTab === "discussion" ? (
            <GroupDiscussion />
          ) : (
            <GroupMembers />
          )}
        </GroupPageContentWrapper>
      </GroupPageWrapper>
      {backgroundEdit && (
        <ChangeBackground
          currentImageSrc={null}
          fallbackImageSrc="/images/background-default-image.svg"
          // eslint-disable-next-line
          onImageUpload={() => console.log("UPLOAD") as any}
          // eslint-disable-next-line
          onImageChangeConfirm={() => console.log("CONFIRM") as any}
          // eslint-disable-next-line
          onImageRemove={console.log("REMOVE") as any}
          onClose={turnOffBackgroundEdit}
          noImageMessage="Obecnie twoja grupa nie ma zdjęcia w tle."
          currentImageMessage="Zdjęcie w tle twojej grupy wygląda następująco:"
          deleteConfirmationMessage="Czy na pewno chcesz usunąć zdjęcie? Zdjęcie w tle twojej grupy będzie wyglądało tak:"
          previewText="Zdjęcie twojej grupy w tle będzie wyglądało następująco:"
          title="Zdjęcie w tle"
        />
      )}
    </>
  );
};
export default Group;
