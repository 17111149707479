import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import BasicTextArea, {
  TextAreaProps as BasicTextAreaProps,
} from "../TextArea/TextArea";

type Props = { name: Path<FieldValues>; control: Control<any> } & Omit<
  BasicTextAreaProps,
  "value" | "onChange" | "onBlur"
>;

const TextArea = ({ name, control, ...textAreaProps }: Props) => {
  // adapts the TextArea component to a typical interface used with react-hook-forms
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur } }) => (
        <BasicTextArea {...{ value, onChange, onBlur, ...textAreaProps }} />
      )}
    />
  );
};

export default TextArea;
