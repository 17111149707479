import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

const HeaderStyle = styled.header`
  width: 100%;
  height: 72px;
  box-sizing: border-box;
  padding: 15px 0 15px 0;
  place-items: center;
  justify-content: center;
  display: flex;
  background-color: ${theme.color.basicWhite};
  box-shadow: 0 2px 6px rgba(19, 20, 21, 0.1);
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const FrontHeaderStyle = styled.div`
  width: 100%;
  // max-width: ${theme.layout.maxContent};
  padding-right: ${theme.layout.contentHorizontalPadding};
  padding-left: ${theme.layout.contentHorizontalPadding};
  place-items: center;
`;

export { HeaderStyle, FrontHeaderStyle };
