import React, { useEffect, useRef, useState } from "react";

import AttachmentSlider from "common/components/AttachmentSlider/AttachmentSlider";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import useIsVisible from "common/hooks/useIsVisible";
import Post from "components/Post/Post";
import PostAttachments from "components/Post/PostContent/PostContentAttachments/PostContentAttachments";
import { useGetPostByIdQuery } from "store/group/groupApi";
import { IPost } from "store/group/groupApi.types";
import { useGetUserQuery } from "store/user/userApi";

import { PostListItemWrapper } from "./GroupPostListItem.styled";

interface GroupPostListItemProps {
  addPage: () => void;
  groupAvatar: string;
  groupName: string;
  lastPost: boolean;
  post: IPost;
  userAvatar: string;
}

const GroupPostListItem = ({
  addPage,
  groupAvatar,
  groupName,
  lastPost,
  post,
  userAvatar,
}: GroupPostListItemProps) => {
  const [isCommentShown, setCommentShown] = useState(false);
  const { data: author } = useGetUserQuery({ sub: post.author.authorId });
  const targetRef = useRef();
  const { isVisible } = useIsVisible(targetRef);
  const { data: postDetail } = useGetPostByIdQuery(
    {
      groupId: post?.objectId,
      postId: post?.postId,
    },
    { skip: !isVisible }
  );
  const sliderModal = useModal();
  const turnOnModal = () => sliderModal.showModal();

  useEffect(() => {
    if (lastPost && isVisible) addPage();
  }, [isVisible, lastPost, addPage]);

  const toggleCommentVisibility = () => {
    setCommentShown((prevComment) => !prevComment);
  };

  return (
    <>
      {author && (
        <>
          <PostListItemWrapper ref={targetRef}>
            <Post
              postHeading={
                <Post.GroupUserSection
                  firstName={author.firstName}
                  lastName={author.lastName}
                  userAvatar={userAvatar}
                  groupData={{
                    groupAvatar: groupAvatar,
                    groupName: groupName,
                  }}
                  createdDate={post?.created}
                />
              }
              postContent={
                <Post.Content
                  postText={
                    postDetail?.content ? postDetail.content : post?.content
                  }
                />
              }
              postAttachments={
                postDetail?.attachments.length > 0 ? (
                  <PostAttachments
                    attachments={postDetail?.attachments}
                    onClick={turnOnModal}
                  />
                ) : null
              }
              postReactions={
                <Post.ReactionCounter reactionCounterText="Jan Kowalski i 302 tys. osób lubi ten post" />
              }
              postFooter={
                <Post.Reaction
                  toggleComment={toggleCommentVisibility}
                  isCommentsShown={isCommentShown}
                />
              }
              postComment={
                isCommentShown && (
                  <Post.Comment
                    currentPostId={postDetail.postId ?? post.postId}
                  />
                )
              }
            />
          </PostListItemWrapper>

          <ModalPortal
            withoutWhiteBackground={true}
            {...sliderModal.modalProps}
          >
            <AttachmentSlider
              attachments={postDetail?.attachments}
              closeModal={sliderModal.modalProps.closeModal}
            />
          </ModalPortal>
        </>
      )}
    </>
  );
};

export default GroupPostListItem;
