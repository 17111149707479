import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getProjectGroups,
  GroupListMemberResponseDto,
} from "../group/groupApi";

import { getAllTasksByAssigneeRq } from "./getAllTasksByAssigneeRq";
import { ITaskResponse } from "./tasker.responses";

export const initTaskerUserViewAsyncThunk = createAsyncThunk(
  "tasker/userView/fetch",
  async (
    assigneeId: string
  ): Promise<{
    allTasksByGroup: ITaskResponse[];
    allProjectsByGroup: GroupListMemberResponseDto[];
  }> => {
    try {
      const data = await Promise.all([
        getAllTasksByAssigneeRq(assigneeId),
        getProjectGroups(),
      ]);
      const response = {
        allTasksByGroup: data[0],
        allProjectsByGroup: data[1],
      };
      console.log(response);
      if (!data[0]) {
        console.error("getAllTasksByAssigneeRq fetch ERROR");
      }
      if (!data[1]) {
        console.error("getProjectGroups fetch ERROR");
      }
      return response;
    } catch (err) {
      if (err.response) {
        console.error(
          `initTaskerUserViewAsyncThunk for type ${assigneeId} returned with status ${err.response.status}`
        );
      } else if (err.request) {
        console.error(
          "No response from the server while executing initTaskerUserViewAsyncThunk"
        );
      }
    }
  }
);
