import React, { useEffect, useRef, useState } from "react";

import Image from "common/components/Image/Image";

import {
  ImageLabel,
  PostFileInput,
  PostModalTextAreaWrapper,
  PublishButton,
  RightOptionsWrapper,
} from "./PostModalOptionsArea.styled";

interface PostModalOptionsAreaProps {
  handleFileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    clearInput: () => void
  ) => void;
  isCreationError: boolean;
  isValid: boolean;
  publishPost: () => any;
}

const PostModalOptionsArea = ({
  handleFileUpload,
  isCreationError,
  publishPost,
  isValid,
}: PostModalOptionsAreaProps) => {
  const filesInput = useRef<HTMLInputElement | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const handleFileUploadWithClearingFileValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    /**
     * Deletes files from input, files are saved in the state.
     * User can delete the file and reupload the same file.
     */
    handleFileUpload(event, () => {
      filesInput.current.value = "";
    });
  };
  const handlePublishPost = () => {
    setIsClicked(true);
    publishPost();
  };
  useEffect(() => {
    if (isCreationError) {
      setIsClicked(false);
    }
  }, [isCreationError]);
  return (
    <PostModalTextAreaWrapper>
      <Image
        src="/icons/emoji-icon.svg"
        width={20}
        height={20}
        marginLeft="26px"
      />
      <RightOptionsWrapper>
        <Image src="/icons/at-sign.svg" width={20} height={20} />
        <PostFileInput
          ref={filesInput}
          type="file"
          hidden
          multiple
          id="fileInput"
          onChange={(event) => handleFileUploadWithClearingFileValue(event)}
        />
        <ImageLabel htmlFor="fileInput">
          <Image
            cursor="pointer"
            src="/icons/paperclip.svg"
            width={20}
            height={20}
            marginLeft="25px"
          ></Image>
        </ImageLabel>
        <PublishButton
          disabled={!isValid || isClicked}
          onClick={handlePublishPost}
        >
          Opublikuj
        </PublishButton>
      </RightOptionsWrapper>
    </PostModalTextAreaWrapper>
  );
};

export default PostModalOptionsArea;
