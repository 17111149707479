import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { Params, SearchResult } from "./index.types";

export const searchApi = createApi({
  reducerPath: "searchApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      "https://s61osotm8a.execute-api.eu-central-1.amazonaws.com/dev/search-lambda", // todo: replace with new endpoint, when it's shipped, now in order to use it you have to install https://chromewebstore.google.com/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf and enable it
    // prepareHeaders: async (headers) => {
    //   const token = await getJwtToken();
    //   if (token) {
    //     headers.set("Authorization", `Bearer ${token}`);
    //   }
    //   return headers;
    // }, // todo: replace passing sub with token when it's ready
  }),
  endpoints: (builder) => ({
    getSearch: builder.query<SearchResult, Params>({
      query: (params) => `?${new URLSearchParams(params)}`,
    }),
  }),
});

export const { useGetSearchQuery } = searchApi;
