export const createGroupRequestHeader = (userSub: string) => {
  return {
    request_id: Date.now().toString(36) + Math.random().toString(36).slice(-2),
    protocol_version: "ANC0001",
    created: new Date().toISOString(),
    requestor: {
      id: userSub,
      type: "USER",
    },
  };
};
