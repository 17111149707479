import React from "react";

import { GroupStyledPlainButton } from "./GroupPlainButton.styled";

interface GroupPlainButtonProps {
  buttonText: string;
  height: string;
  width: string;
  testId: string;
  isProceedButton?: boolean;
  onClick?: () => void;
}

const GroupPlainButton = ({
  buttonText,
  height,
  isProceedButton,
  onClick,
  width,
  testId,
}: GroupPlainButtonProps) => {
  return (
    <GroupStyledPlainButton
      height={height}
      width={width}
      onClick={onClick}
      isProceedButton={isProceedButton}
      test-id={testId}
    >
      {buttonText}
    </GroupStyledPlainButton>
  );
};

export default GroupPlainButton;
