import { IList } from "store/tasker/models/List";

import { apiRequest } from "../config/axiosConfig";

export interface TaskListCreatePayload {
  group_id: string;
  name: string;
  created_by: string;
}

export async function createTaskList(
  data: TaskListCreatePayload
): Promise<string> {
  try {
    const response = await apiRequest<string, TaskListCreatePayload>(
      "POST",
      "TASKLISTS",
      "/create",
      {
        data,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `CreateTaskList returned with status ${err.response.status}`
      );
    }
  }
}

export async function getTaskLists(groupId: string): Promise<IList[]> {
  try {
    const response = await apiRequest<IList[], string>(
      "GET",
      "TASKLISTS",
      `?groupId=${groupId}`,
      {}
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(`GetTaskLists returned with status ${err.response.status}`);
    }
  }
}
