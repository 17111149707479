import React from "react";

import { IconProps } from "./types";

interface InfoIconProps extends IconProps {}
const InfoIcon = ({ width, height, color }: InfoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 7.5H9.5V5.5H11.5V7.5ZM11.5 15.5H9.5V9.5H11.5V15.5ZM10.5 0.5C9.18678 0.5 7.88642 0.758658 6.67317 1.2612C5.45991 1.76375 4.35752 2.50035 3.42893 3.42893C1.55357 5.3043 0.5 7.84784 0.5 10.5C0.5 13.1522 1.55357 15.6957 3.42893 17.5711C4.35752 18.4997 5.45991 19.2363 6.67317 19.7388C7.88642 20.2413 9.18678 20.5 10.5 20.5C13.1522 20.5 15.6957 19.4464 17.5711 17.5711C19.4464 15.6957 20.5 13.1522 20.5 10.5C20.5 9.18678 20.2413 7.88642 19.7388 6.67317C19.2363 5.45991 18.4997 4.35752 17.5711 3.42893C16.6425 2.50035 15.5401 1.76375 14.3268 1.2612C13.1136 0.758658 11.8132 0.5 10.5 0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
