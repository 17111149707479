import React from "react";

import { IconProps } from "./types";

interface InfoIconProps extends IconProps {}

const LocalizationIcon = ({ color, height, width }: InfoIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9904_370316)">
        <path
          d="M8.00065 1.33398C5.42065 1.33398 3.33398 3.42065 3.33398 6.00065C3.33398 9.50065 8.00065 14.6673 8.00065 14.6673C8.00065 14.6673 12.6673 9.50065 12.6673 6.00065C12.6673 3.42065 10.5807 1.33398 8.00065 1.33398ZM4.66732 6.00065C4.66732 4.16065 6.16065 2.66732 8.00065 2.66732C9.84065 2.66732 11.334 4.16065 11.334 6.00065C11.334 7.92065 9.41398 10.794 8.00065 12.5873C6.61398 10.8073 4.66732 7.90065 4.66732 6.00065Z"
          fill="#767676"
        />
        <path
          d="M8.00065 7.66732C8.92113 7.66732 9.66732 6.92113 9.66732 6.00065C9.66732 5.08018 8.92113 4.33398 8.00065 4.33398C7.08018 4.33398 6.33398 5.08018 6.33398 6.00065C6.33398 6.92113 7.08018 7.66732 8.00065 7.66732Z"
          fill="#767676"
        />
      </g>
      <defs>
        <clipPath id="clip0_9904_370316">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocalizationIcon;
