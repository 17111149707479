import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none; // hide scroll bar in Firefox
    &::-webkit-scrollbar {
      display: none; // hide scroll bar in Chrome, Edge, and Safari
    }
  }

  body {
    font-family: 'Inter', 'Inter', sans-serif;     
    width: 100%;
    min-height: 100vh;
  }

  @font-face {
    font-family: 'Inter';
    src: url("/fonts/Inter.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Inter';
    src: url("/fonts/Inter.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    src: url("/fonts/Inter.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url("/fonts/OpenSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url("/fonts/OpenSans-Semibold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url("/fonts/OpenSans-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

`;

export default GlobalStyle;
