import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { initTaskerUserViewAsyncThunk } from "services/api/tasker";
import { getSub } from "store/auth/authUtils";
import { useGetGroupInformationQuery } from "store/group/groupApi";
import { fetchGroupMembers, groupActions } from "store/groupSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const useInitUserView = () => {
  const userId = getSub();
  const dispatch = useAppDispatch();
  const taskerSelector = useAppSelector((s) => s.tasker);
  const params = useParams();
  const { data: currentGroup } = useGetGroupInformationQuery(
    { groupId: params.id },
    { skip: params.id === null || params.id === undefined }
  );
  // const { groupId } = useCurrentGroupId();
  const initTaskerUserView = useCallback(() => {
    if (!taskerSelector.userViewTree.loaded)
      dispatch(initTaskerUserViewAsyncThunk(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId, taskerSelector.userViewTree.loaded]);

  useEffect(() => {
    if (currentGroup) {
      dispatch(groupActions.setChosenGroup(currentGroup));
      dispatch(fetchGroupMembers(currentGroup.groupId));
    }
  }, [currentGroup, dispatch]);

  useEffect(() => {
    if (taskerSelector.MainView === "Group") {
      if (!taskerSelector.userViewTree.loaded) {
        initTaskerUserView();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTaskerUserView, taskerSelector.MainView]);
};
