import * as React from "react";

import { Data } from "services/api/search/index.types";

import { SearchItemCardStyled } from "./SearchItemCard.styled";

export function SearchItemCard(props: Readonly<Omit<Data, "description">>) {
  return (
    <SearchItemCardStyled>
      <img src={props.imageUrl} alt={props.title} />
      <div className="text-sm">
        <h3>{props.title}</h3>
      </div>
    </SearchItemCardStyled>
  );
}
