import { theme } from "common/theme/theme";
import styled from "styled-components";

import { AbsLabel, Label } from "./Label";

interface ValidationLabelProps {
  isInvalid: boolean;
  maxWidth?: string;
}

export const ValidationLabel = styled(Label)<ValidationLabelProps>`
  color: ${({ isInvalid }) =>
    isInvalid ? theme.color.alertRed : theme.color.basicWhite};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
`;

export const AbsValidationLabel = styled(AbsLabel)<ValidationLabelProps>`
  color: ${({ isInvalid }) =>
    isInvalid ? theme.color.alertRed : theme.color.basicBlack};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
`;
