/* eslint-disable */
import React from "react";
import { useFormContext, useFormState, useWatch } from "react-hook-form";

import ConfirmButton from "common/components/ConfirmButton";
import PasswordHints from "common/components/PasswordHints/PasswordHints";
import UserCredentialsArea from "common/components/UserCredentialsArea";

import { useRegistrationStepContext } from "../context";
import { IRegistrationData } from "../types";

import { ButtonWrapper, FormWrapper } from "./RegisterFormBasic.styled";

const RegisterFormBasic = () => {
  const { register } = useFormContext<IRegistrationData>();
  const { errors, touchedFields } = useFormState<IRegistrationData>();
  const email = useWatch<IRegistrationData>({ name: "email" });
  const password = useWatch<IRegistrationData>({ name: "password" });
  const { setCurrentStep } = useRegistrationStepContext();

  const passwordErrors = {
    smallLetter:
      errors.password !== undefined && !errors.password?.types?.smallLetter,
    bigLetter:
      errors.password !== undefined && !errors.password?.types?.bigLetter,
    digit: errors.password !== undefined && !errors.password?.types?.digit,
    specialChar:
      errors.password !== undefined && !errors.password?.types?.specialChar,
    minLength:
      errors.password !== undefined && !errors.password?.types?.minLength,
  };

  async function handleNext(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setCurrentStep("details");
  }
  return (
    <FormWrapper>
      <UserCredentialsArea
        register={register}
        emailErrors={
          errors.email?.message
        }
        passwordErrors={errors.password?.message as string}
        passwordTouched={touchedFields.password}
        emailTouched={touchedFields.email}
      />
      <PasswordHints errorDetails={passwordErrors} />
      <ButtonWrapper>
        <ConfirmButton
          disabled={Boolean(
            !email || errors?.email || !password || errors?.password
          )}
          onClick={handleNext}
        >
          Dalej
        </ConfirmButton>
      </ButtonWrapper>
    </FormWrapper>
  );
};

export default RegisterFormBasic;
