import { Buffer } from "buffer";
import {
  CategoryRequestDto,
  NewNoteRequestDto,
  UpdatedCategorytDto,
  UpdateNoteRequestDto,
} from "components/NotesPage/Types/notes";

import { apiRequest } from "../config/axiosConfig";

export interface NoteResponseDto {
  id: string;
  owner: string;
  created: string;
  last_update_date: string;
  status: NoteStatusDto;
  title: string;
  note_contents: string;
  category: string;
}

export interface CategoryResponseDto {
  category_name: string;
  created: string;
  id: string;
  owner: string;
  parent?: string;
}

export enum NoteStatusDto {
  OPEN = "OPEN",
  DELETED = "DELETED",
}

export async function getNotesList(userId: string): Promise<NoteResponseDto[]> {
  try {
    const response = await apiRequest<any, NoteResponseDto[]>(
      "GET",
      "NOTE",
      `?owner_id=${userId}`
    );
    response.data.forEach((note) => {
      note.note_contents = Buffer.from(note.note_contents, "base64").toString();
    });
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(`GetNotesList returned with status ${err.response.status}`);
    }
    throw err;
  }
}

export async function createNote(
  newNoteData: NewNoteRequestDto
): Promise<string> {
  try {
    const response = await apiRequest<string, NewNoteRequestDto>(
      "POST",
      "NOTE",
      "/create",
      {
        data: newNoteData,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(`CreateNote returned with status ${err.response.status}`);
    }
  }
}

export async function updateNote(
  noteData: UpdateNoteRequestDto
): Promise<string> {
  try {
    const response = await apiRequest<string, UpdateNoteRequestDto>(
      "PUT",
      "NOTE",
      "/update",
      {
        data: noteData,
      }
    );
    console.log(response);
    return response.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
    if (err.response) {
      console.error(`UpdateNote returned with status ${err.response.status}`);
      console.error(`UpdateNote returned with status ${err.response.data}`);
    }
  }
}

export async function getCategoriesList(
  userId: string
): Promise<CategoryResponseDto[]> {
  try {
    const response = await apiRequest<CategoryResponseDto[]>(
      "GET",
      "NOTECATEGORIES",
      `?ownerId=${userId}`
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `GetCategoriesList returned with status ${err.response.status}`
      );
    }
    throw err;
  }
}

export async function deleteNote(userId: string, noteId: string): Promise<any> {
  try {
    const response = await apiRequest(
      "DELETE",
      "NOTE",
      `/delete?owner_id=${userId}&id=${noteId}`,
      {}
    );
    return response;
  } catch (err) {
    if (err.response) {
      console.error(`DeleteNote returned with status ${err.response.status}`);
    }
    throw err;
  }
}

export async function createCategory(
  subcategoryData: CategoryRequestDto
): Promise<string> {
  try {
    const response = await apiRequest<string, CategoryRequestDto>(
      "POST",
      "NOTECATEGORIES",
      "/create",
      {
        data: subcategoryData,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `CreateCategory returned with status ${err.response.status}`
      );
    }
  }
}

export async function editCategory(
  editCategoryData: UpdatedCategorytDto
): Promise<any> {
  try {
    console.log("Sending request to edit category:", editCategoryData);
    const response = await apiRequest<string, UpdatedCategorytDto>(
      "PUT",
      "NOTECATEGORIES",
      `/update`,
      {
        data: editCategoryData,
      }
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(`EditCategory returned with status ${err.response.status}`);
    }
    throw err;
  }
}

// Generic delete function
async function deleteItem(
  ownerId: string,
  itemId: string,
  endpoint: string
): Promise<any> {
  try {
    const response = await apiRequest(
      "DELETE",
      "NOTECATEGORIES",
      `${endpoint}?id=${itemId}&ownerId=${ownerId}`,
      {}
    );
    return response;
  } catch (err) {
    if (err.response) {
      console.error(
        `DeleteItem returned with status ${err.response.status}: ${err.response.data}`
      );
    } else {
      console.error("Error deleting item", err);
    }
    throw err;
  }
}

export async function deleteCategory(
  ownerId: string,
  categoryId: string
): Promise<any> {
  return deleteItem(ownerId, categoryId, "/delete");
}

export async function deleteSubCategory(
  ownerId: string,
  key: string
): Promise<any> {
  return deleteItem(ownerId, key, "/delete");
}

export async function createSubcategory(
  subcategoryData: CategoryRequestDto
): Promise<string> {
  try {
    const response = await apiRequest<string, CategoryRequestDto>(
      "POST",
      "NOTECATEGORIES",
      "create",
      {
        data: subcategoryData,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `CreateSubcategory returned with status ${err.response.status}`
      );
    }
  }
}
