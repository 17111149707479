import React from "react";

import Image from "common/components/Image";
import styled from "styled-components";

export const IconButtonWrapper = styled.a<{
  width: number;
  height: number;
  radius?: string;
  backgroundColor: string;
  marginLeft?: string;
  marginRight?: string;
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.radius ?? "0%"};
  border-color: transparent;
  background-color: ${(props) => props.backgroundColor ?? "transparent"};
  overflow: hidden;
  cursor: pointer;
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
`;

type IconButtonProps = {
  width: number;
  height: number;
  imageWidth?: number;
  imageHeight?: number;
  radius?: string;
  imageRadius?: string;
  icon: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  label?: string;
  position?: string;
  marginLeft?: string;
  marginRight?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

const IconButton = ({
  width,
  height,
  imageHeight,
  imageWidth,
  icon,
  backgroundColor,
  onClick,
  onMouseOver,
  onMouseLeave,
  marginLeft,
  marginRight,
  radius,
  imageRadius,
  className,
  label,
  position,
  top,
  bottom,
  left,
  right,
}: IconButtonProps) => {
  return (
    <IconButtonWrapper
      width={width}
      height={height}
      radius={radius}
      backgroundColor={backgroundColor}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={className}
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Image
        src={icon}
        aria-label={label}
        height={imageHeight || height}
        width={imageWidth || width}
        radius={imageRadius || radius}
      />
    </IconButtonWrapper>
  );
};

export default IconButton;
