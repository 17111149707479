import React, { useMemo } from "react";

import Button from "common/components/Button";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { IconPlus } from "common/icons/IconPlus";
import { useGetProfileQuery, useGetUserQuery } from "store/user/userApi";

import {
  Header,
  RootContainer,
  Separator,
  Title,
} from "../DetailsGroup/DetailsGroup.styled";
import {
  EmptyBox,
  FormBoxContent,
  FormBoxTitle,
} from "../Shared/FormBox.styled";
import { FormBoxContentDate } from "../Shared/FormBoxContentDate";

import { ModalDeleteEducation } from "./ModalDeleteEducation";
import { ModalEducation } from "./ModalEducation";

export const Education = () => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });
  const { data: profile } = useGetProfileQuery(
    { sub, language: user?.language },
    { skip: !sub || !user?.language }
  );

  const { showModal: openEditModal, modalProps: editModalProps } = useModal();
  const { showModal: openDeleteModal, modalProps: deleteModalProps } =
    useModal();

  const dropdownMenuOptions = useMemo(
    () => [
      {
        img: "/icons/edit_icon.svg",
        text: "Edytuj",
        action: () => openEditModal(),
        testid: "edit",
      },
      {
        img: "/icons/delete_icon.svg",
        text: "Usuń",
        action: () => openDeleteModal(),
        testid: "delete",
      },
    ],
    [openEditModal, openDeleteModal]
  );

  return (
    <>
      <RootContainer>
        <Header>
          <Title>Wykształcenie</Title>
          {Boolean(profile?.schoolName?.length) && (
            <MakeActionDropdown dropdownMenuOptions={dropdownMenuOptions} />
          )}
        </Header>
        <Separator />
        {profile?.schoolName?.length ? (
          <FormBoxContent>
            <FormBoxTitle>{profile.schoolName}</FormBoxTitle>
            <FormBoxContentDate
              started={profile.educationStarted}
              finished={profile.educationFinished}
            />
          </FormBoxContent>
        ) : (
          <EmptyBox>
            <Text>Nie masz wpisanego jeszcze żadnego wykształcenia</Text>
            <Button onClick={openEditModal}>
              <IconPlus />
              Dodaj wykształcenie
            </Button>
          </EmptyBox>
        )}
      </RootContainer>
      <ModalPortal {...editModalProps}>
        <ModalEducation
          onClose={editModalProps.closeModal}
          titlePrefix={profile?.schoolName?.length ? "Edytuj" : "Dodaj"}
        />
      </ModalPortal>
      <ModalPortal {...deleteModalProps}>
        <ModalDeleteEducation onClose={deleteModalProps.closeModal} />
      </ModalPortal>
    </>
  );
};
