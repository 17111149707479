import React from "react";

import Flex from "common/components/Flex";
import { Container } from "common/components/Layout/Containers";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { taskerAction } from "store/tasker/taskerSlice";

import CreateTaskButton from "../utils/CreateTaskButton";
import TaskerSwitchView from "../utils/TaskerSwitchView";

import TaskerDone from "./TaskerDone/TaskerDone";
import TaskerTodo from "./TaskerTodo/TaskerTodo";
import { useInitGroupView } from "./useInitGroupView";

export default function GroupView() {
  const dispatch = useAppDispatch();
  const taskerSlice = useAppSelector((state) => state.tasker);

  useInitGroupView();

  const handleOpenNewTask = () => {
    dispatch(taskerAction.setOpenNewTaskView());
  };

  const handleSetTodoTaskerView = () => {
    dispatch(taskerAction.setTodoTaskerView());
  };

  const handleSetDoneTaskerView = () => {
    dispatch(taskerAction.setDoneTaskerView());
  };

  return (
    <Container w="90%" p="40px" bgColor={theme.color.backgroundGray}>
      <Flex justify="space-between" width="100%" p="0 0 16px 0">
        <Text size="24px" weight="bold" color={theme.color.basicBlack}>
          Tasker
        </Text>
        <CreateTaskButton handleOpenNewTask={handleOpenNewTask} />
      </Flex>
      <TaskerSwitchView
        handleSetTodoTaskerView={handleSetTodoTaskerView}
        handleSetDoneTaskerView={handleSetDoneTaskerView}
      />
      {taskerSlice.taskerView.BoardView === "Todo" && <TaskerTodo />}
      {taskerSlice.taskerView.BoardView === "Done" && <TaskerDone />}
    </Container>
  );
}
