import Flex from "common/components/Flex";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const FormSelectScrollbarWrapper = styled.div<{
  scrollBarPadding?: string;
}>`
  height: 100%;
  max-height: 150px;
  overflow-y: scroll;
  padding: ${(props) => props.scrollBarPadding};
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 89px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;

export const FlexButtonCreateGroup = styled(Flex)`
  height: 48px;
  background-color: white;
  cursor: pointer;
`;
