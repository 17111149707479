import Button from "common/components/Button";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const StyledSmallButton = styled(Button)`
  height: 30px;
  font-size: 14px;
  padding: 8px 12px;
  width: fit-content;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  background-color: ${theme.color.veryDarkGray};
`;
