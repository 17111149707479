import React, { useCallback, useEffect, useState } from "react";

import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { notesAction } from "store/notes/notesSlice";

import {
  CodeElement,
  CustomEditor,
  DefaultElement,
  initialValue,
  Leaf,
} from "./EditorUtils";

interface TextEditorProps {
  setCurrentText: (text: string) => void;
}

export default function TextEditor({ setCurrentText }: TextEditorProps) {
  const dispatch = useAppDispatch();

  const notesSlice = useAppSelector((state) => state.note);
  const [editor] = useState(() => withReact(createEditor()));
  const [initContent, setInitContent] = useState<any>(null);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  useEffect(() => {
    if (notesSlice.noteToEdit) {
      setInitContent(JSON.parse(notesSlice.noteToEdit.noteContents));
    } else {
      setInitContent(initialValue);
    }
  }, [notesSlice.noteToEdit]);

  useEffect(() => {
    if (notesSlice.toolboxAction === "bold") {
      CustomEditor.toggleBoldMark(editor);
    }
    dispatch(notesAction.setToolboxAction(null));
  }, [notesSlice.toolboxAction, dispatch, editor]);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "code":
        return <CodeElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "b" && event.ctrlKey) {
      event.preventDefault();
      CustomEditor.toggleBoldMark(editor);
    }
  };

  const handleOnChange = (text: any) => {
    setCurrentText(JSON.stringify(text));
  };

  return (
    <>
      {initContent && (
        <Slate editor={editor} value={initContent} onChange={handleOnChange}>
          <div
            style={{
              padding: "0 15px 0 15px",
            }}
          ></div>
          <Editable
            style={{
              marginTop: "35px",
              width: "606px",
              height: "330px",
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "0 15px 0 15px",
            }}
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            onKeyDown={onKeyDown}
          />
        </Slate>
      )}
    </>
  );
}
