import React, { FC } from "react";

import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { Progress, ProgressWrapper } from "./ProgressBar.styled";
interface ProgressProps {
  value: number;
  mt?: string;
  mb?: string;
  width?: string;
}

const defaultProps: ProgressProps = {
  value: 0,
  mt: "0",
  mb: "0",
  width: "362px",
};

const ProgressBar: FC<ProgressProps> = (props) => {
  return (
    <ProgressWrapper mt={props.mt} mb={props.mb} width={props.width}>
      <Progress id="file" value={props.value} max={100}></Progress>
      <Text
        size="16px"
        color={theme.color.darkCyan}
        font="secondary"
        weight="semibold"
        mt="8px"
      >
        {props.value} %
      </Text>
    </ProgressWrapper>
  );
};

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
