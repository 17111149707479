import React, { useEffect, useState } from "react";

import {
  Input,
  PlaceholderLabel,
  ValidationLabel,
} from "common/components/InputField";

import Flex from "../Flex";

import { FormInputProps } from "./Types";

function FormInput({
  errorText,
  id,
  label,
  mt,
  mb,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  width,
}: FormInputProps) {
  const [clicked, setClicked] = useState<boolean>(false);
  const [error, setError] = useState<string>(errorText);
  function onClick(): void {
    if (clicked === false) setClicked(true);
  }
  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  return (
    <Flex direction="column" mt={mt} mb={mb}>
      <Input
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        onClick={onClick}
        isValid={!error}
        clicked={clicked}
        id={id}
        type={type}
        width={width}
        placeholder={clicked ? placeholder : label}
      />
      <PlaceholderLabel isValid={!error} htmlFor={id}>
        {label}
      </PlaceholderLabel>
      {errorText && <ValidationLabel isValid={!error}>{error}</ValidationLabel>}
    </Flex>
  );
}

export default FormInput;
