import React from "react";

import Avatar from "common/components/Avatar/Avatar";
import GhostButton from "common/components/GhostButton/GhostButton";

import SmallButton from "../SmallButton/SmallButton";

import {
  ButtonsContainer,
  Container,
  Subtitle,
  Title,
  UploadSectionWrapper,
} from "./shared/style";

type ImageEditProps = {
  height?: string;
  imageDefault: string;
  imageSrc: string;
  onEdit: () => void;
  onRemove: () => void;
  subtitle: string;
  title: string;
  width?: string;
};

const AvatarEdit = ({
  height,
  imageDefault,
  imageSrc,
  onEdit,
  onRemove,
  subtitle,
  title,
  width,
}: ImageEditProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <UploadSectionWrapper>
        <Avatar
          editable={false}
          height={height}
          imageSrc={imageSrc || imageDefault}
          width={width}
        />
        <ButtonsContainer>
          {imageSrc && (
            <GhostButton onClick={onRemove}>Usuń zdjęcie</GhostButton>
          )}
          <SmallButton onClick={onEdit}>
            {imageSrc ? "Edytuj zdjęcie" : "Dodaj zdjęcie"}
          </SmallButton>
        </ButtonsContainer>
      </UploadSectionWrapper>
    </Container>
  );
};

export default AvatarEdit;
