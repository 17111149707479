import React from "react";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import ConfirmButton from "common/components/ConfirmButton";
import DatePickerHookForms from "common/components/DatePickerHookForms";
import FormInput from "common/components/FormInputHookForms";
import FormSelect from "common/components/FormSelect/FormSelect";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import RadioButtonGroup from "common/components/RadioButtonGroupHookForms";
import Text from "common/components/Text";
import { Paths } from "common/constants";
import { Gender } from "common/types/Gender";
import { Language } from "common/types/Language";
import { RegistrationFinishedModal } from "components/Authentication/RegistrationFinishedModal";
import { subYears } from "date-fns";
import { createUser } from "services/api/user";
import { toast } from "sonner";

import {
  ButtonWrapper,
  FormWrapper,
} from "../RegisterFormBasic/RegisterFormBasic.styled";
import { IRegistrationData } from "../types";

import { RegulationsWrapper } from "./RegisterFormDetails.styled";

const RegisterFormDetails = () => {
  const finishedModal = useModal();
  const navigate = useNavigate();

  function handleModalClose() {
    finishedModal.modalProps.closeModal();
    navigate(Paths.LOGIN);
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid, touchedFields },
  } = useFormContext<IRegistrationData>();
  const chosenGender = useWatch<IRegistrationData>({ name: "gender" });
  const onSubmit: SubmitHandler<IRegistrationData> = async (data) => {
    try {
      await createUser({
        cognitoDto: { email: data.email, password: data.password },
        firstName: data.firstName,
        lastName: data.lastName,
        gender: data.gender,
        language: data.language.value,
        dateOfBirth: data.dateOfBirth,
      });
      finishedModal.showModal();
    } catch (error) {
      toast.error("Rejestracja nie powiodła się. Spróbuj ponownie później", {
        description: `(${error})`,
        style: {
          color: "red",
        },
      });
    }
  };

  return (
    <>
      <FormWrapper>
        <FormInput
          id="firstName"
          placeholder="Wpisz swoje imię"
          label="Imię"
          type="text"
          isValid={!errors.firstName}
          register={register}
          touched={touchedFields.firstName}
          inputName="firstName"
          errorText={errors.firstName?.message as string}
          mb="35px"
        />
        <FormInput
          id="lastName"
          placeholder="Wpisz swoje nazwisko"
          label="Nazwisko"
          type="text"
          isValid={!errors.lastName}
          touched={touchedFields.lastName}
          register={register}
          inputName="lastName"
          errorText={errors.lastName?.message as string}
          mb="20px"
        />
        <RadioButtonGroup
          setValue={setValue}
          inputName="gender"
          selected={chosenGender as Gender}
          id="gender"
          label="Płeć"
          options={[
            { value: Gender.Female, text: "Kobieta" },
            { value: Gender.Male, text: "Mężczyzna" },
            { value: Gender.Unknown, text: "Inne" },
          ]}
          mb="20px"
        />
        <DatePickerHookForms
          name="dateOfBirth"
          control={control}
          id="dateOfBirth"
          isValid={!errors.dateOfBirth}
          errorText={errors.dateOfBirth?.message}
          minDate={subYears(new Date(), 100)}
          maxDate={new Date()}
          defaultActiveStartDate={subYears(new Date(), 18)}
          label="Data urodzenia"
          mb="40px"
          mt="20px"
        />
        <FormSelect
          id="language"
          options={[
            { value: Language.Polish, text: "polski" },
            { value: Language.English, text: "angielski" },
            { value: Language.German, text: "niemiecki" },
          ]}
          label="Język"
          control={control}
          type="text"
          isValid={!errors.language?.text}
          errorText={errors.language?.text?.message}
          mb="40px"
          mt="20px"
        />
        <ButtonWrapper>
          <ConfirmButton
            type="submit"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Zarejestruj się
          </ConfirmButton>
        </ButtonWrapper>
        <RegulationsWrapper mt="30px">
          <Text font="secondary" size="15px">
            Rejestrując się do Acorn akceptujesz Regulamin w aktualnym brzmieniu
            obowiązującym od dnia 19.07.2021. Informacje o planowanych oraz
            archiwalnych zmianach Regulaminu są dostępne w&nbsp;
            <Link to="/">Polityce i Warunkach.</Link>
          </Text>
        </RegulationsWrapper>
      </FormWrapper>
      <ModalPortal {...finishedModal.modalProps}>
        <RegistrationFinishedModal onClose={handleModalClose} />
      </ModalPortal>
    </>
  );
};

export default RegisterFormDetails;
