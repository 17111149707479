import { apiRequest } from "../config/axiosConfig";

import { ITaskResponse } from "./tasker.responses";

export async function getAllTasksByAssigneeRq(
  groupId: string
): Promise<ITaskResponse[]> {
  try {
    const response = await apiRequest<ITaskResponse[], string>(
      "GET",
      "TASK",
      `/getByAssignee?assigneeId=${groupId}`
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `getAllTasksByAssigneeRq returned with status ${err.response.status}`
      );
    }
  }
}
