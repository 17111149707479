import React, { ChangeEvent, useState } from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import GroupPlainButton from "../Common/GroupPlainButton/GroupPlainButton";
import { groupConfig } from "../groupConfig";

import {
  Counter,
  EditDescriptionTextAreaWrapper,
  EditGroupDescriptionTextArea,
  GroupDescriptionButtons,
  GroupDescriptionEditHeader,
  GroupDescriptionEditWrapper,
  ValidationMessageWrapper,
} from "./GroupDescriptionEdit.styled";

interface GroupDescriptionEditProps {
  closeModal: () => void;
  description?: string;
}

const MAX_ROWS_COUNT = 8;

const GroupDescriptionEdit = ({
  closeModal,
  description,
}: GroupDescriptionEditProps) => {
  const [textAreaRows, setTextAreaRows] = useState(2);
  const [descriptionContent, setDescriptionContent] = useState(
    description ?? ""
  );
  const [isValid, setIsValid] = useState(true);

  const onInput = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (
      e.target.scrollHeight > e.target.clientHeight &&
      textAreaRows < MAX_ROWS_COUNT
    ) {
      setTextAreaRows((prevRows) => ++prevRows);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= groupConfig.descriptionLength) {
      setDescriptionContent(event.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onBlurHandler = () => {
    setIsValid(true);
  };

  return (
    <GroupDescriptionEditWrapper>
      <GroupDescriptionEditHeader weight="bold" size="20px">
        Edytuj opis grupy
      </GroupDescriptionEditHeader>
      <EditDescriptionTextAreaWrapper>
        <EditGroupDescriptionTextArea
          aria-placeholder="Napisz coś o ciekawego o Twojej grupie, możesz użyć 150 znaków."
          isValid={isValid}
          onInput={onInput}
          rows={textAreaRows}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          value={descriptionContent}
          placeholder={
            description ??
            "Napisz coś o ciekawego o Twojej grupie, możesz użyć 150 znaków."
          }
        />
        <Counter align="right" isValid={isValid}>
          {descriptionContent?.length ?? 0}/{groupConfig.descriptionLength}
        </Counter>
      </EditDescriptionTextAreaWrapper>
      {!isValid && (
        <ValidationMessageWrapper>
          <Image src="/icons/redErrorSign.svg" />
          <Text size="12px" ml="10px" color={theme.color.alertRed}>
            Opis może mieć maksymalnie 150 znaków
          </Text>
        </ValidationMessageWrapper>
      )}
      <GroupDescriptionButtons>
        <GroupPlainButton
          width="72px"
          height="30px"
          buttonText="Anuluj"
          onClick={closeModal}
          isProceedButton={false}
          testId="cancel-desc-edit"
        />
        <GroupPlainButton
          width="72px"
          height="30px"
          buttonText="Zapisz"
          // eslint-disable-next-line
          onClick={() => console.log("SAVE")}
          isProceedButton={true}
          testId="save-desc-btn"
        />
      </GroupDescriptionButtons>
    </GroupDescriptionEditWrapper>
  );
};

export default GroupDescriptionEdit;
