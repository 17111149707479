import React from "react";
import { useNavigate } from "react-router-dom";

import Flex from "common/components/Flex";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { useAppDispatch } from "store/hooks";
import { notesAction } from "store/notes/notesSlice";

import { EmptyNotePage } from "../NotePage.styled";

import CreateNoteButton from "./Header/NoteHeader/CreateNoteButton";
export default function NoNotesInfo() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <EmptyNotePage>
      <Text
        size="24px"
        weight="bold"
        lineHeight="36px"
        letterSpacing="0.4px"
        color={theme.color.basicBlack}
      >
        Dodaj swoją pierwszą notatkę
      </Text>
      <Flex direction="column" align="center" gap="24px">
        <Text size="14px" lineHeight="20px" letterSpacing="0.2px">
          Utwórz nową notatkę, aby rozpocząć.
        </Text>
        <CreateNoteButton
          setNewNote={() => {
            dispatch(notesAction.openEditNote(null));
            navigate("/create-note");
          }}
        />
      </Flex>
    </EmptyNotePage>
  );
}
