import { theme } from "common/theme/theme";
import styled from "styled-components";

export const CommentCreationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const CommentInput = styled.input``;

export const CommentAreaBox = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  border-color: #eceeef;
  border-style: solid;
  background-color: ${theme.color.basicWhite};
  cursor: pointer;
`;

export const CommentAreaWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  border-color: #eceeef;
  border-style: solid;
  background-color: ${theme.color.basicWhite};
  cursor: pointer;
  width: 569px;
  height: 41px;
  justify-content: end;
`;

export const CommentIconsArea = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 24px;
`;

export const CommentSmallIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12.67px;
`;
