import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const RadioContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const RadioOuterCircle = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${theme.color.basicBlack};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
`;

export const RadioInnerCircle = styled.div<{ selected: boolean }>`
  width: ${({ selected }) => (selected ? "5px" : "0px")};
  height: ${({ selected }) => (selected ? "5px" : "0px")};
  border-radius: 50%;
  background-color: ${theme.color.darkGray};
  transition: all 0.1s linear;
`;

export const HelperText = styled.div<{ selected: boolean }>`
  color: ${theme.color.basicBlack};
  margin-top: 2px;
  padding-right: 32px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`;
