import React, { FocusEvent, useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";

import IconButton from "common/components/IconButton";
import { AbsLabel } from "common/components/Layout/Form/Label";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import { ScrollbarWrapper } from "common/components/Layout/ScrollbarWrapper";
import Text from "common/components/Text";

import { DropdownInputValue } from "../../../common/components/Forms/Dropdown/DropdownInputValue";
import { DropdownItem } from "../../../common/components/Forms/Dropdown/DropdownItem";
import { DropdownItemsWrapper } from "../../../common/components/Forms/Dropdown/DropdownItemsWrapper";

import { ITaskInForm } from "./NewTaskView";

export interface ReminderOption {
  value: ReminderOptionName;
  text: string;
}

const fieldName: keyof ITaskInForm = "reminder_date";

export enum ReminderOptionName {
  NoRemind = "NoRemind",
  DayBefore = "dayBefore",
}

export const reminderOptions: ReminderOption[] = [
  { value: ReminderOptionName.NoRemind, text: "Brak przypomienia" },
  { value: ReminderOptionName.DayBefore, text: "1 dzien wczesniej" },
];

export interface FormSelectProps {
  control: Control<ITaskInForm>;
  register: UseFormRegister<ITaskInForm>;
}

function ReminderSelect({ control, register }: FormSelectProps) {
  const [isClicked, setIsClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOnMouse, setIsOnMouse] = useState(false);

  function onClick(): void {
    if (!isClicked) setIsClicked(true);
    setIsActive(true);
  }

  const chooseItem = (item: ReminderOption, onChange) => {
    onChange(item.value);
    setIsActive(false);
  };

  const handleBlur = (event: FocusEvent, onBlur) => {
    setIsFocus(false);
    isOnMouse || setIsActive(false);
    onBlur && onBlur(event);
  };

  const handleOnMouseLeave = () => {
    setIsOnMouse(false);
    isFocus || setIsActive(false);
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      {...register(fieldName)}
      render={({ field: { onChange, onBlur, value } }) => (
        <RelativeContainer m="20px 0 0 0">
          <DropdownInputValue
            id={fieldName}
            name={fieldName}
            type="text"
            onClick={onClick}
            value={reminderOptions.find((r) => r.value === value).text}
            isClicked={isClicked}
            isValid
            isActive={isActive}
            onBlur={(e) => handleBlur(e, onBlur)}
            readOnly
            onFocus={() => {
              setIsFocus(true);
            }}
          />
          <IconButton
            width={12}
            height={21}
            icon="/icons/arrowDown.svg"
            position="absolute"
            right="15px"
            bottom="11px"
            onClick={onClick}
          />
          {isActive && (
            <DropdownItemsWrapper>
              <ScrollbarWrapper
                padding="0px 8px 0px 0px"
                onMouseOver={() => setIsOnMouse(true)}
                onMouseLeave={handleOnMouseLeave}
              >
                {reminderOptions.map((el) => (
                  <DropdownItem
                    key={el.value}
                    onClick={() => chooseItem(el, onChange)}
                    data-value={el}
                  >
                    {el.text}
                  </DropdownItem>
                ))}
              </ScrollbarWrapper>
            </DropdownItemsWrapper>
          )}
          <AbsLabel top="-22px" left="5px">
            <Text
              size="14px"
              weight="normal"
              lineHeight="20px"
              letterSpacing="0.2px"
            >
              Przypomnienie
            </Text>
          </AbsLabel>
        </RelativeContainer>
      )}
    />
  );
}

export default ReminderSelect;
