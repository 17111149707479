import React from "react";

import { useAppSelector } from "store/hooks";

import { ProfileMenu } from "./ProfileMenu/ProfileMenu";
import { Search } from "./Search";
import { TopMenuButton, TopMenuPanelBox } from "./TopMenuPanel.styled";

export const TopMenuPanel = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  if (!isAuthenticated) return;
  return (
    <TopMenuPanelBox>
      <Search />
      <TopMenuButton onClick={() => {}}>
        <img src="/icons/plus.svg" alt="Ikona przycisku dodawania" />
      </TopMenuButton>
      <TopMenuButton onClick={() => {}}>
        <img src="/icons/bell_icon.svg" alt="Ikona przycisku powiadomień" />
      </TopMenuButton>
      <ProfileMenu />
    </TopMenuPanelBox>
  );
};
