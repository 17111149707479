import React, { useState } from "react";

import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { theme } from "common/theme/theme";
import { useGetUserQuery } from "store/user/userApi";

import CommentSettings from "../CommentSettings/CommentSettings";

import {
  CommentContentTextWrapper,
  CommentContentWrapper,
  CommentTopWrapper,
} from "./CommentConent.styled";

interface CommentContentProps {
  content: string;
}

const CommentContent = ({ content }: CommentContentProps) => {
  const [isCommentHovered, setCommentHovered] = useState(false);
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub });
  const handleHover = () => {
    setCommentHovered(true);
  };
  const handleBlur = () => {
    setCommentHovered(false);
  };
  return (
    <CommentContentWrapper onMouseEnter={handleHover} onMouseLeave={handleBlur}>
      <CommentContentTextWrapper>
        <CommentTopWrapper>
          <Text
            size="14px"
            weight="bold"
          >{`${user.firstName} ${user.lastName}`}</Text>
          <Text size="14px" color={theme.color.mediumGray}>
            1 godz.
          </Text>
        </CommentTopWrapper>
        <Text>{content}</Text>
      </CommentContentTextWrapper>
      <CommentSettings
        handleBlur={handleBlur}
        handleHover={handleHover}
        isHovered={isCommentHovered}
      />
    </CommentContentWrapper>
  );
};

export default CommentContent;
