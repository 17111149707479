import React, { ChangeEvent } from "react";

import { ModalPortal } from "../Modal/modal-portal";

import {
  ButtonsContainer,
  CancelButton,
  Counter,
  EditArea,
  EditAreaContainer,
  ErrorIcon,
  ErrorMessage,
  SaveButton,
  Title,
} from "./DescriptionEditModal.styled";

type DescriptionModalProps = {
  visible: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onSave: () => void;
  onContentChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const DescriptionEditModal = ({
  visible,
  title,
  content,
  onClose,
  onSave,
  onContentChange,
}: DescriptionModalProps) => {
  const contentTooLong = content?.length > 150;
  return (
    <ModalPortal
      isVisible={visible}
      withCloseButton={true}
      closeModal={onClose}
    >
      <Title>{title}</Title>
      <EditAreaContainer error={contentTooLong}>
        <EditArea
          label=""
          id="edit-description"
          value={content}
          onChange={onContentChange}
        />
        <Counter error={contentTooLong}>{content?.length || 0}/150</Counter>
      </EditAreaContainer>
      {contentTooLong && (
        <ErrorMessage>
          <ErrorIcon src="/icons/exclamation_mark_red.svg" />
          <span>Opis może mieć maksymalnie 150 znaków</span>
        </ErrorMessage>
      )}
      <ButtonsContainer>
        <CancelButton onClick={onClose}>Anuluj</CancelButton>
        <SaveButton onClick={onSave} disabled={contentTooLong}>
          Zapisz
        </SaveButton>
      </ButtonsContainer>
    </ModalPortal>
  );
};

export default DescriptionEditModal;
