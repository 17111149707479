import { theme } from "common/theme/theme";
import styled from "styled-components";

export const DropdownInputValue = styled.input<{
  isActive?: boolean;
  isClicked?: boolean;
  isValid?: boolean;
  noBorder?: boolean;
}>`
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  padding: 13px 10px;
  border-radius: 4px;
  ${(p) => {
    if (!p.noBorder) {
      let color;
      if (p.isActive) {
        color = theme.color.formYellow;
      } else if (!p.isValid) {
        color = theme.color.alertRed;
      } else {
        color = theme.color.slightlyLightGray;
      }

      return `border: 1px solid ${color}`;
    } else {
      return `
        border: 0px solid transparent !important;
        &:focus {
          outline: none;
        }
      `;
    }
  }};
`;
