import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

interface ImageProps {
  image: string;
}

export const PostModalFileWrapper = styled.div`
  display: grid;
  width: 105px;
  height: 107px;
`;

export const FileImage = styled.div<ImageProps>`
  display: flex;
  justify-content: end;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 105px;
  height: 56px;
`;

export const FileItemText = styled(Text)`
  width: 101px;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
`;

export const DeleteFileWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${theme.color.lightGray};
  margin-top: 4px;
  margin-right: 4px;
  cursor: pointer;
`;
