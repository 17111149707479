import { Link } from "react-router-dom";

import styled from "styled-components/macro";

export const ShowMoreButtonStyled = styled(Link)`
  border-top: 1px solid var(--black-white-background-gray, #eceeef);
  display: flex;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
  text-decoration: none;
  color: unset;
  color: var(--black-white-medium-gray, #767676);
`;
