import React, { FocusEvent, useCallback, useState } from "react";

import Image from "common/components/Image";

import StyledDatePicker, {
  DatePickerWrapper,
  PlaceholderLabel,
  ValidationLabel,
} from "./DatePicker.styled";

export interface DatePickerProps {
  errorText: string;
  id: string;
  isValid: boolean;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  mb?: string;
  mt?: string;
  onBlur?: (event: FocusEvent) => void;
  onChange: (event: any) => void;
  value: Date;
  width?: string;
  defaultActiveStartDate?: Date;
  format?: string;
}

const DatePicker = ({
  errorText,
  id,
  isValid,
  label,
  maxDate,
  minDate,
  mb,
  mt,
  onBlur,
  onChange,
  value,
  width,
  defaultActiveStartDate,
  format,
}: DatePickerProps) => {
  // What is this? I need to click in the input to make the validation work properly :/
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isCalendarActive, setIsCalendarActive] = useState<boolean>(false);

  function handleBlur(event: FocusEvent) {
    setIsClicked(true);
    onBlur && onBlur(event);
  }
  const onCalendarOpen = () => {
    setIsClicked(true);
    setIsCalendarActive(true);
  };

  const checkIfPlaceholderLabelIsValid = useCallback(() => {
    if (isCalendarActive) {
      return true;
    } else {
      return isClicked ? isValid : null;
    }
  }, [isCalendarActive, isClicked, isValid]);

  return (
    <DatePickerWrapper mb={mb} mt={mt} onBlur={handleBlur}>
      <StyledDatePicker
        calendarAriaLabel={label}
        calendarIcon={
          <Image
            src="/icons/calendar.svg"
            alt="calendar icon"
            width={18}
            height={20}
          />
        }
        format={format || "dd.MM.yyyy"}
        isActive={isCalendarActive}
        isClicked={isClicked}
        isValid={isValid}
        locale="pl-PL"
        maxDate={maxDate}
        minDate={minDate}
        defaultActiveStartDate={defaultActiveStartDate}
        minDetail="decade"
        onCalendarClose={() => setIsCalendarActive(false)}
        onCalendarOpen={onCalendarOpen}
        onChange={onChange}
        value={value}
        width={width}
      />
      <PlaceholderLabel isValid={checkIfPlaceholderLabelIsValid()} htmlFor={id}>
        {label}
      </PlaceholderLabel>
      {!isValid && errorText && !isCalendarActive && (
        <ValidationLabel isValid={isValid}>{errorText}</ValidationLabel>
      )}
    </DatePickerWrapper>
  );
};

export default DatePicker;
