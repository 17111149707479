import styled from "styled-components/macro";

export const UsersTableItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 371px;
  height: 64px;
  gap: 50px;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  width: 371px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemAddButton = styled.a`
  cursor: pointer;
  margin-right: 37px;
`;

export const LocalizationWrapper = styled.div`
  display: flex;
  width: 371px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
