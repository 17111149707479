import React from "react";

export const IconPlus = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.3613 8.85938H8.36133V13.8594C8.36133 14.4094 7.91133 14.8594 7.36133 14.8594C6.81133 14.8594 6.36133 14.4094 6.36133 13.8594V8.85938H1.36133C0.811328 8.85938 0.361328 8.40938 0.361328 7.85938C0.361328 7.30937 0.811328 6.85938 1.36133 6.85938H6.36133V1.85938C6.36133 1.30937 6.81133 0.859375 7.36133 0.859375C7.91133 0.859375 8.36133 1.30937 8.36133 1.85938V6.85938H13.3613C13.9113 6.85938 14.3613 7.30937 14.3613 7.85938C14.3613 8.40938 13.9113 8.85938 13.3613 8.85938Z"
      fill="currentColor"
    />
  </svg>
);
