import React from "react";

import Flex from "common/components/Flex";
import MainViewLoader from "components/TaskerPage/utils/MainViewLoader";
import { useAppSelector } from "store/hooks";
import { IList } from "store/tasker/models/List";

import AddNewList from "../common/AddNewList";

import TaskerColumnWrapper from "./TaskerColumnWrapper/TaskerColumnWrapper";

export default function TaskerTodo() {
  const taskerSlice = useAppSelector((s) => s.tasker);

  if (taskerSlice.loading) {
    return <MainViewLoader />;
  }

  return (
    <Flex
      direction="row"
      wrap="nowrap"
      mt="15px"
      gap="12px"
      p="0 0 15px 0"
      overFlowX="auto"
    >
      {taskerSlice.groupViewTree.lists.map((l: IList) => {
        return l.tasks.filter((t) => !t.completed).length > 0 ? (
          <TaskerColumnWrapper key={l.id} list={l} />
        ) : null;
      })}
      <AddNewList />
    </Flex>
  );
}
