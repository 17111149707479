import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { notesAction } from "store/notes/notesSlice";

import CreateNoteButton from "./CreateNoteButton";

export default function NoteHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <Text size="24px" weight="bold" color={theme.color.basicBlack}>
        Notatnik
      </Text>
      <CreateNoteButton
        setNewNote={() => {
          dispatch(notesAction.openEditNote(null));
          navigate("/create-note");
        }}
      />
    </>
  );
}
