import React from "react";

export const IconCheckbox = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H0V18H18V0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
      fill="#323232"
    />
  </svg>
);
