import React, { FC } from "react";

import Header from "../Header";

import { MainLayoutContainer } from "./MainLayout.styled";

interface InputProps {
  children?: React.ReactNode;
}

const MainLayout: FC<InputProps> = ({ children }) => {
  return (
    <MainLayoutContainer>
      <Header />
      {children}
    </MainLayoutContainer>
  );
};

export default MainLayout;
