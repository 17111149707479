import React from "react";

import Flex from "common/components/Flex";
import Text from "common/components/Text";

export default function NoFinishedTasks() {
  return (
    <Flex
      height="100%"
      width="100%"
      justify="center"
      align="center"
      direction="column"
    >
      <Text size="24px" weight="bold" font="primary" lineHeight="30px">
        Brak ukończonych zadań
      </Text>
      <Text size="14px" weight="normal" font="primary" lineHeight="20px">
        Tutaj pojawią się ukończone zadania.
      </Text>
    </Flex>
  );
}
