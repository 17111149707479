import React from "react";

import axios, { AxiosError, AxiosResponse } from "axios";
import { mapTaskToUpdateTaskCompletedPayload } from "services/api/tasker/tasker.mappers";
import { taskerUpdateTaskRq } from "services/api/tasker/taskerUpdateTaskRq";
import { useAppDispatch } from "store/hooks";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

import Checkbox from "../Checkbox";

interface TaskCompletedProps {
  task: Task;
}

export default function TaskCompletedUserView({ task }: TaskCompletedProps) {
  const dispatch = useAppDispatch();

  const toggleTaskCompleted = async () => {
    const payload = mapTaskToUpdateTaskCompletedPayload(
      { ...task, completed: !task.completed },
      task.group_id
    );

    const response: AxiosResponse | AxiosError = await taskerUpdateTaskRq(
      payload
    );
    console.log(response);
    if (axios.isAxiosError(response)) {
      console.error(`UpdateTask API Error: ${response}`);
      return;
    } else {
      if (response.status === 200) {
        console.log(`UpdateTask API returned with status ${response.status}`);
      }
    }

    dispatch(
      taskerAction.setTaskCompletedInUserView({
        task,
        completed: !task.completed,
      })
    );
  };

  return (
    <Checkbox
      checked={task.completed}
      width={16}
      height={16}
      uncheckedIconSrc="/icons/rectangle_checkbox_empty.svg"
      checkedIconSrc="/icons/rectangle_checkbox_filled.png"
      onChecked={toggleTaskCompleted}
    />
  );
}
