import React from "react";

import { theme } from "common/theme/theme";
import styled from "styled-components";

import ModalCloseIcon from "./ModalCloseIcon";

interface CloseIconProps {
  width?: string;
  height?: string;
  top: string;
  left: string;
  onClick: any;
}

export const DeleteButton = styled.a<CloseIconProps>`
  position: absolute;
  display: grid;
  border-radius: 100%;
  place-items: center;
  left: ${(props) => props.left || "0"};
  top: ${(props) => props.top || "0"};
  z-index: 10;
  height: ${(props) => props.height || "24px"};
  width: ${(props) => props.width || "24px"};
  background-color: ${theme.color.veryLightGray};
  cursor: pointer;
`;

export default function DeleteIcon({
  width,
  height,
  top,
  left,
  onClick,
}: CloseIconProps) {
  return (
    <DeleteButton
      left={left}
      top={top}
      width={width}
      height={height}
      onClick={onClick}
    >
      <ModalCloseIcon width="9" height="9" />
    </DeleteButton>
  );
}
