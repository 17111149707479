import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

import Button from "../Button";
import Heading from "../Heading";
import Text from "../Text";
import TextArea from "../TextArea";

export const Title = styled(Heading)`
  font-size: 20px;
  margin-top: 12px;
  text-align: center;
`;
type EditAreaContainerProps = { error: boolean };

export const EditAreaContainer = styled.div<EditAreaContainerProps>`
  box-sizing: border-box;
  width: 285px;
  margin: 24px 22px 0;
  padding: 2px 16px 16px 16px;
  border: 1px solid
    ${(props) =>
      props.error ? theme.color.alertRed : theme.color.slightlyLightGray};
  border-radius: 8px;
  color: ${(props) =>
    props.error ? theme.color.alertRed : theme.color.basicBlack};
`;

export const EditArea = styled(TextArea)`
  margin-bottom: 0px;
  > textarea {
    border: none;
    font-size: 14px;
    &:focus {
      border: none;
    }
  }
`;
type CounterProps = { error: boolean };

export const Counter = styled(Text)<CounterProps>`
  font-size: 14px;
  font-weight: ${theme.fontWeight.semibold};
  text-align: right;
  color: ${(props) =>
    props.error ? theme.color.alertRed : theme.color.basicBlack};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 47px;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const CancelButton = styled(Button)`
  height: 30px;
  width: 72px;
  font-family: "Inter";
  font-size: 14px;
  color: ${theme.color.basicBlack};
  background-color: ${theme.color.basicWhite};
`;

export const SaveButton = styled(Button)`
  height: 30px;
  width: 72px;
  font-family: "Inter";
  font-size: 14px;
`;

export const ErrorMessage = styled(Text)`
  font-size: 12px;
  margin-top: 24px;
  color: ${theme.color.alertRed};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ErrorIcon = styled.img`
  height: 10px;
  width: 10px;
`;
