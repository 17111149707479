import React from "react";

import SmallButton from "common/components/SmallButton/SmallButton";
import { useSub } from "common/hooks/useSub";
import { useGetUserQuery, useUpdateProfileMutation } from "store/user/userApi";

import { ModalDeleteCancelButton } from "../Shared/ModalDeleteCancelButton";
import {
  ModalDeleteConfirmationContainer,
  ModalDeleteConfirmationFooter,
  ModalDeleteConfirmationMain,
  ModalDeleteConfirmationTitle,
} from "../Shared/ModalDeleteConfirmation";

interface Props {
  onClose: () => void;
}

export const ModalDeleteJob = ({ onClose }: Props) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });

  const [updateProfile] = useUpdateProfileMutation();

  const onDelete = async () => {
    await updateProfile({
      sub,
      language: user.language,
      profileData: {
        companyName: "",
        jobName: "",
        jobStarted: "",
        jobFinished: "",
      },
    });
    onClose();
  };

  return (
    <ModalDeleteConfirmationContainer>
      <ModalDeleteConfirmationTitle>Usunąć pracę?</ModalDeleteConfirmationTitle>
      <ModalDeleteConfirmationMain>
        Usuwając informacje związane z pracą stracisz je bezpowrotnie. <br />{" "}
        Czy na pewno chcesz to zrobić?
      </ModalDeleteConfirmationMain>
      <ModalDeleteConfirmationFooter>
        <ModalDeleteCancelButton onClick={onClose}>
          Anuluj
        </ModalDeleteCancelButton>
        <SmallButton onClick={onDelete}>Tak, usuń</SmallButton>
      </ModalDeleteConfirmationFooter>
    </ModalDeleteConfirmationContainer>
  );
};
