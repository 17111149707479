import { theme } from "common/theme/theme";
import styled from "styled-components";

export const CommentContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 100% 1fr;
`;

export const CommentContentTextWrapper = styled.div`
  display: grid;
  gap: 8px;
  background-color: ${theme.color.veryLightGray};
  word-break: break-all;
  padding: 12px;
  border-radius: 4px;
`;

export const CommentTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
