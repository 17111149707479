import styled from "styled-components";

export const GroupCloseModalCheckboxes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 22px;
`;

export const GroupCloseArchiveModalWrapper = styled.div`
  width: 305px;
  height: 226px;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
