import React from "react";

import { DropdownMenuContainer } from "./DropdownMenu.styled";
import DropdownMenuOption from "./DropdownMenuOption";

interface Option {
  img: string;
  text: string;
  action: () => void;
  testid: string;
}

type DropdownMenuProps = {
  options: Option[];
  top?: string;
  left?: string;
  onClickClose?: () => void;
  children?: React.ReactNode;
};

const DropdownMenu = ({
  options,
  left,
  top,
  onClickClose,
  children,
}: DropdownMenuProps) => {
  if (options.length === 0) return null;

  return (
    <DropdownMenuContainer
      onClick={(e) => {
        e.stopPropagation();
        onClickClose();
      }}
      top={top}
      left={left}
    >
      {children ||
        options.map((option) => (
          <DropdownMenuOption {...option} key={option.testid} />
        ))}
    </DropdownMenuContainer>
  );
};

export default DropdownMenu;
