import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { NotesRightPanelAddCategoryStyledButton } from "./NotesRightPanel.styled";

interface NotesRightPanelAddCategoryStyledButtonProps {
  handleAddCategory: () => void;
}

const NotesRightPanelAddCategoryButton = ({
  handleAddCategory,
}: NotesRightPanelAddCategoryStyledButtonProps) => {
  return (
    <NotesRightPanelAddCategoryStyledButton
      display="flex"
      variant="outlined"
      border="none"
      height="20px"
      width="180px"
      onClick={handleAddCategory}
    >
      <Text size="14px" color={theme.color.basicBlack} weight="bold" mr="10px">
        Utwórz kategorię
      </Text>
      <Image src="/icons/plus.svg" alt="add category" width={9} height={9} />
    </NotesRightPanelAddCategoryStyledButton>
  );
};

export default NotesRightPanelAddCategoryButton;
