import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const FlexTaskPriority = styled(Flex)`
  border: 2px solid ${() => theme.color.alertRed};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export default function TaskPriorityTagSquare() {
  return (
    <FlexTaskPriority p="8px" height="32px">
      <Image src="/icons/flag_priority.svg" alt="Plus" width={20} height={20} />
      <Text ml="4px" size="14px" weight="semibold" color={theme.color.alertRed}>
        Priorytet
      </Text>
    </FlexTaskPriority>
  );
}
