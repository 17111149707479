import React from "react";
import { UseFormRegister } from "react-hook-form";

import FormInput from "common/components/FormInputHookForms";
import PasswordInputField from "common/components/PasswordInputField/PasswordInputField";
import { ICredentials } from "common/types/ICredentials";

interface UserCredentialsAreaProps {
  emailErrors: string;
  passwordErrors: string;
  emailTouched: boolean;
  passwordTouched: boolean;
  register: UseFormRegister<ICredentials>;
}

function UserCredentialsArea({
  register,
  emailErrors,
  passwordErrors,
  emailTouched,
  passwordTouched,
}: UserCredentialsAreaProps) {
  return (
    <>
      <FormInput
        id="email"
        placeholder="Wpisz swój email"
        label="E-mail"
        type="email"
        isValid={!emailErrors}
        inputName="email"
        register={register}
        errorText={emailErrors}
        mb="47px"
        touched={emailTouched}
      />
      <PasswordInputField
        id="password"
        inputName="password"
        label="Hasło"
        placeholder="Wpisz swoje hasło"
        register={register}
        passwordErrors={passwordErrors}
        passwordTouched={passwordTouched}
      />
    </>
  );
}

export default UserCredentialsArea;
