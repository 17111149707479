import React, { useEffect } from "react";

import Flex from "common/components/Flex";
import { getPossibleUsersToAdd } from "store/groupCreation/groupCreationSlice";
import { IListUser } from "store/groupCreation/types";
import { useAppDispatch, useAppSelector } from "store/hooks";

import EmptyState from "./EmptyState/EmptyState";
import RightUsersTable from "./RightUsersTable/RightUsersTable";
import { UsersScrollbarWrapper } from "./UsersTable.styled";
import UsersTableItem from "./UsersTableItem/UsersTableItem";

interface UsersTableProps {
  addUserHandler: (userId: string) => void;
  deleteUserHandler: (userId: string) => void;
  chosenUsers: IListUser[];
  searchValue: string;
}

const UsersTables = ({
  addUserHandler,
  deleteUserHandler,
  chosenUsers,
  searchValue,
}: UsersTableProps) => {
  const dispatch = useAppDispatch();
  const currentGroupUsers = useAppSelector((state) => state.group.groupMembers);
  useEffect(() => {
    dispatch(getPossibleUsersToAdd());
  }, [dispatch]);

  const notAddedUsers = useAppSelector((state) =>
    state.groupCreation.availableUsers.filter(
      (user) =>
        !chosenUsers.some(
          (chosenUser) => chosenUser?.userData.sub === user?.userData.sub
        ) &&
        user.userData.fullName
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) &&
        !currentGroupUsers.some(
          (groupUser) => groupUser.userId === user.userData.sub
        )
    )
  );

  return (
    <Flex width="514px" height="411px" mt="24px" overFlowX="none">
      <UsersScrollbarWrapper>
        <Flex width="371px" direction="column" gap="11px">
          {notAddedUsers.length !== 0 ? (
            <>
              {notAddedUsers.map((user, i) => (
                <UsersTableItem
                  key={i}
                  chooseHandler={addUserHandler}
                  userId={user.userData?.sub}
                  fullName={user.userData?.fullName}
                  avatar={user.userData?.avatarUrl}
                  occupation={user.profileData?.companyName}
                  localization={user.userData?.city}
                />
              ))}
            </>
          ) : (
            <EmptyState enteredPhrase={searchValue} />
          )}
        </Flex>
      </UsersScrollbarWrapper>
      <RightUsersTable
        chosenUsers={chosenUsers}
        deleteUserHandler={deleteUserHandler}
      />
    </Flex>
  );
};

export default UsersTables;
