import * as React from "react";

import Spinner from "common/components/Spinner";
import { Paths } from "common/constants";
import { SpinnerWrapper } from "components/Layout/LeftPanel/LeftPanelGroup/LeftPanelGroup.styled";

import { SearchItemGroup } from "./index.types";
import { SearchItemCard } from "./SearchItemCard";
import { SearchListResultStyled } from "./SearchListResult.styled";
import { ShowMoreButtonStyled } from "./ShowMoreButton.styled";

type Props = {
  items: SearchItemGroup[];
  totalCount: number;
  isLoading: boolean;
  query: string;
  close: () => void;
};

export const SearchListResult = (props: Readonly<Props>) => {
  return (
    <SearchListResultStyled>
      <div className="container">
        <>
          {props.isLoading && (
            <SpinnerWrapper>
              <Spinner radius="35px" border="5px" />
            </SpinnerWrapper>
          )}
          {props.items.length > 0 && (
            <>
              {props.items.map((item) => (
                <div className="item-group" key={item.title}>
                  <h3 className="title">{item.title}</h3>
                  {item.items.map((item) => (
                    <SearchItemCard key={item.id} {...item} />
                  ))}
                </div>
              ))}
              <ShowMoreButtonStyled
                onClick={props.close}
                to={{
                  pathname: Paths.SEARCH_DETAILS,
                  search: `?${new URLSearchParams({ query: props.query })}`,
                }}
              >
                Zobacz wszystkie wyniki ({props.totalCount})
              </ShowMoreButtonStyled>
            </>
          )}
          {props.items.length === 0 && !props.isLoading && (
            <p>Nie znaleziono</p>
          )}
        </>
      </div>
    </SearchListResultStyled>
  );
};
