import styled from "styled-components";

export const SearchFiltersStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

  h3 {
    text-align: left;
    padding: 0 1rem;
    font-weight: 500;
    color: var(--black-white-basic-black, #1d1d1d);

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  .buttons {
    padding: 0 0.5rem;
    width: 90%;
    display: flex;
    align-items: center;
    gap: 24px;
    flex-direction: column;
  }
  button {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    width: 100%;
    background-color: white;
    appearance: unset;
    border-radius: 8px;
    border: none;
    &.active {
      background: var(--black-white-background-gray, #eceeef);
      font-weight: 600;
    }
  }
`;
