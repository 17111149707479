import Button from "common/components/Button";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const GroupCloseFinalModalWrapper = styled.div`
  width: 305px;
  height: 158px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const GoBackToApplicationButton = styled(Button)`
  width: 174px;
  height: 30px;
  padding: 8px 12px;
  margin-top: 29px;
  font-weight: ${theme.fontWeight.bold};
  font-size: 14px;
  color: ${theme.color.basicWhite};
  background-color: ${theme.color.veryDarkGray};
`;
