import React from "react";

import Button from "common/components/Button";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { useSub } from "common/hooks/useSub";
import { IconCity } from "common/icons/IconCity";
import { IconCountry } from "common/icons/IconCountry";
import { useGetUserQuery } from "store/user/userApi";

import DetailsGroup from "../DetailsGroup/DetailsGroup";

import {
  ContactFormBox,
  ContactFormBoxContent,
  ContactFormBoxIconWrapper,
  ContactFormBoxTitle,
} from "./Contact.styled";
import { ModalContact } from "./ModalContact";

export const Contact = () => {
  const { sub } = useSub();

  const { data: user } = useGetUserQuery({ sub });

  const { showModal, modalProps } = useModal();

  return (
    <>
      <DetailsGroup title="Podstawowe informacje" onOpen={showModal}>
        <ContactFormBox>
          <ContactFormBoxIconWrapper>
            <IconCity />
          </ContactFormBoxIconWrapper>
          <ContactFormBoxTitle>Miasto</ContactFormBoxTitle>
          {user?.city ? (
            <ContactFormBoxContent>{user.city}</ContactFormBoxContent>
          ) : (
            <Button
              variant="outlined"
              fontSize=".9rem"
              width="max-content"
              height="max-content"
              padding=".5rem"
              type="button"
              onClick={showModal}
            >
              + Dodaj miasto
            </Button>
          )}
        </ContactFormBox>
        <ContactFormBox>
          <ContactFormBoxIconWrapper>
            <IconCountry />
          </ContactFormBoxIconWrapper>
          <ContactFormBoxTitle>Kraj</ContactFormBoxTitle>
          {user?.country ? (
            <ContactFormBoxContent>{user.country}</ContactFormBoxContent>
          ) : (
            <Button
              variant="outlined"
              fontSize=".9rem"
              width="max-content"
              height="max-content"
              padding=".5rem"
              type="button"
              onClick={showModal}
            >
              + Dodaj kraj
            </Button>
          )}
        </ContactFormBox>
      </DetailsGroup>
      <ModalPortal {...modalProps}>
        <ModalContact onClose={modalProps.closeModal} />
      </ModalPortal>
    </>
  );
};
