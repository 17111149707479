import React, { FC } from "react";

import { HintsList, HintsListItem } from "./PasswordHints.styled";

interface PasswordHintsProps {
  errorDetails: {
    smallLetter?: boolean;
    bigLetter?: boolean;
    digit?: boolean;
    specialChar?: boolean;
    minLength?: boolean;
  };
}

const PasswordHints: FC<PasswordHintsProps> = ({ errorDetails }) => {
  const { minLength, bigLetter, smallLetter, digit, specialChar } =
    errorDetails;

  const hintsFields: {
    info: string;
    isRequirementMet: boolean;
    id: number;
  }[] = [
    { info: "1 duża litera", isRequirementMet: bigLetter, id: 1 },
    {
      info: "1 mała litera",
      isRequirementMet: smallLetter,
      id: 2,
    },
    { info: "1 cyfra", isRequirementMet: digit, id: 3 },
    {
      info: "1 znak specjalny (*, &, $ itp.)",
      isRequirementMet: specialChar,
      id: 4,
    },
    {
      info: "Minimalnie 8 znaków",
      isRequirementMet: minLength,
      id: 5,
    },
  ];

  return (
    <HintsList>
      {hintsFields.map(({ info, isRequirementMet, id }) => (
        <HintsListItem key={id} disabled={isRequirementMet}>
          {info}
        </HintsListItem>
      ))}
    </HintsList>
  );
};

export default PasswordHints;
