import styled from "styled-components";

export const CommentReplyWrapper = styled.div`
  margin-top: 4px;
`;

export const CommentReplyButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
`;
