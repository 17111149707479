import React, { useState } from "react";

import { DecodedIdToken } from "common/types/IToken";
import { getJwtToken } from "common/utils/auth/getToken";
import jwtDecode from "jwt-decode";
import { IDeleteGroupMessageRequest } from "services/api/group/groupApi";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { groupDelete } from "store/groupSlice";
import { useAppDispatch } from "store/hooks";

import GroupCloseModalArchiveStage from "./GroupCloseModalArchiveStage/GroupCloseModalArchiveStage";
import GroupCloseModalFinal from "./GroupCloseModalFinal/GroupCloseModalFinal";
import GroupCloseModalFirstStage from "./GroupCloseModalFirstStage/GroupCloseModalFirstStage";

type FinalText =
  | "Usunięto wszystkie elementy grupy."
  | "Wybrane komponenty zostały zarchiwizowane.";

interface GroupCloseModalProps {
  closeModal: () => void;
  groupName: string;
  groupId: string;
  groupType: string;
}

export interface IArchiveCheckboxes {
  allCheckboxes: boolean;
  calendar: boolean;
  notes: boolean;
}

const GroupCloseModal = ({
  closeModal,
  groupId,
  groupName,
  groupType,
}: GroupCloseModalProps) => {
  const dispatch = useAppDispatch();
  const [archiveStage, setArchiveStage] = useState(false);
  const [finalStage, setFinalStage] = useState(false);
  const [checkboxes, setCheckboxes] = useState<IArchiveCheckboxes>({
    allCheckboxes: false,
    calendar: false,
    notes: false,
  });

  const goToArchiveStage = () => {
    setArchiveStage(true);
  };

  const leaveArchiveStage = () => {
    setArchiveStage(false);
  };

  const [finalText, setFinalText] = useState<FinalText>(
    "Usunięto wszystkie elementy grupy."
  );

  const goToFinalStage = async () => {
    if (archiveStage) {
      setFinalText("Wybrane komponenty zostały zarchiwizowane.");
      leaveArchiveStage();
    }
    const decodedToken = jwtDecode(await getJwtToken()) as DecodedIdToken;
    const userSub = decodedToken["cognito:username"];
    const deleteGroupRequest: GroupApiRequest<IDeleteGroupMessageRequest> = {
      body: {
        header: createGroupRequestHeader(userSub),
        message: {
          group_id: groupId,
          inactivated: new Date().toISOString(),
          group_type: groupType,
          inactivated_by: userSub,
        },
      },
    };
    dispatch(groupDelete(deleteGroupRequest));
    setFinalStage(true);
  };

  const handleCheckboxes = (checkboxToHandle: keyof IArchiveCheckboxes) => {
    setCheckboxes((prevCheckboxes) => {
      return {
        ...prevCheckboxes,
        [checkboxToHandle]: !prevCheckboxes[checkboxToHandle],
      };
    });
  };

  const handleAllCheckboxes = () => {
    setCheckboxes((prevCheckboxes) => {
      const valueOfAllCheckbox = !prevCheckboxes.allCheckboxes;
      Object.keys(prevCheckboxes).forEach(
        (checkbox) => (prevCheckboxes[checkbox] = valueOfAllCheckbox)
      );
      return { ...prevCheckboxes };
    });
  };
  return (
    <>
      {!archiveStage && !finalStage && (
        <GroupCloseModalFirstStage
          goToArchiveStage={goToArchiveStage}
          goToFinalStage={goToFinalStage}
        />
      )}
      {archiveStage && (
        <GroupCloseModalArchiveStage
          checkboxes={checkboxes}
          handleAllCheckboxes={handleAllCheckboxes}
          checkboxesHandler={handleCheckboxes}
          leaveArchiveStage={leaveArchiveStage}
          goToFinalStage={goToFinalStage}
        />
      )}
      {finalStage && (
        <GroupCloseModalFinal
          closeModal={closeModal}
          groupName={groupName}
          finalText={finalText}
        />
      )}
    </>
  );
};

export default GroupCloseModal;
