import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import Text from "common/components/Text";
import Toggle from "common/components/Toggle/Toggle";
import { localStorageKeys } from "common/constants";
import { theme } from "common/theme/theme";
import { useAppSelector } from "store/hooks";
import { notesAction } from "store/notes/notesSlice";

import { NewNoteAutosaveWrapper } from "../../NewNote.styled";

export default function CreateNoteHeader() {
  const dispatch = useDispatch();
  const autoSave = useAppSelector((state) => state.note.noteAutoSave);
  const handleAutoSave = useCallback(() => {
    dispatch(notesAction.setAutoSave(!autoSave));
  }, [dispatch, autoSave]);

  useEffect(() => {
    if (!autoSave) {
      localStorage.removeItem(localStorageKeys.note);
    }
  }, [autoSave]);
  return (
    <>
      <Text size="24px" weight="bold" color={theme.color.basicBlack}>
        Notatka
      </Text>
      <NewNoteAutosaveWrapper>
        <Text>Autozapis</Text>
        <Text>
          <Toggle defaultChecked={autoSave} onChange={handleAutoSave} />
        </Text>
      </NewNoteAutosaveWrapper>
    </>
  );
}
