import React, { useEffect, useState } from "react";

import { DecodedIdToken } from "common/types/IToken";
import { getJwtToken } from "common/utils/auth/getToken";
import jwtDecode from "jwt-decode";
import { inviteToGroup } from "services/api/group/groupApi";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { getPossibleUsersToAdd } from "store/groupCreation/groupCreationSlice";
import { IListUser } from "store/groupCreation/types";
import { GroupMemberRole } from "store/groupSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import GroupInvitation from "../Common/GroupInvitation/GroupInvitation";
import GroupInvitationButtons from "../Common/GroupInvitation/GroupInvitationButtons/GroupInvitationButtons";

import { GroupInvitationModalWrapper } from "./GroupInvitationModal.styled";
import InvitationModalHeader from "./InvitationModalHeader/InvitationModalHeader";

interface InvitationModalProps {
  turnOffInvitationModal: () => void;
}

const GroupInvitationModal = ({
  turnOffInvitationModal,
}: InvitationModalProps) => {
  const dispatch = useAppDispatch();
  const [chosenUsers, setChosenUsers] = useState<IListUser[]>([]);
  const currentGroupUsers = useAppSelector((state) => state.group.groupMembers);
  const possibleUsersToAdd = useAppSelector((state) =>
    state.groupCreation.availableUsers.filter((availableUser) =>
      currentGroupUsers.some(
        (groupUser) => groupUser.userId !== availableUser.userData.sub
      )
    )
  );
  const currentGroupUser = useAppSelector(
    (state) => state.group.currentGroupUser
  );

  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  useEffect(() => {
    dispatch(getPossibleUsersToAdd());
  }, [dispatch]);

  const invitationHandler = async () => {
    turnOffInvitationModal();
    if (!currentGroupUser) return;
    if (currentGroupUser.userRole !== GroupMemberRole.Admin) return;
    const decodedToken = jwtDecode(await getJwtToken()) as DecodedIdToken;
    chosenUsers.forEach(
      async (user) =>
        await inviteToGroup({
          body: {
            header: createGroupRequestHeader(decodedToken["cognito:username"]),
            message: {
              group_id: currentGroup.groupId,
              member_id: user.userData.sub,
              owner_id: currentGroupUser.userId,
              group_status: currentGroup.groupStatus,
              group_type: currentGroup.groupType,
              created: new Date().toISOString(),
              created_by: currentGroup.createdBy,
            },
          },
        })
    );
  };

  const addUserHandler = (userId: string) => {
    const user = possibleUsersToAdd.find(
      (user) => user.userData.sub === userId
    );
    if (!user) throw new Error("No user");
    setChosenUsers((users) => users.concat(user));
  };

  const deleteUserHandler = (userId: string) => {
    setChosenUsers((users) =>
      users.filter((user) => user.userData.sub !== userId)
    );
  };

  return (
    <GroupInvitationModalWrapper>
      <InvitationModalHeader />
      <GroupInvitation
        deleteUserHandler={deleteUserHandler}
        addUserHandler={addUserHandler}
        chosenUsers={chosenUsers}
      />
      <GroupInvitationButtons
        cancelButtonText="Anuluj"
        proceedButtonText="Zaproś"
        cancelHandler={turnOffInvitationModal}
        proceedHandler={invitationHandler}
      />
    </GroupInvitationModalWrapper>
  );
};

export default GroupInvitationModal;
