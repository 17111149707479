import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const LeftPanelWrapper = styled.aside`
  grid-area: leftSideBar;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 72px);
  overflow: overlay;
  overflow-x: hidden;
  background-color: ${theme.color.basicWhite};
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 72px;

  * {
    user-select: none;
  }
`;
export const AddGroupButton = styled.button`
  display: flex;
  align-items: center;
  height: 40px;
  width: 188px;
  justify-content: space-around;
  border-radius: 8px;
  border: 1px solid ${theme.color.darkerGray};
  background: ${theme.color.basicWhite};
  :hover {
    cursor: pointer;
  }
`;
export const AddGroupButtonWrapper = styled(Wrapper)`
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 72px;
  position: fixed;
  bottom: 0;
  background: ${theme.color.basicWhite};
`;

export const Description = styled.div`
  max-width: 157px;
  margin-left: 8px;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1;
  max-height: 2;
`;

export const CategoryWrapper = styled(Wrapper)`
  width: 260px;
  height: 48px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  :hover {
    cursor: pointer;
  }
`;
