import Card from "common/components/Card/Card";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const GroupHeadingWrapper = styled(Card)`
  height: 240px;
  display: grid;
  grid-template-columns: 1fr 8fr 2fr;
  grid-template-rows: 1.25fr 0.5fr 5fr 1fr;
  grid-template-areas:
    "logo           title         options"
    "logo           type          ."
    "logo           description    members"
    "tab-navigation tab-navigation search";
  grid-auto-flow: column;
  column-gap: 16px;
  row-gap: 12px;
  padding-bottom: 0;
`;

export const TabNavigationWrapper = styled.div`
  display: flex;
  grid-area: tab-navigation;
  justify-self: start;
  align-self: end;
  max-width: 320px;
  width: 100%;
  flex-direction: row;
  font-family: ${theme.fontFamily.primary};
  font-size: 12px;
  font-weight: ${theme.fontWeight.semibold};
`;
export interface TabProps {
  active: boolean;
}

export const GroupMembersWrapper = styled.div`
  grid-area: members;
`;

export const GroupTitleWrapper = styled.div`
  grid-area: title;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-size: 24px;
  font-weight: ${theme.fontWeight.bold};
`;

export const GroupTypeWrapper = styled.div`
  grid-area: type;
  font-size: 12px;
  font-weight: 400;
  margin-top: -6px;
  color: ${theme.color.mediumGray};
`;

export const GroupDescriptionWrapper = styled.div`
  grid-area: description;
  font-size: 14px;
  color: ${theme.color.mediumGray};
`;

export const GroupSearchWrapper = styled.div`
  grid-area: search;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6px;
`;

export const GroupOptionsWrapper = styled.div`
  grid-area: options;
  justify-self: end;
  background-color: ${theme.color.basicWhite};
`;
