import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import BasicDatePicker from "common/components/DatePicker";

export interface DatePickerProps {
  control: Control<any>;
  errorText: string;
  id: string;
  isValid: boolean;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  mb?: string;
  mt?: string;
  name: Path<FieldValues>;
  width?: string;
  defaultActiveStartDate?: Date;
  format?: string;
}

const DatePicker = ({
  control,
  // errorText,
  id,
  // isValid,
  label,
  maxDate,
  minDate,
  mb,
  mt,
  name,
  width,
  defaultActiveStartDate,
  format,
}: DatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <BasicDatePicker
          errorText={error?.message}
          id={id}
          isValid={!error && value > 0}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          mb={mb}
          mt={mt}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          width={width}
          defaultActiveStartDate={defaultActiveStartDate}
          format={format}
        />
      )}
    />
  );
};

export default DatePicker;
