import React, { ReactNode, useEffect, useState } from "react";

import AWS from "aws-sdk";
import { ChangeBackground } from "common/components/ChangeBackground/ChangeBackground";
import WallLayout from "common/components/WallLayout/WallLayout";
import { UserImageType } from "common/types/UserImageType";
import { getUser, updateUser } from "services/api/user/userApi";

import { useSub } from "../../../common/hooks/useSub";

type LayoutProps = { children: ReactNode };

function Layout({ children }: LayoutProps) {
  const [editing, setEditing] = useState(false);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const fallbackImage = "/images/background-default-image.svg";
  const s3 = new AWS.S3();
  const s3Bucket = "smartoak-user-images";
  const userSub = useSub().sub;

  function s3Upload(file: File, key: string) {
    return s3.upload({
      Bucket: s3Bucket,
      Key: key,
      Body: file,
    });
  }

  async function s3Move(sourceFileKey: string, targetFileKey: string) {
    await s3
      .copyObject({
        Bucket: s3Bucket,
        CopySource: `${s3Bucket}/${sourceFileKey}`,
        Key: targetFileKey,
      })
      .promise();
    await s3
      .deleteObject({
        Bucket: s3Bucket,
        Key: sourceFileKey,
      })
      .promise();
  }

  async function s3Delete(key: string) {
    await s3
      .deleteObject({
        Bucket: s3Bucket,
        Key: key,
      })
      .promise();
  }

  function handleImageUpload(file: File) {
    const extension = file.type.split("/")[1];
    const randomString = Math.random().toString(36).substr(2, 5);
    // Random strings provent different images from being stored under the same name,
    // which causes caching/refresh problems when images are changed.
    const fileName = `${userSub}_${UserImageType.Background}_${randomString}.${extension}`;
    setImageFileName(fileName);
    return s3Upload(file, `preview_${fileName}`);
  }

  async function handleImageChangeConfirm() {
    const currentImageKey = backgroundImageUrl?.split("/").slice(-1).pop();
    if (currentImageKey) s3Delete(currentImageKey);
    await s3Move(`preview_${imageFileName}`, imageFileName);
    const backgroundUrl = `https://${s3Bucket}.s3.eu-central-1.amazonaws.com/${imageFileName}`;
    setBackgroundImageUrl(backgroundUrl);
    updateUser(userSub, { backgroundUrl });
  }

  async function handleImageRemove() {
    const currentImageKey = backgroundImageUrl?.split("/").slice(-1).pop();
    if (currentImageKey) s3Delete(currentImageKey);
    setBackgroundImageUrl("");
    updateUser(userSub, { backgroundUrl: "" });
  }

  useEffect(() => {
    async function getBackgroundUrl() {
      const user = await getUser(userSub);
      setBackgroundImageUrl(user.backgroundUrl);
    }
    getBackgroundUrl();
  }, [userSub]);

  return (
    <>
      <WallLayout
        backgroundUrl={backgroundImageUrl || fallbackImage}
        backgroundEditable={true}
        onBackgroundEdit={() => setEditing(true)}
      >
        {children}
      </WallLayout>
      {editing && (
        <ChangeBackground
          currentImageSrc={backgroundImageUrl}
          fallbackImageSrc={fallbackImage}
          onImageUpload={handleImageUpload}
          onImageChangeConfirm={handleImageChangeConfirm}
          onImageRemove={handleImageRemove}
          onClose={() => setEditing(false)}
          currentImageMessage="Aktualne zdjęcie w tle wygląda następująco:"
          deleteConfirmationMessage="Czy na pewno chcesz usunąć zdjęcie? Zdjęcie w tle będzie wyglądało tak:"
          noImageMessage="Obecnie nie masz zdjęcia w tle."
          previewText="Twoje zdjęcie w tle będzie wyglądało następująco:"
          title="Zdjęcie w tle"
        />
      )}
    </>
  );
}

export default Layout;
