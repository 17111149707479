import React from "react";

import Text from "common/components/Text";

import { EmptyStateWrapper } from "./EmptyState.styled";

interface EmptyStateProps {
  enteredPhrase: string;
}

const EmptyState = ({ enteredPhrase }: EmptyStateProps) => {
  return (
    <EmptyStateWrapper>
      <Text weight="bold" size="20px">
        Brak wyników
      </Text>
      <Text mt="16px" size="14px" data-testid="empty-state-message">
        Nie udało nam się znaleźć użytkownika {enteredPhrase.trim()}. Sprawdź
        pisownię lub spróbuj poszukać kogoś innego.
      </Text>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
