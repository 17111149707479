import styled from "styled-components/macro";

export const ContactFormBox = styled.div`
  display: grid;
  grid-template-columns: 5% 95%;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  margin-bottom: 1rem;
  column-gap: 0.25rem;
  row-gap: 0.5rem;
  p {
    padding: 0;
    margin: 0;
  }
  p:last-child {
    grid-column: 2;
  }
`;

export const ContactBoxTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ContactBoxContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
`;

export const ContactBoxIconWrapped = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactFormBoxTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ContactFormBoxContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
`;

export const ContactFormBoxIconWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
