import Flex from "common/components/Flex";
import styled from "styled-components/macro";

export const ModalFormWrapper = styled(Flex)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 494px;
  height: 286px;
`;
