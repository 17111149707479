import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

export default function TaskPriorityTag({ task }) {
  return (
    <Flex>
      {task.priority && (
        <>
          <Image
            src={"/icons/flag_priority.svg"}
            alt="Plus"
            width={16}
            height={16}
            marginRight="5px"
          />
          <Text size="12px" weight="semibold" color={theme.color.priorityRed}>
            Priorytet
          </Text>
        </>
      )}
    </Flex>
  );
}
