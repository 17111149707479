import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export interface FlexTaskPriorityProps {
  priority: boolean;
}

export const FlexTaskPriority = styled(Flex)<FlexTaskPriorityProps>`
  border: 2px
    ${({ priority }) =>
      priority ? theme.color.priorityRed : theme.color.basicBlack}
    solid;
  border-radius: 40px;
`;

type Props = {
  priority: boolean;
  setPriorityFn: () => void;
};

export default function TaskPriorityButton({ priority, setPriorityFn }: Props) {
  const path = `/icons/flag${priority ? "_priority" : ""}.svg`;
  return (
    <FlexTaskPriority
      priority={priority}
      align="center"
      justify="center"
      p="8px 20px"
      onClick={setPriorityFn}
    >
      <Image src={path} alt="icon" width={20} height={20} />
      <Text
        size="14px"
        weight="bold"
        ml="4px"
        color={priority ? theme.color.priorityRed : theme.color.basicBlack}
      >
        {priority ? "Priorytet" : "Ustaw priorytet"}
      </Text>
    </FlexTaskPriority>
  );
}
