import Text from "common/components/Text";
import styled from "styled-components/macro";

export const HeaderText = styled(Text)`
  font-weight: 700;
  text-align: center;
`;

export const HeaderIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
