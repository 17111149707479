import styled from "styled-components/macro";

export const SearchStyled = styled.div`
  margin-right: 4rem;

  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    gap: 0.5rem;
    input {
      width: 200px;
      min-width: 250px;
    }
  }

  .clear-button {
    position: absolute !important;
    right: -75px;
  }

  .divider {
    height: 35px;
    width: 1px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background: #1d1d1d;
  }

  .search-icon {
    margin-right: 1rem;
  }

  input {
    border: none;
    outline: none;
  }
`;
