import { useEffect, useReducer } from "react";

import AWS from "aws-sdk";
import { useSub } from "common/hooks/useSub";
import { UserImageType } from "common/types/UserImageType";
import { updateUser } from "services/api/user/userApi";
import { avatarDelete } from "services/image/imageService";
import { useAppDispatch } from "store/hooks";
import { userApi } from "store/user/userApi";

const s3Bucket = "smartoak-user-images";

type Action =
  | { type: "SET_ERROR"; error: string | null }
  | { type: "SET_FILE"; file: File | null }
  | { type: "SET_UPLOADED_URL"; uploadedUrl: string | null }
  | { type: "SET_PROGRESS"; progress: number }
  | { type: "SET_IMAGE_URL"; imageUrl: string | null };
type State = {
  error: string | null;
  file: File | null;
  uploadedUrl: string | null;
  progress: number;
  imageUrl: string | null;
};
const UploadImageReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "SET_FILE":
      return {
        ...state,
        file: action.file,
      };
    case "SET_IMAGE_URL": {
      return {
        ...state,
        imageUrl: action.imageUrl,
      };
    }
    case "SET_PROGRESS": {
      return {
        ...state,
        progress: action.progress,
      };
    }
    case "SET_UPLOADED_URL": {
      return {
        ...state,
        uploadedUrl: action.uploadedUrl,
      };
    }
  }
};

const initialState = {
  error: null,
  file: null,
  uploadedUrl: null,
  progress: 0,
  imageUrl: null,
};

export const useUploadImage = () => {
  const { sub } = useSub();

  const [state, dispatch] = useReducer(UploadImageReducer, initialState);
  const appDispatch = useAppDispatch();
  const userImageKey = `${sub}_${UserImageType.Avatar}new.jpg`;

  const isValidImage = (file: File): any => {
    dispatch({
      type: "SET_ERROR",
      error: null,
    });
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.size > 1 << 21
    )
      dispatch({
        type: "SET_ERROR",
        error:
          "Błędny rozmiar i format pliku. Wczytaj zdjęcie .png lub .jpg w rozmiarze do 2MB.",
      });
    else if (file.type !== "image/jpeg" && file.type !== "image/png")
      dispatch({
        type: "SET_ERROR",
        error:
          "Błędny format pliku zdjęcia profilowego. Wczytaj plik w formacie .png lub .jpg",
      });
    else if (file.size > 1 << 21)
      dispatch({
        type: "SET_ERROR",
        error:
          "Błędny rozmiar i format pliku. Wczytaj zdjęcie .png lub .jpg w rozmiarze do 2MB.",
      });
    return (
      (file.type === "image/jpeg" || file.type === "image/png") &&
      file.size <= 1 << 21
    );
  };

  const imageUpload = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!isValidImage(file)) return;
    file && dispatch({ type: "SET_FILE", file });
    new AWS.S3()
      .upload(
        { Bucket: s3Bucket, Key: userImageKey, Body: file },
        async (error, data) => {
          console.log({ data, error });
          if (error) {
            await avatarDelete(sub, userImageKey);
            console.error(
              `Uploading image failed with error: ${error.message}`
            );
          }
          dispatch({
            type: "SET_UPLOADED_URL",
            uploadedUrl: data.Location,
          });
          dispatch({
            type: "SET_IMAGE_URL",
            imageUrl: data.Location + "?" + Math.random(),
          });
          console.log(state);
          dispatch({
            type: "SET_PROGRESS",
            progress: 0,
          });
        }
      )
      .on("httpUploadProgress", (event) => {
        dispatch({
          type: "SET_PROGRESS",
          progress: Math.floor((event.loaded / event.total) * 100),
        });
      });
  };

  const saveImage = () => {
    const userImageKey = `${sub}_${UserImageType.Avatar}.jpg`;
    new AWS.S3().upload(
      { Bucket: s3Bucket, Key: userImageKey, Body: state.file },
      async (error, data) => {
        if (error) {
          avatarDelete(sub, userImageKey);
          console.error(`Saving image failed with error: ${error.message}`);
        }
        try {
          await updateUser(sub, { avatarUrl: data.Location });
          dispatch({
            type: "SET_IMAGE_URL",
            imageUrl: data.Location + "?" + Math.random(),
          });
          appDispatch(
            userApi.util.updateQueryData("getUser", { sub }, (draft) => {
              draft = {
                ...draft,
                // add random key in order to refresh browser cache
                avatarUrl: `${data.Location}?${Math.random()}`,
              };
              return draft;
            })
          );
        } catch (error) {
          avatarDelete(sub, userImageKey);
          updateUser(sub, { avatarUrl: null });
          dispatch({
            type: "SET_IMAGE_URL",
            imageUrl: null,
          });
          console.error(error);
        }
        dispatch({
          type: "SET_UPLOADED_URL",
          uploadedUrl: null,
        });
      }
    );
  };

  const cancelUpload = () => {
    dispatch({
      type: "SET_UPLOADED_URL",
      uploadedUrl: null,
    });
  };

  const removeImage = async () => {
    dispatch({
      type: "SET_IMAGE_URL",
      imageUrl: null,
    });
    try {
      await avatarDelete(sub, userImageKey);
    } catch (error) {
      // todo better error handling
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return {
    ...state,
    isValidImage,
    imageUpload,
    saveImage,
    cancelUpload,
    removeImage,
    dispatch,
  };
};
