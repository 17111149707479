import React, { useState } from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import DeleteIcon from "common/icons/DeleteIcon";
import { GroupUser } from "store/tasker/models/GroupUser";

import { UserNamePopUp } from "./ResponsiblePersons/AddPersonsModal/AddPersonsModal.styled";

interface PopUpProfileCardProps {
  onClick: any;
  user: GroupUser;
  top: string;
  left: string;
}

export default function PopUpProfileCard({
  onClick,
  user,
  top,
  left,
}: PopUpProfileCardProps) {
  const [isHover, setIsHover] = useState(false);

  return (
    <RelativeContainer
      key={user.user_id}
      w="45px"
      h="45px"
      p="8px 0"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Text>
        <Image
          src={user.user?.avatarUrl || "/icons/profileIconExample.svg"}
          width={40}
          height={40}
          radius="100%"
        />
      </Text>
      <DeleteIcon top="-9px" left="26px" onClick={onClick} />
      {isHover && (
        <UserNamePopUp top={top} left={left}>
          <Image
            src={user.user?.avatarUrl || "/icons/profileIconExample.svg"}
            alt="polygon"
            width={48}
            height={48}
            data-testid="username-popup"
          />
          <Flex direction="column" width="200px" gap="2px">
            <Text
              size="16px"
              lineHeight="20px"
              weight="bold"
              align="left"
              style={{ alignItems: "left !important" }}
            >
              {user.user?.first_name + user.user?.last_name}
            </Text>
            <Text
              size="14px"
              weight="normal"
              lineHeight="20px"
              align="left"
              style={{ alignItems: "left !important" }}
            >
              Robie gofry przy Molo
            </Text>
            <Flex direction="row">
              <Image
                src="/icons/location_on.svg"
                alt="polygon"
                width={14}
                height={14}
                data-testid="username-popup"
                marginRight="3px"
              />
              <Text size="12px" weight="thin">
                {user.user?.email || "No City"}
              </Text>
            </Flex>
          </Flex>
        </UserNamePopUp>
      )}
    </RelativeContainer>
  );
}
