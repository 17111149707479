import { boolean, object, string } from "yup";

export const educationSchema = object().shape({
  schoolName: string()
    .max(50, "Nazwa uczelni może zawierać maksymalnie 50 znaków.")
    .required("Pole wymagane"),
  educationStarted: string().required("Pole wymagane").min(1, "Pole wymagane"),
  educationFinished: string().when("currentlyInSchool", {
    is: false,
    then: string().required("Pole wymagane").min(1, "Pole wymagane"),
  }),
  currentlyInSchool: boolean(),
});
