import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Buffer } from "buffer";
import Button from "common/components/Button";
import Flex from "common/components/Flex";
import { Input, ValidationLabel } from "common/components/InputField";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import Text from "common/components/Text";
import { localStorageKeys, Paths } from "common/constants";
import { theme } from "common/theme/theme";
import { format } from "date-fns";
import {
  createNote,
  NoteStatusDto,
  updateNote,
} from "services/api/note/noteApi";
import { getSub } from "store/auth/authUtils";
import { useAppSelector } from "store/hooks";

import { useDeleteNote } from "../Hooks/useDeleteNote";
import DeleteNoteModal from "../Modals/deleteNoteModalContent";
import { NotesPageContainer, NotesPageHeader } from "../NotePage.styled";
import { INoteLocalStorage } from "../Types/notes";

import CreateNoteHeader from "./Header/NewNoteHeader/NewNoteHeader";
import {
  NewNoteBottomButtons,
  NewNoteHeader,
  NewNoteWrapper,
  TitleWrapper,
} from "./NewNote.styled";
import TexEditor from "./TextEditor/TextEditor";

export default function NewNote() {
  const userId = getSub();
  const navigate = useNavigate();
  const notesSlice = useAppSelector((state) => state.note);
  const noteToEdit = useAppSelector((state) => state.note.noteToEdit);
  const [currentText, setCurrentText] = useState<string>(
    `[{"type":"paragraph","children":[{"text":"Your note"}]}]`
  );
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [titleTouched, setTitleTouched] = useState(false);
  const { deleteNoteModal, performDeleteNote } = useDeleteNote(
    userId,
    noteToEdit?.id
  );
  useEffect(() => {
    if (notesSlice.noteToEdit) {
      setCurrentText(notesSlice.noteToEdit.noteContents);
      setCurrentTitle(notesSlice.noteToEdit.title);
    }
  }, [notesSlice.noteToEdit]);
  useEffect(() => {
    if (notesSlice.noteAutoSave) {
      const localStorageItem = {
        created: new Date().toString(),
        lastUpdateDate: new Date().toString(),
        noteContents: currentText,
        title: currentTitle,
        status: NoteStatusDto.OPEN,
        id: "localstorage",
        owner: userId,
        category: "",
        categoryName: "",
        edit: false,
      } as INoteLocalStorage;
      localStorage.setItem(
        localStorageKeys.note,
        JSON.stringify(localStorageItem)
      );
      if (notesSlice.noteToEdit?.id) {
        localStorageItem.id = notesSlice.noteToEdit.id;
        localStorageItem.edit = true;
        localStorage.setItem(
          localStorageKeys.note,
          JSON.stringify(localStorageItem)
        );
      }
    }
  }, [
    notesSlice.noteAutoSave,
    currentText,
    currentTitle,
    notesSlice.noteToEdit?.id,
    userId,
  ]);
  console.log(currentText);
  const save = async () => {
    if (currentTitle.length < 3) {
      !titleTouched && setTitleTouched(true);
      return;
    }

    notesSlice.noteToEdit
      ? await updateNote({
          note_contents: Buffer.from(currentText).toString("base64"),
          title: currentTitle,
          id: notesSlice.noteToEdit.id,
        })
      : await createNote({
          note_contents: Buffer.from(currentText).toString("base64"),
          title: currentTitle,
          owner: userId,
          category: null,
        });
    navigate(Paths.NOTES);
  };

  const setTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    !titleTouched && setTitleTouched(true);
    setCurrentTitle(e.target.value);
  };

  const isErrorOnTitle = (): boolean => {
    if (titleTouched && currentTitle.length < 3) {
      return true;
    }
    return false;
  };

  const options = [
    {
      img: "/icons/plus.svg",
      text: "Dodaj kategorię",
      action: () => null,
      testid: "edit-category-btn",
    },
  ];

  useEffect(() => {
    notesSlice.noteToEdit &&
      options.push({
        img: "/icons/trash-can.svg",
        text: "Usuń notatkę",
        action: () => {
          deleteNoteModal.showModal();
        },
        testid: "delete-note-btn",
      });
  });

  return (
    <NotesPageContainer>
      <NotesPageHeader>
        <CreateNoteHeader />
      </NotesPageHeader>
      <NewNoteWrapper>
        <NewNoteHeader>
          {noteToEdit && notesSlice.noteToEdit.lastUpdateDate ? (
            <Text size="12px" color="#4A4A4A">
              Ostatnia zmiana{" "}
              {format(
                new Date(notesSlice.noteToEdit.lastUpdateDate),
                "dd.MM.yyyy HH:mm"
              )}
            </Text>
          ) : (
            <Text size="12px" color="#4A4A4A"></Text>
          )}
          <MakeActionDropdown dropdownMenuOptions={options} />
        </NewNoteHeader>
        <TitleWrapper>
          <Flex position="relative" direction="column">
            <Input
              placeholder="Tytuł.."
              value={currentTitle}
              onChange={(e) => setTitle(e)}
              onBlur={() => setTitleTouched(true)}
              noteTitle
              isValid={currentTitle.length > 3}
            />
            {isErrorOnTitle() && (
              <ValidationLabel isValid={currentTitle.length > 3} top="55px">
                Title must have at least 3 characters
              </ValidationLabel>
            )}
          </Flex>
        </TitleWrapper>
        <TexEditor setCurrentText={setCurrentText} />
      </NewNoteWrapper>
      <NewNoteBottomButtons>
        <Button
          display="flex"
          width="90px"
          height="30px"
          radius="40px"
          backgroundColor="none"
          variant="outlined"
          border="none"
          onClick={() => navigate("/notes")}
        >
          <Text size="16px" color={theme.color.basicBlack}>
            Anuluj
          </Text>
        </Button>

        <Button
          display="flex"
          width="90px"
          height="30px"
          radius="40px"
          margin="0 0 0 10px"
          onClick={save}
        >
          <Text size="16px" color={theme.color.basicWhite}>
            Zapisz
          </Text>
        </Button>
      </NewNoteBottomButtons>
      <DeleteNoteModal
        modalProps={deleteNoteModal.modalProps}
        deleteAction={() => {
          performDeleteNote();
          navigate(Paths.NOTES);
        }}
        redirection={true}
      />
    </NotesPageContainer>
  );
}
