import React, { useEffect, useState } from "react";

import Text from "common/components/Text";

import { InfoTextWrapper } from "./GroupInformationText.styled";
import ShowMore from "./ShowMore/ShowMore";

interface GroupInformationTextProps {
  information: string;
}

const FULL_INFO_LENGTH = 1000;
const LIMITED_INFO_LENGTH = 500;

const GroupInformationText = ({ information }: GroupInformationTextProps) => {
  const [showMore, setShowMore] = useState(false);
  const [shownInformation, setShownInformation] = useState("");

  const showMoreHandler = () => {
    setShowMore((prevShow) => !prevShow);
  };

  useEffect(() => {
    setShownInformation(
      showMore
        ? information?.slice(0, FULL_INFO_LENGTH)
        : information?.slice(0, LIMITED_INFO_LENGTH)
    );
  }, [showMore, information]);

  return (
    <>
      <InfoTextWrapper>
        <Text size="12px">
          {shownInformation}
          {information?.length > LIMITED_INFO_LENGTH && !showMore
            ? "..."
            : null}
        </Text>
      </InfoTextWrapper>
      {information?.length > LIMITED_INFO_LENGTH ? (
        <ShowMore showMoreHandler={showMoreHandler} isShownMore={showMore} />
      ) : null}
    </>
  );
};

export default GroupInformationText;
