import React, { ReactNode, useEffect, useRef } from "react";

import useIsVisible from "common/hooks/useIsVisible";
import { useGetCommentsQuery } from "store/group/groupApi";
import { useAppSelector } from "store/hooks";

import CommentContent from "./CommentContent/CommentContent";
import {
  CommentLine,
  CommentListItemContentWrapper,
  CommentListItemWrapper,
  LineWrapper,
  RoundedLine,
  SubCommentLine,
  UserIconWrapper,
} from "./CommentListItem.styled";
import CommentReply from "./CommentReply/CommentReply";
import CommentSettings from "./CommentSettings/CommentSettings";
import PostSubComment from "./PostSubComment/PostSubComment";
import UserIcon from "./UserIcon/UserIcon";

interface CommentListItemProps {
  content: ReactNode;
  userIcon: ReactNode;
  footer: ReactNode;
  isLastComment: boolean;
  addPage: () => void;
  currentCommentId: string;
  isComment: boolean;
}

const CommentListItem = ({
  content,
  footer,
  userIcon,
  isLastComment,
  addPage,
  currentCommentId,
  isComment,
}: CommentListItemProps) => {
  const targetRef = useRef();
  const { isVisible } = useIsVisible(targetRef);
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const { data: subComments } = useGetCommentsQuery(
    {
      groupId: currentGroup?.groupId,
      page: 1,
      rows: 1,
      parentId: currentCommentId,
    },
    { skip: !isComment }
  );
  useEffect(() => {
    if (isLastComment && isVisible) addPage();
  }, [isVisible, isLastComment, addPage]);
  return (
    <>
      <CommentListItemWrapper
        gridColumn={isComment ? "1fr 568px 1fr" : "52px  1fr 521px 1fr "}
        ref={targetRef}
      >
        {!isComment && (
          <>
            {!isLastComment && <SubCommentLine />}
            <LineWrapper>
              <RoundedLine />
            </LineWrapper>
          </>
        )}

        <UserIconWrapper>
          {userIcon}
          {isComment && subComments && <CommentLine />}
        </UserIconWrapper>

        <CommentListItemContentWrapper>
          {content}
          {footer}
        </CommentListItemContentWrapper>
      </CommentListItemWrapper>
      {isComment && <PostSubComment currentCommentId={currentCommentId} />}
    </>
  );
};

export default CommentListItem;

CommentListItem.UserIcon = UserIcon;
CommentListItem.Content = CommentContent;
CommentListItem.Settings = CommentSettings;
CommentListItem.Footer = CommentReply;
