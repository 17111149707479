import React, { useState } from "react";

import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { theme } from "common/theme/theme";
import { AddSubcategoryModal } from "components/NotesPage/NotesRightPanel/AddSubcategoryModal/AddSubcategoryModal";
import { getSub } from "store/auth/authUtils";

import DeleteCategoryModal from "../Modals/deleteCategoryModalContent";
import { EditCategoryModal } from "../Modals/editCategoryModalContent";
import { ICategory } from "../Types/notes";

import {
  AccordionWrapper,
  CategoryImage,
  CategoryLabel,
} from "./NotesRightPanel.styled";

interface NotesRightPanelCategoryProps {
  title: string;
  isSelected: boolean;
  categoryId: string;
  selectCategory: () => void;
  children: React.ReactNode;
  subcategories?: ICategory[];
  handleUpdateCategories?: (
    categories: ICategory[],
    subcategories: ICategory[]
  ) => void;
}

export interface Parent {
  text: string;
  value: string;
}
export interface AddSubcategoryForm {
  subcategoryName: string;
  parent: Parent;
}

interface CategoryOptions {
  testid: string;
  img: string;
  text: string;
  action: any;
}

const NotesRightPanelCategory = ({
  title,
  isSelected,
  categoryId,
  children,
  selectCategory,
}: NotesRightPanelCategoryProps) => {
  const [show, setShow] = useState(false);
  const ownerId = getSub();
  const addSubcategoryModal = useModal();
  const editCategoryModal = useModal();
  const deleteCategoryModal = useModal();

  const options: CategoryOptions[] = [
    {
      testid: "1",
      text: "Utwórz podkategorię",
      img: "/icons/addSign.svg",
      action: addSubcategoryModal.showModal,
    },
    {
      testid: "2",
      text: "Edytuj kategorię",
      img: "/icons/pen.svg",
      action: editCategoryModal.showModal,
    },
    {
      testid: "3",
      text: "Usuń kategorię",
      img: "/icons/bin.svg",
      action: deleteCategoryModal.showModal,
    },
  ];

  const closeModal = () => {
    addSubcategoryModal.modalProps.closeModal();
  };

  const handleClick = () => setShow((prevIsShow) => !prevIsShow);

  const iconSrc = show ? `/icons/filled_down.svg` : `/icons/filled_right.svg`;

  return (
    <>
      <AccordionWrapper
        tabIndex={0}
        isSelected={isSelected}
        onClick={selectCategory}
      >
        <ModalPortal {...addSubcategoryModal.modalProps}>
          <AddSubcategoryModal onClose={closeModal} categoryId={categoryId} />
        </ModalPortal>

        <DeleteCategoryModal
          ownerId={ownerId}
          itemId={categoryId} // Change to itemId
          modalProps={deleteCategoryModal.modalProps}
          itemName={title} // Change to itemName
          isSubCategory={false} // Set to false for category
        />

        <EditCategoryModal
          categoryId={categoryId}
          modalProps={editCategoryModal.modalProps}
          ownerId={ownerId}
          onClose={editCategoryModal.modalProps.closeModal}
        />

        <>
          <CategoryImage
            src={iconSrc}
            alt="icon"
            width={10}
            height={10}
            onClick={handleClick}
            isHidden={!children && true}
          />
          <CategoryLabel
            title={title}
            weight={isSelected ? "bold" : "normal"}
            size="14px"
            color={theme.color.basicBlack}
            ml="25px"
            mr="12px"
            lineHeight="20px"
            letterSpacing="0.4px"
          >
            {title}
          </CategoryLabel>
        </>
        <MakeActionDropdown dropdownMenuOptions={options} left="-200px" />
      </AccordionWrapper>
      {show && children
        ? React.Children.map(children, (child, index) =>
            React.cloneElement(child as React.ReactElement, {
              subcategoryKey: index,
            })
          )
        : null}
    </>
  );
};

export default NotesRightPanelCategory;
