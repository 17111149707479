import { ICategory } from "components/NotesPage/Types/notes";
import * as yup from "yup";
export const validationSchema = (
  activeCategoryName: string,
  categories: ICategory[]
) => {
  return yup.object().shape({
    parent: yup.object().shape({
      text: yup
        .string()
        .test(
          "categoryNotExists",
          "Wpisana kategoria nie istnieje. Wybierz kategorię z listy.",
          (value) => {
            return categories.map((el) => el.categoryName).includes(value);
          }
        ),
    }),
    subcategoryName: yup
      .string()
      .min(3, "Nazwa jest za krótka, musi zawierać minimum 3 znaki.")
      .max(20, "Nazwa jest za długa, może zawierać maks. 20 znaków.")
      .test(
        "subcategoryExists",
        "Podkategoria o podanej nazwie już istnieje.",
        (value) => {
          const sub = categories.find(
            (item) => item.categoryName === activeCategoryName
          );
          if (!sub) return true;
          const subName = sub.subCategories.map((el) =>
            el.categoryName.toLowerCase()
          );
          return !subName.includes(value.toLowerCase());
        }
      )
      .nullable(true)
      .trim(),
  });
};

export default validationSchema;
