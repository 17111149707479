import { theme } from "common/theme/theme";
import styled from "styled-components";

interface AttachmentSliderBottomItemWrapperProps {
  imageUrl: string;
  isSelected: boolean;
}

export const AttachmentSliderBottomItemWrapper = styled.div<AttachmentSliderBottomItemWrapperProps>`
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected ? `border: solid 3px ${theme.color.veryLightOrange}` : ""};
  border-radius: 4px;
  box-sizing: border-box;
  transition: border 0.05s ease;
  width: 115px;
  height: 65px;
  background-image: url("${({ imageUrl }) => imageUrl}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
