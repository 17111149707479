import React from "react";

import Flex from "common/components/Flex";
import MainViewLoader from "components/TaskerPage/utils/MainViewLoader";
import { useAppSelector } from "store/hooks";
import { GroupData } from "store/tasker/taskerSlice.types";

import TaskerUserColumnWrapper from "./TaskerUserColumnWrapper/TaskerUserColumnWrapper";

export default function TaskerUserTodo() {
  const taskerSlice = useAppSelector((s) => s.tasker);

  if (taskerSlice.loading) {
    return <MainViewLoader />;
  }

  return (
    <Flex
      direction="row"
      wrap="nowrap"
      mt="15px"
      gap="12px"
      p="0 0 15px 0"
      overFlowX="auto"
    >
      {taskerSlice.userViewTree.groups.map((l: GroupData) => {
        return l.group.tasks.filter((t) => !t.completed).length > 0 ? (
          <TaskerUserColumnWrapper key={l.group.id} group={l.group} />
        ) : null;
      })}
    </Flex>
  );
}
