import { useEffect, useState } from "react";

export const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(isMounted);
  useEffect(() => {
    if (isMounted) {
      setShouldRender(true);
    } else {
      setTimeout(() => setShouldRender(false), delayTime);
    }
  }, [isMounted, delayTime]);
  return shouldRender;
};
