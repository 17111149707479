import * as React from "react";

import {
  ModalForm,
  ModalFormTitle,
} from "components/Profile/Details/ModalForm/ModalForm.styled";
import styled from "styled-components";

import { CopyToClipboard } from "./CopyToClipboard";
import { ModalGroup } from "./ModalGroup";
import { ModalLabel } from "./ModalLabel";
import { SendEmail } from "./SendEmail";

const CloseModalStyled = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
  appearance: unset;
  border: none;
  background-color: transparent;
`;

function CloseModalButton(
  props: React.ComponentProps<typeof CloseModalStyled>
) {
  return (
    <CloseModalStyled aria-label="zamknij modal" {...props}>
      <img src="/images/close-modal-icon.svg" />
    </CloseModalStyled>
  );
}

export const InvitationModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <ModalForm>
      <CloseModalButton onClick={onClose} />
      <ModalFormTitle>Zaproś do Acorn</ModalFormTitle>
      <ModalGroup>
        <ModalLabel>Zaproś przy użyciu linka:</ModalLabel>
        <CopyToClipboard />
      </ModalGroup>
      <ModalGroup>
        <ModalLabel>
          Wprowadź adres mailowy, na który <br /> zostanie wysłane zaproszenie:
        </ModalLabel>
        <SendEmail />
      </ModalGroup>
    </ModalForm>
  );
};
