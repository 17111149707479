import React from "react";

export const IconCheckboxEmpty = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 2V16H2V2H16ZM18 0H0V18H18V0Z" fill="#323232" />
  </svg>
);
