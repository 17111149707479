import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "common/components/FormInputHookForms";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import { useSub } from "common/hooks/useSub";
import { useGetUserQuery, useUpdateUserMutation } from "store/user/userApi";
import { object, string } from "yup";

import {
  ModalForm,
  ModalFormButtons,
  ModalFormFooter,
  ModalFormTitle,
} from "../ModalForm/ModalForm.styled";

interface Props {
  onClose: () => void;
}

export const ModalContact = ({ onClose }: Props) => {
  const { sub } = useSub();

  const { data: user } = useGetUserQuery(
    { sub },
    {
      skip: !sub,
    }
  );

  const [updateUser] = useUpdateUserMutation();

  const {
    register,
    handleSubmit: handleContactFormSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      object().shape({
        city: string().max(
          30,
          "Nazwa miasta może zawierać maksymalnie 30 znaków."
        ),
        country: string().max(
          30,
          "Nazwa kraju może zawierać maksymalnie 30 znaków."
        ),
      })
    ),
    defaultValues: {
      city: user?.city ?? "",
      country: user?.country ?? "",
    },
  });

  // WARNING: Yes, default values are decalred in useForm, but sometimes they are not set in the form, so I have to set them manually here
  useEffect(() => {
    if (user?.city) {
      setValue("city", user.city);
    }
    if (user?.country) {
      setValue("country", user.country);
    }
  }, [setValue, user]);

  const onContactFormSubmit = handleContactFormSubmit((userData) => {
    updateUser({ sub, userData });
    onClose();
  });

  return (
    <ModalForm onSubmit={onContactFormSubmit}>
      <ModalFormTitle>Edytuj informacje</ModalFormTitle>
      <FormInput
        mt="1rem"
        mb="3rem"
        label="Miasto"
        touched={true}
        type="text"
        inputName="city"
        register={register}
        id="city"
        placeholder="Miasto"
        isValid={errors.city === undefined}
        errorText={errors.city?.message}
      />
      <FormInput
        mb="2rem"
        label="Kraj"
        touched={true}
        type="text"
        inputName="country"
        register={register}
        id="country"
        placeholder="Kraj"
        isValid={errors.country === undefined}
        errorText={errors.country?.message}
      />
      <ModalFormFooter>
        <ModalFormButtons>
          <GhostButton
            onClick={onClose}
            width="max-content !important"
            padding="1rem"
            display="flex"
            margin="0 .5rem 0 0"
          >
            Anuluj
          </GhostButton>
          <SmallButton>Zapisz</SmallButton>
        </ModalFormButtons>
      </ModalFormFooter>
    </ModalForm>
  );
};
