import React from "react";

import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";

import { DropdownWrapper } from "./CommentSettings.styled";

interface CommentSettingsProps {
  handleBlur: () => void;
  handleHover: () => void;
  isHovered: boolean;
}

const CommentSettings = ({
  handleHover,
  handleBlur,
  isHovered,
}: CommentSettingsProps) => {
  const options = [
    {
      testid: "1",
      text: "Edytuj komentarz",
      img: "/icons/pen.svg",
      action: () => null,
    },
    {
      testid: "2",
      text: "Usuń komentaz",
      img: "/icons/bin.svg",
      action: () => null,
    },
  ];

  return (
    <DropdownWrapper
      isHovered={isHovered}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <MakeActionDropdown
        dropdownMenuOptions={options}
        icon="/icons/comment/settings.svg"
        left="-200px"
      />
    </DropdownWrapper>
  );
};

export default CommentSettings;
