import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const GroupCreationFinalStageWrapper = styled.div`
  height: 347px;
  width: 514px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  place-items: center;
`;

export const CongratulationHeader = styled(Text)``;

export const CongratulationParagraph = styled(Text)``;

export const CongratulationButton = styled.button`
  height: 52px;
  width: 332px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${theme.color.basicBlack};
  border-radius: 40px;
  font-weight: 700;
  font-size: 24px;
  color: ${theme.color.basicWhite};
`;
