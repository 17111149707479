import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface TaskerTextAreaProps {
  clicked?: boolean;
  isValid?: boolean;
  rows?: string;
}

export const TaskerTextArea = styled.textarea<TaskerTextAreaProps>`
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid
    ${(p) => {
      if (p.clicked) {
        if (p.isValid) {
          return theme.color.slightlyLightGray;
        } else {
          return theme.color.alertRed;
        }
      } else {
        return theme.color.slightlyLightGray;
      }
    }};
  &:focus {
    border: 1px solid
      ${(p) =>
        p.isValid
          ? theme.color.darkGray + "!important"
          : theme.color.alertRed + "!important"};
  }
  height: 120px !important;
  color: ${theme.color.mediumGray};
  rows: ${(props) => props.rows || "1"};
`;
