import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const ShowMoreWrapper = styled.a`
  margin-top: 16px;
  margin-left: 116px;
  cursor: pointer;
`;

export const ShowMoreText = styled(Text)`
  color: ${theme.color.mediumGray};
`;
