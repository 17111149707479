import { theme } from "common/theme/theme";
import styled from "styled-components";

interface IGroupPlainButtonProps {
  height: string;
  width: string;
  isProceedButton: boolean;
}

export const GroupStyledPlainButton = styled.button<IGroupPlainButtonProps>`
  color: ${({ isProceedButton }) =>
    isProceedButton ? theme.color.basicWhite : theme.color.basicBlack};
  border-radius: 40px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color ${({ isProceedButton }) =>
    isProceedButton ? theme.color.veryDarkGray : theme.color.basicWhite};
  font-weight: ${theme.fontWeight.bold};
  font-size: 14px;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  text-align: center;
`;
