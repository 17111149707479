import React from "react";

import IconButton from "common/components/IconButton";
import { useSub } from "common/hooks/useSub";
import { postConfig } from "components/Group/GroupDiscussion/postConfig";
import { useGetUserQuery } from "store/user/userApi";

const UserIcon = () => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub });
  return (
    <IconButton
      width={40}
      height={40}
      radius="100%"
      icon={user.avatarUrl ?? postConfig.userPlaceholderAvatar}
      backgroundColor="white"
      marginRight="16px"
    />
  );
};

export default UserIcon;
