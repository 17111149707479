import React from "react";

import { Contact } from "./Contact/Contact";
import { Education } from "./Education/Education";
import { Job } from "./Job/Job";

const Details = () => {
  return (
    <>
      <Contact />
      <Education />
      <Job />
    </>
  );
};

export default Details;
