import React from "react";

import { Descendant, Editor, Node, Text, Transforms } from "slate";

export const initialValue: Descendant[] | any = [
  {
    type: "paragraph",
    children: [
      {
        text: "Your note..",
      },
    ],
  },
];

export const Leaf = (props) => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.bold ? "bold" : "normal" }}
    >
      {props.children}
    </span>
  );
};

export const DefaultElement = (props) => {
  return <p {...props.attributes}> {props.children}</p>;
};

// Define a React component renderer for our code blocks.
export const CodeElement = (props) => {
  return (
    <pre {...props.attributes}>
      <b>{props.children}</b>
    </pre>
  );
};

export const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n: any) => n.bold === true,
      universal: true,
    });

    return !!match;
  },

  isCodeBlockActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n: any) => n.type === "code",
    });

    return !!match;
  },

  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(editor, { bold: isActive ? null : true } as any, {
      match: (n) => Text.isText(n),
      split: true,
    });
  },

  toggleCodeBlock(editor) {
    const isActive = CustomEditor.isCodeBlockActive(editor);
    Transforms.setNodes(editor, { type: isActive ? null : "code" } as any, {
      match: (n: any) => Editor.isBlock(editor, n),
    });
  },
};

export const serializeText = (value) => {
  value = JSON.parse(value) || [];
  return value.map((n: any) => Node.string(n)).join("\n");
};

export const deserializeText = (string: string) => {
  return string.split("\n").map((line) => {
    return {
      children: [{ text: line }],
    };
  });
};
