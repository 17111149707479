import React, { useState } from "react";

import { ToggleContainer, ToggleImage, ToggleSwitch } from "./Toggle.styled";

interface ToggleProps {
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Toggle: React.FC<ToggleProps> = ({ defaultChecked, onChange }) => {
  const [checked, setChecked] = useState(defaultChecked || false);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <ToggleContainer onClick={handleToggle}>
      <ToggleSwitch isActive={checked}>
        {checked && (
          <ToggleImage
            src="/icons/notes/checkmark.svg"
            width={8.8}
            height={6.7}
          />
        )}
      </ToggleSwitch>
    </ToggleContainer>
  );
};

export default Toggle;
