import Card from "common/components/Card/Card";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const GroupMembersWrapper = styled(Card)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 16px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 56px;
  width: 100%;
  height: 832px;
`;

export const GroupMembersInput = styled.input`
  width: 220px;
  height: 18px;
  outline: none;
  border: 1px solid ${theme.color.slightlyLightGray};
  border-radius: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
  padding-left: 16px;
  text-size: 14px;
`;

export const GroupMembersInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
`;

export const MembersSearchIconWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 10px;
`;

export const GroupMembersTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
`;
