import React from "react";

import GroupModal from "components/Group/Common/GroupModal/GroupModal";

interface ConfirmLeavingModalProps {
  cancelButtonHandler: () => void;
  proceedButtonHandler: () => void;
}

const ConfirmLeavingModal = ({
  cancelButtonHandler,
  proceedButtonHandler,
}: ConfirmLeavingModalProps) => {
  return (
    <GroupModal
      cancelButtonHandler={cancelButtonHandler}
      cancelButtonText="Wróć"
      headerText="Przerywasz tworzenie komentarza?"
      descriptionText="Czy chcesz usunąć stworzoną treść?"
      descriptionMarginTop="16px"
      modalHeight="127px"
      modalWidth="303px"
      cancelButtonHeight="35px"
      cancelButtonWidth="77px"
      proceedButtonHeight="35px"
      proceedButtonWidth="76px"
      proceedButtonHandler={proceedButtonHandler}
      proceedButtonText="Usuń"
      modalPadding="32px"
      singleButton={false}
      spacingConfiguration={{
        spaceBetweenDescriptionAndButtons: "32px",
        spaceBetweenHeaderAndDescription: "16px",
      }}
    />
  );
};

export default ConfirmLeavingModal;
