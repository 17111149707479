import React from "react";

import Button from "common/components/Button";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";

import { GroupButtonWrapper } from "./GroupButtonPrimary.styled";

interface GroupButtonPrimaryProps {
  clickHandler: () => void;
  buttonHeight: string;
  buttonWidth: string;
  lightMode: boolean;
  buttonText: string;
}

const GroupButtonPrimary = ({
  buttonHeight,
  buttonWidth,
  buttonText,
  clickHandler,
  lightMode,
}: GroupButtonPrimaryProps) => {
  return (
    <GroupButtonWrapper>
      <Button
        width={buttonWidth}
        height={buttonHeight}
        variant={lightMode ? "outlined" : "primary"}
        onClick={clickHandler}
        data-testid="group-handle-button"
      >
        <Wrapper>
          <Image
            src={lightMode ? "/icons/accept-sign.svg" : "/icons/plus_white.svg"}
            alt="plus"
            width={14}
            height={14}
          />
          <Text
            weight="bold"
            color={lightMode ? theme.color.basicBlack : theme.color.basicWhite}
            ml="13px"
          >
            {buttonText}
          </Text>
        </Wrapper>
      </Button>
    </GroupButtonWrapper>
  );
};

export default GroupButtonPrimary;
