import { shiftDate } from "common/utils/dateUtils";
import { ITaskInForm } from "components/TaskerPage/NewTaskView/NewTaskView";
import { getSub } from "store/auth/authUtils";
import { GroupUser } from "store/tasker/models/GroupUser";
import { Task } from "store/tasker/models/Task";

import { ITaskResponse } from "./tasker.responses";
import { TaskerUpdateTaskPayload } from "./taskerUpdateTaskRq";

export function mapTaskToUpdateTaskPayload(
  data: ITaskInForm,
  assignee: GroupUser,
  groupId: string,
  taskId: string
): TaskerUpdateTaskPayload {
  let reminder_date: string | null = null;
  if (data.reminder_date === "dayBefore") {
    reminder_date = shiftDate(data.realisationDate, -1).toISOString();
  }

  const payload: TaskerUpdateTaskPayload = {
    task_title: data.task_title,
    description: data.description,
    deadline_date: data.realisationDate.toISOString(),
    reminder_date: reminder_date,
    assignee: assignee?.user_id ?? null,
    list_id: data.listName,
    priority: data.priority,
    created_by: getSub(),
    group_id: groupId,
    task_id: taskId,
    completed: data.completed,
    attachments: null,
  };

  if (data.category_ids?.length > 0) {
    payload.category_ids = data.category_ids.map((l) => l.id);
  }

  return payload;
}

export function mapTaskToUpdateTaskCompletedPayload(
  task: Task,
  groupId: string
): TaskerUpdateTaskPayload {
  const payload: TaskerUpdateTaskPayload = {
    task_id: task.id,
    task_title: task.task_title,
    group_id: groupId,
    assignee: task.assignee?.user_id ?? null,
    created_by: task.created_by,
    deadline_date: task.date,
    reminder_date: task.reminder_date,
    completed: task.completed,
    description: task.description,
    priority: task.priority,
    list_id: task.list_id,
    attachments: null,
    category_ids: task.category_ids,
  };

  return payload;
}

export function createTaskFromTaskResponse(task: ITaskResponse): Task {
  return {
    id: task.task_id,
    attachments: task.attachments,
    task_title: task.task_title,
    description: task.description,
    completed: task.completed,
    priority: task.priority,
    date: task.date,
    created: task.created,
    created_by: task.created_by,
    group_id: task.group_id,
    category_ids: task.category_ids,
    last_update_date: task.last_update_date,
    list_id: task.list_id,
    reminder_date: task.reminder_date,
  };
}
