import React, { FC } from "react";

import Heading from "common/components/Heading";
import Text from "common/components/Text";

const Dashboard: FC = () => {
  return (
    <>
      <Heading>Witaj!</Heading>
      <Text mt="24px">To jest Twoja strona</Text>
    </>
  );
};

export default Dashboard;
