import React from "react";
import { UseFormSetValue } from "react-hook-form";

import Image from "common/components/Image/Image";
import RadioButton from "common/components/RadioButtonHookForms/RadioButton";
import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";

import {
  OptionDescriptionWrapper,
  PublicationOptionWrapper,
} from "./PublicationOption.styled";

interface PublicationOptionProps<T> {
  optionText: string;
  iconSrc: string;
  inputName: string;
  setValue: UseFormSetValue<T>;
  selected: string;
  shouldTextBeDisplayed: boolean;
  value: string;
}

const PublicationOption = <T,>({
  optionText,
  iconSrc,
  inputName,
  setValue,
  selected,
  shouldTextBeDisplayed,
  value,
}: PublicationOptionProps<T>) => {
  return (
    <PublicationOptionWrapper>
      <OptionDescriptionWrapper>
        <Image src={iconSrc} />
        <Text size="16px" color={theme.color.basicBlack}>
          {optionText}
        </Text>
      </OptionDescriptionWrapper>
      <RadioButton
        setValue={setValue}
        inputName={inputName}
        selected={selected}
        shouldTextBeDisplayed={shouldTextBeDisplayed}
        value={value}
      />
    </PublicationOptionWrapper>
  );
};

export default PublicationOption;
