import React, { useState } from "react";

import GroupModal from "../Common/GroupModal/GroupModal";

interface CancelRequestProps {
  closeCancelRequest: () => void;
  confirmCancellation: () => void;
  groupName: string;
}

const CancelRequest = ({
  closeCancelRequest,
  confirmCancellation,
  groupName,
}: CancelRequestProps) => {
  const [isRequestCancelled, setIsRequestCancelled] = useState(false);
  const requestCancellationHandler = () => {
    confirmCancellation();
    setIsRequestCancelled(true);
  };
  return (
    <>
      {!isRequestCancelled && (
        <GroupModal
          headerText="Wycofać prośbę?"
          descriptionText={`Czy na pewno chcesz wycofać wysłaną prośbę o dołączenie do grupy ${groupName}`}
          cancelButtonText="Anuluj"
          cancelButtonHeight="30px"
          cancelButtonWidth="93px"
          cancelButtonHandler={closeCancelRequest}
          proceedButtonHandler={requestCancellationHandler}
          proceedButtonText="Tak wycofaj"
          proceedButtonHeight="30px"
          proceedButtonWidth="114px"
          singleButton={false}
          modalWidth="305px"
          modalHeight="154px"
          modalPadding="12px"
          spacingConfiguration={{
            spaceBetweenDescriptionAndButtons: "24px",
            spaceBetweenHeaderAndDescription: "16px",
          }}
        />
      )}
      {isRequestCancelled && (
        <GroupModal
          singleButton={true}
          singleButtonData={{
            singleButtonWidth: "174px",
            singleButtonHandler: closeCancelRequest,
            singleButtonText: "Przejdź do platformy",
            singleButtonHeight: "30px",
          }}
          modalWidth="305px"
          modalHeight="134px"
          modalPadding="12px"
          headerText="Prośba została wycofana"
          descriptionText={`Twoja prośba o dołączenie do grupy ${groupName} została wycofana poprawnie`}
          spacingConfiguration={{
            spaceBetweenDescriptionAndButtons: "24px",
            spaceBetweenHeaderAndDescription: "16px",
          }}
        />
      )}
    </>
  );
};

export default CancelRequest;
