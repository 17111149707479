import React from "react";

import { useAppSelector } from "store/hooks";

import { MemberCounterText } from "./MembersCounter.styled";

const MembersCounter = () => {
  const groupUsers = useAppSelector((state) => state.group.groupMembers);
  return (
    <MemberCounterText size="12px" mr="16px">
      {groupUsers?.length} członków
    </MemberCounterText>
  );
};

export default MembersCounter;
