import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const ItemWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-items: left;
  width: 90%;
  border-radius: 0.75rem;
  padding: 0.75rem;
  margin: 0 auto;

  background-color: ${theme.color.basicWhite};
  border: none;
  :hover {
    background: ${theme.color.veryLightGray};
    cursor: pointer;
  }
`;
