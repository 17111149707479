import { IPost } from "./groupApi.types";
import { IPostDto } from "./groupApi.types.DTO";

export const mapPostDto = (post: IPostDto): IPost => {
  return {
    attachments: post.attachments,
    postId: post.post_id,
    objectType: post.object_type,
    objectId: post.object_id,
    created: post.created,
    postStatus: post.post_status,
    content: post.content,
    commentsAllowance: post.comments_allowance,
    reactions: post.reactions,
    author: {
      authorType: post.author.author_type,
      authorId: post.author.author_id,
    },
  };
};

export const mapPostsDto = (posts: IPostDto[]): IPost[] => {
  return posts.map((post) => mapPostDto(post));
};
