import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const RightPanelWrapper = styled.aside`
  grid-area: rightSideBar;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 72px);
  overflow: overlay;
  overflow-x: hidden;
  background-color: ${theme.color.basicWhite};
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 72px;

  * {
    user-select: none;
  }
`;
