import React from "react";

export const IconCity = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.93029 0.333008C2.37272 0.333008 0.304199 2.41967 0.304199 4.99967C0.304199 8.49967 4.93029 13.6663 4.93029 13.6663C4.93029 13.6663 9.55637 8.49967 9.55637 4.99967C9.55637 2.41967 7.48785 0.333008 4.93029 0.333008ZM1.62594 4.99967C1.62594 3.15967 3.10629 1.66634 4.93029 1.66634C6.75429 1.66634 8.23463 3.15967 8.23463 4.99967C8.23463 6.91967 6.33133 9.79301 4.93029 11.5863C3.55568 9.80634 1.62594 6.89967 1.62594 4.99967Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.93049 6.66634C5.84296 6.66634 6.58267 5.92015 6.58267 4.99967C6.58267 4.0792 5.84296 3.33301 4.93049 3.33301C4.01802 3.33301 3.27832 4.0792 3.27832 4.99967C3.27832 5.92015 4.01802 6.66634 4.93049 6.66634Z"
      fill="#4A4A4A"
    />
  </svg>
);
