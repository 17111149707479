import React from "react";

import UserListItem from "common/components/UserListItem/UserListItem";
import { IGroupMember } from "store/groupSlice";

import {
  MembersTable,
  MembersTableItemWrapper,
  MembersTableTitle,
} from "./GroupMemberTable.styled";

interface GroupMembersTableProps {
  tableTitle: string;
  users: IGroupMember[];
}

const GroupMembersTable = ({ tableTitle, users }: GroupMembersTableProps) => {
  return (
    <MembersTable>
      <MembersTableTitle>{`${tableTitle} (${users.length})`}</MembersTableTitle>
      <MembersTableItemWrapper>
        {users.map((user) => {
          return (
            <UserListItem
              key={user.user.sub}
              avatarUrl="/icons/user-avatar-placeholder.svg"
              fullName={`${user.user.firstName} ${user.user.lastName}`}
              occupation="Mock occupation"
              localization="Mock localization"
            />
          );
        })}
      </MembersTableItemWrapper>
    </MembersTable>
  );
};

export default GroupMembersTable;
