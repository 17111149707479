import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export default function LineSpacingItem() {
  const iconDown = `/icons/down.svg`;

  return (
    <Flex justify="space-between">
      <Wrapper>
        <Text ml="10px" size="14px">
          Interlinia
        </Text>
      </Wrapper>
      <Flex>
        <NoteKitBorderWrapper p="8px 10px">
          <Text size="14px">Pojedyncza</Text>
          <Text ml="10px">
            <Image src={iconDown} alt="icon" width={13} height={13} top="2px" />
          </Text>
        </NoteKitBorderWrapper>
      </Flex>
    </Flex>
  );
}
