import { theme } from "common/theme/theme";
import styled from "styled-components";
interface CommentListItemWrapperProps {
  gridColumn: string;
}

export const CommentListItemWrapper = styled.div<CommentListItemWrapperProps>`
  display: grid;
  grid-template-columns: ${({ gridColumn }) => ` ${gridColumn} `};
  margin-bottom: 16px;
  word-break: break-all;
  position: relative;
`;
export const UserIconWrapper = styled.div`
  position: relative;
`;
export const LineWrapper = styled.div`
  width: 40px;
  height: 100%;
  position: relative;
`;
export const RoundedLine = styled.div`
  width: 17px;
  height: 22px;
  border: 2px solid ${theme.color.mediumGray};
  border-right: 0px;
  border-top: 0px;
  border-radius: 0 0 0 10px;
  position: absolute;
  left: 19px;
  top: -6px;
`;
export const SubCommentLine = styled.div`
  height: 105px;
  width: 2px;
  background-color: ${theme.color.mediumGray};
  position: absolute;
  left: 19px;
`;
export const CommentLine = styled.div`
  height: 52px;
  width: 2px;
  background-color: ${theme.color.mediumGray};
  position: absolute;
  left: 19px;
  top: 50px;
`;

export const CommentListItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
