import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";

import { NoteKitBorderRadiusWrapper } from "../shared/NoteKitStyles";

export default function TableItem() {
  const icons: string[] = [
    `/icons/noteKit/icon_table.svg`,
    `/icons/noteKit/icon_table_border.svg`,
    `/icons/noteKit/icon_table_background.svg`,
    `/icons/noteKit/icon_table_edit.svg`,
  ];

  return (
    <Flex justify="space-between">
      <Wrapper>
        <Text ml="10px" size="14px">
          Tabela
        </Text>
      </Wrapper>
      <Flex gap="4px">
        {icons.map((v: string, idx: number) => {
          return (
            <NoteKitBorderRadiusWrapper key={idx}>
              <Image src={v} alt="icon" width={32} height={32} />
            </NoteKitBorderRadiusWrapper>
          );
        })}
      </Flex>
    </Flex>
  );
}
