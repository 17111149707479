import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import Image from "common/components/Image";
import { Paths } from "common/constants/paths";

import { BannerWrapper } from "./Banner.styled";

const Banner: FC = () => {
  const navigate = useNavigate();
  const handleImageClick = () => {
    navigate(`${Paths.DASHBOARD}`);
  };
  return (
    <BannerWrapper>
      <Image
        onClick={handleImageClick}
        src="/images/banner.svg"
        alt="Logo Acorn"
        width={116.95}
        height={35}
        cursor="pointer"
      />
    </BannerWrapper>
  );
};

export default Banner;
