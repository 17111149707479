import React, { ReactNode } from "react";

import Image from "../Image/Image";

import {
  EditBackgroundButton,
  InnerContainer,
  OuterContainer,
} from "./WallLayout.styled";

type WallLayoutProps = {
  backgroundUrl?: string;
  backgroundEditable?: boolean;
  onBackgroundEdit?: () => void;
  className?: string;
  children: ReactNode;
};

const WallLayout = ({
  backgroundUrl,
  backgroundEditable,
  onBackgroundEdit,
  className,
  children,
}: WallLayoutProps) => {
  return (
    <OuterContainer className={className} backgroundUrl={backgroundUrl}>
      {backgroundEditable && (
        <EditBackgroundButton onClick={onBackgroundEdit}>
          <Image src="/icons/camera_icon2.svg" alt="Camera icon" />
        </EditBackgroundButton>
      )}
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  );
};

export default WallLayout;
