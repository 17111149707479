import { ITaskInForm } from "components/TaskerPage/NewTaskView/NewTaskView";
import { getSub } from "store/auth/authUtils";

import { GroupUser } from "../models/GroupUser";
import { Task } from "../models/Task";

export function mapUpdatedTaskToUpdateInStore(
  taskId: string,
  values: ITaskInForm,
  assignee: GroupUser | null
): Task {
  return {
    id: taskId,
    task_title: values.task_title,
    description: values.description,
    list_id: values.listName,
    date: values.realisationDate.toString(),
    completed: values.completed,
    priority: values.priority,
    created: new Date().toString(),
    created_by: getSub(),
    group_id: values.groupId,
    category_ids: values.category_ids.map((l) => l.id),
    last_update_date: new Date().toString(),
    assignee,
    reminder_date: new Date().toString(),
  };
}
