import React, { ChangeEvent, FC } from "react";

import Image from "common/components/Image";

import { CheckboxContainer, HiddenCheckbox } from "./Checkbox.styled";

export interface CheckBoxProps {
  checked: boolean;
  width: number;
  height: number;
  checkedIconSrc: string;
  uncheckedIconSrc: string;
  onChecked: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: FC<CheckBoxProps> = ({
  checked,
  width,
  height,
  checkedIconSrc,
  uncheckedIconSrc,
  onChecked,
}) => {
  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>): void {
    onChecked(event);
  }
  return (
    <CheckboxContainer width={width} height={height}>
      <HiddenCheckbox
        checked={checked}
        onChange={handleCheckboxChange}
        height={height}
        width={width}
      />
      {checked ? (
        <Image src={checkedIconSrc} height={height} width={width} />
      ) : (
        <Image
          src={uncheckedIconSrc}
          layout="absolute"
          height={height}
          width={width}
        />
      )}
    </CheckboxContainer>
  );
};
export default Checkbox;
