import React, { ReactNode } from "react";

import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";

import {
  PostCreationModalWrapper,
  PostModalTextOptionsWrapper,
  PostModalUserSection,
} from "./PostCreationModal.styled";
import PostModalOptionsArea from "./PostModalOptions/PostModalOptionsArea";
import PostModalTextArea from "./PostModalTextArea/PostModalTextArea";
import PostModalUser from "./PostModalUser/PostModalUser";

interface PostCreationModalProps {
  headerTitle: string;
  display: string;
  userSection: ReactNode;
  contentSection: ReactNode;
  optionsSection: ReactNode;
}

const PostCreationModal = ({
  headerTitle,
  display,
  userSection,
  contentSection,
  optionsSection,
}: PostCreationModalProps) => {
  return (
    <PostCreationModalWrapper display={display}>
      <PostModalUserSection>
        <Text size="16px" weight="bold" color={theme.color.veryDarkGray}>
          {headerTitle}
        </Text>
        {userSection}
        {contentSection}
      </PostModalUserSection>
      <PostModalTextOptionsWrapper>
        {optionsSection}
      </PostModalTextOptionsWrapper>
    </PostCreationModalWrapper>
  );
};

export default PostCreationModal;
PostCreationModal.PostModalUser = PostModalUser;
PostCreationModal.PostModalTextArea = PostModalTextArea;
PostCreationModal.PostModalOptions = PostModalOptionsArea;
