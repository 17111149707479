import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface SpinnerWrapperProps {
  margin?: string | number;
}

export const ShowMoreWrapper = styled(Wrapper)`
  width: 260px;
  height: 48px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  :hover {
    cursor: pointer;
  }
`;

export const SpinnerWrapper = styled.div<SpinnerWrapperProps>`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.margin};
`;

export const WrapperUnderlined = styled.div`
  border-bottom: solid 1px ${theme.color.lightGray};
`;
