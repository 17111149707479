import React from "react";

import { theme } from "common/theme/theme";

import Text from "../Text";

import {
  CircularIconWrapper,
  HeaderIconText,
  HeaderIconWrapper,
  IconTextWrapper,
  Splitter,
} from "./HeaderIcon.styled";

interface HeaderIconProps {
  backgroundColor: string;
  children: React.ReactNode;
  onClickHandler?: () => void;
  iconHeight: string;
  iconWidth: string;
  cursorPointer?: boolean;
  text: string;
  boldText?: boolean;
  splitterText?: string;
  testId?: string;
  textSize?: string;
}

const HeaderIcon = ({
  backgroundColor,
  boldText,
  cursorPointer,
  onClickHandler,
  children,
  iconHeight,
  iconWidth,
  splitterText,
  text,
  textSize,
  testId,
}: HeaderIconProps) => {
  return (
    <HeaderIconWrapper data-testid={testId}>
      <CircularIconWrapper
        backgroundColor={backgroundColor}
        cursorPointer={cursorPointer}
        height={iconHeight}
        width={iconWidth}
        onClick={onClickHandler}
      >
        {children}
      </CircularIconWrapper>
      <IconTextWrapper>
        <HeaderIconText
          bold={boldText}
          size={textSize}
          data-testid={testId + "-text"}
        >
          {text}
        </HeaderIconText>
        <Splitter />
        <Text color={theme.color.mediumGray} mt="4px" size="12px">
          {splitterText}
        </Text>
      </IconTextWrapper>
    </HeaderIconWrapper>
  );
};

export default HeaderIcon;
