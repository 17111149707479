import React, { useEffect, useState } from "react";

import Avatar from "common/components/Avatar/Avatar";
import AvatarEdit from "common/components/AvatarChange/AvatarEdit";
import AvatarRemove from "common/components/AvatarChange/AvatarRemove";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useSub } from "common/hooks/useSub";
import { useGetUserQuery } from "store/user/userApi";

import AvatarUpload from "../../../../common/components/AvatarChange/AvatarUpload";

import { useUploadImage } from "./useUploadImage";

const UserImage = () => {
  const {
    imageUpload,
    error,
    dispatch,
    cancelUpload,
    saveImage,
    progress,
    removeImage,
    uploadedUrl,
  } = useUploadImage();

  const { sub } = useSub();

  const { data: user } = useGetUserQuery({ sub });
  const [copyImageUrl, setCopyImageUrl] = useState(user?.avatarUrl);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isImageRemoveConfirmed, setIsImageRemoveConfirmed] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const handleEditAvatar = () => {
    setIsEditModalOpen(true);
  };

  const imageRequirements = [
    { key: "size", text: "Maksymalny rozmiar zdjęcia to: 2 MB" },
  ];

  const closeModal = () => {
    setIsEditModalOpen(false);
    setIsImageRemoveConfirmed(false);
    setIsImageUploading(false);
    setCopyImageUrl(user?.avatarUrl);
    dispatch({
      type: "SET_ERROR",
      error: null,
    });
  };

  const cancelRemove = () => {
    setCopyImageUrl(user?.avatarUrl);
    setIsImageRemoveConfirmed(false);
    // setIsEditModalOpen(false);
  };

  const editImage = () => {
    setIsImageUploading(true);
  };

  useEffect(() => {
    setCopyImageUrl(user?.avatarUrl);
  }, [user?.avatarUrl]);

  return (
    <>
      <Avatar
        editable={true}
        imageSrc={user?.avatarUrl || "/icons/user-avatar-placeholder.svg"}
        onEdit={handleEditAvatar}
      />
      <ModalPortal
        closeModal={closeModal}
        isVisible={isEditModalOpen}
        withCloseButton={true}
      >
        {!isImageRemoveConfirmed && !isImageUploading && (
          <AvatarEdit
            height="160px"
            imageDefault="/icons/user-avatar-placeholder.svg"
            imageSrc={copyImageUrl}
            onEdit={editImage}
            onRemove={removeImage}
            subtitle={
              user?.avatarUrl
                ? "Twoje zdjęcie profilowe wygląda następująco:"
                : "Obecnie nie masz zdjęcia profilowego"
            }
            title="Zdjęcie profilowe"
            width="160px"
          />
        )}
        {isImageRemoveConfirmed && (
          <AvatarRemove
            height="160px"
            imageSrc={copyImageUrl || "/icons/user-avatar-placeholder.svg"}
            onCancel={cancelRemove}
            onRemove={removeImage}
            subtitle="Czy na pewno chcesz usunąć zdjęcie? Twój awatar będzie wyglądał tak:"
            title="Usuń zdjęcie"
            width="160px"
          />
        )}
        {isImageUploading && (
          <AvatarUpload
            error={error}
            onImageUpload={imageUpload}
            imageDefault="/icons/user-avatar-placeholder.svg"
            imageRequirements={imageRequirements}
            imageSrc={copyImageUrl}
            imageUploadedUrl={uploadedUrl}
            onCancelUpload={cancelUpload}
            onSaveImage={saveImage}
            subtitle="Twoje zdjęcie profilowe będzie wyglądało następująco"
            title="Dodaj zdjęcie profilowe"
            uploadProgress={progress}
          />
        )}
      </ModalPortal>
    </>
  );
};

export default UserImage;
