import { boolean, object, string } from "yup";

export const jobSchema = object().shape({
  jobName: string()
    .max(40, "Nazwa stanowiska może zawierać maksymalnie 40 znaków.")
    .required("Pole wymagane"),
  companyName: string()
    .max(40, "Nazwa firmy może zawierać maksymalnie 40 znaków.")
    .required("Pole wymagane"),
  jobStarted: string().required("Pole wymagane").min(1, "Pole wymagane"),
  jobFinished: string().when("currentlyInCompany", {
    is: false,
    then: string().required("Pole wymagane").min(1, "Pole wymagane"),
  }),
  currentlyInCompany: boolean(),
});
