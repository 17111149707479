import styled from "styled-components";

interface ContainerProps {
  m?: string;
  p?: string;
  w?: string;
  h?: string;
  bgColor?: string;
  bRadius?: string;
  boxShadow?: string;
  cursor?: string;
}

export const Container = styled.div<ContainerProps>`
  margin: ${(props) => props.m || "0"};
  padding: ${(props) => props.p || "0"};
  width: ${(props) => props.w || "100%"};
  height: ${(props) => props.h || "100%"};
  border-radius: ${(props) => props.bRadius || "0"};
  background-color: ${({ bgColor }) => bgColor || "inherit"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  cursor: ${({ cursor }) => cursor || "default"};
  box-sizing: border-box;
`;

interface AbsContainerProps {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export const AbsContainer = styled(Container)<AbsContainerProps>`
  position: absolute;
  ${({ top }) => {
    if (top) return "top: " + top;
  }};

  ${({ bottom }) => {
    if (bottom) return "bottom: " + bottom;
  }};

  ${({ left }) => {
    if (left) return "left: " + left;
  }};

  ${({ right }) => {
    if (right) return "right: " + right;
  }};
`;
