import React, { useEffect, useState } from "react";

import axios, { AxiosError, AxiosResponse } from "axios";
import Button from "common/components/Button";
import Flex from "common/components/Flex";
import CancelAcceptButtons from "common/components/Forms/Buttons/CancelAcceptButtons";
import Checkbox from "common/components/Forms/Checkboxes/Checkbox";
import Image from "common/components/Image";
import { Container } from "common/components/Layout/Containers";
import TaskPriorityTagSquare from "common/components/TagsAndLabels/Tags/TaskPriorityTagSquare/TaskPriorityTagSquare";
import TaskCategory from "common/components/TagsAndLabels/TaskCategory/TaskCategory";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { TaskDetailsPersons } from "components/TaskerPage/utils/taskDetailsModal/TaskDetailsPersons/TaskDetailsPersons";
import { getGroupList } from "services/api/group/groupApi";
import { mapTaskToUpdateTaskCompletedPayload } from "services/api/tasker/tasker.mappers";
import { taskerUpdateTaskRq } from "services/api/tasker/taskerUpdateTaskRq";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { GroupUser } from "store/tasker/models/GroupUser";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

interface ListView {
  id: string;
  name: string;
  group_id: string;
}

export default function UserViewTaskDetailsModal({ closeModal }) {
  const dispatch = useAppDispatch();
  const taskerSelector = useAppSelector((s) => s.tasker);

  const [task, setTask] = useState<Task | null>(null);
  const [list, setList] = useState<ListView | null>(null);
  const [createdBy, setCreatedBy] = useState<GroupUser | null>(null);
  const [moreDescription, setMoreDescription] = useState<boolean | null>(null);
  const [groupData, setGroupData] = useState<{ id: string; name: string }>(
    null
  );

  const initForUserView = async () => {
    const chosenGroupId = taskerSelector.userViewTree.taskFromGroupId;

    const chosenGroup = taskerSelector.userViewTree.groups.find(
      (g) => g.group.id === chosenGroupId
    );
    if (!chosenGroup) {
      console.warn("GROUP IS NULL IN TASKER STORE, FETCHING FROM API...");
      try {
        const response = await getGroupList("PROJECT");
        console.log(response);
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
    }
    if (chosenGroup?.group?.name) {
      setGroupData({ id: chosenGroupId, name: chosenGroup.group.name });
    }

    const allUsers = chosenGroup.allUsers;
    const { task } = taskerSelector.taskerView.editTaskData;
    setTask(task);
    if (allUsers) {
      const createdBy = allUsers.find((u) => u.user_id === task.created_by);
      if (createdBy) {
        setCreatedBy(createdBy);
      }
    }

    const {
      group: { id },
    } = taskerSelector.userViewTree.groups.find(
      (l) => l.group.id === chosenGroupId
    );

    if (!id) {
      console.log("NO GROUP ID AGAIN??");
    }

    const list = chosenGroup.allLists.find((l) => l.id === task.list_id);
    if (!list) {
      console.log("NO TASK LIST AGAIN??");
    } else {
      setList({
        id: list.id,
        name: list.name,
        group_id: chosenGroupId,
      });
    }

    if (task.description) {
      const descriptionLen = task.description.length;
      if (descriptionLen > 300) {
        setMoreDescription(false);
      }
    }
  };

  useEffect(() => {
    if (taskerSelector.MainView === "User") {
      initForUserView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskerSelector.MainView]);

  const setTaskCompleted = async (completed: boolean) => {
    const newTask = { ...task, completed };
    const payload = mapTaskToUpdateTaskCompletedPayload(newTask, groupData.id);
    const response: AxiosResponse | AxiosError = await taskerUpdateTaskRq(
      payload
    );

    if (axios.isAxiosError(response)) {
      console.log(response);
    } else {
      if (response.status === 200) {
        console.log("Task Completed Updated");
        dispatch(
          taskerAction.setTaskCompletedInUserView({
            task,
            completed: !task.completed,
          })
        );
      }
    }

    setTask((prev) => ({ ...prev, completed }));
  };

  const toggleMoreDescription = () => {
    setMoreDescription((prev) => !prev);
  };

  const getCategoriesList = (category_ids: string[]) => {
    const allCategories = taskerSelector.allCategories;
    const categories = [];
    category_ids?.forEach((categoryId) => {
      const a = allCategories.find((c) => c.id === categoryId);
      if (a) {
        categories.push(a);
      }
    });

    return categories;
  };

  if (!task) {
    return <div>loading</div>;
  }

  return (
    <Container w="820px" p="32px">
      <Flex direction="column" gap="24px">
        <Flex>
          <Text size="16px" weight="normal">
            {groupData?.name ?? "GROUP NAME ERROR"}
          </Text>
          <Text ml="32px" mr="32px" size="16px" weight="normal">
            /
          </Text>
          <Text size="18px" weight="semibold">
            {list?.name ?? "LIST NAME ERROR"}
          </Text>
        </Flex>

        <Flex direction="row" justify="space-between">
          <Text size="20px" weight="extraBold">
            {task.task_title}
          </Text>
          {task.priority && <TaskPriorityTagSquare />}
        </Flex>

        <Flex gap="24px">
          {getCategoriesList(task?.category_ids).map((l, idx) => (
            <TaskCategory
              key={l.id}
              text={l.name}
              textSize="14px"
              p={"0px 12px"}
              colorIdx={idx}
            />
          ))}
        </Flex>
        <Flex>
          <Flex>
            <Text size="14px" weight="semibold">
              Data dodania:
            </Text>
            <Text size="14px" mr="40px" ml="8px">
              {new Date(task.date).getDate()}{" "}
              {new Date(task.date).toLocaleString("default", {
                month: "long",
              })}
            </Text>
          </Flex>
          <Flex>
            <Text size="14px" weight="semibold">
              Termin realizacji:
            </Text>
            <Text size="14px" mr="40px" ml="8px" weight="bold">
              {new Date(task.date).getDate()}{" "}
              {new Date(task.date).toLocaleString("default", {
                month: "long",
              })}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          p="0 0 16px 0"
          style={{
            borderBottom: `0.5px solid ${theme.color.slightlyLightGray}`,
          }}
        >
          <Text
            size="14px"
            style={{
              wordWrap: "break-word",
            }}
          >
            {moreDescription === null
              ? task.description
              : moreDescription
              ? task.description
              : task.description.slice(0, 300).concat("...")}
          </Text>
          <Button
            variant="outlined"
            height="25px"
            margin="8px 0 0 0"
            width="100px"
            border="none"
          >
            <Text weight="semibold" size="14px" onClick={toggleMoreDescription}>
              {moreDescription === false
                ? "Zobacz więcej"
                : moreDescription === true
                ? "Zobacz mniej"
                : null}
            </Text>
          </Button>
        </Flex>
        <TaskDetailsPersons assignee={task.assignee} createdBy={createdBy} />
        <Flex
          gap="16px"
          p="8px 0 8px 10px"
          style={{
            borderBottom: `0.5px solid ${theme.color.slightlyLightGray}`,
          }}
        >
          <Image src="/icons/arrow2-right.svg" height={24} width={24} />
          <Flex align="center">
            <Text size="14px" weight="bold">
              Załączniki
            </Text>
          </Flex>
          <Flex align="center">
            <Text size="14px">(8)</Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          p="8px 0 8px 10px"
          style={{
            borderBottom: `0.5px solid ${theme.color.slightlyLightGray}`,
          }}
        >
          <Image src="/icons/arrow2-right.svg" height={24} width={24} />
          <Text size="14px" weight="bold">
            Komentarze
          </Text>
          <Text size="14px">(5)</Text>
        </Flex>
        <Flex
          gap="16px"
          p="8px 0 8px 10px"
          style={{
            borderBottom: `0.5px solid ${theme.color.slightlyLightGray}`,
          }}
        >
          <Image src="/icons/arrow2-right.svg" height={24} width={24} />
          <Text size="14px" weight="bold">
            Historia zmian
          </Text>
          <Text size="14px">(4)</Text>
        </Flex>
        <Flex align="center" gap="24px">
          <Checkbox
            checked={task?.completed ?? false}
            uncheckedIconSrc="/icons/rectangle_checkbox_empty.svg"
            checkedIconSrc="/icons/rectangle_checkbox_filled.png"
            height={16}
            width={16}
            onChecked={() => setTaskCompleted(!task?.completed)}
          />
          <Text size="16px">Dodaj do ukończonych</Text>
        </Flex>
        <Flex mt="16px">
          <CancelAcceptButtons
            flexJustifyType="space-between"
            acceptButton={{
              handleCloseModalAndAccept: () => {
                closeModal();
                console.log(3);
                dispatch(
                  taskerAction.setOpenEditTaskView({
                    task,
                    listId: task.list_id,
                  })
                );
                console.log(4);
              },
              textAccept: "Edytuj zadanie",
            }}
            cancelButton={{
              handleCloseModalAndCancel: () => {},
              textCancel: "Archiwizuj",
            }}
          />
        </Flex>
      </Flex>
    </Container>
  );
}
