import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Language } from "common/types/Language";
import { UpdateUserProfileDto } from "common/types/UpdateUserProfileDto";
import { UserProfileDto } from "common/types/UserProfileDto";
import { UserRequestDto } from "common/types/UserRequestDto";
import { UserResponseDto } from "common/types/UserResponseDto";
import { getJwtToken } from "common/utils/auth/getToken";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_NEURON_API_URL_RED}/user`,
    prepareHeaders: async (headers) => {
      const token = await getJwtToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["getUser", "getProfile"],
  endpoints: (builder) => ({
    getProfile: builder.query<
      UserProfileDto,
      { sub: string; language: string }
    >({
      query: ({ sub, language }) => `/profile/${sub}/${language}`,
      providesTags: ["getProfile"],
    }),
    updateProfile: builder.mutation<
      UpdateUserProfileDto,
      {
        sub: string;
        language: Language;
        profileData: Partial<UpdateUserProfileDto>;
      }
    >({
      query: ({ sub, language, profileData }) => ({
        url: `/profile/${sub}/${language}`,
        method: "PATCH",
        body: profileData,
      }),
      invalidatesTags: ["getProfile"],
    }),
    getUser: builder.query<UserResponseDto, { sub: string }>({
      query: ({ sub }) => `/account/bySub/${sub}`,
      providesTags: ["getUser"],
    }),
    updateUser: builder.mutation<
      boolean,
      { sub: string; userData: Partial<UserRequestDto> }
    >({
      query: ({ sub, userData }) => ({
        url: `/account/${sub}`,
        method: "PATCH",
        body: userData,
      }),
      invalidatesTags: ["getUser"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetUserQuery,
  useUpdateUserMutation,
} = userApi;
