import { GroupUser } from "store/tasker/models/GroupUser";

import { apiRequest } from "../config/axiosConfig";

export async function getAllUsersByGroupRq(
  groupId: string
): Promise<GroupUser[]> {
  try {
    const response: any = await apiRequest<GroupUser[], string>(
      "GET",
      "GROUP",
      `/${groupId}/user`
    );
    return response.data.body.message;
  } catch (err) {
    if (err.response) {
      console.error(
        `getAllUsersByGroupRq returned with status ${err.response.status}`
      );
    }
  }
}
