import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import CancelRequest from "components/Group/CancelRequest/CancelRequest";
import GroupInvitationModal from "components/Group/GroupInvitationModal/GroupInvitationModal";
import { GroupMemberRole } from "store/groupSlice";
import { useAppSelector } from "store/hooks";

import GroupButtonPrimary from "./GroupButtonPrimary/GroupButtonPrimary";

const GroupButton = () => {
  const [invitationMode, setInvitationMode] = useState(false);
  const [cancelGroupRequestMode, setCancelGroupRequestMode] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const modal = useModal({ withCloseButton: true });
  const group = useAppSelector((state) => state.group.chosenGroup);
  const userInGroup = useAppSelector((state) => state.group.currentGroupUser);

  useEffect(() => {
    setRequestSent(false);
  }, [group?.name]);

  const isUserAdmin = useMemo(() => {
    return userInGroup?.userRole === GroupMemberRole.Admin;
  }, [userInGroup?.userRole]);

  const turnOnCancelGroupRequestMode = useCallback(() => {
    setCancelGroupRequestMode(true);
    modal.showModal();
  }, [modal]);

  const turnOffCancelGroupRequestMode = () => {
    setCancelGroupRequestMode(false);
    modal.modalProps.closeModal();
  };

  const cancelRequestHandler = () => {
    setRequestSent(false);
  };

  const sendGroupRequest = () => {
    setRequestSent(true);
  };

  const turnOnInvitationMode = useCallback(() => {
    setInvitationMode(true);
    modal.showModal();
  }, [modal]);

  const turnOffInvitationMode = () => {
    setInvitationMode(false);
    modal.modalProps.closeModal();
  };

  const modalCloseHandler = () => {
    if (cancelGroupRequestMode) turnOffCancelGroupRequestMode();
    if (invitationMode) turnOffInvitationMode();
  };

  const userStateHandler = useMemo(() => {
    if (userInGroup && isUserAdmin) {
      return {
        buttonText: "Zaproś",
        buttonIsVisible: true,
        groupButtonHandler: turnOnInvitationMode,
      };
    }
    if (userInGroup) {
      return {
        buttonIsVisible: false,
      };
    }
    if (!userInGroup) {
      return {
        buttonText: requestSent ? "Wysłano prośbę" : "Dołącz",
        buttonIsVisible: true,
        groupButtonHandler: requestSent
          ? turnOnCancelGroupRequestMode
          : sendGroupRequest,
      };
    }
  }, [
    userInGroup,
    requestSent,
    turnOnCancelGroupRequestMode,
    isUserAdmin,
    turnOnInvitationMode,
  ]);

  return (
    <>
      {userStateHandler.buttonIsVisible && (
        <>
          <GroupButtonPrimary
            clickHandler={userStateHandler.groupButtonHandler}
            lightMode={requestSent}
            buttonText={userStateHandler.buttonText}
            buttonHeight="36px"
            buttonWidth={requestSent ? "189px" : "112px"}
          />
          <ModalPortal
            withCloseButton={true}
            isVisible={modal.modalProps.isVisible}
            closeModal={() => modalCloseHandler()}
          >
            {cancelGroupRequestMode && (
              <CancelRequest
                groupName={group?.name}
                confirmCancellation={cancelRequestHandler}
                closeCancelRequest={turnOffCancelGroupRequestMode}
              />
            )}
            {invitationMode && (
              <GroupInvitationModal
                turnOffInvitationModal={turnOffInvitationMode}
              />
            )}
          </ModalPortal>
        </>
      )}
    </>
  );
};

export default GroupButton;
