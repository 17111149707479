import styled from "styled-components/macro";

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  text-align: left;
  margin-bottom: 1rem;

  p {
    margin: 0;
    padding: 0;
  }

  P:first-child {
    font-size: 1rem;
  }
  p:nth-child(2) {
    font-size: 0.875rem;
  }
`;

export const FormBoxTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const FormBoxContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;
`;

export const EmptyBox = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  button {
    font-size: 1rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: max-content;
    padding: 0.75rem 1.125rem;
    margin-top: 1rem;
  }
`;
