import React from "react";

import { IListUser } from "store/groupCreation/types";

import {
  RightUsersTableNavigationDown,
  RightUsersTableNavigationUp,
  RightUsersTableWrapper,
} from "./RightUsersTable.styled";
import RightUsersTableItem from "./RightUsersTableItem/RightUsersTableItem";

interface RightUsersTableProps {
  chosenUsers: IListUser[];
}

const RightUsersTable = ({ chosenUsers }: RightUsersTableProps) => {
  const scrollUp = (e) => {
    e.preventDefault();
    const container = document.getElementById("chosenMembers");
    container.scrollBy(0, -50);
  };
  const scrollDown = (e) => {
    e.preventDefault();
    const container = document.getElementById("chosenMembers");
    container.scrollBy(0, 50);
  };
  return (
    <>
      {chosenUsers.length > 7 ? (
        <RightUsersTableNavigationUp onClick={scrollUp}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.075 7.075L0 6L6 0L12 6L10.925 7.075L6 2.15L1.075 7.075Z"
              fill="#4A4A4A"
            />
          </svg>
        </RightUsersTableNavigationUp>
      ) : null}
      <RightUsersTableWrapper id="chosenMembers">
        {chosenUsers
          .slice(0)
          .reverse()
          .map((user) => (
            <RightUsersTableItem
              name={user.userData.fullName}
              key={user.userData.sub}
              userSub={user.userData.sub}
              avatar={user.userData.avatarUrl}
            />
          ))}
      </RightUsersTableWrapper>
      {chosenUsers.length > 7 ? (
        <RightUsersTableNavigationDown onClick={scrollDown}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.075 7.075L0 6L6 0L12 6L10.925 7.075L6 2.15L1.075 7.075Z"
              fill="#4A4A4A"
            />
          </svg>
        </RightUsersTableNavigationDown>
      ) : null}
    </>
  );
};

export default RightUsersTable;
