import Button from "common/components/Button";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface AllCategoryChipProps {
  isSelected?: boolean;
}

interface AccordionWrapperProps {
  isSelected?: boolean;
}
interface CategoryImageProps {
  isHidden?: boolean;
}

export const AccordionWrapper = styled(Wrapper)<AccordionWrapperProps>`
  height: 40px;
  margin: 4px 0;
  padding: 0 16px;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isSelected ? theme.color.lightGray : theme.color.basicWhite};
  :hover {
    background: ${theme.color.whiteGray};
    cursor: pointer;
  }
`;

export const SubAccordionWrapper = styled(AccordionWrapper)`
  justify-content: space-between;
  padding-left: 68px;
  outline: none;
`;

export const AccordionWrapperUnderlined = styled.div`
  box-sizing: border-box;
  width: 260px;
  padding: 40px 12px 12px;
  overflow-y: visible;
  position: relative;
  border-bottom: solid 1px ${theme.color.veryLightGray};
`;

export const AllCategoryChip = styled.div<AllCategoryChipProps>`
  height: 40px;
  margin: 4px 0;
  padding: 0 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  outline: none;
  background-color: ${(props) =>
    props.isSelected ? theme.color.lightGray : theme.color.basicWhite};
  :hover {
    background: ${theme.color.whiteGray};
    cursor: pointer;
  }
`;

export const CategoryLabel = styled(Text)`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

export const CategoryImage = styled(Image)<CategoryImageProps>`
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  padding: 3px;
`;

export const NotesRightPanelAddCategoryStyledButton = styled(Button)`
  margin: 16px 0;
  padding-left: 36px;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 12px;
`;
