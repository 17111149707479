import styled from "styled-components/macro";

export const RightUsersTableWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 411px;
  width: 129px;
  gap: 10px;
  overflow-y: hidden;
  scroll-behavior: smooth;
  margin-left: 12px;
`;
export const RightUsersTableNavigationUp = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  width: 130px;
  height: 32px;
  background: #f2f2f2;
  opacity: 0.9;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 19;
`;
export const RightUsersTableNavigationDown = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  width: 130px;
  height: 32px;
  background: #f2f2f2;
  opacity: 0.9;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 95%;
  right: 0%;
  transform: rotate(180deg);
  z-index: 19;
`;
export const RightTableWrapper = styled.div`
  position: relative;
`;
