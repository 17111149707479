import React, { ChangeEvent, useEffect, useState } from "react";

import Flex from "common/components/Flex";
import FormInput from "common/components/FormInput";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { categoryCreate, fetchCategories } from "store/categoriesSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { NameLengthCounter } from "../NameCounter/NameCounter";

import {
  CategoryNameWrapper,
  Container,
} from "./AddCategoryModalContent.styled";
import { validateCategoryName } from "./helper";

interface AddCategoryModalContentProps {
  onClose: () => void;
}

export const AddCategoryModalContent = ({
  onClose,
}: AddCategoryModalContentProps) => {
  const { sub } = useSub();
  const dispatch = useAppDispatch();
  const [category, setCategory] = useState("");
  const [nameError, setNameError] = useState<string>("");
  const [isAdding, setIsAdding] = useState(false);

  const { categories } = useAppSelector((state) => state.category);
  const [catNames, setCatNames] = useState([]);

  useEffect(() => {
    const names = categories.map((item) => item.categoryName);
    setCatNames(names);
  }, [categories]);

  const validate = (values: string) => {
    const categoryNameError = validateCategoryName(values, catNames);
    setNameError(categoryNameError);
    return categoryNameError;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  const handleAddCategory = async () => {
    const error = validate(category);
    if (!error && !isAdding) {
      setIsAdding(true);
      const newCategory = {
        owner: sub,
        category_name: category,
      };
      await dispatch(categoryCreate(newCategory));
      await dispatch(fetchCategories(sub));
      onClose();
      setIsAdding(false);
    }
  };

  return (
    <Flex width="494px" height="210px" direction="column" justify="flex-start">
      <Text
        size="20px"
        weight="bold"
        lineHeight="24px"
        letterSpacing="1px"
        mt="12px"
        mb="24px"
      >
        Utwórz kategorię
      </Text>
      <Container>
        <CategoryNameWrapper>
          <FormInput
            id="categoryName"
            placeholder="Podaj nazwę kategorii"
            label="Nazwa kategorii"
            value={category}
            type="text"
            onChange={handleChange}
            errorText={nameError}
            mt="20px"
            mb="40px"
            onBlur={() => validate(category)}
          />
          <NameLengthCounter length={category.length} />
        </CategoryNameWrapper>
        <Flex justify="flex-end" mt="12px" mb="12px">
          <GhostButton onClick={onClose} type="reset" mr="24px">
            Anuluj
          </GhostButton>
          <SmallButton onClick={handleAddCategory}>Utwórz</SmallButton>
        </Flex>
      </Container>
    </Flex>
  );
};
