import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import styled from "styled-components/macro";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export const TextColor = styled.div`
  height: 20px;
  width: 20px;
  background-color: black;
`;

export default function TextColorItem() {
  const iconSrc = `/icons/down.svg`;
  const iconSquare = `/icons/noteKit/icon_crop_square.svg`;

  return (
    <Flex align="center" justify="space-between">
      <Text ml="10px" size="14px">
        Kolor tekstu
      </Text>
      <NoteKitBorderWrapper p="8px 11px 4px 11px">
        <Text>
          <Image src={iconSquare} alt="icon" width={20} height={20} top="2px" />
        </Text>
        <Text ml="10px">
          <Image src={iconSrc} alt="icon" width={13} height={12} top="2px" />
        </Text>
      </NoteKitBorderWrapper>
    </Flex>
  );
}
