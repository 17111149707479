import React from "react";

import { useGetFileQuery } from "store/upload/uploadApi";

import {
  PostAttachmentImage,
  PostAttachmentImageOverlay,
  PostAttachmentImageOverlayText,
  PostAttachmentImageOverlayWrapper,
  PostContentAttachmentWrapper,
} from "./PostContentAttachment.styled";

interface PostContentAttachmentProps {
  attachment: string;
  moreImagesCount?: number;
}

const PostContentAttachment = ({
  attachment,
  moreImagesCount,
}: PostContentAttachmentProps) => {
  const { data } = useGetFileQuery({ fileUrl: attachment });
  return (
    <PostContentAttachmentWrapper>
      <PostAttachmentImage imageUrl={data}>
        {moreImagesCount && (
          <PostAttachmentImageOverlayWrapper>
            <PostAttachmentImageOverlay />
            <PostAttachmentImageOverlayText>
              Zobacz {moreImagesCount} więcej
            </PostAttachmentImageOverlayText>
          </PostAttachmentImageOverlayWrapper>
        )}
      </PostAttachmentImage>
    </PostContentAttachmentWrapper>
  );
};

export default PostContentAttachment;
