import Card from "common/components/Card/Card";
import styled from "styled-components/macro";

export const GroupPostsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
`;

export const GroupPostsList = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
