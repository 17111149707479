import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const StepperHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 37.5rem;
  padding: 2rem 0;
  * {
    color: ${theme.color.darkerGray};
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-align: center;
    flex: 1;

    &.current-step,
    &.done {
      * {
        color: black;
      }
      font-weight: ${theme.fontWeight.bold};
      .step-button {
        border: 2px solid black;
        background: black;
        color: white;
      }
    }

    .done {
      * {
      }
    }
  }
  .line {
    flex: 1;
    background: ${theme.color.darkerGray};
    height: 2px;
    width: 100%;
    margin-top: 3rem;
  }

  .step-button {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${theme.color.darkerGray};
    width: 2rem;
    height: 2rem;
    background: transparent;
  }
`;
