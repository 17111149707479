import styled from "styled-components/macro";

export const HeadingCard = styled.div`
  min-height: 171px;
  padding-bottom: 0;
`;

export const CardWrapper = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  margin-top: 15px;
  margin-bottom: 3px;
`;
