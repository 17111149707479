import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const MembersTable = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const MembersTableTitle = styled(Text)`
  color: ${theme.color.basicBlack};
`;

export const MemberTableItem = styled.div``;

export const MembersTableItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
