import { apiRequest } from "../config/axiosConfig";
import { GroupApiRequest, GroupApiResponse } from "../shared/apiResponse";

// group create
interface ICreateGroupRequestDtoGroupOwner {
  group_owner_id: string;
  group_owner_type: string;
}

export interface ICreateGroupRequestDtoMessage {
  created_by: string;
  created: string;
  group_status: string;
  group_type: string;
  group_access: string;
  group_owner: ICreateGroupRequestDtoGroupOwner;
  name: string;
  description: string;
  tags: string[];
}

// group create responses
interface ICreateGroupResponseDtoMessage {
  group_id: string;
  created: string;
}

// User assignment
export interface IAssignUserRequestMessage {
  group_id: string;
  user_id: string;
  group_type: string;
  user_role: string;
  created_by: string;
  created: string;
}

interface IAssignUserResponseMessage {
  group_id: string;
  created: string;
}

export async function createGroup(
  groupData: GroupApiRequest<ICreateGroupRequestDtoMessage>
): Promise<GroupApiResponse<ICreateGroupResponseDtoMessage>> {
  const response = await apiRequest<
    GroupApiResponse<ICreateGroupResponseDtoMessage>,
    GroupApiRequest<ICreateGroupRequestDtoMessage>
  >("POST", "GROUP", "/create", { data: groupData });
  return response.data;
}

export async function userAssignment(
  groupData: GroupApiRequest<IAssignUserRequestMessage>
): Promise<GroupApiResponse<IAssignUserResponseMessage>> {
  const response = await apiRequest<
    GroupApiResponse<IAssignUserResponseMessage>,
    GroupApiRequest<IAssignUserRequestMessage>
  >("POST", "GROUP", "/user", {
    data: groupData,
  });
  return response.data;
}
