import React from "react";

import { MemberImage } from "./MembersItem.styled";

interface MembersItemProps {
  memberImg: string;
  zIndex: number;
}

const MembersItem = ({ memberImg, zIndex }: MembersItemProps) => {
  return <MemberImage src={memberImg} zIndex={zIndex} width={32} height={32} />;
};

export default MembersItem;
