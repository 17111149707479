import styled from "styled-components/macro";

interface DatePickerWrapperProps {
  mt?: string;
  mb?: string;
}

export const RegulationsWrapper = styled.div<DatePickerWrapperProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
`;
