import styled from "styled-components/macro";

export const ProfileCreationGreetingsStyles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  height: 500px;
  max-width: 600px;

  header,
  footer {
    display: flex;
  }

  header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
  }

  footer {
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
    a {
      text-decoration: none;
      color: inherit;
    }

    button {
      height: max-content;
      width: max-content;
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }
`;
