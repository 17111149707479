import React from "react";

import Text from "common/components/Text/Text";

import { PostContentWrapper } from "./PostContent.styled";

interface PostContentProps {
  postText: string;
}

const PostContent = ({ postText }: PostContentProps) => {
  return (
    <PostContentWrapper>
      <Text size="14px" lineHeight="20px">
        {postText}
      </Text>
    </PostContentWrapper>
  );
};

export default PostContent;
