import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Paths } from "common/constants";
import { useClickAway } from "common/hooks/useClickAway";
import ModalCloseIcon from "common/icons/ModalCloseIcon";

import { TopMenuButton } from "../TopMenuPanel.styled";

import { SearchStyled } from "./index.styled";
import { SearchListResult } from "./SearchListResult";
import { useSearch } from "./useSearch";

export function Search() {
  const [isOpen, setIsOpen] = React.useState(false);
  const close = () => setIsOpen(false);
  const navigate = useNavigate();

  const [parent] = useAutoAnimate({
    easing: "ease-out",
  });
  useClickAway(parent, () => close());

  const { onChange, query, displayItems, totalCount, isLoading } = useSearch();

  const moveToDetails = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate({
        pathname: Paths.SEARCH_DETAILS,
        search: `?${new URLSearchParams({
          query,
        })}`,
      });
      close();
    }
  };

  return (
    <SearchStyled>
      <div className="input-wrapper" ref={parent as any}>
        <img
          src="/icons/search_black.svg"
          alt="Ikona  wyszukiwania"
          className="search-icon"
        />
        <div className="divider" />
        <input
          placeholder="Szukaj"
          onFocus={() => setIsOpen(true)}
          onChange={onChange}
          value={query}
          onKeyDown={moveToDetails}
        />
        <TopMenuButton
          className="clear-button"
          onClick={() => {
            close();
            onChange("");
          }}
        >
          <ModalCloseIcon width="14px" height="14px" />
        </TopMenuButton>
        {isOpen && query && (
          <SearchListResult
            close={close}
            query={query}
            isLoading={isLoading}
            items={displayItems}
            totalCount={totalCount}
          />
        )}
      </div>
    </SearchStyled>
  );
}
