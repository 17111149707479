import * as React from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "common/components/FormInputHookForms";
import SmallButton from "common/components/SmallButton/SmallButton";
import { apiRequest } from "services/api/config/axiosConfig";
import { toast } from "sonner";
import styled from "styled-components";
import { object, string } from "yup";

const PersonAddIconStyled = styled.img`
  margin-right: 0.5rem;
`;

const BtnWrapper = styled.div`
  margin-left: auto;
  width: max-content;
`;

export const SendEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      object().shape({
        email: string()
          .email("Nieprawidłowy format adresu e-mail")
          .required("Wymagany adres email"),
      })
    ),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    const result = await apiRequest("POST", "MAIL", "/send", {
      data: {
        htmlBody: `<a href=${window.location.origin}>Przejdź do Acorn</a>`,
        recipient: `${email}`,
        subject: "Zaproszenie do Acorn",
        textBody: `Hej zostałeś zaproszony do Acorn`,
      },
    });
    if (typeof result.data === "string") {
      toast[result.status === 200 ? "success" : "error"](result.data);
    }
  });

  return (
    <>
      <FormInput
        mt="1rem"
        mb="3rem"
        label="Email"
        touched={true}
        type="text"
        inputName="email"
        register={register}
        id="email"
        placeholder="Email"
        isValid={errors.email === undefined}
        errorText={errors.email?.message}
      />
      <BtnWrapper>
        <SmallButton type="button" onClick={onSubmit}>
          <PersonAddIconStyled src="/icons/person-add.svg" />
          Zaproś
        </SmallButton>
      </BtnWrapper>
    </>
  );
};
