import React from "react";

import { TaskCardGroupView } from "common/components/Card/TaskCard/TaskCardGroupView";
import Flex from "common/components/Flex";
import Image from "common/components/Image";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import NoFinishedTasks from "components/TaskerPage/utils/NoFinishedTasks";
import { useAppSelector } from "store/hooks";

export default function TaskerDone() {
  const taskerSliceTree = useAppSelector((s) => s.tasker.groupViewTree);
  const categoriesSelector = useAppSelector((s) => s.tasker);
  const categories = categoriesSelector.allCategories;
  const isAnyTaskDone = (): boolean => {
    const count = taskerSliceTree.lists.some((l) => {
      return l.tasks.some((t) => t.completed);
    });
    return count;
  };

  if (!isAnyTaskDone()) {
    return <NoFinishedTasks />;
  }

  return (
    <Flex
      direction="row"
      wrap="nowrap"
      mt="15px"
      gap="12px"
      p="0 0 15px 0"
      overFlowX="auto"
    >
      {taskerSliceTree.lists.map((l, listIdx) => {
        if (l.tasks.filter((t) => t.completed).length === 0) {
          return null;
        } else {
          return (
            <Flex key={listIdx} direction="column">
              <RelativeContainer
                bgColor={theme.color.veryVeryLightGray}
                w="280px"
                p="12px"
                bRadius="8px"
                h="auto"
              >
                <Flex direction="row" justify="space-between">
                  <Text size="14px" weight="semibold">
                    {l.name}
                  </Text>
                  <Image
                    src={"/icons/options.svg"}
                    alt="icon"
                    width={14}
                    height={14}
                  />
                </Flex>
                <Flex direction="column">
                  {l.tasks.map((t) => {
                    if (t.completed) {
                      return (
                        <TaskCardGroupView
                          key={t.id}
                          task={t}
                          category={
                            categories.find(
                              (c) => c.id === t.category_ids?.[0]
                            ) ?? null
                          }
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </Flex>
              </RelativeContainer>
            </Flex>
          );
        }
      })}
    </Flex>
  );
}
