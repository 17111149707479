import React from "react";

import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { GroupCloseModalHeader } from "../GroupCloseModal.styled";

import {
  GoBackToApplicationButton,
  GroupCloseFinalModalWrapper,
} from "./GroupCloseModalFinalstyled";

interface GroupCloseModalFinalProps {
  closeModal: () => void;
  groupName: string;
  finalText: string;
}

const GroupCloseModalFinal = ({
  closeModal,
  groupName,
  finalText,
}: GroupCloseModalFinalProps) => {
  return (
    <GroupCloseFinalModalWrapper>
      <GroupCloseModalHeader>Grupa zamknięta</GroupCloseModalHeader>
      <Text
        align="center"
        mt="16px"
        size="14px"
        data-testid="close-group-info"
        color={theme.color.veryDarkGray}
      >
        Grupa {groupName} została zamknięta poprawnie. {finalText}
      </Text>
      <GoBackToApplicationButton
        onClick={closeModal}
        data-testid="return-to-site-btn"
      >
        Przejdź do platformy
      </GoBackToApplicationButton>
    </GroupCloseFinalModalWrapper>
  );
};

export default GroupCloseModalFinal;
