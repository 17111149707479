import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface RadioButtonGroupWrapperProps {
  mt?: string;
  mb?: string;
}

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${theme.color.darkGray};
  margin-bottom: 13px;
`;

export const RadioButtonRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RadioButtonGroupWrapper = styled.div<RadioButtonGroupWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
`;
