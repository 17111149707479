import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Image from "common/components/Image";
import TabNavigation from "common/components/TabNavigation";
import { Paths } from "common/constants/paths";
import { theme } from "common/theme/theme";
import RegisterForm from "components/Authentication/RegisterForm/index";
import { authAction } from "store/auth/authSlice";
import { useAppDispatch } from "store/hooks";

import {
  AuthenticationWrapper,
  ColumnWrapper,
  FormWrapper,
  TabColumnWrapper,
  TabNavigationWrapper,
} from "./Authentication.styled";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import LoginForm from "./LoginForm";
import RemindPasswordForm from "./RemindPasswordForm/RemindPasswordForm";

const Authentication: FC = () => {
  const [query] = useSearchParams();
  const [active, setActive] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(
    () =>
      setActive(
        query.get("id") || query.get("register") || query.get("active")
      ),
    [query]
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = e.currentTarget.id;
    if (index !== active) {
      setActive(index);
      dispatch(authAction.setLoginError(""));
    }
  };

  return (
    <AuthenticationWrapper>
      <ColumnWrapper>
        <Image
          src={`/images/${active === "register" ? "pen" : "handshake"}.svg`}
          alt="Grafika autoryzacji"
          width={681}
          height={844}
        />
      </ColumnWrapper>
      <ColumnWrapper>
        <TabColumnWrapper>
          <TabNavigationWrapper>
            {active !== "change-password" && (
              <>
                <TabNavigation
                  id="login"
                  path={`${Paths.AUTHPAGE}?id=login`}
                  isActive={active === "login" || active === "remind"}
                  activeColor={theme.color.basicBlack}
                  paddingBottom={13}
                  borderHeight={2}
                  onClick={handleClick}
                >
                  Zaloguj się
                </TabNavigation>
                <TabNavigation
                  id="register"
                  path={`${Paths.AUTHPAGE}?id=register`}
                  isActive={active === "register"}
                  activeColor={theme.color.basicBlack}
                  paddingBottom={13}
                  borderHeight={2}
                  onClick={handleClick}
                >
                  Zarejestruj się
                </TabNavigation>
              </>
            )}
          </TabNavigationWrapper>
          <FormWrapper>
            {active === "login" && <LoginForm />}
            {(active === "register" || active === null) && <RegisterForm />}
            {active === "remind" && <RemindPasswordForm email={""} />}
            {active === "change-password" && <ChangePasswordForm />}
          </FormWrapper>
        </TabColumnWrapper>
      </ColumnWrapper>
    </AuthenticationWrapper>
  );
};
export default Authentication;
