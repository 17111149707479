import React, { useState } from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { GroupUser } from "store/tasker/models/GroupUser";

interface Props {
  createdBy: GroupUser | undefined;
  assignee: GroupUser | undefined;
}

export const TaskDetailsPersons = ({
  createdBy = undefined,
  assignee = undefined,
}: Props) => {
  const [folded, setFolded] = useState(true);
  return (
    <Flex
      gap="8px"
      direction="column"
      p="0px 0 8px 10px"
      style={{
        borderBottom: `0.5px solid ${theme.color.slightlyLightGray}`,
      }}
    >
      <Flex
        gap="16px"
        align="center"
        onClick={() => setFolded(!folded)}
        style={{ cursor: "hover" }}
      >
        <Image
          src={`/icons/arrow2-${folded ? "right" : "down"}.svg`}
          height={24}
          width={24}
        />
        <Flex align="center">
          <Text size="14px" weight="bold">
            Osoby
          </Text>
        </Flex>
        <Text size="14px">(3)</Text>
      </Flex>
      {!folded && (
        <Flex gap="80px" p="0 0 0 40px">
          <Flex direction="column" gap="16px">
            <Flex>
              <Text size="14px">Osoba zgłaszająca:</Text>
            </Flex>
            <Flex>
              <Image
                src={"/icons/profileIconExample.svg"}
                width={32}
                height={32}
                radius="100%"
              />
              <Flex align="center">
                <Text size="14px" ml="8px">
                  {createdBy
                    ? createdBy.user?.first_name +
                      " " +
                      createdBy.user?.last_name
                    : "CREATED_BY USER ERROR?"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column" gap="16px">
            <Flex>
              <Text size="14px">Osoba przypisana:</Text>
            </Flex>
            <Flex>
              <Image
                src={"/icons/profileIconExample.svg"}
                width={32}
                height={32}
                radius="100%"
              />
              <Flex align="center">
                <Text size="14px" ml="8px">
                  {assignee
                    ? assignee.user?.first_name + " " + assignee.user?.last_name
                    : "USER ASSIGNEE ERROR?"}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
