import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { UseFormTrigger } from "react-hook-form/dist/types";

import Button from "common/components/Button";
import Flex from "common/components/Flex";
import Image from "common/components/Image";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { GroupUser } from "store/tasker/models/GroupUser";

import { ITaskInForm } from "../NewTaskView";
import PopUpProfileCard from "../PopUpProfileCard";

import AddPersonsModal from "./AddPersonsModal/AddPersonsModal";

const fieldName: keyof ITaskInForm = "assignee";

interface GroupCreationProps {
  assignee: GroupUser | null;
  setValue: UseFormSetValue<ITaskInForm>;
  setAssignee: any;
  trigger: UseFormTrigger<ITaskInForm>;
}

export default function ResponsiblePersons({
  assignee,
  setValue,
  setAssignee,
  trigger,
}: GroupCreationProps) {
  const modal = useModal({ withCloseButton: true });

  const handleRemoveUser = () => {
    setAssignee(null);

    setValue(fieldName, null);
    trigger(fieldName);
  };

  const handleCloseModalAndSetAssignee = (user: GroupUser | null) => {
    setAssignee(user);
    setValue(fieldName, user.user_id);
    trigger(fieldName);
    modal.modalProps.closeModal();
  };

  const handleCloseModalAndCancel = () => {
    trigger(fieldName);
    modal.modalProps.closeModal();
  };

  return (
    <RelativeContainer>
      <ModalPortal {...modal.modalProps}>
        <AddPersonsModal
          handleCloseModalAndSetAssignee={handleCloseModalAndSetAssignee}
          handleCloseModalAndCancel={handleCloseModalAndCancel}
          assignee={assignee}
        />
      </ModalPortal>
      <Flex direction="column" mb="10px">
        <Text
          size="14px"
          weight="thin"
          lineHeight="20px"
          color={theme.color.basicBlack}
        >
          Osoba odpowiedzialna
        </Text>
        <Flex direction="row" mt="5px">
          <Flex>
            {assignee && (
              <PopUpProfileCard
                top="50px"
                left="60px"
                key={assignee.user_id}
                user={assignee}
                onClick={handleRemoveUser}
              />
            )}
          </Flex>
          {!assignee && (
            <Button
              variant="outlined"
              padding="8px 2px"
              border="none"
              display="flex"
              width="130px"
              margin="0 0 10px 10px"
            >
              <Image
                src={`/icons/plus.svg`}
                alt="Plus"
                width={14}
                height={14}
              />
              <Text
                size="14px"
                weight="bold"
                letterSpacing="0.2px"
                color={theme.color.basicBlack}
                onClick={() => modal.showModal()}
              >
                Dodaj osobę
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </RelativeContainer>
  );
}
