import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import RouterLink from "common/components/CleanRouterLink";
import Image from "common/components/Image";

import {
  Button,
  Description,
  Header,
  ImageWrapper,
  MainWrapper,
  Title,
} from "./Page404.styled";

const Page404: FC = () => {
  const navigate = useNavigate();
  return (
    <MainWrapper>
      <ImageWrapper>
        <Image
          src={"/images/404image.png"}
          alt="page 404"
          width={1440}
          height={294}
        />
      </ImageWrapper>
      <Header>404</Header>
      <Title>Oops! Coś poszło nie tak</Title>
      <Description>
        Nie udało nam się znaleźć strony, której szukasz. Zweryfikuj adres lub
        wróć na stronę główną i wyszukaj ponownie
      </Description>
      <RouterLink to="/">
        <Button onClick={() => navigate(-1)}>Wróć</Button>
      </RouterLink>
    </MainWrapper>
  );
};

export default Page404;
