import React, { useState } from "react";

import DropdownMenu from "../DropdownMenu/DropdownMenu";
import Image from "../Image";

import { ImageButton, OptionsWrapper } from "./MakeActionDropdown.styled";

interface MakeActionDropdownProps {
  dropdownMenuOptions: Array<{
    img: string;
    text: string;
    action: () => void;
    testid: string;
  }>;
  top?: string;
  left?: string;
  children?: React.ReactNode;
  icon?: string;
}

const MakeActionDropdown = ({
  dropdownMenuOptions,
  top,
  left,
  children,
  icon,
}: MakeActionDropdownProps) => {
  const [editIsShown, setEditIsShown] = useState(false);

  // It can be setEditIsShown(true) if we do not want to hide dropdown on "edytuj opis" click.
  const handleEdit = () => {
    setEditIsShown((editIsShown) => !editIsShown);
  };

  const onBlurHandler = () => {
    setEditIsShown(false);
  };
  return (
    <OptionsWrapper>
      <ImageButton onClick={handleEdit} onBlur={onBlurHandler}>
        <Image
          src={icon || "/icons/action_icon.svg"}
          alt="Opcje"
          width={18}
          height={18}
        />
        {editIsShown && (
          <DropdownMenu
            options={dropdownMenuOptions}
            onClickClose={() => setEditIsShown(false)}
            top={top}
            left={left}
          >
            {children}
          </DropdownMenu>
        )}
      </ImageButton>
    </OptionsWrapper>
  );
};

export default MakeActionDropdown;
