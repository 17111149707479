import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Flex from "common/components/Flex";
import CancelAcceptButtons from "common/components/Forms/Buttons/CancelAcceptButtons";
import { DropdownInputValue } from "common/components/Forms/Dropdown/DropdownInputValue";
import { DropdownItem } from "common/components/Forms/Dropdown/DropdownItem";
import { DropdownItemsWrapper } from "common/components/Forms/Dropdown/DropdownItemsWrapper";
import { FormSelectScrollbarWrapper } from "common/components/FormSelect/FormSelect.styled";
import IconButton from "common/components/IconButton";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import TaskCategory from "common/components/TagsAndLabels/TaskCategory/TaskCategory";
import Text from "common/components/Text";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { createTaskCategory } from "services/api/taskCategory";
import { TaskCategoryCreatePayload } from "services/api/taskCategory/taskCategoryApi";
import { getSub } from "store/auth/authUtils";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ICategory } from "store/tasker/models/Category";
import { taskerAction } from "store/tasker/taskerSlice";
import * as yup from "yup";

import {
  FlexButtonCreateCategory,
  InputWrapper,
} from "./AddCategoriesForm.styled";

export interface INewCategory {
  value: string;
}

const config = {
  nameMinLength: 3,
  nameMaxLength: 30,
};

const TaskCreationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required("Nazwa kategorii jest wymagana")
      .min(
        config.nameMinLength,
        `Nazwa kategorii musi mieć przynajmniej ${config.nameMinLength} znaków`
      )
      .max(
        config.nameMaxLength,
        `Nazwa kategorii może mieć maksymalnie ${config.nameMaxLength} znaków`
      )
      .matches(
        /^[a-zA-Z0-9-żźćńółęąśŻŹĆĄŚĘŁÓŃ ]*$/,
        "Nazwa kategorii może zawierać tylko litery, cyfry i myślniki"
      ),
  });

export interface AddCategoriesFormProps {
  id?: string;
  errorText?: string;
  isValid?: boolean;
  setOpenForm: (prev?) => void;
  added: ICategory[];
  closeModalAndAddCategories: (categories: ICategory[]) => void;
}

function AddCategoriesForm({
  setOpenForm,
  added,
  closeModalAndAddCategories,
}: AddCategoriesFormProps) {
  const dispatch = useAppDispatch();
  const taskerSelector = useAppSelector((s) => s.tasker);
  const { groupId } = useCurrentGroupId();
  const [addedWhenOpen, setAddedWhenOpen] = useState<ICategory[]>([]);
  const [categoriesToAdd, setCategoriesToAdd] = useState<ICategory[]>([]);
  const [allCategories, setAvailableCategories] = useState<ICategory[]>([]);
  const [isFocus, setIsFocus] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOnMouse, setIsOnMouse] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!added) return;
    setAddedWhenOpen([...added]);
    setCategoriesToAdd([...added]);
  }, [added]);

  useEffect(() => {
    if (taskerSelector.MainView === "Group") {
      const categories = taskerSelector.allCategories.filter((l) => {
        const x = categoriesToAdd.findIndex((c) => c.id === l.id);
        return x === -1;
      });
      setAvailableCategories(categories);
    } else if (taskerSelector.MainView === "User") {
      const groupId = taskerSelector.userViewTree.taskFromGroupId;
      const group = taskerSelector.userViewTree.groups.find(
        (g) => g.group.id === groupId
      );
      const categories = group.allCategories.filter((l) => {
        const x = categoriesToAdd.findIndex((l2) => l2.id === l.id);
        return x === -1;
      });
      setAvailableCategories(categories);
    }
  }, [
    categoriesToAdd,
    taskerSelector.MainView,
    taskerSelector.allCategories,
    taskerSelector.userViewTree.taskFromGroupId,
    taskerSelector.userViewTree.groups,
  ]);

  const { register, setValue } = useForm<INewCategory>({
    resolver: yupResolver(TaskCreationSchema()),
    mode: "all",
    defaultValues: {
      value: "",
    },
  });

  const handleBlur = () => {
    setIsFocus(false);
    isOnMouse || setIsActive(true);
  };

  const handleChangeInputValue = (name: string) => {
    const exists: number = allCategories.findIndex((o) => o.name === name);
    if (exists !== -1) {
      const category = allCategories[exists] as ICategory;
      setValue("value", name);
      setInputValue(category.name);
    } else {
      setValue("value", name);
      setInputValue(name);
    }
  };

  function handleClick(): void {
    if (!isClicked) setIsClicked(true);
    setIsActive(true);
  }

  const handleOnMouseLeave = () => {
    setIsOnMouse(false);
    isFocus || setIsActive(true);
  };

  const handleChooseItem = (category: ICategory) => {
    setInputValue("");
    setIsActive(false);
    setCategoriesToAdd((prev) => [...prev, category]);
  };

  const handleCreateCategory = async () => {
    setInputValue("");
    setIsActive(false);
    // eslint-disable-next-line no-debugger
    debugger;
    const group_id =
      taskerSelector.MainView === "Group"
        ? groupId
        : taskerSelector.userViewTree.taskFromGroupId;

    const newCategory: TaskCategoryCreatePayload = {
      group_id: group_id,
      name: inputValue,
      created_by: getSub(),
    };

    const newCategoryId = await createTaskCategory(newCategory);
    const category_: ICategory = {
      id: newCategoryId,
      created: new Date().toISOString(),
      created_by: getSub(),
      delete_date: "",
      group_id: groupId,
      last_update_date: new Date().toISOString(),
      name: inputValue,
    };

    setCategoriesToAdd((prev) => [...prev, category_]);
    dispatch(taskerAction.addNewCategory(category_));
  };

  const handleRemoveCategory = (id: string) => {
    setCategoriesToAdd([...categoriesToAdd].filter((l) => l.id !== id));
  };

  const filterWithoutChoosenFn = (category: ICategory) => {
    if (!category) return true;
    return category.name.toLowerCase().includes(inputValue.toLowerCase());
  };

  return (
    <RelativeContainer
      w="500px"
      h="auto"
      bgColor="#FFF"
      boxShadow="-2px 2px 20px 0px rgba(0, 0, 0, 0.20)"
    >
      <IconButton
        position="absolute"
        right="17px"
        top="17px"
        width={13}
        height={13}
        icon="/icons/close_big.svg"
      />
      <Flex direction="column" p="50px 30px 20px 30px">
        <Flex mb="7px">
          <Text>Etykiety</Text>
        </Flex>
        <Flex>
          <InputWrapper>
            <Flex height="100%" width="100%" align="center">
              {categoriesToAdd?.map((l, idx) => (
                <TaskCategory
                  key={l.id}
                  text={l.name}
                  handleRemove={() => handleRemoveCategory(l.id)}
                  h="29px"
                  colorIdx={idx}
                />
              ))}
              <DropdownInputValue
                {...register("value", {
                  onChange: (e) => handleChangeInputValue(e.target.value),
                  onBlur: () => handleBlur(),
                })}
                id={"value"}
                name={"value"}
                value={inputValue}
                noBorder={true}
                isClicked={true}
                isActive={false}
                isValid={true}
                onClick={handleClick}
                onFocus={() => {
                  setIsFocus(true);
                }}
              />
              {isActive && (allCategories.length > 0 || inputValue.length > 0) && (
                <DropdownItemsWrapper
                  isActive={isActive}
                  onMouseOver={() => setIsOnMouse(true)}
                  onMouseLeave={handleOnMouseLeave}
                >
                  <FormSelectScrollbarWrapper scrollBarPadding="0px 8px 0px 0px">
                    {allCategories?.filter(filterWithoutChoosenFn).map((el) => (
                      <DropdownItem
                        key={el.name}
                        onClick={() => handleChooseItem(el)}
                        data-value={el}
                      >
                        {el.name}
                      </DropdownItem>
                    ))}
                    {isActive && inputValue.length > 0 && (
                      <FlexButtonCreateCategory
                        onMouseOver={() => setIsOnMouse(true)}
                        onMouseLeave={handleOnMouseLeave}
                        onClick={handleCreateCategory}
                      >
                        Utwórz grupe {inputValue}
                      </FlexButtonCreateCategory>
                    )}
                  </FormSelectScrollbarWrapper>
                </DropdownItemsWrapper>
              )}
              <IconButton
                position="absolute"
                right="14px"
                top="17px"
                width={16}
                height={16}
                icon="/icons/arrowDown.svg"
              />
            </Flex>
          </InputWrapper>
        </Flex>
        <Flex mt="20px" width="100%">
          <CancelAcceptButtons
            flexJustifyType="space-between"
            cancelButton={{
              handleCloseModalAndCancel: () => {
                closeModalAndAddCategories(addedWhenOpen);
                setOpenForm((prev) => !prev);
              },
            }}
            acceptButton={{
              handleCloseModalAndAccept: () => {
                closeModalAndAddCategories(categoriesToAdd);
                setOpenForm((prev) => !prev);
              },
            }}
          />
        </Flex>
      </Flex>
    </RelativeContainer>
  );
}

export default AddCategoriesForm;
