import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PostWrapper = styled.div`
  background-color: ${theme.color.basicWhite};
  display: grid;
  grid-template-rows: auto auto;
  gap: 16px;
  width: 684px;
  height: auto;
  border-radius: 8px;
  padding-bottom: 8px;
`;

export const PostDataWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-rows: auto 1fr;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
`;

export const PostReactionWrapper = styled.div`
  padding-left: 32px;
  padding-right: 32px;
`;
