import styled from "styled-components";

export const DropdownItem = styled.li`
  margin: 4px 0;
  padding: 12px;
  border-radius: 8px;
  line-height: 124%;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    background-color: #eceeef;
  }
`;
