import React from "react";

import { GroupInformationWrapper } from "./GroupInformation.styled";
import GroupInformationHeader from "./GroupInformationHeader/GroupInformationHeader";
import GroupInformationText from "./GroupInformationText/GroupInformationText";

interface InformationProps {
  information: string;
}

export const GroupInformation = ({ information }: InformationProps) => {
  return (
    <GroupInformationWrapper>
      <GroupInformationHeader />
      <GroupInformationText information={information} />
    </GroupInformationWrapper>
  );
};
