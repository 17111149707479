import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface TextAreaProps {
  marginRight?: string;
}

export const AddPostButtonIconWrapper = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  max-width: 80px;
  align-items: end;
  justify-content: end;
`;

export const PostAreaTextWrapper = styled.div<TextAreaProps>`
  width: 569px;
  height: 41px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  border-color: #eceeef;
  border-style: solid;
  background-color: ${theme.color.basicWhite};
  cursor: pointer;
  margin-right: ${(props) => props.marginRight};
`;
