import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "common/types/Language";
import { getLastAuthUser } from "common/utils/auth/getLastAuthUser";
import { imageBase64Decode } from "common/utils/imageBase64Decode";
import { convertGroupFromDto, IGroup } from "components/Group/Types/group";
import {
  createGroup,
  IAssignUserRequestMessage,
  ICreateGroupRequestDtoMessage,
  userAssignment,
} from "services/api/group/createGroup";
import {
  editGroupStatus,
  IEditGroupStatusRequest,
} from "services/api/group/editGroup";
import {
  closeGroup,
  getDiscussGroups,
  getGroupInfo,
  getGroupMembers,
  getProjectGroups,
  IDeleteGroupMessageRequest,
  IGetGroupInfoRequest,
  IGroupMemberResponseDto,
} from "services/api/group/groupApi";
import { GroupApiRequest } from "services/api/shared/apiResponse";

import { GroupListMemberResponseDto } from "./../services/api/group/groupApi";

export enum GroupTypeEnum {
  DISCUSS = "DISCUSS",
  PROJECT = "PROJECT",
  UNKNOWN = "UNKNOWN",
}

export interface GroupMemberAvatar {
  bucket: string;
  data: string;
  key: string;
}

export interface GroupListMember {
  id: string;
  type: GroupTypeEnum;
  name: string;
  role: string;
  picture: string;
}

export enum GroupMemberRole {
  Admin = "ADMIN",
  Member = "MEMBER",
}
export interface IGroupMember {
  avatar: string;
  groupId: string;
  userId: string;
  userRole: GroupMemberRole;
  userStatusHistory: any;
  user: IGroupMemberUserData;
}

interface IGroupMemberUserData {
  sub: string;
  gender: string;
  dateOfBirth: string;
  lastName: string;
  language: Language;
  firstName: string;
  email: string;
}

export const getGroupListMemberInitialState = (): GroupListMember => ({
  id: "",
  type: GroupTypeEnum.UNKNOWN,
  name: "",
  role: "",
  picture: "",
});

export interface GroupState {
  isLoading: boolean;
  groups: GroupListMember[];
  groupMembers: IGroupMember[];
  createGroupId: string;
  chosenGroup: IGroup;
  currentGroupUser: IGroupMember;
  currentGroupUserRole: GroupMemberRole;
}

const initialState: GroupState = {
  isLoading: false,
  groups: [],
  groupMembers: [],
  createGroupId: "",
  chosenGroup: null,
  currentGroupUser: null,
  currentGroupUserRole: null,
};

export const fetchGroupsList = createAsyncThunk("group/fetchGroupsList", () => {
  const discussGroups = getDiscussGroups();
  const projectGroups = getProjectGroups();
  return Promise.all([discussGroups, projectGroups]);
});

export const fetchGroupMembers = createAsyncThunk(
  "group/fetchUsers",
  async (groupId: string) => {
    const response = await getGroupMembers(groupId);
    return response;
  }
);

export const groupCreate = createAsyncThunk(
  "group/create",
  async (groupData: GroupApiRequest<ICreateGroupRequestDtoMessage>) => {
    const response = await createGroup(groupData);
    return response;
  }
);

export const groupDelete = createAsyncThunk(
  "/user",
  async (groupToDeleteData: GroupApiRequest<IDeleteGroupMessageRequest>) => {
    const response = await closeGroup(groupToDeleteData);
    return response;
  }
);

export const assignUser = createAsyncThunk(
  "/user",
  async (userData: GroupApiRequest<IAssignUserRequestMessage>) => {
    const response = await userAssignment(userData);
    return response.body;
  }
);

export const changeGroupStatus = createAsyncThunk(
  "/group",
  async (groupStatusData: GroupApiRequest<IEditGroupStatusRequest>) => {
    const response = await editGroupStatus(groupStatusData);
    return response.body;
  }
);

export const getGroupInformation = createAsyncThunk(
  "/group",
  async (groupStatusData: IGetGroupInfoRequest) => {
    const response = await getGroupInfo(
      groupStatusData.groupId,
      groupStatusData.groupType
    );
    return response;
  }
);

function convertGroupListMemberFromDto(
  groupDto: GroupListMemberResponseDto
): GroupListMember {
  return {
    id: groupDto.group_id,
    type: GroupTypeEnum[groupDto.group_type],
    name: groupDto.name,
    role: "Admin",
    picture: groupDto.logo
      ? imageBase64Decode(groupDto.logo.data)
      : "/images/acornDefault.png",
  };
}

function convertGroupMembersFromDto(
  userDto: IGroupMemberResponseDto
): IGroupMember {
  return {
    avatar:
      userDto.avatar?.data !== undefined
        ? imageBase64Decode(userDto.avatar.data)
        : "/icons/user-avatar-placeholder.svg",
    groupId: userDto.group_id,
    userId: userDto.user_id,
    userRole: userDto.user_role,
    userStatusHistory: userDto.user_status_history,
    user: {
      firstName: userDto.user.first_name,
      lastName: userDto.user.last_name,
      gender: userDto.user.gender,
      language: userDto.user.language,
      sub: userDto.user.sub,
      dateOfBirth: userDto.user.date_of_birth,
      email: userDto.user.email,
    },
  };
}

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setChosenGroup: (state, action: PayloadAction<IGroup>) => {
      state.chosenGroup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchGroupsList.fulfilled, (state, action) => {
      state.isLoading = false;
      const allGroups = action.payload.flat().filter((item) => item !== null);
      state.groups = allGroups.map((group) =>
        convertGroupListMemberFromDto(group)
      );
    });
    builder.addCase(fetchGroupMembers.fulfilled, (state, action) => {
      const groupMembers = action.payload;
      state.groupMembers = groupMembers?.map((user) =>
        convertGroupMembersFromDto(user)
      );
      state.currentGroupUser =
        state.groupMembers.find(
          (members) => members.userId === getLastAuthUser()
        ) ?? null;
    });
    builder.addCase(groupCreate.fulfilled, (state, action) => {
      state.createGroupId = action.payload.body.message.group_id;
    });
    builder.addCase(getGroupInformation.fulfilled, (state, action) => {
      state.chosenGroup = convertGroupFromDto(action.payload);
    });
  },
});

export default groupSlice.reducer;
export const groupActions = groupSlice.actions;
