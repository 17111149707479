import React, { Dispatch, SetStateAction, useState } from "react";

import IconButton from "common/components/IconButton";
import Image from "common/components/Image";
import { useSub } from "common/hooks/useSub";
import { postConfig } from "components/Group/GroupDiscussion/postConfig";
import { useGetUserQuery } from "store/user/userApi";

import {
  CommentAreaWrapper,
  CommentCreationWrapper,
  CommentIconsArea,
  CommentSmallIcons,
} from "./CommentCreation.styled";
import CommentCreationModal from "./CommentCreationModal";

interface CommentCreationProps {
  currentPostId: string;
  refetchComments: () => void;
  setCommentWasCreated: Dispatch<SetStateAction<boolean>>;
}

const CommentCreation = ({
  currentPostId,
  setCommentWasCreated,
  refetchComments,
}: CommentCreationProps) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub });
  const [turnModal, setTurnModal] = useState(false);

  const turnOnModal = () => {
    setTurnModal(true);
  };
  const turnOffModal = () => {
    setTurnModal(false);
  };

  return (
    <>
      <CommentCreationWrapper>
        <IconButton
          width={40}
          height={40}
          radius="100%"
          icon={user.avatarUrl ?? postConfig.userPlaceholderAvatar}
          backgroundColor="white"
        />
        <CommentAreaWrapper onClick={turnOnModal}>
          <CommentIconsArea>
            <CommentSmallIcons>
              <Image
                src="/icons/at-sign.svg"
                alt="logo email małpa"
                width={16}
                height={16}
              />
              <Image
                src="/icons/paperclip.svg"
                alt="logo załącznik"
                width={16}
                height={16}
              />
            </CommentSmallIcons>
            <Image
              src="/icons/send.svg"
              alt="logo wysłanie wiadomości"
              width={20}
              height={20}
            />
          </CommentIconsArea>
        </CommentAreaWrapper>
      </CommentCreationWrapper>
      <CommentCreationModal
        parentId={currentPostId}
        refetchPosts={refetchComments}
        setPostWasCreated={setCommentWasCreated}
        setTurnOffModal={turnOffModal}
        turnModal={turnModal}
      />
    </>
  );
};

export default CommentCreation;
