/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "common/components/Button";
import { CheckboxLifting } from "common/components/CheckboxLifting/CheckboxLifting";
import DatePicker from "common/components/DatePickerHookForms";
import FormInput from "common/components/FormInputHookForms";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import { jobSchema } from "common/constants/jobSchema";
import { useSub } from "common/hooks/useSub";
import {
  useGetProfileQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from "store/user/userApi";
import styled from "styled-components/macro";

import { useStepper } from "../Stepper";

const ProfileCreationWorkStyles = styled.form`
  margin: 2rem 0;
  max-width: 600px;
  width: 100%;
  gap: 2rem;
  display: flex;
  height: 500px;
  flex-direction: column;
  div:first-of-type:not(:last-of-type) {
    width: 100%;
  }

  .checkbox {
    margin-top: 2rem;
  }

  button {
    height: max-content;
    width: max-content;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    a {
      text-decoration: none;
      color: inherit;
    }

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const ProfileCreationWork = () => {
  const { nextStep } = useStepper();
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });
  const { data: profile } = useGetProfileQuery(
    {
      sub,
      language: user?.language,
    },
    {
      skip: !sub || !user?.language,
    }
  );
  const [updateProfile] = useUpdateProfileMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(jobSchema),
    defaultValues: {
      jobName: "",
      companyName: "",
      jobStarted: "" as string | Date,
      jobFinished: "" as string | Date,
      currentlyInCompany: false,
    },
  });

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 100, 0, 1);

  const onSubmit = handleSubmit(async (profileData) => {
    const parsed = {
      ...profileData,
      jobStarted: new Date(profileData.jobStarted).toISOString(),
      jobFinished:
        profileData.jobStarted && !profileData.currentlyInCompany
          ? new Date(profileData.jobStarted).toISOString()
          : "",
    };

    await updateProfile({
      sub,
      language: user.language,
      profileData: parsed,
    });
    nextStep();
  });

  useEffect(() => {
    if (!profile) {
      return;
    }
    if (profile.currentlyInCompany) {
      setValue("currentlyInCompany", profile.currentlyInCompany);
    }
    if (profile.jobName) {
      setValue("jobName", profile.jobName);
    }
    if (profile.companyName) {
      setValue("companyName", profile.companyName);
    }
    if (profile.jobFinished) {
      setValue("jobFinished", new Date(profile.jobFinished));
    }
    if (profile.jobStarted) {
      setValue("jobStarted", new Date(profile.jobStarted));
    }
  }, [profile, setValue]);

  return (
    <ProfileCreationWorkStyles onSubmit={onSubmit}>
      <FormInput
        label="Stanowisko"
        touched={true}
        type="text"
        inputName="jobName"
        register={register}
        id="jobName"
        placeholder="Stanowisko"
        isValid={errors.jobName === undefined}
        errorText={errors.jobName?.message}
      />
      <FormInput
        label="Nazwa firmy"
        touched={true}
        type="text"
        inputName="companyName"
        register={register}
        id="companyName"
        placeholder="Nazwa firmy"
        isValid={errors.companyName === undefined}
        errorText={errors.companyName?.message}
      />
      <DatePicker
        label="Data rozpoczęcia"
        control={control}
        id="country"
        name="jobStarted"
        isValid={errors.jobStarted === undefined}
        errorText={errors.jobStarted?.message}
        maxDate={new Date()}
        minDate={minDate}
      />
      {!watch("currentlyInCompany") && (
        <DatePicker
          label="Data zakończenia"
          control={control}
          id="country"
          name="jobFinished"
          isValid={errors.jobFinished === undefined}
          errorText={errors.jobFinished?.message}
          maxDate={new Date()}
          minDate={new Date(watch("jobStarted"))}
        />
      )}
      <CheckboxLifting {...register("currentlyInCompany")} className="checkbox">
        Nadal tutaj pracuję
      </CheckboxLifting>
      <footer>
        <Link to="/create-profile?step=1">
          <Button type="button">Wstecz</Button>
        </Link>
        <div>
          <Link to="/create-profile?step=3">
            <GhostButton type="button">Pomiń krok</GhostButton>
          </Link>
          <Button type="submit">Dalej</Button>
        </div>
      </footer>
    </ProfileCreationWorkStyles>
  );
};
