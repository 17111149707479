import React from "react";

import { IconProps } from "./types";

interface MembersIconProps extends IconProps {}

const MembersIcon = ({ width, height, color }: MembersIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 13"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.25C14.13 7.25 15.57 7.64 16.74 8.15C17.82 8.63 18.5 9.71 18.5 10.88V11.5C18.5 12.05 18.05 12.5 17.5 12.5H7.5C6.95 12.5 6.5 12.05 6.5 11.5V10.89C6.5 9.71 7.18 8.63 8.26 8.16C9.43 7.64 10.87 7.25 12.5 7.25ZM4.5 7.5C5.6 7.5 6.5 6.6 6.5 5.5C6.5 4.4 5.6 3.5 4.5 3.5C3.4 3.5 2.5 4.4 2.5 5.5C2.5 6.6 3.4 7.5 4.5 7.5ZM5.63 8.6C5.26 8.54 4.89 8.5 4.5 8.5C3.51 8.5 2.57 8.71 1.72 9.08C0.98 9.4 0.5 10.12 0.5 10.93V11.5C0.5 12.05 0.95 12.5 1.5 12.5H5V10.89C5 10.06 5.23 9.28 5.63 8.6ZM20.5 7.5C21.6 7.5 22.5 6.6 22.5 5.5C22.5 4.4 21.6 3.5 20.5 3.5C19.4 3.5 18.5 4.4 18.5 5.5C18.5 6.6 19.4 7.5 20.5 7.5ZM24.5 10.93C24.5 10.12 24.02 9.4 23.28 9.08C22.43 8.71 21.49 8.5 20.5 8.5C20.11 8.5 19.74 8.54 19.37 8.6C19.77 9.28 20 10.06 20 10.89V12.5H23.5C24.05 12.5 24.5 12.05 24.5 11.5V10.93ZM12.5 0.5C14.16 0.5 15.5 1.84 15.5 3.5C15.5 5.16 14.16 6.5 12.5 6.5C10.84 6.5 9.5 5.16 9.5 3.5C9.5 1.84 10.84 0.5 12.5 0.5Z"
        fill={color}
      />
    </svg>
  );
};

export default MembersIcon;
