import React, { FocusEvent, useEffect, useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";

import { DropdownInputValue } from "common/components/Forms/Dropdown/DropdownInputValue";
import { DropdownItem } from "common/components/Forms/Dropdown/DropdownItem";
import IconButton from "common/components/IconButton";
import Image from "common/components/Image";
import { Container } from "common/components/Layout/Containers";
import { AbsValidationLabel } from "common/components/Layout/Form/ValidationLabel";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { ITaskInForm } from "components/TaskerPage/NewTaskView/NewTaskView";
import {
  createTaskList,
  getTaskLists,
  TaskListCreatePayload,
} from "services/api/taskList/taskListApi";
import { getSub } from "store/auth/authUtils";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IList } from "store/tasker/models/List";
import { taskerAction } from "store/tasker/taskerSlice";

import { FlexButtonCreateGroup } from "./GroupSelect.styled";
import {
  FormSelectScrollbarWrapper,
  WhiteDropdownItemsWrapper,
} from "./ListSelect.styled";
import ModalCreateListForm from "./ModalAddListForm/ModalCreateListForm";

const fieldName: keyof ITaskInForm = "listName";

export interface FormSelectProps {
  control: Control<ITaskInForm>;
  register: UseFormRegister<ITaskInForm>;
  errorText?: string;
  isValid: boolean;
}

function GroupSelect({
  control,
  register,
  isValid,
  errorText,
}: FormSelectProps) {
  const dispatch = useAppDispatch();
  const { groupId: routeGroupId } = useCurrentGroupId();
  const taskerSelector = useAppSelector((s) => s.tasker);
  const hoveredGroupId = taskerSelector.userViewTree.taskFromGroupId;

  const [isClicked, setIsClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOnMouse, setIsOnMouse] = useState(false);
  const [initList, setInitList] = useState(false);
  const [allLists, setAvailableLists] = useState<IList[]>([]);
  const [inputValue, setInputValue] = useState<IList>({
    id: "",
    name: "",
    group_id:
      taskerSelector.MainView === "User" ? hoveredGroupId : routeGroupId,
    last_update_date: "",
    tasks: [],
  });
  const [, setNewListName] = useState("");
  const modal = useModal({ withCloseButton: true });

  const groupId =
    taskerSelector.MainView === "User" ? hoveredGroupId : routeGroupId;

  useEffect(() => {
    if (errorText) {
      setIsClicked(true);
    }
  }, [errorText]);

  const fetchTaskLists = async () => {
    const lists = await getTaskLists(groupId);
    setAvailableLists(lists);
    return lists;
  };

  const handleBlur = (event: FocusEvent, onBlur) => {
    setIsFocus(false);
    isOnMouse || setIsActive(false);
    onBlur && onBlur(event);
  };

  const handleOnMouseLeave = () => {
    setIsOnMouse(false);
    isFocus || setIsActive(false);
  };

  const handleClickOnInput = async (): Promise<void> => {
    if (!isClicked) {
      setIsClicked(true);
      fetchTaskLists();
    }
    setIsActive(true);
  };

  const handleChooseItem = (item: IList, onChange) => {
    onChange(item.id);
    setInputValue(item);
    setIsActive(false);
  };

  const handleChangeInputValue = (name: string, onChange) => {
    const exists: number = allLists.findIndex((o) => o.name === name);
    if (exists !== -1) {
      const item = allLists[exists] as IList;
      onChange(item.id);
      setInputValue(item);
    } else {
      onChange("");
      setInputValue({
        id: "",
        name,
        group_id: groupId,
        last_update_date: "",
        tasks: [],
      });
    }
  };

  const filterWithoutChoosenFn = (options: IList) => {
    if (!options) return true;
    return options.name.toLowerCase().includes(inputValue.name.toLowerCase());
  };

  const initListValueForCreatingNewTaskInList = async (onChange) => {
    if (!initList && taskerSelector.taskerView.NewTaskListId) {
      setIsClicked(true);
      const lists: IList[] = await fetchTaskLists();
      const selected = lists?.find(
        (l) => l.id === taskerSelector.taskerView.NewTaskListId
      );
      setAvailableLists(lists);
      if (selected) {
        onChange(taskerSelector.taskerView.NewTaskListId);
        setInputValue(selected);
        setInitList(true);
      }
    }
  };

  const initListValueForEditingTaskInGroupView = async (onChange) => {
    if (
      !initList &&
      taskerSelector.taskerView.editTaskData &&
      taskerSelector.MainView === "Group"
    ) {
      const listId = taskerSelector.taskerView.editTaskData.task.list_id;
      const lists: IList[] = await fetchTaskLists();
      const selected = lists?.find((l) => l.id === listId);
      if (selected) {
        onChange(listId);
        setInputValue(selected);
        setInitList(true);
      }
    }
  };

  const initListValueForEditingTaskInUserView = async (onChange) => {
    if (
      !initList &&
      taskerSelector.taskerView.editTaskData &&
      taskerSelector.MainView === "User"
    ) {
      const listId = taskerSelector.taskerView.editTaskData.task.list_id;
      const groupId = taskerSelector.userViewTree.taskFromGroupId;
      const group = taskerSelector.userViewTree.groups.find(
        (l) => l.group.id === groupId
      )!;
      const lists = group.allLists;
      const selected = lists?.find((l) => l.id === listId);
      if (selected) {
        onChange(listId);
        setInputValue(selected);
        setInitList(true);
      }
      setAvailableLists(lists);
    }
  };

  const handleCreateList = async (name: string, onChange: any) => {
    const newListPayload: TaskListCreatePayload = {
      group_id: groupId,
      name: name,
      created_by: getSub(),
    };

    const id: string = await createTaskList(newListPayload);

    const newList: IList = {
      id,
      group_id: groupId,
      last_update_date: new Date().toISOString(),
      name: name,
      tasks: [],
    };

    setAvailableLists((prev) => {
      return [...prev, newList];
    });
    setNewListName(name);
    onChange(id);
    setInputValue({
      id,
      name: newList.name,
      group_id: groupId,
      last_update_date: new Date().toISOString(),
      tasks: [],
    });
    setIsActive(false);
    dispatch(taskerAction.addTaskList(newList));
    modal.modalProps.closeModal();
  };

  const openModal = () => {
    modal.showModal();
    handleOnMouseLeave();
  };

  const handleBlurOnCreateList = (event: any, onBlur) => {
    setIsFocus(false);
    isOnMouse || setIsActive(false);
    onBlur && onBlur(event);
  };

  const handleCloseModalAndCancel = () => {
    modal.modalProps.closeModal();
  };

  return (
    <Container m="20px 0 0 0">
      <Controller
        name={fieldName}
        control={control}
        {...register(fieldName)}
        render={({ field: { onChange, onBlur } }) => {
          initListValueForCreatingNewTaskInList(onChange);
          initListValueForEditingTaskInGroupView(onChange);
          initListValueForEditingTaskInUserView(onChange);
          return (
            <RelativeContainer>
              <ModalPortal {...modal.modalProps}>
                <ModalCreateListForm
                  handleCloseModalAndAccept={handleCreateList}
                  handleCloseModalAndCancel={handleCloseModalAndCancel}
                  onChange={onChange}
                  value={inputValue.name}
                  alreadyExistingLists={allLists}
                />
              </ModalPortal>
              <AbsValidationLabel isInvalid={!isValid} top="-20px" left="5px">
                Lista
              </AbsValidationLabel>
              <IconButton
                width={12}
                height={21}
                icon="/icons/arrowDown.svg"
                position="absolute"
                right="15px"
                bottom="11px"
                onClick={handleClickOnInput}
              />
              <DropdownInputValue
                id={fieldName}
                name={fieldName}
                onClick={handleClickOnInput}
                value={inputValue.name}
                isClicked={isClicked}
                isActive={isActive}
                isValid={isValid}
                onFocus={() => {
                  setIsFocus(true);
                }}
                onBlur={(e) => handleBlur(e, onBlur)}
                onChange={(e) => {
                  handleChangeInputValue(e.target.value, onChange);
                }}
              />
              <label className="form__label"></label>
              {isActive && allLists.length > 0 && (
                <WhiteDropdownItemsWrapper
                  isActive={isActive}
                  onMouseOver={() => setIsOnMouse(true)}
                  onMouseLeave={handleOnMouseLeave}
                >
                  <FormSelectScrollbarWrapper scrollBarPadding="0px 8px 0px 0px">
                    {allLists?.filter(filterWithoutChoosenFn).map((el) => (
                      <DropdownItem
                        key={el.name}
                        onClick={() => handleChooseItem(el, onChange)}
                        data-value={el}
                      >
                        {el.name}
                      </DropdownItem>
                    ))}
                  </FormSelectScrollbarWrapper>
                </WhiteDropdownItemsWrapper>
              )}
              {isActive &&
                allLists?.filter(filterWithoutChoosenFn).length === 0 &&
                inputValue.name.length > 0 && (
                  <WhiteDropdownItemsWrapper
                    isActive={isActive}
                    onMouseOver={() => setIsOnMouse(true)}
                    onMouseLeave={handleOnMouseLeave}
                    onClick={(e) => {
                      openModal();
                      handleBlurOnCreateList(e, onBlur);
                    }}
                  >
                    <FlexButtonCreateGroup align="center" justify="center">
                      Utwórz listę
                    </FlexButtonCreateGroup>
                  </WhiteDropdownItemsWrapper>
                )}
              {!isValid && isClicked && (
                <AbsValidationLabel
                  isInvalid={!isValid}
                  bottom="-22px"
                  left="0px"
                >
                  <Image
                    src={"/icons/error_outline.svg"}
                    alt="Plus"
                    width={16}
                    height={16}
                    marginRight={"3px"}
                    layout={"relative"}
                    top={"2px"}
                  />
                  {inputValue.name !== "" ? "Nie ma takiej listy" : errorText}
                </AbsValidationLabel>
              )}
            </RelativeContainer>
          );
        }}
      />
    </Container>
  );
}

export default GroupSelect;
