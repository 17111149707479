import React, { useEffect, useState } from "react";

import Image from "common/components/Image";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import Text from "common/components/Text";
import { Paths } from "common/constants";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { useCurrentPath } from "common/hooks/useCurrentPath";
import { theme } from "common/theme/theme";
import GroupCreation from "components/GroupCreation/GroupCreation";
import { fetchGroupsList, GroupTypeEnum } from "store/groupSlice";
import { useAppSelector } from "store/hooks";
import { store } from "store/store";

import {
  AddGroupButton,
  AddGroupButtonWrapper,
  LeftPanelWrapper,
} from "./LeftPanel.styled";
import LeftPanelGroup from "./LeftPanelGroup/LeftPanelGroup";
import LeftPanelGroupHeader from "./LeftPanelHeader/LeftPanelGroupHeader";
import LeftPanelTools from "./LeftPanelTools/LeftPanelTools";

const useFetchGroupList = () => {
  const [isActive, setIsActive] = useState(false);
  const [fetched, setFetched] = useState(false);

  // much better will be split this to discuss and project, but it's too much
  // time expensive right now
  useEffect(() => {
    if (!isActive || fetched) {
      return;
    }

    store.dispatch(fetchGroupsList());
    setFetched(true);
  }, [fetched, isActive, setFetched]);

  return { setIsActive: () => setIsActive(true) };
};

const LeftPanel = () => {
  const modal = useModal({ withCloseButton: false });
  const { setIsActive } = useFetchGroupList();
  const { groupId } = useCurrentGroupId();

  const currentGroupType = useAppSelector(
    (state) => state.group.groups.find((group) => group.id === groupId)?.type
  );

  const { currentPath } = useCurrentPath();
  const openModal = () => modal.showModal();

  return (
    <>
      <LeftPanelWrapper onMouseEnter={setIsActive} onClick={setIsActive}>
        {currentPath.includes(Paths.GROUP) && <LeftPanelGroupHeader />}
        {currentGroupType !== GroupTypeEnum.DISCUSS && <LeftPanelTools />}
        <LeftPanelGroup
          groupType={GroupTypeEnum.PROJECT}
          description="Grupy projektowe"
        />
        <LeftPanelGroup
          groupType={GroupTypeEnum.DISCUSS}
          description="Grupy dyskusyjne"
        />
        <AddGroupButtonWrapper>
          <AddGroupButton onClick={openModal}>
            <Text size="14px" color={theme.color.basicBlack}>
              Utwórz grupę
            </Text>
            <Image src="/icons/plus.svg" alt="Plus" width={14} height={14} />
          </AddGroupButton>
        </AddGroupButtonWrapper>
        <ModalPortal {...modal.modalProps}>
          <GroupCreation closeModal={modal.modalProps.closeModal} />
        </ModalPortal>
      </LeftPanelWrapper>
    </>
  );
};

export default LeftPanel;
