import { theme } from "common/theme/theme";
import styled from "styled-components";

export const DropdownItemsWrapper = styled.div<{
  isActive?: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 42px;
  border-radius: 8px;
  font-size: 16px;
  list-style: none;
  padding: 12px;
  background-color: white;
  box-shadow: -2px 2px 20px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
  border-radius: 4px;
  border: 1px solid
    ${(p) => {
      if (p.isActive) return theme.color.formYellow;
      return theme.color.slightlyLightGray;
    }};
`;
