import React from "react";

import HeaderIcon from "common/components/HeaderIcon/HeaderIcon";
import InfoIcon from "common/icons/InfoIcon";
import MembersIcon from "common/icons/MembersIcon";
import { theme } from "common/theme/theme";

import { HeaderIconsWrapper, HeaderText } from "./GroupCreationHeader.styled";
interface GroupCreationHeaderProps {
  firstStage: boolean;
  goToFirstStage: () => void;
  goToSecondStage: () => void;
}
const GroupCreationHeader = ({
  firstStage,
  goToFirstStage,
  goToSecondStage,
}: GroupCreationHeaderProps) => {
  const firstStageLight = firstStage
    ? theme.color.basicWhite
    : theme.color.darkGray;
  const firstStageDark = firstStage
    ? theme.color.darkGray
    : theme.color.basicWhite;

  return (
    <>
      <HeaderText size="24px" color={theme.color.basicBlack}>
        Tworzenie grupy
      </HeaderText>
      <HeaderIconsWrapper>
        <HeaderIcon
          backgroundColor={firstStageDark}
          boldText={true}
          cursorPointer={true}
          testId="info-icon"
          text="Informacje"
          iconWidth="44px"
          iconHeight="44px"
          onClickHandler={goToFirstStage}
        >
          <InfoIcon width="20px" height="20px" color={firstStageLight} />
        </HeaderIcon>
        <HeaderIcon
          backgroundColor={firstStageLight}
          boldText={true}
          cursorPointer={true}
          text="Członkowie"
          iconWidth="44px"
          testId="members-icon"
          iconHeight="44px"
          onClickHandler={goToSecondStage}
          splitterText="opcjonalne"
        >
          <MembersIcon width="24px" height="12px" color={firstStageDark} />
        </HeaderIcon>
      </HeaderIconsWrapper>
    </>
  );
};

export default GroupCreationHeader;
