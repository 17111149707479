import React from "react";

import { InvitationModalHeaderText } from "./InvitationModalHeader.styled";

const InvitationModalHeader = () => {
  return (
    <InvitationModalHeaderText>Zaproś osoby do grupy</InvitationModalHeaderText>
  );
};

export default InvitationModalHeader;
