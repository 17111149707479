import styled from "styled-components/macro";

export const HintsListItem = styled.li<{ disabled: boolean }>`
  font-size: 14px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.color.slightlyLightGray : theme.color.darkGray};
  text-decoration: ${({ disabled }) => (disabled ? "line-through" : "none")}; ;
`;

export const HintsList = styled.ul`
  margin-top: 30px;
`;
