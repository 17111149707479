import React from "react";

import { HeaderSplitter, HeaderText } from "./GroupInformationHeader.styled";

const GroupInformationHeader = () => {
  return (
    <>
      <HeaderText>Opis</HeaderText>
      <HeaderSplitter />
    </>
  );
};

export default GroupInformationHeader;
