import React from "react";
import { useDropzone } from "react-dropzone";

import { ButtonsContainer } from "../AvatarChange/shared/style";
import { BackgroundPreview } from "../Background/BackgroundPreview";
import GhostButton from "../GhostButton/GhostButton";
import SmallButton from "../SmallButton/SmallButton";

import {
  BackgroundChangeContainer,
  BackgroundChangeDescription,
  BackgroundChangeSubtitle,
  BackgroundChangeTitle,
  BackgroundErrorContainer,
  BackgroundErrorIcon,
  BackgroundErrorMessage,
} from "./BackgroundChange.styled";

type BackgroundUploadProps = {
  backgroundImageDefault: string;
  backgroundImage: string;
  onImageUpload: (file: File[]) => void;
  uploadProgress: number;
  backgroundUploadedUrl: string;
  error: string;
  onCancelUpload: () => void;
  onSave: () => void;
  previewText: string;
};

export const BackgroundUpload = ({
  backgroundImageDefault,
  backgroundImage,
  onImageUpload,
  uploadProgress,
  error,
  backgroundUploadedUrl,
  onCancelUpload,
  onSave,
  previewText,
}: BackgroundUploadProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onImageUpload,
    noClick: true,
  });

  return (
    <BackgroundChangeContainer {...getRootProps()}>
      <BackgroundChangeTitle>Dodaj zdjęcie w tle</BackgroundChangeTitle>
      {backgroundUploadedUrl ? (
        <>
          <BackgroundChangeSubtitle ml="20px" mr="20px">
            {previewText}
          </BackgroundChangeSubtitle>
          <BackgroundPreview
            backgroundImage={backgroundUploadedUrl || backgroundImageDefault}
          />
          <ButtonsContainer>
            <GhostButton onClick={onCancelUpload}>Anuluj</GhostButton>
            <SmallButton onClick={onSave}>Zapisz</SmallButton>
          </ButtonsContainer>
        </>
      ) : (
        <>
          <BackgroundChangeSubtitle>
            Przeciągnij plik bezpośrednio na pole poniżej
          </BackgroundChangeSubtitle>
          <BackgroundPreview
            backgroundImage={backgroundImage || backgroundImageDefault}
            showAddImageIcon={true}
            uploadProgress={uploadProgress}
          />
          <input {...getInputProps()} />
          <BackgroundChangeDescription>lub</BackgroundChangeDescription>
          <input {...getInputProps()} />
          <ButtonsContainer>
            <SmallButton onClick={open}>Wybierz z komputera</SmallButton>
          </ButtonsContainer>
          {error ? (
            <BackgroundErrorContainer>
              <BackgroundErrorIcon src="/icons/errorField.svg" />
              <BackgroundErrorMessage>{error}</BackgroundErrorMessage>
            </BackgroundErrorContainer>
          ) : (
            <BackgroundChangeDescription>
              Rekomendowane wymiary to: 920px x 280px Maksymalny rozmiar zdjęcia
              to: 3MB
            </BackgroundChangeDescription>
          )}
        </>
      )}
    </BackgroundChangeContainer>
  );
};
