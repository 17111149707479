import styled from "styled-components";

export const SearchResultCardStyled = styled.div`
  display: flex;
  width: 684px;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: white;

  .content {
    a {
      display: flex;
      height: 25px;
      padding: 8px 20px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 40px;
      background: #1d1d1d;
      color: white;
      border: none;
      text-decoration: none;
    }

    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;

      .header {
        display: flex;
        align-items: center;
        gap: 1rem;
        h3 {
          display: flex;
          width: 368px;
          height: 40px;
          padding-left: 4px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
      }

      p {
        margin-top: 0;
        color: #131415;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        align-self: stretch;
      }
      a {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`;
