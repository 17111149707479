import { useMemo } from "react";

import { getUser } from "store/auth/authUtils";

export const useSub = () => {
  const user = getUser();

  return useMemo(() => {
    let sub: string | null = null;
    let error: Error | null = null;
    if (!user)
      return {
        sub,
        error,
      };
    user.getSession((_error, userSession) => {
      if (error) {
        error = _error;
      }
      if (!userSession)
        return {
          sub,
          error,
        };
      const { sub: _sub } = userSession.idToken.payload;
      sub = _sub;
    });

    return { sub, error };
  }, [user]);
};
