import React, { useEffect, useState } from "react";

function useIsVisible<T extends HTMLElement>(
  targetRef: React.MutableRefObject<T>
): { isVisible: boolean } {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerRefValue = targetRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);

          if (observerRefValue) {
            observer.unobserve(observerRefValue);
          }
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (observerRefValue) {
      observer.observe(observerRefValue);
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [targetRef]);

  return { isVisible };
}

export default useIsVisible;
