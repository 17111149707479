import React from "react";

import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { ModalProps } from "common/components/Modal/types";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";

import { useDeleteCategory } from "../Hooks/useDeleteCategory";
import { ModalButtonsWrapper, ModalContentWrapper } from "../Note/Note.styled";

interface DeleteCategoryModalProps {
  ownerId: string;
  itemId: string;
  modalProps: ModalProps;
  itemName: string;
  isSubCategory?: boolean; // Optional prop to distinguish between category and subcategory
}

export const DeleteCategoryModal = ({
  ownerId,
  itemId,
  modalProps,
  itemName,
  isSubCategory,
}: DeleteCategoryModalProps) => {
  const { performDeleteCategory } = useDeleteCategory(ownerId, itemId);

  const handleDelete = async () => {
    const result = await performDeleteCategory();
    if (result) {
      modalProps.closeModal();
      // You might also want to trigger a re-fetch or UI update here if necessary
    }
  };

  return (
    <ModalPortal {...modalProps}>
      <ModalContentWrapper
        width="369px"
        height="200px"
        alignItems="center"
        justifyContent="space-around"
      >
        <Text size="20px" weight="bold" lineHeight="20px">
          Usunąć {isSubCategory ? "podkategorię" : "kategorię"}? {itemName}
        </Text>
        <Text size="14px" weight="normal" lineHeight="20px">
          Usuwanie {isSubCategory ? "podkategorii" : "kategorii"} jest
          nieodwracalne,
          <br />
          ale wszystkie notatki zostaną zachowane.
          <br />
          Czy na pewno chcesz to zrobić?
        </Text>
        <ModalButtonsWrapper>
          <GhostButton onClick={modalProps.closeModal}>Anuluj</GhostButton>
          <SmallButton onClick={handleDelete}>Tak, usuń</SmallButton>
        </ModalButtonsWrapper>
      </ModalContentWrapper>
    </ModalPortal>
  );
};

export default DeleteCategoryModal;
