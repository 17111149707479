import Card from "common/components/Card/Card";
import styled from "styled-components/macro";

export const GroupInformationWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 832px;
`;
