import EditImageIconButton from "common/components/EditImageIconButton/EditImageIconButton";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const Container = styled.div`
  position: relative;
  text-align: center;
`;

export const Image = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: cover;
  border-radius: 50%;
`;

export const EditButton = styled(EditImageIconButton)`
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: ${theme.color.veryLightGray};
`;
