import {
  hasCapitalLetterRegex,
  hasLowerCaseLetterRegex,
  hasNumberRegex,
  specialCharRegex,
} from "common/constants/regex";
import * as yup from "yup";
const passwordError = "Wprowadzone hasło nie spełnia wszystkich wymagań.";

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Wymagane pole")
    .test("minLength", passwordError, (v) => v.length >= 8)
    .test("bigLetter", passwordError, (v) => hasCapitalLetterRegex.test(v))
    .test("smallLetter", passwordError, (v) => hasLowerCaseLetterRegex.test(v))
    .test("digit", passwordError, (v) => hasNumberRegex.test(v))
    .test("specialChar", passwordError, (v) => specialCharRegex.test(v)),
});
