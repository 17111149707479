import React from "react";

import Flex from "common/components/Flex";
import { Container } from "common/components/Layout/Containers";
import Text from "common/components/Text";
import SearchIcon from "common/icons/SearchIcon";
import { theme } from "common/theme/theme";
import { useAppSelector } from "store/hooks";

import {
  GroupCreationSearchBar,
  SearchInputSection,
} from "./GroupCreationSearch.styled";

interface GroupCreationSearchProps {
  setSearchValue: (searchVal: string) => void;
}

const GroupCreationSearch = ({ setSearchValue }: GroupCreationSearchProps) => {
  const chosenUsers = useAppSelector(
    (state) => state.groupCreation.chosenUsers
  );
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  return (
    <Flex
      width="512px"
      height="40px"
      mt="24px"
      justify="space-around"
      gap="48px"
    >
      <SearchInputSection>
        <GroupCreationSearchBar
          placeholder="Szukaj..."
          onChange={onChangeHandler}
        />
        <Container w="24px" h="24px" m="0 0 0 -47px">
          <SearchIcon
            width="24px"
            height="24px"
            color={theme.color.mediumGray}
          />
        </Container>
      </SearchInputSection>
      <Flex align="center" justify="center" width="100%" height="100%">
        <Text
          color={theme.color.mediumGray}
          size="14px"
          data-testid="user-counter"
        >
          wybrano ({chosenUsers.length})
        </Text>
      </Flex>
    </Flex>
  );
};

export default GroupCreationSearch;
