import styled from "styled-components/macro";

export const CheckboxContainer = styled.div<{
  height: number;
  width: number;
}>`
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<{
  height: number;
  width: number;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
`;
