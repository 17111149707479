import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import GlobalStyle from "common/theme/GlobalStyle";
import { theme } from "common/theme/theme";
import MainLayout from "components/Layout/MainLayout/MainLayout";
import { Toaster } from "sonner";
import { store } from "store/store";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";

import AppRouter from "./AppRouter";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Normalize />
        <GlobalStyle />
        <MainLayout>
          <AppRouter />
        </MainLayout>
      </ThemeProvider>
    </BrowserRouter>
    <Toaster position="top-center" />
  </Provider>
);
