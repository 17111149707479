import { theme } from "common/theme/theme";
import styled from "styled-components";

export const UsersScrollbarWrapper = styled.div`
  width: 403px;
  height: 411px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 259.08px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;

interface UserNamePopUpProps {
  top?: string;
  left?: string;
}

export const UserNamePopUp = styled.div<UserNamePopUpProps>`
  background: ${theme.color.basicWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;
  position: absolute;
  height: auto;
  left: ${({ left }) => "calc(50% - " + left + " / 2)" || "0px"};
  top: ${({ top }) => top || "0px"};
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  z-index: 999;
`;

export const DeleteButton = styled.a`
  position: absolute;
  display: grid;
  border-radius: 100%;
  place-items: center;
  left: 24px;
  top: -3px;
  z-index: 10;
  height: 24px;
  width: 24px;
  background-color: ${theme.color.veryLightGray};
  cursor: pointer;
`;
