import { RefObject, useCallback, useEffect } from "react";

export const useClickAway = <T>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void
) => {
  const listener = useCallback(
    (event: MouseEvent) => {
      if (
        !ref.current ||
        (ref.current as Node).contains(event.target as Node)
      ) {
        return;
      }
      handler(event);
    },
    [ref, handler]
  );

  useEffect(() => {
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [ref, handler, listener]);
};
