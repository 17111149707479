import React, { useEffect, useState } from "react";

// import DescriptionEditDropdown from "common/components/DescriptionEditDropdown/DescriptionEditDropdown";
import DescriptionEditModal from "common/components/DescriptionEditModal/DescriptionEditModal";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { Language } from "common/types/Language";
import * as userApi from "services/api/user/userApi";

import { useSub } from "../../../../common/hooks/useSub";

import { Container, Content } from "./UserDescription.styled";

const UserDescription = () => {
  const sub = useSub().sub;
  const lang = Language.Polish;
  // Get real user's language here when user sessions feature is ready
  const [currentContent, setCurrentContent] = useState("");
  const [newContent, setNewContent] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);

  function handleEditDropdownClick() {
    setNewContent(currentContent);
    setEditModalOpen(true);
  }

  function handleEditModalClose() {
    setTimeout(() => setNewContent(""), 1000);
    setEditModalOpen(false);
  }

  async function handleEditsSave() {
    try {
      await userApi.updateProfile(sub, lang, { userDescription: newContent });
    } catch (error) {
      // todo better error handling
      console.error(error);
    }
    setCurrentContent(newContent);
    setEditModalOpen(false);
  }

  useEffect(() => {
    async function fetchCurrentDescription() {
      try {
        const userProfile = await userApi.getProfile(sub, lang);
        setCurrentContent(userProfile.userDescription || "");
      } catch (error) {
        // todo better error handling
        console.error(error);
      }
    }

    fetchCurrentDescription();
  }, [sub, lang]);
  const dropdownMenuOptions = [
    {
      img: "/icons/pen.svg",
      text: "Edytuj opis",
      action: handleEditDropdownClick,
      testid: "edit-desc-btn",
    },
  ];
  return (
    <>
      <Container>
        <Content>{currentContent}</Content>
        <MakeActionDropdown dropdownMenuOptions={dropdownMenuOptions} />
      </Container>
      <DescriptionEditModal
        visible={editModalOpen}
        title="Edytuj swój krótki opis"
        content={newContent}
        onClose={handleEditModalClose}
        onSave={handleEditsSave}
        onContentChange={(e) => setNewContent(e.target.value)}
      />
    </>
  );
};

export default UserDescription;
