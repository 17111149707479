import React, { useEffect } from "react";

import Flex from "common/components/Flex";
import {
  getPossibleUsersToAdd,
  groupCreationAction,
} from "store/groupCreation/groupCreationSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import EmptyState from "./EmptyState/EmptyState";
import RightUsersTable from "./RightUsersTable/RightUsersTable";
import { UsersScrollbarWrapper } from "./UsersTable.styled";
import UsersTableItem from "./UsersTableItem/UsersTableItem";

interface UsersTableProps {
  searchValue: string;
}

const UsersTables = ({ searchValue }: UsersTableProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPossibleUsersToAdd());
  }, [dispatch]);

  const chosenUsers = useAppSelector(
    (state) => state.groupCreation.chosenUsers
  );
  const notAddedUsers = useAppSelector((state) =>
    state.groupCreation.availableUsers.filter(
      (user) =>
        !state.groupCreation.chosenUsers.some(
          (chosenUser) => chosenUser?.userData.sub === user?.userData.sub
        ) &&
        user.userData.fullName
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
    )
  );

  const chooseUserHandler = (userSub: string) => {
    const userAlreadyAdded = chosenUsers.some(
      (user) => user.userData.sub === userSub
    );
    if (userAlreadyAdded) {
      dispatch(groupCreationAction.deleteChosenUser(userSub));
    } else {
      dispatch(groupCreationAction.addChosenUser(userSub));
    }
  };

  return (
    <Flex width="514px" height="411px" mt="24px" overFlowX="none">
      <UsersScrollbarWrapper>
        <Flex width="371px" direction="column" gap="11px">
          {notAddedUsers.length !== 0 ? (
            <>
              {notAddedUsers.map((user, i) => (
                <UsersTableItem
                  key={i}
                  chooseHandler={chooseUserHandler}
                  fullName={user.userData?.fullName}
                  avatar={user.userData?.avatarUrl}
                  occupation={user.profileData?.companyName}
                  userSub={user.userData?.sub}
                  localization={user.userData?.city}
                />
              ))}
            </>
          ) : (
            <EmptyState enteredPhrase={searchValue} />
          )}
        </Flex>
      </UsersScrollbarWrapper>
      <RightUsersTable chosenUsers={chosenUsers} />
    </Flex>
  );
};

export default UsersTables;
