import * as yup from "yup";

import { postConfig } from "./postConfig";

export const postCreationSchema = yup.object().shape({
  textContent: yup
    .string()
    .max(postConfig.postLength)
    .min(postConfig.minLength, postConfig.minimumValidationText),
});
