import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";

import { NoteKitBorderRadiusWrapper } from "../shared/NoteKitStyles";

export default function BulletPointsItem() {
  const leftIcon = `/icons/noteKit/icon_list_bulleted.svg`;
  const centerIcon = `/icons/noteKit/icon_list_numered.svg`;
  return (
    <Flex justify="space-between">
      <Wrapper>
        <Text ml="10px" size="14px">
          Wypunktowanie
        </Text>
      </Wrapper>
      <Flex gap="4px">
        <NoteKitBorderRadiusWrapper>
          <Image src={leftIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
        <NoteKitBorderRadiusWrapper>
          <Image src={centerIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
      </Flex>
    </Flex>
  );
}
