import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { authAction } from "./authSlice";
import { getUser, userExists, userPool } from "./authUtils";

export const handleUserSession = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const userNotAuthenticated = () => {
      dispatch(authAction.setAuth(false));
      getUser()?.signOut();
    };

    const handleSession = () => {
      try {
        getUser().getSession((error: Error, session: CognitoUserSession) => {
          if (!error && session && session.isValid()) {
            dispatch(authAction.setAuth(true));
          } else {
            userNotAuthenticated();
          }
        });
      } catch (error) {
        userNotAuthenticated();
      }
    };
    handleSession();
  };
};

export const login = (email: string, password: string) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(authAction.setBeingLoggedIn(true));
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    return new Promise<void>(function (resolve, reject) {
      user.authenticateUser(authDetails, {
        onSuccess: () => {
          dispatch(authAction.setAuth(true));
          dispatch(authAction.setBeingLoggedIn(false));
          resolve();
        },
        onFailure: (error) => {
          dispatch(authAction.setAuth(false));
          dispatch(dispatch(authAction.setBeingLoggedIn(false)));
          if (error.message === "Incorrect username or password.") {
            dispatch(
              authAction.setLoginError(
                "Podany adres e-mail i/lub hasło są niepoprawne."
              )
            );
          } else if (error.message === "Password attempts exceeded") {
            dispatch(
              authAction.setLoginError(
                "Przekroczono limit prób logowania. Spróbuj ponownie później."
              )
            );
          } else reject(error);
        },
        // eslint-disable-next-line
        newPasswordRequired: (data) => console.log(data), // TODO: handle this if needed
      });
    });
  };
};

export const logout = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (userExists()) {
      getUser()?.signOut();
    }
    dispatch(handleUserSession());
  };
};
