import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getJwtToken } from "common/utils/auth/getToken";

import { UPLOAD_API_CONSTANTS } from "./uploadApi.constants";

interface IUploadFileData {
  bucket: string;
  folder: string;
  file: File;
}

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_NEURON_API_URL}/${UPLOAD_API_CONSTANTS.UPLOAD_PATH}`,
    prepareHeaders: async (headers) => {
      const token = await getJwtToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<any, { uploadFileData: IUploadFileData }>({
      query: ({ uploadFileData }) => {
        return {
          url: `/${uploadFileData.bucket}/${uploadFileData.folder}/${uploadFileData.file.name}`,
          method: "PUT",
          body: uploadFileData.file,
        };
      },
    }),
    getFile: builder.query<any, { fileUrl: string }>({
      query: ({ fileUrl }) => {
        return {
          url: fileUrl,
          responseHandler: async (response) =>
            URL.createObjectURL(await response.blob()),
        };
      },
    }),
  }),
});

export const { useUploadFileMutation, useGetFileQuery } = uploadApi;
