import React from "react";

import { SearchFiltersStyled } from "./SearchFilters.styled";
import { useSearch } from "./useSearch";

const filterButtons = [
  { type: "all", label: "Wszystkie", icon: "/images/infinity-icon.svg" },
  {
    type: "discuss",
    label: "Grupy dyskusyjne",
    icon: "/images/infinity-icon.svg",
  },
  {
    type: "projects",
    label: "Grupy projektowe",
    icon: "/images/infinity-icon.svg",
  },
  { type: "users", label: "Użytkownicy", icon: "/images/user-icon.svg" },
] as const;

export const SearchFilters = () => {
  const { type, setType } = useSearch();

  return (
    <SearchFiltersStyled>
      <h3>Filtry</h3>
      <div className="buttons">
        {filterButtons.map((button) => (
          <button
            key={button.type}
            className={type === button.type ? "active" : ""}
            onClick={() => setType(button.type)}
          >
            <img src={button.icon} alt={`${button.type}-icon`} />
            {button.label}
          </button>
        ))}
      </div>
    </SearchFiltersStyled>
  );
};
