import React from "react";

import GhostButton from "common/components/GhostButton/GhostButton";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { theme } from "common/theme/theme";
import { textTruncate } from "common/utils";

import { deleteNote } from "./index";
import {
  ActionWrapper,
  Category,
  ImageWrapper,
  ModalButtonsWrapper,
  ModalContentWrapper,
  NoteWrapper,
} from "./Note.styled";

interface NoteProps {
  header?: string;
  text?: string;
  date?: string;
  categoryName?: string;
  id?: string;
  editNote: () => void;
}

const defaultProps: NoteProps = {
  header: "Tytuł notatki może mieć 2 linijki mak...",
  text: "Rozbij jajka, rozmieszaj widelcem, dodając wod...",
  date: "02.10.2022",
  editNote() {},
};

const Note = (props) => {
  const sub = useSub();
  const userID = sub.sub;

  const delteNoteModal = useModal();
  const deleteNotetest = () => {
    props.deleteNoteFromState();
    delteNoteModal.modalProps.closeModal();
    deleteNote(userID, props.id);
  };

  const dropdownMenuOptions = [
    {
      img: "/icons/pen.svg",
      text: "Edytuj notatkę",
      action: () => props.editNote(props.id),
      testid: "edit-category-btn",
    },
    {
      img: "/icons/trash-can.svg",
      text: "Usuń notatkę",
      action: () => delteNoteModal.showModal(),
      testid: "delete-note-btn",
    },
    {
      img: "/icons/change.svg",
      text: props.categoryName ? "Zmień kategorię" : "Dodaj kategorię",
      action: () => null,
      testid: "edit-category-btn",
    },
  ];
  const date = new Date(props.date).toLocaleDateString("pl-PL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const ModalContent = () => {
    return (
      <ModalContentWrapper
        width="369px"
        height="200px"
        alignItems="center"
        justifyContent="space-around"
      >
        <Text size="20px" weight="bold" lineHeight="20px">
          Czy chcesz usunąć notatkę?
        </Text>

        <Text size="14px" weight="normal" lineHeight="20px">
          Usuwając notatkę stracisz ją bezpowrotnie. Czy na pewno chcesz to
          zrobić?
        </Text>
        <ModalButtonsWrapper>
          <GhostButton onClick={delteNoteModal.modalProps.closeModal}>
            Anuluj
          </GhostButton>
          <SmallButton onClick={deleteNotetest}>Tak, usuń</SmallButton>
        </ModalButtonsWrapper>
      </ModalContentWrapper>
    );
  };

  return (
    <NoteWrapper>
      <ModalPortal {...delteNoteModal.modalProps}>
        <ModalContent />
      </ModalPortal>
      <ActionWrapper>
        <Text size="12px" mb="12px" color={theme.color.basicBlack}>
          {date}
        </Text>
        <ImageWrapper style={{ margin: 0, alignSelf: "baseline" }}>
          <MakeActionDropdown
            dropdownMenuOptions={dropdownMenuOptions}
            top="30px"
          />
        </ImageWrapper>
      </ActionWrapper>
      {props.header ? (
        <Text
          size="16px"
          mb="12px"
          mr="26px"
          lineHeight="24px"
          color={theme.color.basicBlack}
          wordWrap="break-word"
          overflow="hidden"
          display="-webkit-box"
          boxOrient="vertical"
          lineClamp={2}
          letterSpacing="0.4px"
          weight="extraBold"
        >
          {textTruncate(props.header, 40)}
        </Text>
      ) : (
        <></>
      )}
      <Text
        size="14px"
        color={theme.color.basicBlack}
        lineHeight="20px"
        wordWrap="break-word"
        overflow="hidden"
        display="-webkit-box"
        boxOrient="vertical"
        lineClamp={2}
      >
        {textTruncate(props?.text, 50)}
      </Text>
      {props.categoryName ? <Category>{props.categoryName}</Category> : null}
    </NoteWrapper>
  );
};

Note.defaultProps = defaultProps;

export default Note;
