import React, {
  createRef,
  FunctionComponent,
  PropsWithChildren,
  useEffect,
} from "react";

import autoAnimate from "@formkit/auto-animate";
import { useDelayMount } from "common/hooks/useDelayMount";
import { useDelayUnmount } from "common/hooks/useDelayUnmount";
import { Portal } from "common/portal";

import { ModalContainer } from "./modal-container";
import { BackdropStyled } from "./modal.styles";
import { ModalProps } from "./types";

export interface ModalPortalProps extends PropsWithChildren, ModalProps {
  centerFixed?: boolean;
}

export const ModalPortal: FunctionComponent<ModalPortalProps> = ({
  children,
  centerFixed = false,
  isVisible,
  ...modalProps
}) => {
  const backdropRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (backdropRef.current) {
      autoAnimate(backdropRef.current);
    }
  }, [backdropRef]);

  const shouldRenderModal = useDelayMount(isVisible, 300);
  const shouldRenderBackdrop = useDelayUnmount(isVisible, 300);

  if (!shouldRenderBackdrop) {
    return null;
  }

  return (
    <Portal rootId="modal">
      <BackdropStyled
        centerFixed={centerFixed}
        ref={backdropRef}
        id="backdrop-styled"
        onClick={(e) => {
          e.target === e.currentTarget && modalProps.closeModal();
        }}
      >
        {shouldRenderModal && !modalProps.withoutWhiteBackground && (
          <ModalContainer centerFixed={centerFixed} {...modalProps}>
            {children}
          </ModalContainer>
        )}
        {shouldRenderModal && modalProps.withoutWhiteBackground && children}
      </BackdropStyled>
    </Portal>
  );
};
