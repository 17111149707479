import { useEffect, useState } from "react";

export const useDelayMount = (isMounted: boolean, delayTime: number) => {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    if (isMounted) {
      setTimeout(() => setShouldRender(true), delayTime);
    } else {
      setShouldRender(false);
    }
  }, [isMounted, delayTime]);
  return shouldRender;
};
