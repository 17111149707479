import { configureStore } from "@reduxjs/toolkit";
import { searchApi } from "services/api/search";
import categoryReducer from "store/categoriesSlice";
import groupReducer from "store/groupSlice";

import { authReducer } from "./auth/authSlice";
import { groupApi } from "./group/groupApi";
import { groupCreationReducer } from "./groupCreation/groupCreationSlice";
import { notesReducer } from "./notes/notesSlice";
import { taskerReducer } from "./tasker/taskerSlice";
import { uploadApi } from "./upload/uploadApi";
import { userApi } from "./user/userApi";

export const store = configureStore({
  reducer: {
    group: groupReducer,
    groupCreation: groupCreationReducer,
    category: categoryReducer,
    auth: authReducer,
    note: notesReducer,
    tasker: taskerReducer,
    [userApi.reducerPath]: userApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(groupApi.middleware)
      .concat(uploadApi.middleware)
      .concat(searchApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
