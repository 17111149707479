import styled from "styled-components/macro";

export const FormWrapper = styled.form`
  margin-top: 140px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
