import Image from "common/components/Image";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface MemberImageProps {
  zIndex: number;
}

export const MemberImage = styled(Image)<MemberImageProps>`
  border: 1px solid ${theme.color.basicWhite};
  box-sizing: border-box;
  background-color: ${theme.color.basicWhite};
  border-radius: 100%;
  z-index: ${({ zIndex }) => zIndex};
`;
