import { theme } from "common/theme/theme";
import styled from "styled-components";

export const Counter = styled.p`
  color: ${theme.color.mediumGray};
  margin-top: 3px;
  text-align: right;
`;

export const ShortDescriptionWrapper = styled.div``;
