import React, { FC, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Image from "common/components/Image";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import TabNavigation from "common/components/TabNavigation";
import { Paths } from "common/constants/paths";
import { theme } from "common/theme/theme";

import GroupCloseModal from "../GroupCloseModal/GroupCloseModal";
import GroupDescriptionEdit from "../GroupDescriptionEdit/GroupDescriptionEdit";
import GroupSettings from "../GroupSettings/GroupSettings";
import { IGroup } from "../Types/group";

import GroupAvatar from "./GroupAvatar/GroupAvatar";
import GroupButton from "./GroupButton/GroupButton";
import {
  GroupDescriptionWrapper,
  GroupHeadingWrapper,
  GroupMembersWrapper,
  GroupOptionsWrapper,
  GroupSearchWrapper,
  GroupTitleWrapper,
  GroupTypeWrapper,
  TabNavigationWrapper,
} from "./GroupHeading.styled";
import MembersBanner from "./MembersBanner/MembersBanner";

type GroupTabs = "info" | "discussion" | "members";

interface GroupHeadingProps {
  group: IGroup;
  currentLogo: string;
}

enum GroupTypeEnum {
  DISCUSS = "Grupa dyskusyjna",
  PROJECT = "Grupa projektowa",
}

const GroupHeading: FC<GroupHeadingProps> = ({ group, currentLogo }) => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditDescriptionMode, setEditDescriptionMode] = useState(false);
  const [isDeleteMode, setDeleteMode] = useState(false);
  const [isSettingsMode, setSettingsMode] = useState(false);
  const modal = useModal({ withCloseButton: true });
  const activeTab = searchParams.get("activeTab");

  const handleGroupTabClick = (e) => {
    const index = e.target.id;
    if (index !== activeTab) {
      navigate({ pathname: location.pathname, search: `activeTab=${index}` });
    }
  };

  const getActiveTabPath = (tab: GroupTabs) =>
    `${Paths.GROUP}/${id}?activeTab=${tab}`;

  const turnOnEditDescriptionMode = () => {
    setEditDescriptionMode(true);
    modal.showModal();
  };

  const turnOffEditDescriptionMode = () => {
    setEditDescriptionMode(false);
    modal.modalProps.closeModal();
  };

  const onClickDeleteHandler = () => {
    setDeleteMode(true);
    modal.showModal();
  };

  const onCloseDeleteModal = () => {
    setDeleteMode(false);
    modal.modalProps.closeModal();
  };

  const onSettingsEdit = () => {
    setSettingsMode(true);
    modal.showModal();
  };

  const turnOffSettingsMode = () => {
    setSettingsMode(false);
    modal.modalProps.closeModal();
  };

  const modalCloseHandler = () => {
    if (isEditDescriptionMode) turnOffEditDescriptionMode();
    if (isDeleteMode) onCloseDeleteModal();
    if (isSettingsMode) turnOffSettingsMode();
  };

  const dropdownMenuOptions = [
    {
      action: turnOnEditDescriptionMode,
      img: "/icons/pen.svg",
      text: "Edytuj opis",
      testid: "edit-popup-button",
    },
    {
      action: onSettingsEdit,
      img: "/icons/settings.svg",
      text: "Ustawienia grupy",
      testid: "group-settings-button",
    },
    {
      action: onClickDeleteHandler,
      img: "/icons/bin.svg",
      text: "Zamknij grupę",
      testid: "delete-popup-button",
    },
  ];

  return (
    <>
      <GroupHeadingWrapper>
        <GroupAvatar logo={currentLogo} />
        <GroupOptionsWrapper>
          <MakeActionDropdown dropdownMenuOptions={dropdownMenuOptions} />
        </GroupOptionsWrapper>
        <GroupMembersWrapper>
          <MembersBanner />
          <GroupButton />
        </GroupMembersWrapper>
        <GroupTitleWrapper>{group?.name}</GroupTitleWrapper>
        <GroupTypeWrapper>{GroupTypeEnum[group?.groupType]}</GroupTypeWrapper>
        <GroupDescriptionWrapper>{group?.description}</GroupDescriptionWrapper>
        <GroupSearchWrapper>
          <Image
            src="/icons/search_icon.svg"
            alt="Szukaj"
            width={24}
            height={24}
          />
        </GroupSearchWrapper>
        <TabNavigationWrapper>
          <TabNavigation
            id="info"
            path={getActiveTabPath("info")}
            isActive={activeTab === "info"}
            activeColor={theme.color.formYellow}
            paddingBottom={6}
            borderHeight={5}
            onClick={handleGroupTabClick}
          >
            Informacje
          </TabNavigation>
          <TabNavigation
            id="discussion"
            path={getActiveTabPath("discussion")}
            isActive={activeTab === "discussion"}
            activeColor={theme.color.formYellow}
            paddingBottom={6}
            borderHeight={5}
            onClick={handleGroupTabClick}
          >
            Dyskusja
          </TabNavigation>
          <TabNavigation
            id="members"
            path={getActiveTabPath("members")}
            isActive={activeTab === "members"}
            activeColor={theme.color.formYellow}
            paddingBottom={6}
            borderHeight={5}
            onClick={handleGroupTabClick}
          >
            Członkowie
          </TabNavigation>
        </TabNavigationWrapper>
      </GroupHeadingWrapper>
      <ModalPortal
        withCloseButton={true}
        isVisible={modal.modalProps.isVisible}
        closeModal={() => modalCloseHandler()}
      >
        {isDeleteMode && (
          <GroupCloseModal
            closeModal={onCloseDeleteModal}
            groupId={group?.groupId}
            groupName={group?.name}
            groupType={group?.groupType}
          />
        )}
        {isSettingsMode && (
          <GroupSettings
            closeModal={turnOffSettingsMode}
            groupAccess={group?.groupAccess}
            groupId={group?.groupId}
            groupStatus={group?.groupStatus}
            groupType={group?.groupType}
          />
        )}
        {isEditDescriptionMode && (
          <GroupDescriptionEdit
            closeModal={turnOffEditDescriptionMode}
            description={group?.description}
          />
        )}
      </ModalPortal>
    </>
  );
};
export default GroupHeading;
