import React from "react";

import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import styled from "styled-components/macro";

export const ModalDeleteConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 1rem;
  max-width: 20rem;
  text-align: center;
`;

export const ModalDeleteConfirmationTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const ModalDeleteConfirmationMain = styled.main`
  font-size: 0.95rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export const ModalDeleteConfirmationFooter = styled.footer`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
`;

export const ModalDeleteConfirmation = ({
  itemToDelete,
  onAccept,
  onReject,
}: {
  itemToDelete: string;
  onAccept: () => void;
  onReject: () => void;
}) => {
  return (
    <ModalDeleteConfirmationContainer>
      <ModalDeleteConfirmationTitle>
        Usunąć {itemToDelete}
      </ModalDeleteConfirmationTitle>
      <ModalDeleteConfirmationMain>
        Usuwając informacje związane z nauką w tym miejscu, stracisz je
        bezpowrotnie. <br /> Czy na pewno chcesz to zrobić?
      </ModalDeleteConfirmationMain>
      <ModalDeleteConfirmationFooter>
        <GhostButton
          width="max-content !important"
          padding="1rem"
          display="flex"
          margin="0 .5rem 0 0"
          onClick={onReject}
        >
          Anuluj
        </GhostButton>
        <SmallButton onClick={onAccept}>Tak, usuń</SmallButton>
      </ModalDeleteConfirmationFooter>
    </ModalDeleteConfirmationContainer>
  );
};
