import React from "react";

import GroupButton from "components/Group/Common/GroupButton/GroupButton";

import { GroupSettingsButtonsWrapper } from "./GroupSettingsButtons.styled";

interface GroupSettingsButtonProps {
  closeModal: () => void;
  onClickHandler: () => void;
}

const GroupSettingsButtons = ({
  closeModal,
  onClickHandler,
}: GroupSettingsButtonProps) => {
  return (
    <GroupSettingsButtonsWrapper>
      <GroupButton
        buttonLight={true}
        buttonText="Anluj"
        clickHandler={closeModal}
        testId="cancel-group-settings"
        width="143px"
        height="36px"
      />
      <GroupButton
        buttonLight={false}
        buttonText="Zapisz"
        testId="cancel-group-settings"
        width="143px"
        height="36px"
        clickHandler={onClickHandler}
      />
    </GroupSettingsButtonsWrapper>
  );
};

export default GroupSettingsButtons;
