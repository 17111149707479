import React from "react";
import { Outlet } from "react-router-dom";

import LeftPanel from "../LeftPanel/LeftPanel";
import MainContent from "../MainContent/MainContent";
import RightPanel from "../RightPanel/RightPanel";

import { PanelsContentWrapper } from "./PanelsLayout.styled";

const PanelsLayout = () => {
  return (
    <PanelsContentWrapper>
      <LeftPanel />
      <MainContent>
        <Outlet />
      </MainContent>
      <RightPanel />
    </PanelsContentWrapper>
  );
};

export default PanelsLayout;
