import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import DropdownMenuOption from "common/components/DropdownMenu/DropdownMenuOption";
import Flex from "common/components/Flex";
import Image from "common/components/Image";
import { AbsContainer } from "common/components/Layout/Containers";
import { theme } from "common/theme/theme";

const menuOpts = [
  {
    action: () => {},
    img: "/icons/edit_icon.svg",
    text: "Zmień nazwę",
    testid: "edit-popup-button",
  },
  {
    action: () => {},
    img: "/icons/edit_icon.svg",
    text: "Menu 2",
    testid: "delete-popup-button",
  },
  {
    action: () => {},
    img: "/icons/edit_icon.svg",
    text: "Menu 3",
    testid: "delete-popup-button",
  },
];

export default function TaskerMenu() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <Flex>
      <Image
        src={"/icons/options.svg"}
        cursor="pointer"
        alt="icon"
        width={14}
        height={14}
        onClick={() => setIsOpenMenu((prev) => !prev)}
      />
      {isOpenMenu && (
        <OutsideClickHandler
          onOutsideClick={() =>
            isOpenMenu ? setIsOpenMenu((prev) => !prev) : null
          }
        >
          <AbsContainer
            w="190px"
            top="32px"
            right="10px"
            bgColor={theme.color.basicWhite}
            h="auto"
            p="15px"
            boxShadow="-2px 2px 20px 0px rgba(0, 0, 0, 0.20)"
            bRadius="8px"
          >
            {menuOpts.map((option, index) => (
              <DropdownMenuOption
                key={index}
                img={option.img}
                text={option.text}
                action={option.action}
                testid={option.testid}
              />
            ))}
          </AbsContainer>
        </OutsideClickHandler>
      )}
    </Flex>
  );
}
