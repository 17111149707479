import React, { useMemo, useState } from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { Paths } from "common/constants";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { useCurrentPath } from "common/hooks/useCurrentPath";
import { theme } from "common/theme/theme";

import { CategoryWrapper, NameWrapper } from "../LeftPanel.styled";

import {
  ImageWrapper,
  LeftPanelElementWrapper,
  LinkWrapper,
  ToolsWrapper,
} from "./LeftPanelTools.styled";

type MenuItemsType = {
  icon: string;
  name: string;
  to: Paths;
};

const menuItems: MenuItemsType[] = [
  {
    icon: "/icons/calendar.svg",
    name: "Kalendarz",
    to: Paths.CALENDAR,
  },
  {
    icon: "/icons/notes.svg",
    name: "Notatnik",
    to: Paths.NOTES,
  },
  {
    icon: "/icons/task-board.svg",
    name: "Moje zadania",
    to: Paths.USER_TASK_BOARD,
  },
  {
    icon: "/icons/task-board.svg",
    name: "Zadania grupy",
    to: Paths.GROUP_TASK_BOARD,
  },
];

const useDisplayingItems = () => {
  const { currentPath } = useCurrentPath();
  return useMemo(() => {
    switch (currentPath) {
      case Paths.USER_TASK_BOARD:
        return [menuItems[0], menuItems[2]];
      case Paths.GROUP_TASK_BOARD:
        return [menuItems[0], menuItems[3]];
      case Paths.GROUP:
        return [menuItems[0], menuItems[3]];
      case Paths.DASHBOARD:
        return [menuItems[0], menuItems[1], menuItems[2]];
      default:
        return [menuItems[0], menuItems[1], menuItems[2]];
    }
  }, [currentPath]);
};

const ToolItem = ({ icon, name, to }: MenuItemsType) => {
  const { currentPath } = useCurrentPath();
  const { groupId } = useCurrentGroupId();

  const isActive = currentPath.toLowerCase() === to.toLowerCase();

  return (
    <LinkWrapper to={to === Paths.GROUP_TASK_BOARD ? `${to}/${groupId}` : to}>
      <ToolsWrapper
        color={isActive ? theme.color.veryLightGray : theme.color.basicWhite}
      >
        <ImageWrapper>
          <Image src={icon} alt={name} width={18} height={20} />
        </ImageWrapper>
        <NameWrapper>
          <Text
            color={theme.color.basicBlack}
            weight={isActive ? "bold" : undefined}
          >
            {name}
          </Text>
        </NameWrapper>
      </ToolsWrapper>
    </LinkWrapper>
  );
};

const LeftPanelTools = () => {
  const { currentPath } = useCurrentPath();

  const items = useDisplayingItems();

  const [expanded, setExpanded] = useState(() => {
    const isChildActive = items
      .map((item) => item.to)
      .some((item) => currentPath.includes(item));

    return isChildActive;
  });

  return (
    <LeftPanelElementWrapper>
      <>
        <CategoryWrapper onClick={() => setExpanded((c) => !c)}>
          <Text color={theme.color.basicBlack}>Narzędzia</Text>
          <Image
            src={`/icons/${expanded ? "up" : "down"}.svg`}
            alt={`strzałka w ${expanded ? "górę" : "dół"}`}
            width={12}
            height={7}
          />
        </CategoryWrapper>
        {expanded &&
          items.map((item) => <ToolItem {...item} key={item.name} />)}
      </>
    </LeftPanelElementWrapper>
  );
};

export default LeftPanelTools;
