import React, { Dispatch, SetStateAction, useMemo } from "react";

import { IPost } from "store/group/groupApi.types";

import CommentListItem from "./CommentListItem/CommentListItem";
import { CommentListWrapper } from "./CommentsList.styled";

interface CommentListProps {
  commentList: IPost[];
  addPage: () => void;
  refetchComments?: () => void;
  setCommentWasCreated?: Dispatch<SetStateAction<boolean>>;
}

const CommentList = ({
  commentList,
  addPage,
  refetchComments,
  setCommentWasCreated,
}: CommentListProps) => {
  const commentItems = useMemo(() => {
    if (!commentList || commentList.length === 0) {
      return [];
    }

    return commentList.map((comment, index) => ({
      content: <CommentListItem.Content content={comment.content} />,
      footer: (
        <CommentListItem.Footer
          currentCommentId={comment.postId}
          refetchComments={refetchComments}
          setCommentWasCreated={setCommentWasCreated}
        />
      ),
      userIcon: <CommentListItem.UserIcon />,
      key: comment.postId,
      isLastComment: index === commentList.length - 1,
      addPage: addPage,
      currentCommentId: comment.postId,
      isComment: true,
    }));
  }, [commentList, addPage, refetchComments, setCommentWasCreated]);
  return (
    <CommentListWrapper>
      {commentItems.map((item) => (
        <CommentListItem key={item.key} {...item} />
      ))}
    </CommentListWrapper>
  );
};

export default CommentList;
