import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PostPublicationSettingsModalWrapper = styled.div`
  height: 241px;
  width: 398px;
  display: grid;
  grid-template-rows: 1fr 59px;
  padding-top: 33px;
`;

export const PublicationRadioButtons = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 30px;
  margin-top: 32px;
`;

export const PublicationButtons = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${theme.color.mediumGray};
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 24px;
  padding-right: 25px;
`;

export const PublicationSettingsTopSection = styled.div`
  padding-left: 21px;
`;

export const PublicationSettingsBottomSection = styled.div``;

export const PublicationTextWrapper = styled.div`
  height: 32px;
  display: grid;
  align-items: center;
`;
