import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { AllCategoryChip } from "./NotesRightPanel.styled";

interface NotesRightPanelChipProps {
  isSelected: boolean;
  selectCategory: () => void;
}

const NotesRightPanelChip = ({
  isSelected,
  selectCategory,
}: NotesRightPanelChipProps) => {
  return (
    <AllCategoryChip
      tabIndex={0}
      isSelected={isSelected}
      onClick={selectCategory}
    >
      <Image src="/icons/infinity.svg" alt="infinity" width={24} height={11} />
      <Text
        size="14px"
        color={theme.color.basicBlack}
        weight={isSelected ? "bold" : "normal"}
        ml="12px"
        lineHeight="20px"
        letterSpacing="0.4px"
      >
        Wszystkie
      </Text>
    </AllCategoryChip>
  );
};

export default NotesRightPanelChip;
