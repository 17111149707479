import React from "react";

import { Container } from "common/components/Layout/Containers";
import SearchIcon from "common/icons/SearchIcon";
import { theme } from "common/theme/theme";

import {
  CounterText,
  SearchBar,
  SearchBarWrapper,
  SearchInputSection,
} from "./InvitationSearch.styled";

interface GroupCreationSearchProps {
  chosenUsersCount: number;
  setSearchValue: (searchVal: string) => void;
}

const GroupInvitationSearch = ({
  chosenUsersCount,
  setSearchValue,
}: GroupCreationSearchProps) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  return (
    <SearchBarWrapper>
      <SearchInputSection>
        <SearchBar placeholder="Szukaj..." onChange={onChangeHandler} />
        <Container w="24px" h="24px" m="0 0 0 -47px">
          <SearchIcon
            width="24px"
            height="24px"
            color={theme.color.mediumGray}
          />
        </Container>
      </SearchInputSection>
      <CounterText
        color={theme.color.mediumGray}
        size="14px"
        data-testid="user-counter"
      >
        wybrano ({chosenUsersCount})
      </CounterText>
    </SearchBarWrapper>
  );
};

export default GroupInvitationSearch;
