import React from "react";

import Image from "common/components/Image";

import {
  CongratulationButton,
  CongratulationHeader,
  CongratulationParagraph,
  GroupCreationFinalStageWrapper,
} from "./GroupCreationFinalStage.styled";

interface GroupCreationFinalStageProps {
  closeModal: () => void;
}

const GroupCreationFinalStage = ({
  closeModal,
}: GroupCreationFinalStageProps) => {
  return (
    <GroupCreationFinalStageWrapper data-testid="congratulations-wrapper">
      <CongratulationHeader weight="bold" size="32px">
        Gratulacje!
      </CongratulationHeader>
      <CongratulationParagraph>
        Udało Ci się utworzyć grupę!
      </CongratulationParagraph>
      <Image src="/images/congratulations.png" alt="success" />
      <CongratulationButton
        onClick={closeModal}
        data-testid="proceed-to-platform-btn"
      >
        Przejdź do platformy
      </CongratulationButton>
    </GroupCreationFinalStageWrapper>
  );
};

export default GroupCreationFinalStage;
