import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import styled from "styled-components/macro";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export const TextItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px;
`;

export default function TextSizeItem() {
  const iconSrc = `/icons/down.svg`;

  return (
    <Flex align="center" justify="space-between">
      <Text ml="10px" size="14px">
        Rozmiar tekstu
      </Text>
      <NoteKitBorderWrapper p="7px 11px">
        <Text size="14px">12</Text>
        <Text ml="10px">
          <Image src={iconSrc} alt="icon" width={13} height={13} top="2px" />
        </Text>
      </NoteKitBorderWrapper>
    </Flex>
  );
}
