import styled from "styled-components/macro";

export type FlexJustifyType =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "initial"
  | "inherit";

interface FlexProps {
  direction?:
    | "row"
    | "row-reverse"
    | "column"
    | "column-reverse"
    | "initial"
    | "inherit";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  align?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "inherit";
  justify?: FlexJustifyType;
  gap?: string;
  mt?: string;
  mb?: string;
  p?: string;
  width?: string;
  height?: string;
  overFlowX?: string;
  position?: string;
  cursor?: string;
}

const defaultProps: FlexProps = {
  direction: "row",
  wrap: "nowrap",
  align: "stretch",
  justify: "flex-start",
  gap: "0",
  mt: "0",
  mb: "0",
  p: "0",
  cursor: "default",
};

const Flex = styled.div<FlexProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  gap: ${(props) => props.gap};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  padding: ${(props) => props.p};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow-x: ${(props) => props.overFlowX};
  position: ${(props) => props.position};
  cursor: ${(props) => props.cursor};
`;

Flex.defaultProps = defaultProps;

export default Flex;
