import styled from "styled-components/macro";

export const IconButtonWrapper = styled.a<{
  width: number;
  height: number;
  radius?: string;
  backgroundColor: string;
  marginLeft?: string;
  marginRight?: string;
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.radius ?? "0%"};
  border-color: transparent;
  background-color: ${(props) => props.backgroundColor ?? "transparent"};
  overflow: hidden;
  cursor: pointer;
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
`;
