import Flex from "common/components/Flex";
import { CategoryWrapper } from "common/components/TagsAndLabels/TaskCategory/TaskCategory.styled";
import styled from "styled-components";

export const FlexButtonCreateCategory = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  list-style: none;
  background-color: white;
  z-index: 3;
  padding-left: 15px;
  border: 0px solid transparent !important;
  &:hover {
    background-color: #eceeef;
  }
`;

export const CustomCategory = styled(CategoryWrapper)`
  margin-bottom: 0px;
`;

export const InputWrapper = styled.div`
  position: relative;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #b0b2b2;
  width: 100%;
`;
