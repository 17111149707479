import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const MainWrapper = styled(Wrapper)`
  flex-direction: column;
`;

export const ImageWrapper = styled(Wrapper)`
  flex-direction: column;
  left: 318px;
  top: 111px;
`;

export const Header = styled.span`
  margin-top: 18px;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.huge};
`;

export const Title = styled.span`
  font-weight: ${theme.fontWeight.semibold};
  font-size: ${theme.fontSize.big};
`;

export const Description = styled.p`
  margin-top: 31px;
  margin-bottom: 47px;
  font-size: ${theme.fontSize.small};
  align-items: flex-end;
  line-height: 150%;
  max-width: 440px;
`;
export const Button = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 212px;
  height: 56px;
  background: ${theme.color.basicBlack};
  border-radius: 32px;
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.base}; //oryginalnie 24px
  color: ${theme.color.basicWhite};
`;
