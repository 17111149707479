import React from "react";

import Button from "common/components/Button";
import Image from "common/components/Image";
import Text from "common/components/Text";

import StyledLogoutModal, { Loader } from "./LogoutModal.styled";

const LogoutModal = ({ closeModal, logout, isBeingLoggedOut }) => {
  return (
    <StyledLogoutModal>
      <Image src="icons/logo.svg" width={29} height={39} />
      <div>
        <Text align="center" weight="bold" mb="14px">
          {!isBeingLoggedOut ? "Wylogować z Acorn?" : "Trwa wylogowywanie"}
        </Text>
        <Text align="center" size="14px">
          {!isBeingLoggedOut
            ? " W każdym momencie możesz zalogować się ponownie."
            : " "}
        </Text>
      </div>

      <div>
        {!isBeingLoggedOut ? (
          <>
            <Button
              onClick={logout}
              variant="primary"
              fontSize="16px"
              radius="40px"
              width="220px"
              height="36px"
            >
              Wyloguj
            </Button>
            <Button
              onClick={closeModal}
              variant="outlined"
              border="none"
              fontSize="14px"
              margin="4px auto 0"
              height="42px"
            >
              Anuluj
            </Button>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </StyledLogoutModal>
  );
};

export default LogoutModal;
