import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PostModalTextAreaWrapper = styled.div`
  display: grid;
  gap: 14px;
  margin-top: 18px;
  margin-bottom: 14px;
  margin-right: 7px;
`;

export const PostModalTextAreaField = styled.div`
  width: 100%;
  height: 100%;
  min-height: 120px;
  border: none;
  font-size: 14px;
  :focus {
    outline: none;
  }
  :empty:not(:focus):before {
    content: attr(data-placeholder);
    color: ${theme.color.mediumGray};
  }
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ValidationMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PostModalTextAreaFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 423px;
  margin-right: 13px;
  gap: 21px;
`;

export const PostModalFilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
`;

export const PostModalTextAreaTopWrapper = styled.div`
  max-height: 413px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 402.44px;
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 259.08px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;
