import React from "react";

import GroupButton from "../../GroupButton/GroupButton";

import { GroupInvitationButtonsWrapper } from "./GroupInvitationButtons.styled";

interface GroupInvitationButtonsProps {
  cancelButtonText: string;
  cancelHandler: () => void;
  proceedHandler: () => void;
  proceedButtonText: string;
}

const GroupInvitationButtons = ({
  cancelButtonText,
  cancelHandler,
  proceedHandler,
  proceedButtonText,
}: GroupInvitationButtonsProps) => {
  return (
    <GroupInvitationButtonsWrapper>
      <GroupButton
        buttonText={cancelButtonText}
        buttonLight={true}
        height="36px"
        clickHandler={cancelHandler}
        width="160px"
        testId="cancel-invitation-button"
      />
      <GroupButton
        buttonText={proceedButtonText}
        buttonLight={false}
        clickHandler={proceedHandler}
        width="160px"
        height="36px"
        testId="proceed-invitation-button"
      />
    </GroupInvitationButtonsWrapper>
  );
};

export default GroupInvitationButtons;
