import { apiRequest } from "../config/axiosConfig";

import { ITaskResponse } from "./tasker.responses";

export async function getAllTasksByGroupRq(
  groupId: string
): Promise<ITaskResponse[]> {
  try {
    const response = await apiRequest<ITaskResponse[], string>(
      "GET",
      "TASK",
      `?groupId=${groupId}`
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `getAllTasksByGroup returned with status ${err.response.status}`
      );
    }
  }
}
