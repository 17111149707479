import Flex from "common/components/Flex";
import styled from "styled-components";

export const NameListFlex = styled(Flex)`
  @media only screen and (min-width: 1261px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 1260px) {
    flex-direction: column;
    margin-bottom: 15px;
  }
`;

export const RealisationReminderFlex = styled(Flex)`
  @media only screen and (min-width: 1201px) {
    flex-direction: row;
    gap: 48px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 10px;
  }
`;
