import React, { useCallback, useEffect, useState } from "react";

import { useGetCommentsQuery } from "store/group/groupApi";
import { IPost } from "store/group/groupApi.types";
import { useAppSelector } from "store/hooks";

import CommentCreation from "./CommentCreation/CommentCreation";
import CommentList from "./CommentList/CommentList";
import { PostCommentWrapper } from "./PostComment.styled";

interface PostCommentProps {
  currentPostId: string;
}

const PostComment = ({ currentPostId }: PostCommentProps) => {
  const [commentList, setCommentList] = useState<IPost[]>();
  const [page, setPage] = useState(0);
  const [commentWasCreated, setValue] = useState(false);
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const { data: comments } = useGetCommentsQuery(
    {
      groupId: currentGroup?.groupId,
      page: page,
      rows: 4,
      parentId: currentPostId,
    },
    {
      skip: currentGroup?.groupId === undefined,
    }
  );

  const {
    refetch: refetchAfterCreation,
    data: commentAfterCreation,
    isSuccess: commentWasSuccessfullyCreated,
  } = useGetCommentsQuery(
    {
      groupId: currentGroup?.groupId,
      page: 0,
      rows: 1,
      parentId: currentPostId,
    },
    { skip: !commentWasCreated }
  );

  const setCommentWasCreated = (value: boolean) => {
    setValue(value);
  };

  const handlePageAddition = useCallback(() => {
    setPage((page) => ++page);
  }, []);

  function mergeComments(prev: IPost[] | undefined, next: IPost[]): IPost[] {
    const commentMap = new Map<string, IPost>();

    if (prev !== undefined) {
      prev.forEach((comment) => {
        commentMap.set(comment.postId, comment);
      });
    }

    next.forEach((comment) => {
      commentMap.set(comment.postId, comment);
    });

    return Array.from(commentMap.values());
  }

  useEffect(() => {
    setCommentList((prevComments) => {
      if (prevComments === undefined) {
        return comments;
      }
      if (page === 0) return prevComments;
      return mergeComments(prevComments, comments || []);
    });
  }, [comments, page]);

  useEffect(() => {
    setCommentList((prevComments) => {
      if (commentWasSuccessfullyCreated) {
        return mergeComments(prevComments, commentAfterCreation || []);
      }
      return prevComments;
    });
  }, [commentAfterCreation, commentWasSuccessfullyCreated]);
  return (
    <PostCommentWrapper>
      <CommentCreation
        currentPostId={currentPostId}
        setCommentWasCreated={setCommentWasCreated}
        refetchComments={refetchAfterCreation}
      />
      <CommentList commentList={commentList} addPage={handlePageAddition} />
    </PostCommentWrapper>
  );
};

export default PostComment;
