import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import styled from "styled-components/macro";

type DropdownMenuOptionProps = {
  testid: string;
  img: string;
  text: string;
  action: () => void;
};

const DropdownMenuOptionContainer = styled.div`
  max-height: 40px;
  width: 75%;
  padding: 12px 24px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0 4px 0px;
  cursor: pointer;
  border-radius: 8px;

  img {
    flex: 0.2;
    height: auto;
    max-width: 18px;
    border-radius: 0px;
  }
  p {
    flex: 0.8;
  }
  &:hover {
    background: #f2f2f2;
  }
`;

const DropdownMenuOption = ({
  img,
  text,
  action,
  testid,
}: DropdownMenuOptionProps) => {
  return (
    <DropdownMenuOptionContainer onClick={action} data-testid={testid}>
      <Image src={img} alt={"action icon"} />
      <Text size="14px" align="left">
        {text}
      </Text>
    </DropdownMenuOptionContainer>
  );
};

export default DropdownMenuOption;
