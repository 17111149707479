import React from "react";

import Flex from "common/components/Flex";
import { PlusSvgSign } from "common/components/SvgIcons/PlusSvgSign";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { ButtonStyled } from "./AddNewList";

type Props = {
  handleOpenNewTask: () => void;
};

export default function AddNewTaskButton({ handleOpenNewTask }: Props) {
  return (
    <Flex width="100%" justify="center" mt="20px" mb="5px">
      <Flex direction="row" justify="space-between">
        <ButtonStyled onClick={handleOpenNewTask}>
          <PlusSvgSign color="white" />
          <Text
            ml="7px"
            size="14px"
            weight="semibold"
            color={theme.color.basicBlack}
          >
            Utwórz zadanie
          </Text>
        </ButtonStyled>
      </Flex>
    </Flex>
  );
}
