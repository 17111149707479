import React from "react";

import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";

import { PostReactionCounterWrapper } from "./PostReactionsCounter.styled";

interface PostReactionCounterProps {
  reactionCounterText: string;
}

const PostReactionCounter = ({
  reactionCounterText,
}: PostReactionCounterProps) => {
  return (
    <PostReactionCounterWrapper>
      <Text size="12px" color={theme.color.mediumGray}>
        {reactionCounterText}
      </Text>
    </PostReactionCounterWrapper>
  );
};

export default PostReactionCounter;
