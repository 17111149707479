import React, { useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import ModalCloseIcon from "common/icons/ModalCloseIcon";
import { DecodedIdToken } from "common/types/IToken";
import { getJwtToken } from "common/utils/auth/getToken";
import jwtDecode from "jwt-decode";
import { ICreateGroupRequestDtoMessage } from "services/api/group/createGroup";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { groupCreate } from "store/groupCreation/createAction";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as yup from "yup";

import { formConfig } from "./formConfig";
import { CloseIconWrapper, GroupCreationForm } from "./GroupCreation.styled";
import GroupCreationButtons from "./GroupCreationButtons/GroupCreationButtons";
import GroupCreationFinalStage from "./GroupCreationFinalStage/GroupCreationFinalStage";
import { GroupCreationFirstStage } from "./GroupCreationFirstStage/GroupCreationFirstStage";
import GroupCreationHeader from "./GroupCreationHeader/GroupCreationHeader";
import GroupCreationSecondStage from "./GroupCreationSecondStage/GroupCreationSecondStage";
import { IGroupCreationFields } from "./types";

interface GroupCreationProps {
  closeModal: () => void;
}

const GroupCreationSchema = (existingGroupNames: string[]) =>
  yup.object().shape({
    groupName: yup
      .string()
      .required("Nazwa grupy jest wymagana")
      .min(
        formConfig.groupNameMinLength,
        `Wpisana nazwa jest za krótka, musi zawierać minimum ${formConfig.groupNameMinLength} znaki.`
      )
      .max(
        formConfig.groupNameMaxLength,
        `Wpisana nazwa jest za długa, musi zawierać maksymalnie ${formConfig.groupNameMaxLength} znaki.`
      )
      .matches(
        /^[a-zA-Z0-9-żźćńółęąśŻŹĆĄŚĘŁÓŃ ]*$/,
        formConfig.validation.onlyLetters
      )
      .matches(/^(?!.*\s\s)/, formConfig.validation.onlyLetters)
      .test(
        "spaceAtTheBeginning",
        formConfig.validation.onlyLetters,
        (value) => value[0] !== " "
      )
      .test(
        "groupExists",
        "Grupa o podanej nazwie już istnieje",
        (value) => !existingGroupNames.includes(value)
      ),
    shortDescription: yup
      .string()
      .max(formConfig.shortDescriptionMaxLength, `Wpisany opis jest za długi.`),
  });

const GroupCreation = ({ closeModal }: GroupCreationProps) => {
  const dispatch = useAppDispatch();
  const [firstStage, setFirstStage] = useState(true);
  const [finalStage, setFinalStage] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const existingGroupNames = useAppSelector((state) => state.group).groups.map(
    (g) => g.name
  );
  const chosenUsers = useAppSelector(
    (state) => state.groupCreation.chosenUsers
  );

  const { control, register, setValue, watch, formState, handleSubmit } =
    useForm<IGroupCreationFields>({
      resolver: yupResolver(GroupCreationSchema(existingGroupNames)),
      mode: "all",
      defaultValues: {
        groupName: "",
        shortDescription: "",
        privacy: "PUBLIC",
        groupType: "DISCUSS",
      },
    });

  const onSubmit: SubmitHandler<IGroupCreationFields> = async (data) => {
    const decodedToken = jwtDecode(await getJwtToken()) as DecodedIdToken;
    const userSub = decodedToken["cognito:username"];
    const groupData: GroupApiRequest<ICreateGroupRequestDtoMessage> = {
      body: {
        header: createGroupRequestHeader(userSub),
        message: {
          created_by: userSub,
          created: new Date().toISOString(),
          group_status: "ACTIVE",
          group_type: data.groupType,
          group_access: data.privacy,
          group_owner: {
            group_owner_id: userSub,
            group_owner_type: "USER",
          },
          name: data.groupName,
          description: data.shortDescription,
          tags: ["test"],
        },
      },
    };
    dispatch(groupCreate(groupData, chosenUsers));
  };

  const goToFirstStage = () => {
    setFirstStage(true);
  };

  const goToSecondStage = () => {
    setFirstStage(false);
  };

  const setSearchValueHandler = (searchVal: string) => {
    setSearchValue(searchVal);
  };

  const goToFinalStage = () => {
    // TODO, fill with more data
    handleSubmit(onSubmit)();
    setFinalStage(true);
  };

  const formData = useWatch({ control });

  const canProceed = useMemo(
    () =>
      formData.groupName.length !== 0 &&
      !formState.errors.groupName &&
      !formState.errors.shortDescription,
    [
      formData.groupName.length,
      formState.errors.groupName,
      formState.errors.shortDescription,
    ]
  );

  return (
    <>
      {!finalStage ? (
        <GroupCreationForm>
          <CloseIconWrapper onClick={closeModal}>
            <ModalCloseIcon width="14" height="14" />
          </CloseIconWrapper>
          <GroupCreationHeader
            firstStage={firstStage}
            goToFirstStage={goToFirstStage}
            goToSecondStage={goToSecondStage}
          />
          {firstStage ? (
            <GroupCreationFirstStage
              register={register}
              setValue={setValue}
              formData={formData}
              watch={watch}
              formState={formState}
            />
          ) : (
            <GroupCreationSecondStage
              searchValue={searchValue}
              setSearchValue={setSearchValueHandler}
            />
          )}
          <GroupCreationButtons
            closeModal={closeModal}
            firstStage={firstStage}
            goToFirstStage={goToFirstStage}
            goToSecondStage={goToSecondStage}
            goToFinalStage={goToFinalStage}
            isProceedButtonDisabled={!canProceed}
          />
        </GroupCreationForm>
      ) : (
        <GroupCreationFinalStage closeModal={closeModal} />
      )}
    </>
  );
};

export default GroupCreation;
