import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { Input, PlaceholderLabel } from "common/components/InputField";
import InputMessage from "common/components/InputMessage";
import styled from "styled-components/macro";

import Flex from "../Flex";

const TextAreaInput = styled(Input)`
  resize: none;
  overflow: hidden;
  cursor: auto;
`;

const Message = styled(InputMessage)`
  position: absolute;
  bottom: -20px;
`;

export interface TextAreaProps {
  id: string;
  placeholder?: string;
  label: string;
  value: string;
  isValid?: boolean;
  touched?: boolean;
  validationText?: string;
  className?: string;
  width?: string;
  mt?: string;
  mb?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
}

const TextArea = (props: TextAreaProps) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const isValid = props.touched ? props.isValid : null;

  function onClick(): void {
    if (clicked === false) setClicked(true);
  }

  useEffect(() => {
    // auto resize the text area
    const minHeight = 20; // px
    const maxHeight = 200; // px
    textAreaRef.current.style.height = minHeight + "px"; // ensures shrink on deletes
    textAreaRef.current.style.height =
      Math.min(textAreaRef.current.scrollHeight - 28, maxHeight) + "px";
  }, [props.value]);

  return (
    <Flex
      position="relative"
      direction="column"
      mt={props.mt}
      mb={props.mb}
      className={props.className}
    >
      <TextAreaInput
        as="textarea"
        ref={textAreaRef}
        onClick={onClick}
        isValid={isValid}
        id={props.id}
        value={props.value}
        width={props.width}
        onChange={props.onChange}
        onBlur={props.onBlur}
        clicked={clicked}
        placeholder={clicked ? props.placeholder : props.label}
      />
      <PlaceholderLabel
        touched={props.touched}
        isValid={isValid}
        htmlFor={props.id}
      >
        {props.label}
      </PlaceholderLabel>
      {props.validationText && (
        <Message variant={isValid === false ? "error" : "info"}>
          {props.validationText}
        </Message>
      )}
    </Flex>
  );
};

TextArea.defaultProps = { mb: "36px" };

export default TextArea;
