import React, { FC, useMemo, useState } from "react";
import { FixedSizeList as List } from "react-window";

import Image from "common/components/Image";
import Spinner from "common/components/Spinner";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { GroupTypeEnum } from "store/groupSlice";
import { useAppSelector } from "store/hooks";

import { CategoryWrapper } from "../LeftPanel.styled";

import {
  ShowMoreWrapper,
  SpinnerWrapper,
  WrapperUnderlined,
} from "./LeftPanelGroup.styled";
import LeftPanelGroupItem from "./LeftPanelGroupItem/LeftPanelGroupItem";

interface GroupCategoryProps {
  groupType: GroupTypeEnum;
  description: string;
}

const LeftPanelGroup: FC<GroupCategoryProps> = ({ groupType, description }) => {
  const [showItems, setShowItems] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const groups = useAppSelector((state) =>
    state.group.groups.filter((group) => group.type === groupType)
  );

  const isLoading = useAppSelector((state) => state.group.isLoading);

  const toggleCategory = () => {
    setShowItems((current) => {
      const show = !current;
      if (!show) {
        setShowAll(false);
      }
      return show;
    });
  };
  const toggleShowMore = () => setShowAll((current) => !current);

  const displayingGroup = useMemo(() => {
    return groups.slice(0, showAll ? groups.length : 3);
  }, [groups, showAll]);

  return (
    <WrapperUnderlined>
      <>
        <CategoryWrapper onClick={toggleCategory}>
          <Text color={theme.color.basicBlack}>{description}</Text>
          <Image
            src={`/icons/${showItems ? "up" : "down"}.svg`}
            alt={`strzałka w ${showItems ? "górę" : "dół"}`}
            width={12}
            height={7}
          />
        </CategoryWrapper>

        {isLoading && showItems && (
          <SpinnerWrapper>
            <Spinner radius="35px" border="5px" />
          </SpinnerWrapper>
        )}
        {!isLoading && showItems && (
          <List
            height={66 * displayingGroup.length}
            itemCount={displayingGroup.length}
            itemSize={66}
            width={260}
          >
            {({ index }) => (
              <LeftPanelGroupItem
                key={displayingGroup[index].id}
                id={displayingGroup[index].id}
                name={displayingGroup[index].name}
                role={displayingGroup[index].role}
                picture={displayingGroup[index].picture}
              />
            )}
          </List>
        )}
        {showItems && !isLoading && (
          <ShowMoreWrapper onClick={toggleShowMore}>
            <Text size="14px" color={theme.color.mediumGray}>
              {showAll ? "Zobacz mniej" : "Zobacz więcej"}
            </Text>
          </ShowMoreWrapper>
        )}
      </>
    </WrapperUnderlined>
  );
};

export default LeftPanelGroup;
