import { Link } from "react-router-dom";

import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const ImageWrapper = styled(Wrapper)`
  margin-right: 19px;
`;

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  width: 100%;
`;

export const ToolsWrapper = styled(Wrapper)`
  padding: 1rem;
  width: 90%;
  border-radius: 0.75rem;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
  background: ${(props) => props.color};
  :hover {
    background: ${theme.color.veryLightGray};
  }
`;

export const LeftPanelElementWrapper = styled(Wrapper)`
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px ${theme.color.veryLightGray};
`;
