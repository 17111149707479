export const createCategoryFormConfig = {
  categoryNameMinLength: 3,
  categoryNameMaxLength: 20,
};

export const validateCategoryName = (value: string, arr: string[]) => {
  if (value.length < createCategoryFormConfig.categoryNameMinLength)
    return "Nazwa jest za krótka, musi zawierać minimum 3 znaki.";
  if (value.length > createCategoryFormConfig.categoryNameMaxLength)
    return "Nazwa jest za długa, musi zawierać maks. 20 znaków.";
  if (
    arr.length &&
    arr.some((item) => item.toLowerCase().trim() === value.toLowerCase().trim())
  )
    return "Kategoria o podanej nazwie już istnieje.";
};
