import styled from "styled-components/macro";

export const GroupCreationForm = styled.form`
  width: 514px;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
`;

export const CloseIconWrapper = styled.a`
  cursor: pointer;
  position: absolute;
  right: 21px;
  top: 21px;
`;
