import { apiRequest } from "../config/axiosConfig";
import { GroupApiRequest, GroupApiResponse } from "../shared/apiResponse";

export interface IEditGroupStatusRequest {
  group_id: string;
  group_type: string;
  group_status: string;
  group_access: string;
}

interface IEditGroupStatusResponse {}

export async function editGroupStatus(
  editGroupStatusData: GroupApiRequest<IEditGroupStatusRequest>
): Promise<GroupApiResponse<IEditGroupStatusResponse>> {
  const response = await apiRequest<
    GroupApiResponse<IEditGroupStatusResponse>,
    GroupApiRequest<IEditGroupStatusRequest>
  >("PATCH", "GROUP", "", {
    data: editGroupStatusData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    },
  });
  return response.data;
}
