import React from "react";
import { useNavigate } from "react-router-dom";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { Paths } from "common/constants/paths";
import { theme } from "common/theme/theme";

import { Description, NameWrapper } from "../../LeftPanel.styled";

import { ItemWrapper } from "./LeftPanelGroupItem.styled";

interface IGroupItem {
  id: string;
  name: string;
  role: string;
  picture: string;
}

const LeftPanelGroupItem = (data: IGroupItem) => {
  const navigate = useNavigate();
  const handleItemClick = () => {
    navigate(`${Paths.GROUP}/${data.id}`);
  };
  return (
    <ItemWrapper onClick={handleItemClick}>
      <Image
        src={data.picture}
        alt="logo group"
        width={42}
        height={42}
        radius="100%"
      />
      <Description>
        <NameWrapper>
          <Text weight="bold" size="14px" color={theme.color.basicBlack}>
            {data.name}
          </Text>
        </NameWrapper>
        <Text size="12px" color={theme.color.darkGray} align="left">
          {data.role}
        </Text>
      </Description>
    </ItemWrapper>
  );
};

export default LeftPanelGroupItem;
