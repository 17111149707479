import React, { FocusEvent, useState } from "react";
import { Control, Controller } from "react-hook-form";

import Image from "common/components/Image";
import styled from "styled-components";

import { AbsValidationLabel } from "../Layout/Form/ValidationLabel";

import StyledDatePicker, { DatePickerWrapper } from "./TaskerDatePicker.styled";

export const TaskerDatePickerWrapper = styled(DatePickerWrapper)`
  // padding: 0px;
`;

export const StyledTaskerDatePicker = styled(StyledDatePicker)`
  // padding: 0px;
`;

export interface DatePicker2Props {
  control: Control<any>;
  errorText: string;
  name: string;
  id: string;
  isValid: boolean;
  label: string;
  maxDate?: Date;
  minDate?: Date;
  mb?: string;
  mt?: string;
  onBlur?: (event: FocusEvent) => void;
  width?: string;
  defaultActiveStartDate?: Date;
  format?: string;
}

const TaskerDatePicker = ({
  control,
  name,
  errorText,
  isValid,
  label,
  maxDate,
  minDate,
  mb,
  mt,
  onBlur,
  defaultActiveStartDate,
  format,
  width,
}: DatePicker2Props) => {
  // What is this? I need to click in the input to make the validation work properly :/
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isCalendarActive, setIsCalendarActive] = useState<boolean>(false);

  function handleBlur(event: any) {
    setIsClicked(true);
    onBlur && onBlur(event);
  }
  const onCalendarOpen = () => {
    setIsClicked(true);
    setIsCalendarActive(true);
  };

  // const checkIfPlaceholderLabelIsValid = useCallback(() => {
  //   if (isCalendarActive) {
  //     return true;
  //   } else {
  //     return isClicked ? isValid : null;
  //   }
  // }, [isCalendarActive, isClicked, isValid]);

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <TaskerDatePickerWrapper
          mb={mb}
          mt={mt}
          onBlur={() => handleBlur(onBlur)}
        >
          <StyledTaskerDatePicker
            calendarAriaLabel={label}
            calendarIcon={
              <Image
                src="/icons/calendar.svg"
                alt="calendar icon"
                layout="relative"
                width={18}
                height={20}
                top={"-6px"}
                right={"15px"}
              />
            }
            format={format || "dd.MM.yyyy"}
            isActive={isCalendarActive}
            isClicked={isClicked}
            isValid={isValid}
            locale="pl-PL"
            maxDate={maxDate}
            minDate={minDate}
            defaultActiveStartDate={defaultActiveStartDate}
            minDetail="decade"
            onCalendarClose={() => setIsCalendarActive(false)}
            onCalendarOpen={onCalendarOpen}
            onChange={onChange}
            value={value}
            width={width}
          />
          <AbsValidationLabel
            isInvalid={!isValid}
            fontSize="14px"
            top="-20px"
            left="5px"
          >
            {label}
          </AbsValidationLabel>
          {!isValid && errorText && !isCalendarActive && (
            <AbsValidationLabel
              isInvalid={!isValid}
              fontSize="14px"
              bottom="-20px"
              left="5px"
            >
              {error?.message}
            </AbsValidationLabel>
          )}
        </TaskerDatePickerWrapper>
      )}
    />
  );
};

export default TaskerDatePicker;
