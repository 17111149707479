import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface MemberItemWrapperProps {
  zIndex: string;
}

export const MembersItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 25.6px);
  margin-right: 6px;
  place-self: end;
`;

export const MemberItemWrapper = styled.div<MemberItemWrapperProps>`
  border: 1px solid ${theme.color.basicWhite};
  display: grid;
  place-items: center;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  background-color: #c4c4c4;
  z-index: ${({ zIndex }) => zIndex};
`;

export const MembersWrapper = styled.div`
  display: grid;
  width: 100%;
`;
