import React, { useState } from "react";

import { BackgroundEdit } from "../BackgroundChange/BackgroundEdit";
import { BackgroundRemove } from "../BackgroundChange/BackgroundRemove";
import { BackgroundUpload } from "../BackgroundChange/BackgroundUpload";
import { ModalPortal } from "../Modal/modal-portal";

interface UploadHandler {
  promise(): Promise<{ Location: string }>;
  on(
    event: "httpUploadProgress",
    listener: (progress: { loaded: number; total: number }) => void
  ): this;
}

interface ChangeBackgroundProps {
  title: string;
  previewText: string;
  currentImageMessage: string;
  deleteConfirmationMessage: string;
  currentImageSrc: string;
  fallbackImageSrc: string;
  noImageMessage: string;
  onImageRemove: () => Promise<any>;
  onImageUpload: (file: File) => UploadHandler;
  onImageChangeConfirm: () => Promise<any>;
  onClose: () => void;
}

export const ChangeBackground = ({
  title,
  previewText,
  currentImageSrc,
  deleteConfirmationMessage,
  currentImageMessage,
  fallbackImageSrc,
  noImageMessage,
  onImageUpload,
  onImageChangeConfirm,
  onImageRemove,
  onClose,
}: ChangeBackgroundProps) => {
  const [isBackgroundRemoveConfirmed, setIsBackgroundRemoveConfirmed] =
    useState(false);
  const [isImageUploading, setIsBackgroundUploading] = useState(false);
  const [error, setError] = useState(null);
  const [backgroundUploadedUrl, setBackgroundUploadedUrl] = useState(null);
  const [backgroundUploadProgress, setBackgroundUploadProgress] =
    useState<number>(0);

  const closeModal = () => {
    onClose();
    setIsBackgroundRemoveConfirmed(false);
    setIsBackgroundUploading(false);
    setError(null);
  };

  const editBackground = () => {
    setIsBackgroundUploading(true);
  };

  const handleBackgroundRemove = () => {
    setIsBackgroundRemoveConfirmed(true);
    if (isBackgroundRemoveConfirmed) {
      onImageRemove();
      onClose();
      setIsBackgroundRemoveConfirmed(false);
    }
  };

  const cancelRemove = () => {
    setIsBackgroundRemoveConfirmed(false);
  };

  const isValidImage = (file: File): any => {
    setError(null);

    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.size > 3145728
    )
      setError(
        "Błędny rozmiar i format pliku. Wczytaj zdjęcie .png lub .jpg w rozmiarze do 3MB."
      );
    else if (file.type !== "image/jpeg" && file.type !== "image/png")
      setError(
        "Błędny format pliku zdjęcia w tle. Wczytaj plik w formacie .png lub .jpg"
      );
    else if (file.size > 3145728)
      setError(
        "Błędny rozmiar pliku zdjęcia w tle. Wczytaj plik o maksymalnym rozmiarze 3 MB"
      );
    return (
      (file.type === "image/jpeg" || file.type === "image/png") &&
      file.size < 3145728
    );
  };

  const uploadImage = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!isValidImage(file)) return;
    try {
      const uploaded = await onImageUpload(file)
        .on("httpUploadProgress", (event) => {
          setBackgroundUploadProgress(
            Math.floor((event.loaded / event.total) * 100)
          );
        })
        .promise();
      setBackgroundUploadedUrl(uploaded.Location);
      setBackgroundUploadProgress(0);
    } catch (error) {
      console.error(`Uploading image failed with error: ${error.message}`);
    }
  };

  const saveImage = async () => {
    try {
      await onImageChangeConfirm();
      setBackgroundUploadedUrl(null);
    } catch (error) {
      console.error(
        `Saving background image failed with error: ${error.message}`
      );
    }
    closeModal();
  };

  const cancelBackgroundUpload = () => {
    setBackgroundUploadedUrl(null);
    closeModal();
  };

  return (
    <ModalPortal
      closeModal={closeModal}
      isVisible={true}
      withCloseButton={true}
    >
      {!isBackgroundRemoveConfirmed && !isImageUploading && (
        <BackgroundEdit
          title={title}
          subtitle={currentImageSrc ? currentImageMessage : noImageMessage}
          imageDefault={fallbackImageSrc}
          backgroundImage={currentImageSrc}
          onEdit={editBackground}
          onRemove={handleBackgroundRemove}
        />
      )}
      {isBackgroundRemoveConfirmed && (
        <BackgroundRemove
          title="Usuń zdjęcie"
          subtitle={deleteConfirmationMessage}
          imageDefault={fallbackImageSrc}
          onCancelRemove={cancelRemove}
          onConfirmRemove={handleBackgroundRemove}
        />
      )}
      {isImageUploading && (
        <BackgroundUpload
          backgroundImageDefault={fallbackImageSrc}
          backgroundImage={currentImageSrc}
          uploadProgress={backgroundUploadProgress}
          onImageUpload={uploadImage}
          backgroundUploadedUrl={backgroundUploadedUrl}
          error={error}
          onCancelUpload={cancelBackgroundUpload}
          onSave={saveImage}
          previewText={previewText}
        />
      )}
    </ModalPortal>
  );
};
