import { theme } from "common/theme/theme";
import styled from "styled-components";

export const CloseIconWrapper = styled.a`
  cursor: pointer;
  display: grid;
  justify-content: end;
`;

export const GroupCloseModalHeader = styled.h3`
  color: ${theme.color.darkGray};
  font-weight: ${theme.fontWeight.bold};
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
`;

export const GroupCloseButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 58.5px;
  margin-top: 24px;
`;
