import { Dispatch, SetStateAction } from "react";
import { UseFormGetValues, UseFormTrigger } from "react-hook-form";

import { mapTaskToUpdateTaskPayload } from "services/api/tasker/tasker.mappers";
import { taskerCreateTaskRq } from "services/api/tasker/taskerCreateTaskRq";
import { taskerUpdateTaskRq } from "services/api/tasker/taskerUpdateTaskRq";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { mapCreatedTaskToAddToStore } from "store/tasker/mappers/mapCreatedTaskToAddToStore";
import { mapUpdatedTaskToUpdateInStore } from "store/tasker/mappers/mapUpdatedTaskToUpdateInStore";
import { GroupUser } from "store/tasker/models/GroupUser";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

import { ITaskInForm } from "./NewTaskView";

interface useNewTaskSubmitProps {
  getValues: UseFormGetValues<ITaskInForm>;
  trigger: UseFormTrigger<ITaskInForm>;
  setFirstValidate: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  assignee: GroupUser | null;
  errors: any;
}

export const useNewTaskSubmit = ({
  getValues,
  trigger,
  setFirstValidate,
  isValid,
  assignee,
  errors,
}: useNewTaskSubmitProps) => {
  const dispatch = useAppDispatch();
  const taskerSelector = useAppSelector((s) => s.tasker);

  const createNewTask = async (): Promise<void> => {
    const values = getValues();
    dispatch(taskerAction.setLoading(true));
    const resp = await taskerCreateTaskRq(values, values.groupId);
    if (!resp) {
      console.log("create task error");
      console.log(resp);
      return;
    }

    const createdTask: Task = mapCreatedTaskToAddToStore(
      resp,
      values,
      assignee
    );

    dispatch(taskerAction.addNewTask(createdTask));
    dispatch(taskerAction.setLoading(false));
  };

  const updateTask = async () => {
    dispatch(taskerAction.setLoading(true));
    console.log(getValues());
    const resp = await taskerUpdateTaskRq(
      mapTaskToUpdateTaskPayload(
        getValues(),
        assignee,
        getValues().groupId,
        taskerSelector.taskerView.editTaskData.task.id
      )
    );

    if (!resp) {
      console.log("update task error");
      console.log(resp);
    }

    const updatedTask: Task = mapUpdatedTaskToUpdateInStore(
      taskerSelector.taskerView.editTaskData.task.id,
      getValues(),
      assignee
    );

    dispatch(taskerAction.updateTask(updatedTask));
    dispatch(taskerAction.setLoading(false));
  };

  const handleSubmit = async () => {
    setFirstValidate(true);

    if (!isValid) {
      console.log("Form not valudate");
      console.log(errors);
      trigger();
      return;
    }

    if (taskerSelector.taskerView.editTaskData) {
      updateTask();
    } else {
      createNewTask();
    }
  };

  return {
    handleSubmit,
  };
};
