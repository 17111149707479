import React, { useState } from "react";

import {
  Input,
  PlaceholderLabel,
  ValidationLabel,
} from "common/components/InputField";

import Flex from "../Flex";

import { FormInputProps } from "./Types";

function FormInput({
  errorText,
  id,
  inputName,
  isValid,
  label,
  mt,
  mb,
  placeholder,
  register,
  touched,
  type,
  width,
  autoFocus,
}: FormInputProps) {
  const [clicked, setClicked] = useState<boolean>(false);

  function onClick(): void {
    if (clicked === false) setClicked(true);
  }

  return (
    <Flex position="relative" direction="column" mt={mt} mb={mb}>
      <Input
        {...register(inputName)}
        onClick={onClick}
        isValid={isValid}
        clicked={clicked}
        id={id}
        type={type}
        width={width}
        placeholder={clicked ? placeholder : label}
        autoFocus={autoFocus}
      />
      <PlaceholderLabel isValid={isValid} touched={touched} htmlFor={id}>
        {label}
      </PlaceholderLabel>
      {!isValid && errorText && touched && (
        <ValidationLabel isValid={isValid}>{errorText}</ValidationLabel>
      )}
    </Flex>
  );
}

export default FormInput;
