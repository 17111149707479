import React, { FC } from "react";

import Banner from "common/components/Banner";

import { TopMenuPanel } from "../../../common/components/TopMenuPanel/TopMenuPanel";

import { FrontHeaderStyle, HeaderStyle } from "./Header.styled";

const Header: FC = () => {
  return (
    <HeaderStyle>
      <FrontHeaderStyle>
        <Banner />
      </FrontHeaderStyle>
      <TopMenuPanel />
    </HeaderStyle>
  );
};

export default Header;
