import React from "react";

import { format } from "date-fns";

import { FormBoxContent } from "./FormBox.styled";
interface Props {
  started: string | Date;
  finished?: string | Date;
}

export const FormBoxContentDate = ({ started, finished }: Props) => (
  <FormBoxContent>
    <span>{format(new Date(started), "MM.yyyy")}</span>
    {Boolean(typeof finished === "string" ? finished.length : finished) && (
      <span>{` - ${format(new Date(finished), "MM.yyyy")}`}</span>
    )}
  </FormBoxContent>
);
