import React from "react";

import { ShowMoreText, ShowMoreWrapper } from "./ShowMore.styled";

interface ShowMoreProps {
  showMoreHandler: () => void;
  isShownMore: boolean;
}

const ShowMore = ({ showMoreHandler, isShownMore }: ShowMoreProps) => {
  return (
    <ShowMoreWrapper>
      <ShowMoreText onClick={showMoreHandler} size="12px">
        {isShownMore ? "Zobacz mniej" : "Zobacz więcej"}
      </ShowMoreText>
    </ShowMoreWrapper>
  );
};

export default ShowMore;
