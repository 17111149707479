import React from "react";
import {
  DeepPartialSkipArrayKey,
  FormState,
  UnpackNestedValue,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import FormInput from "common/components/FormInputHookForms";
import FormTextArea from "components/FormTextArea";
import GroupRadioButtons from "components/Group/Common/GroupCreationRadioButtons/GroupRadioButtons";
import { GroupAccess, GroupType } from "components/Group/Types/group";

import { formConfig } from "../formConfig";
import { IGroupCreationFields } from "../types";

import {
  Counter,
  ShortDescriptionWrapper,
} from "./GroupCreationFirstStage.styled";

interface GroupCreationFirstStageProps {
  formData: UnpackNestedValue<DeepPartialSkipArrayKey<IGroupCreationFields>>;
  formState: FormState<IGroupCreationFields>;
  register: UseFormRegister<IGroupCreationFields>;
  setValue: UseFormSetValue<IGroupCreationFields>;
  watch: UseFormWatch<IGroupCreationFields>;
}

export const GroupCreationFirstStage = ({
  formState: { errors, touchedFields },
  formData,
  register,
  setValue,
  watch,
}: GroupCreationFirstStageProps) => {
  return (
    <>
      <FormInput
        id="groupName"
        placeholder="Podaj nazwę grupy"
        label="Podaj nazwę grupy"
        type="text"
        isValid={!errors.groupName}
        inputName="groupName"
        register={register}
        errorText={errors.groupName?.message}
        mt="40px"
        touched={touchedFields.groupName}
      />
      <ShortDescriptionWrapper>
        <FormTextArea
          id="shortDescription"
          placeholder="Wpisz krótki opis"
          label="Krótki opis (opcjonalnie)"
          isValid={!errors.shortDescription}
          inputName="shortDescription"
          register={register}
          resize="none"
          errorText={errors.shortDescription?.message}
          mt="50px"
          touched={touchedFields.shortDescription}
          autosize
        />
        <Counter>
          {formData.shortDescription.length}/
          {formConfig.shortDescriptionMaxLength}
        </Counter>
      </ShortDescriptionWrapper>
      <GroupRadioButtons<IGroupCreationFields>
        header="Ustawienie prywatności:"
        firstDescription="Każdy może sprawdzić listę członków grupy i zobaczyć ich posty"
        secondDescription="Tylko członkowie grupy mogą sprawdzić listę członków grupy i zobaczyć ich posty"
        setValue={setValue}
        selected={watch("privacy")}
        inputName="privacy"
        firstButtonValue={"PUBLIC" as GroupAccess}
        secondButtonValue={"PRIVATE" as GroupAccess}
        firstButtonText="publiczna"
        secondButtonText="prywatna"
      />
      <GroupRadioButtons<IGroupCreationFields>
        header="Typ grupy"
        setValue={setValue}
        firstDescription="Służy do rozwijania zainteresowań, dzielenia się wiedzą i poznawania partnerów biznesowych"
        secondDescription="Przestrzeń z narzędziami dla zespołów projektowych, organizacji, klubów, firm i wirtualnych klas"
        selected={watch("groupType")}
        inputName="groupType"
        firstButtonValue={"DISCUSS" as GroupType}
        secondButtonValue={"PROJECT" as GroupType}
        firstButtonText="dyskusyjna"
        secondButtonText="projektowa"
      />
    </>
  );
};
