import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const AuthenticationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 1250px) {
    & > :first-child {
      display: none;
    }
  }

  &::before {
    content: "";
    padding-left: ${theme.layout.contentHorizontalPadding};
  }

  &::after {
    content: "";
    padding-right: ${theme.layout.contentHorizontalPadding};
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  width: 50%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const TabColumnWrapper = styled.div`
  max-width: 660px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  // @media (min-width: 1250px) {
  margin-top: 50px;
  // }
`;

export const TabRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 2px solid ${theme.color.basicBlack};
`;

export const TabNavigationWrapper = styled.div`
  display: flex;
  max-width: 660px;
  flex-direction: row;
  font-size: 24px;
  font-family: ${theme.fontFamily.secondary};
  font-weight: ${theme.fontWeight.semibold};
`;

export const FormWrapper = styled.div`
  margin-left: 70px;
  margin-right: 70px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  min-height: 300px;
  max-height: 500px;
  justify-content: space-between;
`;
