import React from "react";

import { GroupButtonStyled } from "./GroupButton.styled";

interface GroupButtonProps {
  buttonText: string;
  buttonLight: boolean;
  clickHandler?: () => void;
  disabled?: boolean;
  testId: string;
  width: string;
  height: string;
  ml?: string;
}

const GroupButton = ({
  buttonText,
  buttonLight,
  clickHandler,
  disabled,
  height,
  testId,
  width,
}: GroupButtonProps) => {
  return (
    <GroupButtonStyled
      width={width}
      height={height}
      disabled={disabled}
      light={buttonLight}
      onClick={clickHandler}
      data-testid={testId}
      type="button"
    >
      {buttonText}
    </GroupButtonStyled>
  );
};

export default GroupButton;
