import React from "react";
import { useDropzone } from "react-dropzone";

import AWS from "aws-sdk";
import Avatar from "common/components/Avatar/Avatar";
import GhostButton from "common/components/GhostButton/GhostButton";
import Image from "common/components/Image";
import ProgressBar from "common/components/ProgressBar";
import { theme } from "common/theme/theme";

import SmallButton from "../SmallButton/SmallButton";
import Text from "../Text/Text";

import {
  ButtonsContainer,
  Container,
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
  FlexContainer,
  ImageContainer,
  Subtitle,
  Title,
  UploadHeader,
  UploadSectionWrapper,
} from "./shared/style";

type AvatarUploadProps = {
  error: string;
  imageDefault: string;
  imageRequirements: { key: string; text: string }[];
  imageSrc: string;
  imageUploadedUrl: string;
  onCancelUpload: () => void;
  onImageUpload: (file: File[]) => void;
  onSaveImage: () => void;
  subtitle: string;
  title: string;
  uploadProgress: number;
} & React.HTMLAttributes<HTMLDivElement>;

AWS.config.update({
  region: "eu-central-1",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "eu-central-1:210a4cca-553a-4377-bb8a-68ee604eea01",
  }),
});

const AvatarUpload = ({
  error,
  imageDefault,
  imageSrc,
  imageUploadedUrl,
  onCancelUpload,
  onImageUpload,
  onSaveImage,
  subtitle,
  title,
  uploadProgress,
  ...restProps
}: AvatarUploadProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onImageUpload,
    noClick: true,
  });

  return (
    <Container {...getRootProps()} {...restProps}>
      <UploadHeader>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </UploadHeader>
      {!imageUploadedUrl ? (
        <UploadSectionWrapper>
          <FlexContainer>
            <Avatar
              editable={false}
              height="160px"
              imageSrc={imageSrc || imageDefault}
              width="160px"
            />
            <ImageContainer>
              {uploadProgress > 0 ? (
                <ProgressBar value={uploadProgress} mt="29px" width="130px" />
              ) : (
                <Image src="/icons/add-image.svg" width={100} height={100} />
              )}
            </ImageContainer>
          </FlexContainer>
          <Text size="14px" color={theme.color.darkGray}>
            lub
          </Text>
          <ButtonsContainer>
            <input {...getInputProps()} />
            <SmallButton type="button" onClick={open}>
              Wybierz z komputera
            </SmallButton>
          </ButtonsContainer>
          {error && (
            <ErrorContainer>
              <ErrorIcon src="/icons/errorField.svg" />
              <ErrorMessage>
                {error.split(".")[0] + "."}
                <br></br>
                {error.split(".").slice(1).join(".")}
              </ErrorMessage>
            </ErrorContainer>
          )}
          {!error && (
            <Text size="12px" color={theme.color.mediumGray}>
              Maksymalny rozmiar zdjęcia to: 2 MB
            </Text>
          )}
        </UploadSectionWrapper>
      ) : (
        <>
          <input id="user-image-input" {...getInputProps()} />
          <Avatar
            editable={false}
            height="160px"
            imageSrc={imageUploadedUrl}
            width="160px"
          />
          <ButtonsContainer>
            <GhostButton type={"button"} onClick={onCancelUpload}>
              Anuluj
            </GhostButton>
            <SmallButton type={"button"} onClick={onSaveImage}>
              Zapisz
            </SmallButton>
          </ButtonsContainer>
        </>
      )}
    </Container>
  );
};

export default AvatarUpload;
