import styled from "styled-components";

interface CommentSettingsImageProps {
  isHovered: boolean;
}

export const DropdownWrapper = styled.div<CommentSettingsImageProps>`
  align-self: center;
  cursor: pointer;
  opacity: ${({ isHovered }) => (isHovered ? "1" : "0")}}
  transition: all 0.2s;
`;
