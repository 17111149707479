import React from "react";

import Image from "common/components/Image/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import {
  PostButtonWrapper,
  PostReactionButton,
  PostReactionWrapper,
} from "./PostReaction.styled";

interface IPostReactionProps {
  isCommentsShown: boolean;
  toggleComment: () => void;
}

const PostReaction = ({
  toggleComment,
  isCommentsShown,
}: IPostReactionProps) => {
  const formatReactions = (reactions: number) => {
    if (reactions < 1000) {
      return reactions.toString();
    }

    if (reactions < 10000) {
      return (reactions / 1000).toFixed(1).replace(".0", "") + " tys.";
    }

    if (reactions < 100000) {
      return Math.round(reactions / 1000) + " tys.";
    }

    if (reactions < 1000000) {
      return (reactions / 1000).toFixed(0) + " tys.";
    }

    if (reactions < 10000000) {
      return (reactions / 1000000).toFixed(1).replace(".0", "") + " mln";
    }

    return (reactions / 1000000).toFixed(0) + " mln";
  };
  const reactions = formatReactions(189590);
  return (
    <PostReactionWrapper commentsShown={isCommentsShown}>
      <PostButtonWrapper>
        <PostReactionButton>
          <Image width={24} height={24} src="/icons/like.svg" />
        </PostReactionButton>
        <Text weight="extraBold" color={theme.color.basicBlack}>
          {reactions}
        </Text>
      </PostButtonWrapper>
      <PostButtonWrapper>
        <PostReactionButton onClick={toggleComment}>
          <Image width={24} height={24} src="/icons/comment.svg" />
        </PostReactionButton>
        <Text weight="extraBold" color={theme.color.basicBlack}>
          0
        </Text>
      </PostButtonWrapper>
    </PostReactionWrapper>
  );
};

export default PostReaction;
