import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";

import StyledSuccessBlock from "./SuccessBlock.styled";

interface SuccessBlockProps {
  children?: React.ReactNode;
  title: string;
  path: string;
}

const SuccessBlock = ({ title, children, path }: SuccessBlockProps) => {
  const navigate = useNavigate();
  return (
    <StyledSuccessBlock>
      <Heading align="center" mt="20px" mb="20px">
        {title}
      </Heading>
      {children}
      <Image
        src="/images/congratulations.png"
        alt="Obrazek ukończonego wypelnienia profilu"
        width={252}
        height={168}
      />
      <Text mb="40px"></Text>
      <Button width="329px" onClick={() => navigate(path)}>
        Przejdź do platformy
      </Button>
    </StyledSuccessBlock>
  );
};

export default SuccessBlock;
