import React from "react";
import { Path, UseFormSetValue } from "react-hook-form";

import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import { PostPublicationType } from "common/types/PostPublicationType";
import GroupButton from "components/Group/Common/GroupButton/GroupButton";

import {
  PostPublicationSettingsModalWrapper,
  PublicationButtons,
  PublicationRadioButtons,
  PublicationSettingsBottomSection,
  PublicationSettingsTopSection,
  PublicationTextWrapper,
} from "./PostPublicationSettingsModal.styled";
import PublicationOption from "./PublicationOption/PublicationOption";

interface PostPublicationSettingsModalProps<T> {
  firstRadioButtonValue: PostPublicationType;
  goBack: () => void;
  saveChanges: () => void;
  inputName: Path<T>;
  setValue: UseFormSetValue<T>;
  selected: string;
  shouldTextBeDisplayed: boolean;
  secondRadioButtonValue: PostPublicationType;
}

const PostPublicationSettingsModal = <T,>({
  firstRadioButtonValue,
  goBack,
  saveChanges,
  inputName,
  setValue,
  selected,
  shouldTextBeDisplayed,
  secondRadioButtonValue,
}: PostPublicationSettingsModalProps<T>) => {
  return (
    <PostPublicationSettingsModalWrapper>
      <PublicationSettingsTopSection>
        <PublicationTextWrapper>
          <Text weight="bold" color={theme.color.basicBlack}>
            Ustawienia publikacji
          </Text>
        </PublicationTextWrapper>
        <PublicationRadioButtons>
          <PublicationOption
            optionText="Publiczny"
            iconSrc="/icons/earth.svg"
            inputName={inputName}
            setValue={setValue}
            selected={selected}
            value={firstRadioButtonValue as string}
            shouldTextBeDisplayed={shouldTextBeDisplayed}
          />
          <PublicationOption
            optionText="Prywatny"
            iconSrc="/icons/lock.svg"
            inputName={inputName}
            setValue={setValue}
            selected={selected}
            value={secondRadioButtonValue as string}
            shouldTextBeDisplayed={shouldTextBeDisplayed}
          />
        </PublicationRadioButtons>
      </PublicationSettingsTopSection>
      <PublicationSettingsBottomSection>
        <PublicationButtons>
          <GroupButton
            buttonText="Wróć"
            buttonLight={true}
            width="77px"
            height="35px"
            testId="back-to-post"
            clickHandler={saveChanges}
          />
          <GroupButton
            buttonText="Gotowe"
            buttonLight={false}
            width="94px"
            height="35px"
            testId="confirm-change-publication"
            clickHandler={goBack}
          />
        </PublicationButtons>
      </PublicationSettingsBottomSection>
    </PostPublicationSettingsModalWrapper>
  );
};

export default PostPublicationSettingsModal;
