import React, { useRef, useState } from "react";

import Image from "common/components/Image";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import ModalCloseIcon from "common/icons/ModalCloseIcon";

import {
  DeleteButton,
  FirstChildMarginFlex,
  UserNamePopUp,
} from "./RightUsersTableItem.styled";

interface RightUsersTableItemProps {
  avatar: string;
  deleteUser: () => void;
  name: string;
}

const RightUsersTableItem = ({
  avatar,
  deleteUser,
  name,
}: RightUsersTableItemProps) => {
  const table = useRef<HTMLDivElement>();

  const onClickHandler = () => {
    deleteUser();
  };
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <FirstChildMarginFlex
      firstChildMargin="3px 0 0 0"
      align="center"
      gap="10.95px"
      ref={table}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <RelativeContainer w="45px" h="45px">
        {isHovering && (
          <UserNamePopUp>
            <Image
              layout="absolute"
              src="/images/polygon.png"
              alt="polygon"
              width={9}
              height={9}
              data-testid="username-popup"
            />
            <Text size="12px" lineHeight="14.52px">
              {name}
            </Text>
          </UserNamePopUp>
        )}
        <Image src={avatar} width={40} height={40} radius="100%" />
        <DeleteButton onClick={onClickHandler} data-testid="delete-user-btn">
          <ModalCloseIcon width="9" height="9" />
        </DeleteButton>
      </RelativeContainer>
    </FirstChildMarginFlex>
  );
};

export default RightUsersTableItem;
