import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export default function ZoomItem() {
  const iconDown = `/icons/down.svg`;

  return (
    <Flex align="center" justify="space-between">
      <Text ml="10px" size="14px">
        Powiększenie
      </Text>
      <NoteKitBorderWrapper p="7px 11px">
        <Text size="14px">100%</Text>
        <Text ml="10px">
          <Image src={iconDown} alt="icon" width={13} height={12} top="2px" />
        </Text>
      </NoteKitBorderWrapper>
    </Flex>
  );
}
