import * as React from "react";

import { theme } from "common/theme/theme";
import { toast } from "sonner";
import styled from "styled-components";

const CopyToClipboardStyled = styled.div`
  position: relative;
  border: none;
  margin: none;
  height: 2.5rem;
  padding: 0 0.25rem;
  background-color: ${theme.color.lightGray};
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  input {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: ${theme.fontSize.small};
  }
  svg {
    transition: scale 200ms ease-in;
  }
  svg:active {
    scale: 0.9;
  }
  button {
    appearance: unset;
    border: none;
    margin-top: 0.25rem;
  }
`;
export function CopyToClipboard() {
  const baseUrl = window.location.origin;

  const copyLinkIntoClipboard = async () => {
    await navigator.clipboard.writeText(baseUrl);
    toast.success("Link został skopiowany do schowka!");
  };

  return (
    <CopyToClipboardStyled>
      <input readOnly value={baseUrl}></input>
      <button
        type="button"
        onClick={copyLinkIntoClipboard}
        aria-label="skopiuj link do schowka"
      >
        <img src="/icons/copy.svg" />
      </button>
    </CopyToClipboardStyled>
  );
}
