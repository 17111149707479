import CleanRouterLink from "common/components/CleanRouterLink";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface TabProps {
  isActive: boolean;
  activeColor: string;
  paddingBottom: number;
  borderHeight: number;
}

export const Tab = styled.button<TabProps>`
  background: ${theme.color.basicWhite};
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  color: ${theme.color.mediumGray};
  background-color: ${theme.color.basicWhite};
  cursor: pointer;
  border: none;
  color: ${({ isActive }) =>
    isActive ? `${theme.color.basicBlack}` : `${theme.color.mediumGray}`};
  border-bottom: ${({ isActive, activeColor, borderHeight }) =>
    isActive
      ? `${borderHeight}px solid ${activeColor}`
      : `${borderHeight}px solid transparent`};
  text-align: center;
  box-sizing: border-box;
  font-weight: inherit;
`;

export const StyledRouterLink = styled(CleanRouterLink)`
  width: 100%;
`;
