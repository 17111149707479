import React, { ChangeEvent, useState } from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

import {
  PlaceholderLabel,
  TextArea,
  TextAreaWrapper,
  ValidationLabel,
} from "./FormTextArea.styled";

export interface FormTextAreaProps {
  errorText?: string;
  id: string;
  inputName: Path<FieldValues>;
  isValid: boolean;
  label: string;
  resize?: "none";
  mt?: string;
  mb?: string;
  placeholder: string;
  register: UseFormRegister<any>;
  touched: boolean;
  width?: string;
  autosize?: boolean;
}

function FormTextArea({
  errorText,
  id,
  inputName,
  isValid,
  label,
  mt,
  mb,
  placeholder,
  register,
  resize,
  touched,
  width,
  autosize,
}: FormTextAreaProps) {
  const [clicked, setClicked] = useState<boolean>(false);

  const onClick = (): void => {
    if (clicked === false) setClicked(true);
  };

  const onInput = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (autosize && e.target.scrollHeight > e.target.offsetHeight) {
      e.target.rows++;
    }
  };

  return (
    <TextAreaWrapper mt={mt} mb={mb}>
      <TextArea
        {...register(inputName)}
        onClick={onClick}
        isValid={isValid}
        clicked={clicked}
        id={id}
        width={width}
        placeholder={clicked ? placeholder : label}
        resize={resize}
        rows={1}
        onInput={onInput}
      />
      <PlaceholderLabel isValid={isValid} touched={touched} htmlFor={id}>
        {label}
      </PlaceholderLabel>
      {!isValid && errorText && touched && (
        <ValidationLabel isValid={isValid}>{errorText}</ValidationLabel>
      )}
    </TextAreaWrapper>
  );
}

export default FormTextArea;
