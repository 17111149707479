import React from "react";

import {
  BackgroundImageAdd,
  BackgroundImageAddIcon,
  BackgroundImagePreview,
} from "../BackgroundChange/BackgroundChange.styled";
import ProgressBar from "../ProgressBar";

type BackgroundProps = {
  backgroundImage: string;
  showAddImageIcon?: boolean;
  uploadProgress?: number;
};

export const BackgroundPreview = ({
  backgroundImage,
  showAddImageIcon,
  uploadProgress,
}: BackgroundProps) => {
  return (
    <>
      {!showAddImageIcon && !uploadProgress && (
        <BackgroundImagePreview
          src={backgroundImage}
          alt="Background image preview"
        />
      )}
      {showAddImageIcon && uploadProgress === 0 && (
        <BackgroundImageAdd
          backgroundImage={`linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${backgroundImage})`}
        >
          <BackgroundImageAddIcon src="/icons/add-image.svg" />
        </BackgroundImageAdd>
      )}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <BackgroundImageAdd
          backgroundImage={`linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(${backgroundImage})`}
        >
          <ProgressBar value={uploadProgress} mt="29px" width="130px" />
        </BackgroundImageAdd>
      )}
    </>
  );
};
