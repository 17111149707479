import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICategory } from "store/tasker/models/Category";
import { GroupUser } from "store/tasker/models/GroupUser";
import { IList } from "store/tasker/models/List";

import { getAllTaskCategoriesByGroup } from "../taskCategory";
import { getTaskLists } from "../taskList";

import { getAllTasksByGroupRq } from "./getAllTasksByGroupRq";
import { getAllUsersByGroupRq } from "./getAllUsersByGroupRq";
import { ITaskResponse } from "./tasker.responses";

interface getAllDataForGroupViewDto {
  allTasks: ITaskResponse[];
  allLists: IList[];
  allUsers: GroupUser[];
  allCategories: ICategory[];
}

export async function getAllDataForGroupView(
  groupId: string
): Promise<getAllDataForGroupViewDto> {
  const response = await Promise.all([
    getAllTasksByGroupRq(groupId),
    getTaskLists(groupId),
    getAllUsersByGroupRq(groupId),
    getAllTaskCategoriesByGroup(groupId),
  ]);
  const data = {
    allTasks: response[0],
    allLists: response[1],
    allUsers: response[2],
    allCategories: response[3],
  };
  console.log(data);
  if (!response[0]) {
    console.error("getAllTasksByGroupRq fetch ERROR");
  }
  if (!response[1]) {
    console.error("getTaskLists fetch ERROR");
  }
  if (!response[2]) {
    console.error("getAllUsersByGroupRq fetch ERROR");
  }
  if (!response[3]) {
    console.error("getAllTaskCategoriesByGroup fetch ERROR");
  }

  return data;
}

export interface getAllGroupDataForUserViewDto {
  allLists: IList[];
  allUsers: GroupUser[];
  allCategories: ICategory[];
}

export async function getAllGroupDataForUserView(
  groupId: string
): Promise<getAllGroupDataForUserViewDto> {
  const response = await Promise.all([
    getTaskLists(groupId),
    getAllUsersByGroupRq(groupId),
    getAllTaskCategoriesByGroup(groupId),
  ]);
  const data = {
    allLists: response[0],
    allUsers: response[1],
    allCategories: response[2],
  };
  console.log(data);
  if (!response[0]) {
    console.error("getTaskLists fetch ERROR");
  }
  if (!response[1]) {
    console.error("getAllUsersByGroupRq fetch ERROR");
  }
  if (!response[2]) {
    console.error("getAllTaskCategoriesByGroup fetch ERROR");
  }
  return data;
}

export const initTaskerGroupViewAsyncThunk = createAsyncThunk(
  "tasker/groupView/fetch",
  async (groupId: string) => {
    return getAllDataForGroupView(groupId);
  }
);
