import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PostReactionWrapper = styled.div<{ commentsShown: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  place-items: center;
  width: 100%;
  height: 52px;
  border-top: 1px solid ${theme.color.slightlyLightGray};
  border-bottom: 1px solid
    ${({ commentsShown }) =>
      commentsShown ? theme.color.slightlyLightGray : theme.color.basicWhite};
`;

export const PostReactionButton = styled.button`
  display: flex;
  justify-conent: space-around;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

export const PostButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
