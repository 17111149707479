import styled from "styled-components/macro";

type DropdownMenuContainerProps = {
  left?: string;
  top?: string;
};

const DropdownMenuContainer = styled.div<DropdownMenuContainerProps>`
  width: 240px;
  background-color: ${({ theme }) => theme.color.basicWhite};
  position: absolute;
  top: ${({ top }) => top || "25px"};
  left: ${({ left }) => left || "-240px"};
  box-shadow: -2px 2px 20px 0px #00000033;
  border-radius: 8px;
  display: grid;
  place-items: center;
  z-index: 2;
  padding-top: 5px;
  padding-bottom: 5px;
`;
export { DropdownMenuContainer };
