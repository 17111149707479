import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PlaceholderLabel = styled.label<{
  isValid: boolean;
  touched: boolean;
}>`
  position: absolute;
  top: 10px;
  transition: all 0.3s;
  font-size: 16px;
  color: ${({ isValid, touched }) =>
    isValid === false && touched
      ? theme.color.alertRed
      : theme.color.basicBlack};
`;

interface TextAreaProps {
  isValid: boolean;
  width?: string;
  clicked: boolean;
  resize: string;
}

export const TextArea = styled.textarea<TextAreaProps>`
  width: ${(p) => p.width || "100%"};
  max-height: 74px; // 4 lines
  padding: 14px 0px;
  cursor: pointer;
  background: none;
  text-align: left;
  border: none;
  outline: 0;
  overflow: auto;
  resize: ${({ resize }) => resize};
  border-bottom: 2px solid
    ${(props) => {
      if (props.clicked && props.isValid !== null) {
        return props.isValid ? theme.color.formGreen : theme.color.alertRed;
      } else {
        return theme.color.basicBlack;
      }
    }};
  color: ${theme.color.mediumGray};
  &:focus {
    outline: none;
    border-bottom: 2px solid
      ${(p) =>
        p.isValid === true
          ? theme.color.formGreen
          : p.isValid === false
          ? theme.color.alertRed
          : theme.color.basicBlack};
  }
  &::placeholder {
    color: transparent;
  }
  &:not(:placeholder-shown),
  &:focus {
    & + ${PlaceholderLabel} {
      top: -18px;
      font-size: 87.5%;
    }
  }
`;

interface TextAreaWrapperProps {
  mt?: string;
  mb?: string;
}

export const TextAreaWrapper = styled.div<TextAreaWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
`;

export const ValidationLabel = styled.label<{ isValid: boolean }>`
  position: absolute;
  font-size: 14px;
  color: ${({ isValid }) =>
    isValid === false ? theme.color.alertRed : theme.color.basicBlack};

  bottom: -18px;
  left: 0;
  padding-top: 10px;
`;
