import React from "react";

import Button from "common/components/Button";
import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { IconPlus } from "common/icons/IconPlus";
import { useGetProfileQuery, useGetUserQuery } from "store/user/userApi";

import {
  Header,
  RootContainer,
  Separator,
  Title,
} from "../DetailsGroup/DetailsGroup.styled";
import {
  EmptyBox,
  FormBox,
  FormBoxContent,
  FormBoxTitle,
} from "../Shared/FormBox.styled";
import { FormBoxContentDate } from "../Shared/FormBoxContentDate";

import { ModalDeleteJob } from "./ModalDeleteJob";
import { ModalJob } from "./ModalJob";

export const Job = () => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });

  const { data: profile } = useGetProfileQuery(
    { sub, language: user?.language },
    { skip: !sub || !user?.language }
  );
  const { showModal: openEditModal, modalProps: editModalProps } = useModal();
  const { showModal: openDeleteModal, modalProps: deleteModalProps } =
    useModal();

  return (
    <>
      <RootContainer>
        <Header>
          <Title>Praca</Title>
          {Boolean(profile?.companyName?.length) && (
            <MakeActionDropdown
              dropdownMenuOptions={[
                {
                  img: "/icons/edit_icon.svg",
                  text: "Edytuj",
                  action: () => openEditModal(),
                  testid: "edit",
                },
                {
                  img: "/icons/delete_icon.svg",
                  text: "Usuń",
                  action: () => openDeleteModal(),
                  testid: "delete",
                },
              ]}
            />
          )}
        </Header>
        <Separator />
        {profile?.companyName ? (
          <FormBox>
            <FormBoxTitle>{profile.jobName}</FormBoxTitle>
            <FormBoxContent>{profile.companyName}</FormBoxContent>
            <FormBoxContentDate
              started={profile.jobStarted}
              finished={profile.jobFinished}
            />
          </FormBox>
        ) : (
          <EmptyBox>
            <Text>Nie masz wpisanego jeszcze żadnego doświadczenia</Text>
            <Button onClick={openEditModal}>
              <IconPlus />
              Dodaj pracę
            </Button>
          </EmptyBox>
        )}
      </RootContainer>
      <ModalPortal {...editModalProps}>
        <ModalJob
          onClose={editModalProps.closeModal}
          titlePrefix={profile?.companyName?.length ? "Edytuj" : "Dodaj"}
        />
      </ModalPortal>
      <ModalPortal {...deleteModalProps}>
        <ModalDeleteJob onClose={deleteModalProps.closeModal} />
      </ModalPortal>
    </>
  );
};
