import { theme } from "common/theme/theme";
import styled from "styled-components";

interface PostUserImageWrapperProps {
  image: string;
}

export const PostGroupUserSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
`;

export const PostGroupUserImageWrapper = styled.div<PostUserImageWrapperProps>`
  width: 40px;
  height: 40px;
  background-image: url("${({ image }) => image}");
  background-size: cover;
  position: relative;
  border-radius: 100%;
`;

export const PostGroupUserImage = styled.img`
  box-sizing: border-box;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.color.basicWhite};
  border-radius: 100%;
  z-index: 10;
  background-color: ${theme.color.basicWhite};
`;

export const PostGroupHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PostHeaderGroupUserPropertiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
`;
