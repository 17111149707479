import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const RootContainer = styled.div`
  padding: calc(2rem - 2.5%) calc(4rem - 2.5%);
`;

export const Separator = styled.div`
  width: 100%;
  border-radius: 1rem;
  opacity: 0.5;
  padding: 0.125rem 0;
  border-top: 0.15rem solid ${theme.color.mediumGray};
`;

export const Header = styled.div`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Text).attrs({ as: "h3" })`
  font-size: 1rem;
  color: ${({ theme }) => theme.color.basicBlack};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1rem;
  letter-spacing: 0.02em;
`;

export const Content = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
