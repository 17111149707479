import { Theme } from "./theme.interface";

export const theme: Theme = {
  fontSize: {
    small: "1.25rem",
    base: "1.6rem",
    big: "2.5rem",
    huge: "13.75rem",
  },
  fontWeight: {
    thin: "300",
    normal: "400",
    semibold: "600",
    bold: "700",
    extraBold: "900",
  },
  fontFamily: {
    primary: '"Inter", sans-serif',
    secondary: '"Open Sans", sans-serif',
  },
  color: {
    blue: "#90CAF9",
    darkBlue: "#0277BD",
    darkCyan: "#00838F",
    blueGray: "#CFD8DC",
    mediumGray: "#767676",
    darkGray: "#4A4A4A",
    darkerGray: "#D7D7D7",
    slightlyLightGray: "#B0B2B2",
    lightGray: "#ECEEEF",
    veryLightGray: "#F7F7F7",
    veryVeryLightGray: "#F6F6F6",
    whiteGray: "#F2F2F2",
    backgroundGray: "#E5E5E5",
    formGreen: "#00BD58",
    formYellow: "#FEE542",
    veryLightOrange: "#FFDB80",
    veryDarkGray: "#323232",
    basicBlack: "#1D1D1D",
    basicWhite: "#FFFFFF",
    disabledPrimary: "#AAAAAA",
    disabledSecondary: "#E0DFE0",
    alertRed: "#BC2B24",
    priorityRed: "#D84315",
  },
  spacing: {
    xs: 4,
    s: 8,
    m: 12,
    l: 16,
  },
  breakpoints: {
    phone: "640px",
    desktop: "1000px",
  },
  layout: {
    maxContent: "1389px",
    contentHorizontalPadding: "24px",
    contentVerticalPadding: "24px",
  },
  boxShadow: {
    base: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
  },
};
