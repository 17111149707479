import React, { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "common/components/Button";
import Flex from "common/components/Flex";
import FormInput from "common/components/FormInput";
import Text from "common/components/Text";
import { Paths } from "common/constants";
import { generateCodeToRemindPassword } from "services/api/user/userApi";
import { authAction } from "store/auth/authSlice";
import { useAppDispatch } from "store/hooks";

import { validateEmail } from "./helper";
import { Form } from "./RemindPasswordForm.styled";
import { RegisterFormDetailsProps } from "./Types";

const RemindPasswordForm: FC<RegisterFormDetailsProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>("");
  const [formError, setFormError] = useState<string>("");

  const validate = (values: string) => {
    const emailError = validateEmail(values);
    setFormError(emailError);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const isFormValid = !formError;

  const onClick = async (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      await generateCodeToRemindPassword(email);
      dispatch(authAction.setUserMailToRemindPassword(email));
      navigate(Paths.CHANGE_PASSWORD);
    } catch (error) {
      const message = error.response?.data?.message;
      if (
        message.startsWith("Attempt limit exceeded, please try again later.")
      ) {
        setFormError(
          "Zbyt dużo prób przypominania hasła. Spróbuj ponownie później."
        );
      } else {
        setFormError(message);
      }
    }
  };

  return (
    <Form>
      <div>
        <Text size="30px" weight="bold" mb="25px">
          Przypominanie hasła
        </Text>
        <Text size="18px" mb="6px">
          Wpisz <strong>swój adres e-mail, </strong>aby odzyskać hasło.
        </Text>
        <Text size="18px">
          Wyślemy Ci <strong>jednorazowy kod </strong> do Twojego konta.
        </Text>
        <FormInput
          id="email"
          placeholder="E-mail"
          label="E-mail"
          value={email}
          type="email"
          onChange={handleChange}
          errorText={formError}
          mt="42px"
          mb="124px"
          onBlur={() => validate(email)}
        />
      </div>
      <Flex justify="center">
        <Button
          type="submit"
          disabled={!isFormValid}
          onClick={onClick}
          width="272px"
          height="56px"
        >
          Wyślij e-mail
        </Button>
      </Flex>
    </Form>
  );
};

export default RemindPasswordForm;
