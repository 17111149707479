import React, {
  createContext,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { IconCheckmark } from "common/icons/IconCheckmark";

import { StepperHeaderStyles } from "./styles";

interface StepperContextType {
  activeStepId: number;
  setActiveStepId: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  steps: string[];
}

const StepperContext = createContext<StepperContextType | null>(null);

export function useStepper() {
  const stepper = useContext(StepperContext);
  if (!stepper) {
    throw new Error("useStepper must be used within a Stepper");
  }
  return stepper;
}

// step component to wrap each step with it
function Step({ id, children }: { id?: number; children: ReactNode }) {
  const { activeStepId } = useStepper();

  const isActive = activeStepId === id;

  if (!isActive) {
    return null;
  }

  // eslint-disable-next-line no-undef
  return children as JSX.Element;
}

export function Stepper({
  children,
  steps,
}: {
  children: ReactNode;
  steps: string[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStepId, setActiveStepId] = useState(
    Number(searchParams.get("step") || 0)
  );

  const nextStep = useCallback(() => {
    setActiveStepId((prev) => prev + 1);
    setSearchParams({ step: `${activeStepId + 1}` });
  }, [activeStepId, setSearchParams]);

  const prevStep = useCallback(() => {
    setActiveStepId((prev) => prev - 1);
    setSearchParams({ step: `${activeStepId - 1}` });
  }, [activeStepId, setSearchParams]);

  // update active step id when search params change
  useEffect(() => {
    setActiveStepId(Number(searchParams.get("step") || 0));
  }, [searchParams]);

  // memoize context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      activeStepId,
      setActiveStepId,
      nextStep,
      prevStep,
      steps,
    }),
    [activeStepId, setActiveStepId, nextStep, prevStep, steps]
  );

  // copy react children and pass prop id to each child element (Step) with index - 1
  const childrenWithProps = React.Children.map(children, (child, idx) => {
    if (React.isValidElement(child) && child.type === Step) {
      // @ts-ignore
      return React.cloneElement(child, { id: idx - 1 });
    }
    return child;
  });

  return (
    <StepperContext.Provider value={value}>
      {childrenWithProps}
    </StepperContext.Provider>
  );
}

function Header({ withInitialStep }: { withInitialStep?: boolean }) {
  const { steps, activeStepId } = useStepper();

  return (
    <StepperHeaderStyles>
      {steps.map((step, index) => {
        const isCurrentStep =
          activeStepId === (withInitialStep ? index + 1 : index);
        const isDone = activeStepId > (withInitialStep ? index + 1 : index);

        return (
          <Fragment key={step}>
            <div
              className={`step ${isCurrentStep && "current-step"} ${
                isDone && "done"
              } 
            `}
            >
              <span>{step}</span>
              <button className="step-button">
                {isDone ? <IconCheckmark /> : index + 1}
              </button>
            </div>
            {index !== steps.length - 1 && <div className="line"></div>}
          </Fragment>
        );
      })}
    </StepperHeaderStyles>
  );
}

Stepper.Header = Header;
Stepper.Step = Step;
