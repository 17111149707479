import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CategoryRequestDto,
  CategoryResponseDto,
  ICategory,
} from "common/types/Category";
import {
  createCategory,
  deleteCategory,
  editCategory,
  getCategoriesList,
} from "services/api/note/noteApi";

export const categoryCreate = createAsyncThunk(
  "category/create",
  async (categoryData: CategoryRequestDto) => {
    const response = await createCategory(categoryData);
    return response;
  }
);

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (userId: string) => {
    const response = await getCategoriesList(userId);
    return response;
  }
);

export const deleteCategoryThunk = createAsyncThunk(
  "category/deleteCategory",
  async ({ userId, categoryId }: { userId: string; categoryId: string }) => {
    await deleteCategory(userId, categoryId);
    return categoryId;
  }
);

export const editCategoryThunk = createAsyncThunk(
  "category/editCategory",
  async (editCategoryData: {
    id: string;
    category_name: string;
    owner: string;
  }) => {
    await editCategory(editCategoryData);
    return {
      id: editCategoryData.id,
      category_name: editCategoryData.category_name,
    };
  }
);

export const convertCategoryFromDto = (
  categoryDto: CategoryResponseDto
): ICategory | null => {
  const category: ICategory = {
    categoryId: categoryDto.id,
    categoryName: categoryDto.category_name,
    ...categoryDto,
  };
  return category;
};

export interface CategoryState {
  status: "idle" | "loading" | "succeeded";
  categories: ICategory[];
}

const initialState: CategoryState = {
  status: "idle",
  categories: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.status = "succeeded";
      const allCategories = action.payload.map((cat) =>
        convertCategoryFromDto(cat)
      );
      const categories = allCategories.filter((item) => !item.parent);
      const subCategories = allCategories.filter((item) => item.parent);
      const finalCategories: ICategory[] = categories?.map((category) => ({
        ...category,
        subCategories: subCategories?.filter(
          (subCategory) => subCategory.parent === category.id
        ),
      }));
      state.categories = finalCategories;
    });
    builder.addCase(categoryCreate.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(categoryCreate.fulfilled, (state) => {
      state.status = "idle";
    });
    builder.addCase(deleteCategoryThunk.fulfilled, (state, action) => {
      state.categories = state.categories.filter(
        (category) => category.id !== action.payload
      );
    });
    builder.addCase(editCategoryThunk.fulfilled, (state, action) => {
      const { id, category_name } = action.payload;
      const category = state.categories.find((cat) => cat.id === id);
      if (category) {
        category.categoryName = category_name;
      }
    });
  },
});

export default categorySlice.reducer;
