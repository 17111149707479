import React, { useMemo } from "react";

import Image from "common/components/Image/Image";
import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import { format, isSameYear } from "date-fns";
import pl from "date-fns/locale/pl";

import { IGroupPostData } from "../types";

import {
  PostGroupHeaderWrapper,
  PostGroupUserImage,
  PostGroupUserImageWrapper,
  PostGroupUserSectionWrapper,
  PostHeaderGroupUserPropertiesWrapper,
} from "./PostGroupUserSection.styled";

interface PostUserSectionsProps {
  createdDate: string;
  groupData: IGroupPostData;
  firstName: string;
  lastName: string;
  userAvatar: string;
}

const PostGroupUserSection = ({
  createdDate,
  groupData,
  firstName,
  lastName,
  userAvatar,
}: PostUserSectionsProps) => {
  const publishedDate = useMemo(() => {
    const postDate = new Date(createdDate);
    const isFromCurrentYear = isSameYear(postDate, Date.now());
    const monthNamePolish = isFromCurrentYear
      ? format(postDate, "d MMMM", { locale: pl })
      : format(postDate, "d MMMM yyyy", { locale: pl });
    const postHour = format(postDate, "HH:mm", { locale: pl });
    return `${monthNamePolish} o ${postHour}`;
  }, [createdDate]);

  return (
    <PostGroupUserSectionWrapper>
      <PostGroupUserImageWrapper image={groupData.groupAvatar}>
        <PostGroupUserImage src={userAvatar} />
      </PostGroupUserImageWrapper>
      <PostGroupHeaderWrapper>
        <PostHeaderGroupUserPropertiesWrapper>
          <Text color={theme.color.basicBlack} weight="bold">
            {groupData.groupName}
          </Text>
          <Image src="/icons/dot.svg" />
          <Text
            color={theme.color.basicBlack}
          >{`${firstName} ${lastName}`}</Text>
        </PostHeaderGroupUserPropertiesWrapper>
        <Text size="12px">{publishedDate}</Text>
      </PostGroupHeaderWrapper>
      <Image width={24} height={24} src="/icons/three-dots-menu.svg" />
    </PostGroupUserSectionWrapper>
  );
};

export default PostGroupUserSection;
