import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INote } from "components/NotesPage/Types/notes";

interface DefaultState {
  notes: INote[];
  noteViewOpen: boolean;
  toolboxAction: string | null;
  noteToEdit: INote | null;
  noteAutoSave: boolean;
}

const notesSlice = createSlice({
  name: "notes",
  initialState: {
    noteViewOpen: false,
    toolboxAction: null,
    noteAutoSave: false,
  } as DefaultState,
  reducers: {
    setNotes(state, action: PayloadAction<INote[]>) {
      state.notes = action.payload;
    },
    deleteNote(state, action: PayloadAction<string>) {
      state.notes = state.notes.filter((note) => note.id !== action.payload);
    },
    openNewNote(state) {
      state.noteViewOpen = true;
    },
    setToolboxAction(state, action) {
      state.toolboxAction = action.payload;
    },
    openEditNote(state, action: PayloadAction<INote | null>) {
      state.noteToEdit = action.payload;
      state.noteViewOpen = true;
    },
    cancelNote(state) {
      state.noteViewOpen = false;
      state.noteToEdit = null;
    },
    setAutoSave(state, action: PayloadAction<boolean>) {
      state.noteAutoSave = action.payload;
    },
  },
});

export const notesReducer = notesSlice.reducer;
export const notesAction = notesSlice.actions;
