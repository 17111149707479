import { theme } from "common/theme/theme";
import styled, { css } from "styled-components";

export const StyledFormSelect = styled.div<{
  mt: string;
  mb: string;
}>`
  font-size: 1.2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
`;

export const PlaceholderLabel = styled.label<{
  isValid: boolean;
}>`
  position: absolute;
  transition: all 0.3s;
  z-index: 0;
  font-size: 16px;
  color: ${({ isValid }) =>
    isValid === false ? theme.color.alertRed : theme.color.basicBlack};
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 10px;
`;

export const DropdownField = styled.input<{
  isActive: boolean;
  isClicked: boolean;
  isValid: boolean;
}>`
  font-size: 16px;
  color: ${theme.color.mediumGray};
  width: ${(p) => p.width || "100%"};
  padding: 14px 0px;
  cursor: pointer;
  background: none;
  text-align: left;
  border: none;
  outline: 0;
  border-bottom: 2px solid ${theme.color.basicBlack};
  border-bottom: 2px solid
    ${(p) => {
      if (p.isClicked && p.isValid !== null) {
        if (p.isValid === true && !p.isActive) return theme.color.formGreen;
        if (p.isValid === false && !p.isActive) return theme.color.alertRed;
      }
      if (p.isActive) return theme.color.formYellow;
      return theme.color.darkGray;
    }};

  ${(props) =>
    (props.isActive || props.value) &&
    css`
      &:not(:placeholder-shown),
      &:focus {
        & + ${PlaceholderLabel} {
          top: -26px;
          font-size: 14px;
        }
      }
    `};
`;

export const List = styled.ul<{
  isActive: boolean;
  rows?: number;
  width?: number;
  padding?: string;
}>`
  display: ${(props) => !props.isActive && `none`};
  position: absolute;
  top: 36px;
  padding: ${(props) => (props.padding ? props.padding : "12px")};
  width: ${(props) => (props.width ? props.width + `px` : `260px`)};
  max-height: ${(props) => (props.rows ? props.rows * 50 + `px` : null)};
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2), -2px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-size: 16px;
  list-style: none;
  background-color: white;
  z-index: 3;
  :has(li:active) + label {
    opacity: 0;
  }
`;

export const ListItem = styled.li`
  margin: 4px 0;
  padding: 12px;
  border-radius: 8px;
  line-height: 124%;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    background-color: #eceeef;
  }
`;

export const FormSelectScrollbarWrapper = styled.div<{
  scrollBarPadding?: string;
}>`
  height: 100%;
  max-height: 150px;
  overflow-y: scroll;
  padding: ${(props) => props.scrollBarPadding};
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 89px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;

export default StyledFormSelect;
