import React from "react";

import Button from "common/components/Button";
import { ButtonVariant } from "common/components/Button/Button";
import Flex from "common/components/Flex";
import { FlexJustifyType } from "common/components/Flex/Flex";
import Spinner from "common/components/Spinner";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

interface Props {
  flexJustifyType?: FlexJustifyType;
  height?: string;
  size?: string;
  loading?: boolean;
  gap?: string;
  cancelButton: {
    handleCloseModalAndCancel: () => void;
    textCancel?: string;
    cancelButtonPadding?: string;
    cancelButtonWidth?: string;
    cancelButtonBorder?: string;
    variantCancel?: ButtonVariant;
  };
  acceptButton: {
    handleCloseModalAndAccept: () => void;
    textAccept?: string;
    acceptButtonPadding?: string;
    acceptButtonWidth?: string;
    acceptButtonBorder?: string;
    variantAccept?: ButtonVariant;
  };
}

export default function CancelAcceptButtons({
  flexJustifyType = "flex-end",
  height = "36px",
  size = "14px",
  loading,
  gap,
  cancelButton: {
    handleCloseModalAndCancel,
    textCancel = "Anuluj",
    variantCancel = "outlined",
    cancelButtonPadding,
    cancelButtonWidth,
    cancelButtonBorder,
  },
  acceptButton: {
    handleCloseModalAndAccept,
    textAccept = "Zapisz",
    variantAccept = "primary",
    acceptButtonPadding,
    acceptButtonWidth,
    acceptButtonBorder,
  },
}: Props) {
  return (
    <Flex justify={flexJustifyType} gap={gap ?? "40px"} width={"100%"}>
      <Button
        height={height}
        variant={variantCancel}
        padding={cancelButtonPadding ?? "8px 24px"}
        display="flex"
        border={cancelButtonBorder ?? "inherit"}
        width={cancelButtonWidth ?? "auto"}
      >
        <Text
          size={size}
          weight="bold"
          letterSpacing="0.2px"
          color={theme.color.basicBlack}
          onClick={handleCloseModalAndCancel}
        >
          {textCancel}
        </Text>
      </Button>
      <Button
        height={height}
        variant={variantAccept}
        padding={acceptButtonPadding ?? "8px 24px"}
        display="flex"
        border={acceptButtonBorder ?? "inherit"}
        width={acceptButtonWidth ?? "auto"}
        backgroundColor={theme.color.basicBlack}
      >
        {loading ? (
          <Spinner border="2px" radius="15px" />
        ) : (
          <Text
            size={size}
            weight="bold"
            letterSpacing="0.2px"
            color={theme.color.basicWhite}
            onClick={handleCloseModalAndAccept}
          >
            {textAccept}
          </Text>
        )}
      </Button>
    </Flex>
  );
}
