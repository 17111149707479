import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const HeaderText = styled(Text)`
  margin-top: 32px;
  margin-left: 116px;
  margin-right: 544px;
  margin-bottom: 16px;
`;

export const HeaderSplitter = styled.div`
  border: 0.5px ${theme.color.mediumGray} solid;
  margin-left: 32px;
  margin-right: 32px;
`;
