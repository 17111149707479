import * as React from "react";

import { Data } from "services/api/search/index.types";

import { SearchResultCardStyled } from "./SearchResultCard.styled";

type Props = Readonly<Data> & {
  actionButton?: React.ReactNode;
  showMoreButton?: React.ReactNode;
};

export function SearchResultCard(props: Props) {
  return (
    <SearchResultCardStyled>
      <div className="content">
        <div className="text">
          <div className="header">
            <img
              src={props.imageUrl}
              alt={`${props.title} - ${props.description}`}
            />
            <h3>{props.title}</h3>
          </div>
          <p>{props.description}</p>
        </div>
        {props.actionButton}
      </div>
      {props.showMoreButton}
    </SearchResultCardStyled>
  );
}
