import React from "react";

import { Container, EditButton, Image } from "./Avatar.styled";

type AvatarProps = {
  imageSrc: string;
  editable?: boolean;
  width?: string;
  height?: string;
  onEdit?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};
const Avatar = ({
  imageSrc,
  editable,
  onEdit,
  width = "84px",
  height = "84px",
}: AvatarProps) => {
  return (
    <Container>
      <Image width={width} height={height} src={imageSrc} />
      {editable && <EditButton onClick={onEdit} />}
    </Container>
  );
};

export default Avatar;
