import React from "react";
import { Path, UseFormSetValue } from "react-hook-form";

import RadioButton from "common/components/RadioButtonHookForms";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { RadioButtonWrapper } from "./GroupRadioButton.styled";

interface GroupCreationRadioButtonProps<T> {
  description: string;
  inputName: Path<T>;
  selected: string;
  setValue: UseFormSetValue<T>;
  radioButtonText: string;
  value: string;
}

const GroupCreationRadioButton = <T,>({
  description,
  inputName,
  selected,
  setValue,
  radioButtonText,
  value,
}: GroupCreationRadioButtonProps<T>) => {
  return (
    <RadioButtonWrapper>
      <RadioButton<T>
        inputName={inputName}
        selected={selected}
        setValue={setValue}
        text={radioButtonText}
        value={value}
      />
      <Text
        size="12px"
        color={theme.color.mediumGray}
        lineHeight="16px"
        align="left"
      >
        {description}
      </Text>
    </RadioButtonWrapper>
  );
};

export default GroupCreationRadioButton;
