import { ICategory } from "store/tasker/models/Category";

import { apiRequest } from "../config/axiosConfig";

export interface TaskCategoryCreatePayload {
  group_id: string;
  name: string;
  created_by: string;
}

export async function createTaskCategory(
  data: TaskCategoryCreatePayload
): Promise<string> {
  try {
    console.log(data);
    const response = await apiRequest<string, TaskCategoryCreatePayload>(
      "POST",
      "TASKCATEGORIES",
      "/create",
      {
        data,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `CreateTaskCategory returned with status ${err.response.status}`
      );
    }
  }
}

export async function getAllTaskCategoriesByGroup(
  groupId: string
): Promise<ICategory[]> {
  try {
    const response = await apiRequest<ICategory[], string>(
      "GET",
      "TASKCATEGORIES",
      `?groupId=${groupId}`,
      {}
    );

    return response.data;
  } catch (err) {
    if (err.response) {
      console.error(
        `GetTaskCategory returned with status ${err.response.status}`
      );
    }
  }
}
