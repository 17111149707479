import AWS from "aws-sdk";
import * as userApi from "services/api/user/userApi";

export async function avatarDelete(sub: string, userImageKey: string) {
  const s3Bucket = "smartoak-user-images";
  new AWS.S3().deleteObject(
    { Bucket: s3Bucket, Key: userImageKey },
    (error) => {
      if (error) console.error(error);
    }
  );
  try {
    await userApi.updateUser(sub, { avatarUrl: "" });
  } catch (error) {
    console.error(error);
  }
}

export async function backgroundDelete(sub: string, userBackgroundKey: string) {
  const s3Bucket = "smartoak-user-images";
  new AWS.S3().deleteObject(
    { Bucket: s3Bucket, Key: userBackgroundKey },
    (error) => {
      if (error) console.error(error);
    }
  );
  try {
    await userApi.updateUser(sub, { backgroundUrl: "" });
  } catch (error) {
    console.error(error);
  }
}
