import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
`;

export const CategoryNameWrapper = styled.div`
  position: relative;
  & input:focus {
    border-bottom: 2px solid ${theme.color.formYellow};
  }

  & input:focus + label {
    color: inherit;
  }

  & input:focus ~ label:nth-of-type(2) {
    opacity: 0;
  }
  & div:has(input:focus) + p {
    color: ${theme.color.mediumGray};
  }
`;
