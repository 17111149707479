import React from "react";

import GroupButton from "../GroupButton/GroupButton";

import {
  GroupModalHeaderText,
  GroupModalTextContent,
  GroupModalWrapper,
} from "./GroupModal.styled";
import GroupModalButtons from "./GroupModalButtons/GroupModalButtons";
import GroupModalDescription from "./GroupModalDescription/GroupModalDescription";

interface ISingleButton {
  singleButtonHandler: () => void;
  singleButtonWidth: string;
  singleButtonHeight: string;
  singleButtonText: string;
}

interface ISpacingConfiguration {
  spaceBetweenHeaderAndDescription: string;
  spaceBetweenDescriptionAndButtons: string;
}

export interface GroupModalProps {
  headerText: string;
  modalHeight: string;
  modalPadding?: string;
  modalWidth: string;
  descriptionMarginTop?: string;
  descriptionText: string;
  singleButton: boolean;
  singleButtonData?: ISingleButton;
  proceedButtonHandler?: () => void;
  cancelButtonHandler?: () => void;
  proceedButtonText?: string;
  cancelButtonText?: string;
  cancelButtonWidth?: string;
  cancelButtonHeight?: string;
  proceedButtonWidth?: string;
  proceedButtonHeight?: string;
  spacingConfiguration: ISpacingConfiguration;
}

// ODSTEPY MIEDZY CZESCIA TEKSTU A PRZYCISKAMI

const GroupModal = ({
  cancelButtonText,
  cancelButtonHandler,
  cancelButtonHeight,
  cancelButtonWidth,
  descriptionMarginTop,
  descriptionText,
  headerText,
  modalHeight,
  modalPadding,
  modalWidth,
  proceedButtonText,
  proceedButtonHeight,
  proceedButtonWidth,
  proceedButtonHandler,
  singleButtonData,
  singleButton,
  spacingConfiguration,
}: GroupModalProps) => {
  return (
    <GroupModalWrapper
      width={modalWidth}
      height={modalHeight}
      gap={spacingConfiguration.spaceBetweenDescriptionAndButtons}
      padding={modalPadding}
    >
      <GroupModalTextContent
        gap={spacingConfiguration.spaceBetweenHeaderAndDescription}
      >
        <GroupModalHeaderText>{headerText}</GroupModalHeaderText>
        <GroupModalDescription
          descriptionText={descriptionText}
          descriptionMarginTop={descriptionMarginTop}
        />
      </GroupModalTextContent>
      {singleButton && (
        <GroupButton
          buttonLight={false}
          clickHandler={singleButtonData.singleButtonHandler}
          buttonText={singleButtonData.singleButtonText}
          width={singleButtonData.singleButtonWidth}
          height={singleButtonData.singleButtonHeight}
          testId="success-group-modal-button"
        />
      )}
      {!singleButton && (
        <GroupModalButtons
          cancelButtonText={cancelButtonText}
          cancelButtonHandler={cancelButtonHandler}
          cancelButtonHeight={cancelButtonHeight}
          cancelButtonWidth={cancelButtonWidth}
          proceedButtonHandler={proceedButtonHandler}
          proceedButtonHeight={proceedButtonHeight}
          proceedButtonWidth={proceedButtonWidth}
          proceedButtonText={proceedButtonText}
        />
      )}
    </GroupModalWrapper>
  );
};

export default GroupModal;
