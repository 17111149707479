import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import UserListItem from "common/components/UserListItem/UserListItem";

import { ItemAddButton } from "./UsersTableItem.styled";

interface UsersTableItemProps {
  avatar: string;
  chooseHandler: (userSub: string) => void;
  localization: string;
  fullName: string;
  occupation: string;
  userSub: string;
  showAddButton?: boolean;
}

const UsersTableItem = ({
  avatar,
  chooseHandler,
  localization,
  fullName,
  userSub,
  occupation,
  showAddButton = true,
}: UsersTableItemProps) => {
  const onChooseHandler = () => {
    chooseHandler(userSub);
  };

  return (
    <Flex
      direction="row"
      justify="space-between"
      align="center"
      width="371px"
      height="64px"
      gap="50px"
    >
      <UserListItem
        avatarUrl={avatar}
        localization={localization}
        fullName={fullName}
        occupation={occupation}
      />
      {showAddButton && (
        <ItemAddButton onClick={onChooseHandler} data-testid="add-btn">
          <Image src="/icons/addSign.svg" />
        </ItemAddButton>
      )}
    </Flex>
  );
};

export default UsersTableItem;
