import React from "react";
import {
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
} from "react-hook-form";

import Image from "common/components/Image/Image";
import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";
import { postConfig } from "components/Group/GroupDiscussion/postConfig";

import PostModalFileComponent from "./PostModalFileComponent/PostModalFile";
import {
  CounterWrapper,
  PostModalFilesWrapper,
  PostModalTextAreaField,
  PostModalTextAreaFieldWrapper,
  PostModalTextAreaTopWrapper,
  PostModalTextAreaWrapper,
  ValidationMessageWrapper,
} from "./PostModalTextArea.styled";

interface PostModalTextAreaProps<T> {
  files: File[];
  handleFileDeletion: (file: File) => void;
  setValue: UseFormSetValue<T>;
  registerFieldName: Path<T>;
  isValid: boolean;
  valueCount: number;
}

const PostModalTextArea = <T,>({
  files,
  handleFileDeletion,
  setValue,
  registerFieldName,
  isValid,
  valueCount,
}: PostModalTextAreaProps<T>) => {
  return (
    <PostModalTextAreaWrapper>
      <PostModalTextAreaTopWrapper>
        <PostModalTextAreaFieldWrapper>
          <PostModalTextAreaField
            role="textbox"
            contentEditable="true"
            data-placeholder="O czym chcesz napisać?"
            onInput={(event) =>
              setValue(
                registerFieldName,
                event.currentTarget.textContent as UnpackNestedValue<
                  PathValue<T, Path<T>>
                >,
                {
                  shouldValidate: true,
                }
              )
            }
          />
          <PostModalFilesWrapper>
            {files.map((file, index) => (
              <PostModalFileComponent
                key={index}
                file={file}
                handleFileDeletion={handleFileDeletion}
              />
            ))}
          </PostModalFilesWrapper>
        </PostModalTextAreaFieldWrapper>
      </PostModalTextAreaTopWrapper>

      {!isValid && (
        <CounterWrapper>
          <ValidationMessageWrapper>
            <Image src="/icons/warning-icon.svg" />
            <Text size="12px" color={theme.color.alertRed} ml="4px">
              Zbyt długa treść. Limit wynosi 3000 znaków.
            </Text>
          </ValidationMessageWrapper>
          <Text color={theme.color.alertRed} size="14px">
            {valueCount}/{postConfig.postLength}
          </Text>
        </CounterWrapper>
      )}
    </PostModalTextAreaWrapper>
  );
};

export default PostModalTextArea;
