import React, { useCallback } from "react";
import { Path, PathValue, UnpackNestedValue } from "react-hook-form";

import {
  HelperText,
  RadioContainer,
  RadioInnerCircle,
  RadioOuterCircle,
} from "./RadioButton.styled";
import { RadioButtonProps } from "./Types";

function RadioButton<T>({
  inputName,
  selected,
  setValue,
  text,
  value,
}: RadioButtonProps<T>) {
  const changeValue = useCallback(() => {
    setValue(
      inputName as Path<T>,
      value as UnpackNestedValue<PathValue<T, Path<T>>>
    );
  }, [setValue, inputName, value]);
  const selectedValue = selected === value;
  return (
    <RadioContainer onClick={changeValue}>
      <RadioOuterCircle selected={selectedValue}>
        <RadioInnerCircle selected={selectedValue} />
      </RadioOuterCircle>
      {text?.length > 0 && (
        <HelperText selected={selectedValue}>{text}</HelperText>
      )}
    </RadioContainer>
  );
}

export default RadioButton;
