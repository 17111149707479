import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const PasswordInputWrapper = styled.div`
  position: relative;
`;

export const CheckboxContainer = styled.div`
  position: absolute;
  top: 10px;
  left: calc(100% - 23px);
  background: ${theme.color.basicWhite};
  padding-left: 3px;
`;
