import styled from "styled-components/macro";

const bg1 = [
  "rgba(106, 27, 154, 0.1)",
  "rgba(13, 85, 147, 0.4)",
  "rgba(60, 149, 7, 0.5)",
  "red",
];

export const CategoryWrapper = styled.div<{
  p?: string;
  h?: string;
  colorIdx: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 7px;
  ${({ h }) => "height: " + h ?? "29px"};
  width: max-content;
  border-radius: 8px;
  ${({ p }) => "padding: " + p ?? "0 4px"};
  background: ${({ colorIdx }) => bg1[colorIdx] ?? bg1[0]};
  margin-bottom: 7px;
  margin-right: 4px;
`;

export const CategoryText = styled.div`
  color: #6a1b9a;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
  width: max-content;
`;
