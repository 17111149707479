import {
  CategoryResponseDto,
  NoteResponseDto,
  NoteStatusDto,
} from "services/api/note/noteApi";

export interface ICategory {
  categoryName: string;
  created?: string;
  id: string;
  owner: string;
  parent?: string;
  subCategories?: ICategory[];
}

export interface CategoryRequestDto {
  category_name: string;
  parent?: string;
  owner: string;
}

export interface INote {
  categoryName: string | null;
  id: string;
  owner: string;
  created: string;
  lastUpdateDate: string;
  status: NoteStatusDto;
  title: string;
  noteContents: string;
  category: string;
}

export interface INoteLocalStorage extends INote {
  edit: boolean;
}

export interface NewNoteRequestDto {
  owner: string;
  title: string;
  note_contents: string;
  category: string;
}

export interface UpdateNoteRequestDto {
  id: string;
  title: string;
  note_contents: string;
}

export interface UpdatedCategorytDto {
  id: string;
  owner: string;
  category_name: string;
  parent?: string;
}

export const convertCategoryFromDto = (
  categoryDto: CategoryResponseDto
): ICategory | null => {
  try {
    const category: ICategory = {
      categoryName: categoryDto.category_name,
      ...categoryDto,
    };
    return category;
  } catch (err) {
    console.log("Converting category response failed");
    return null;
  }
};

export const convertNoteFromDto = (noteDto: NoteResponseDto): INote | null => {
  try {
    const note: INote = {
      lastUpdateDate: noteDto.last_update_date,
      noteContents: noteDto.note_contents,
      ...noteDto,
      categoryName: null,
    };
    return note;
  } catch (err) {
    console.log("Converting note response failed");
    return null;
  }
};
