import React from "react";

import Button from "common/components/Button";
import Flex from "common/components/Flex";
import { Container } from "common/components/Layout/Containers";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const ButtonStyled = styled(Button)`
  height: 30px;
  font-size: 14px;
  padding: 8px 12px;
  width: fit-content;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  background-color: ${theme.color.basicWhite};

  &:hover {
    background-color: ${theme.color.whiteGray};
  }
`;

export default function AddNewList() {
  return (
    <Flex direction="column">
      <Container
        bgColor={theme.color.basicWhite}
        w="280px"
        p="12px"
        bRadius="8px"
        h="auto"
      >
        <Flex align="center" justify="center">
          <ButtonStyled>
            <Text
              ml="7px"
              size="14px"
              weight="semibold"
              color={theme.color.basicBlack}
            >
              Dodaj listę
            </Text>
          </ButtonStyled>
        </Flex>
      </Container>
    </Flex>
  );
}
