import styled from "styled-components";

export const PostAttachmentsWrapper = styled.div`
  height: 344px;
`;

export const TwoPostAttachmentsGrid = styled.div`
  display: grid;
  height: 100%;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
`;

export const ThreePostAttachmentsGrid = styled.div`
  display: grid;
  height: 100%;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
`;

export const ThreePostAttachmentsRightGrid = styled.div`
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
`;
