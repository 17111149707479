import WallLayout from "common/components/WallLayout/WallLayout";
import styled from "styled-components/macro";

export const GroupPageWrapper = styled(WallLayout)`
  //padding-top: 0;
`;

export const GroupPageContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px;
`;
