import Heading from "common/components/Heading";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const Title = styled(Heading)`
  width: 300px;
  font-size: 20px;
  text-align: center;
  color: ${theme.color.darkGray};
`;
export const Subtitle = styled.p`
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: center;
  line-height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: ${theme.color.darkGray};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 24px;
`;

export const Container = styled.div`
  width: 300px;
  padding: 12px;

  .choose-button {
    width: 100%;
    white-space: nowrap;
    text-decoration: underline;
  }

  header {
    display: flex;
    flex-direction: column;

    h2,
    p {
      margin: 0;
      padding: 0;
    }

    h2 {
      font-weight: ${theme.fontWeight.semibold};
      margin-bottom: 0.5rem;
    }
  }
`;

export const Info = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 24px;
  color: ${theme.color.mediumGray};
`;

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 80%;
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

export const ErrorMessage = styled(Text)`
  font-size: 12px;
  color: ${theme.color.alertRed};
  display: flex;
  text-wrap: nowrap;
  line-height: 20px;
`;

export const ErrorIcon = styled.img`
  height: 24px;
  width: 24px;
`;

export const UploadHeader = styled.header`
  display: grid;
  place-items: center;
  margin-bottom: 24px;
`;

export const UploadSectionWrapper = styled.div`
  display: grid;
  gap: 24px;
  place-items: center;
`;
