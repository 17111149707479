import React from "react";

import { StyledSmallButton } from "./SmallButton.styled";

type Props = {
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  disabled?: boolean;
};

const SmallButton = ({ children, type, onClick }: Props) => {
  return (
    <StyledSmallButton type={type} onClick={onClick}>
      {children}
    </StyledSmallButton>
  );
};
export default SmallButton;
