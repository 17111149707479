import React from "react";

import { TaskCardGroupView } from "common/components/Card/TaskCard/TaskCardGroupView";
import Flex from "common/components/Flex";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import TaskerMenu from "components/TaskerPage/utils/TaskerMenu";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IList } from "store/tasker/models/List";
import { taskerAction } from "store/tasker/taskerSlice";

import AddNewTaskButton from "../../common/AddNewTaskButton";

export interface TaskerColumnWrapperProps {
  list: IList;
}

export default function TaskerColumnWrapper({
  list,
}: TaskerColumnWrapperProps) {
  const dispatch = useAppDispatch();
  const categoriesSelector = useAppSelector((s) => s.tasker);
  const categories = categoriesSelector.allCategories;

  const handleOpenNewTask = (listId: string) => {
    dispatch(taskerAction.setOpenNewTaskView(listId));
  };

  return (
    <Flex direction="column">
      <RelativeContainer
        bgColor={theme.color.veryVeryLightGray}
        w="280px"
        p="12px"
        bRadius="8px"
        h="auto"
      >
        <Flex direction="row" justify="space-between">
          <Text size="14px" weight="semibold">
            {list.name}
          </Text>
          <TaskerMenu />
        </Flex>
        <Flex direction="column">
          {list.tasks.map((t) => {
            if (!t.completed) {
              return (
                <TaskCardGroupView
                  key={t.id}
                  task={t}
                  category={
                    categories.find((c) => c.id === t.category_ids?.[0]) ?? null
                  }
                />
              );
            } else {
              return null;
            }
          })}
        </Flex>
        <AddNewTaskButton
          handleOpenNewTask={() => {
            handleOpenNewTask(list.id);
          }}
        />
      </RelativeContainer>
    </Flex>
  );
}
