import React from "react";

interface PlusSvgSignProps {
  color: string;
}

export const PlusSvgSign = ({ color }: PlusSvgSignProps) => {
  if (color === "black")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_24857_48735)">
          <path
            d="M15.8327 10.8334H10.8327V15.8334H9.16602V10.8334H4.16602V9.16675H9.16602V4.16675H10.8327V9.16675H15.8327V10.8334Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_24857_48735">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );

  if (color === "white")
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <g clipPath="url(#clip0_24857_24959)">
          <path
            d="M16.3327 11.3334H11.3327V16.3334H9.66602V11.3334H4.66602V9.66675H9.66602V4.66675H11.3327V9.66675H16.3327V11.3334Z"
            fill="#1D1D1D"
          />
        </g>
        <defs>
          <clipPath id="clip0_24857_24959">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
};
