import styled from "styled-components";

interface GroupModalWrapperProps {
  width: string;
  height: string;
  padding: string;
  gap: string;
}

interface GroupTextContentProps {
  gap: string;
}

export const GroupModalWrapper = styled.div<GroupModalWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
`;

export const GroupModalHeaderText = styled.h3`
  font-size: 20px;
  text-align: center;
  margin: 0;
`;

export const GroupModalTextContent = styled.div<GroupTextContentProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;
