import styled from "styled-components/macro";

export const CheckboxLiftingStyles = styled.label`
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 1rem;

  input {
    display: none;
  }

  .check_icon--empty {
    display: block;
  }

  .check_icon--filled {
    display: none;
  }

  .icon_wrapper {
    transition: all 0.1s ease-in-out;
    display: flex;
    align-items: center;
  }

  input:checked + .icon_wrapper {
    .check_icon--empty {
      display: none;
    }

    .check_icon--filled {
      display: block;
    }
  }

  span,
  .icon_wrapper {
    margin-top: -2rem;
  }
`;
