import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import AvatarUpload from "common/components/AvatarChange/AvatarUpload";
import Button from "common/components/Button";
import { Counter } from "common/components/DescriptionEditModal/DescriptionEditModal.styled";
import TextArea from "common/components/TextAreaHookForms/TextArea";
import { useSub } from "common/hooks/useSub";
import { useUploadImage } from "components/Profile/Summary/UserImage/useUploadImage";
import {
  useGetProfileQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
  useUpdateUserMutation,
} from "store/user/userApi";
import styled from "styled-components/macro";
import { object, string } from "yup";

const ProfileCreationInfoStyles = styled.form`
  margin: 2rem 0;
  max-width: 600px;
  width: 100%;
  gap: 2rem;
  display: flex;
  height: 500px;
  flex-direction: column;
  div:first-of-type:not(:last-of-type) {
    width: 100%;
  }

  label:first-child {
    font-weight: 600;
  }

  .checkbox {
    margin-top: 2rem;
  }

  .avatar-upload {
    width: 100%;
    padding: 0;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    button {
      height: max-content;
      width: max-content;
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
    a {
      text-decoration: none;
      color: inherit;
    }

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const ProfileCreationInformation = ({
  onOpenModal,
}: {
  onOpenModal: () => void;
}) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });
  const { data: profile } = useGetProfileQuery(
    {
      sub,
      language: user?.language,
    },
    {
      skip: !sub || !user?.language,
    }
  );
  const [updateProfile] = useUpdateProfileMutation();
  const [updateUser] = useUpdateUserMutation();

  const avatarProps = useUploadImage();

  const imageRequirements = [
    { key: "size", text: "Maksymalny rozmiar zdjęcia to: 2 MB" },
  ];

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(
      object().shape({
        userDescription: string()
          .max(150, "Zbyt długi opis. Możesz wpisać maksymalnie 150 znaków")
          .required("Pole wymagane"),
      })
    ),
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      userDescription: "",
    },
  });

  const userDescriptionValue = useWatch({ control, name: "userDescription" });

  const onSubmit = handleSubmit(async (profileData) => {
    await updateProfile({
      sub,
      language: user.language,
      profileData,
    });
    await updateUser({ sub, userData: { profileFormSubmitted: true } });
    onOpenModal();
  });

  useEffect(() => {
    if (profile?.userDescription) {
      setValue("userDescription", profile.userDescription);
    }
  }, [profile?.userDescription, setValue]);

  return (
    <ProfileCreationInfoStyles onSubmit={onSubmit}>
      <label htmlFor="userDescription">Dodaj krótki opis o sobie</label>
      <TextArea
        label="Opis"
        touched
        name="userDescription"
        control={control}
        id="userDescription"
        placeholder="Opis"
        isValid={Boolean(!errors.userDescription)}
        validationText={errors.userDescription?.message}
        mb={"0"}
      />
      <Counter id="counter" error={Boolean(userDescriptionValue.length > 150)}>
        {userDescriptionValue?.length || 0}/150
      </Counter>
      <AvatarUpload
        imageSrc={avatarProps.imageUrl}
        imageUploadedUrl={avatarProps.uploadedUrl}
        onCancelUpload={avatarProps.cancelUpload}
        onImageUpload={avatarProps.imageUpload}
        onSaveImage={avatarProps.saveImage}
        uploadProgress={avatarProps.progress}
        className="avatar-upload"
        error={avatarProps.error}
        imageDefault="/icons/user-avatar-placeholder.svg"
        imageRequirements={imageRequirements}
        subtitle="Przeciągnij plik bezpośrednio na pole poniżej lub wybierz z komputera. Maksymalny rozmiar zdjęcia to 2MB."
        title="Dodaj zdjęcie profilowe"
      />
      <footer>
        <Link to="/create-profile?step=2">
          <Button type="button" variant="outlined">
            Wstecz
          </Button>
        </Link>
        <Button type="submit">Zakończ</Button>
      </footer>
    </ProfileCreationInfoStyles>
  );
};
