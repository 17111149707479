import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface OpenMenuButtonProps {
  image: string;
}

const OpenMenuButton = styled.div<OpenMenuButtonProps>`
  height: 48px;
  width: 48px;
  border-radius: 48px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  background: url(${(props) => props.image}) no-repeat center center / cover;
`;

const MenuBox = styled.div`
  width: 248px;
  background-color: ${theme.color.basicWhite};
  position: absolute;
  top: 72px;
  right: 24px;
  padding: 16px 0 12px 12px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 8px 15px -5px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  user-select: none;
`;

const MenuButton = styled.div`
  width: 100%;
`;

const MenuLine = styled.div`
  width: 260px;
  border-top: 1px solid ${theme.color.darkGray};
  margin: 8px 0 8px -12px;
`;

export { OpenMenuButton, MenuBox, MenuLine, MenuButton };
