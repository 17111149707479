import React from "react";

import IconButton from "common/components/IconButton";
import { theme } from "common/theme/theme";

type EditImageIconButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const EditImageIconButton = ({
  className,
  onClick,
}: EditImageIconButtonProps) => {
  return (
    <IconButton
      className={className}
      onClick={onClick}
      icon="/icons/camera_icon2.svg"
      height={24}
      width={24}
      imageHeight={12}
      imageWidth={13}
      backgroundColor={theme.color.basicWhite}
      radius="50%"
      imageRadius="0"
      label="camera_icon"
    />
  );
};

export default EditImageIconButton;
