import React from "react";
import { useDispatch } from "react-redux";

import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { ModalProps } from "common/components/Modal/types";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";
import { notesAction } from "store/notes/notesSlice";

import { ModalButtonsWrapper, ModalContentWrapper } from "../Note/Note.styled";

interface DeleteNoteModalProps {
  deleteAction: () => void;
  modalProps: ModalProps;
  redirection: boolean;
}

const DeleteNoteModal = ({
  deleteAction,
  modalProps,
  redirection,
}: DeleteNoteModalProps) => {
  const dispatch = useDispatch();
  const finalDelete = () => {
    deleteAction();
    modalProps.closeModal();
    if (redirection) {
      dispatch(notesAction.cancelNote());
    }
  };
  return (
    <ModalPortal {...modalProps}>
      <ModalContentWrapper
        width="369px"
        height="200px"
        alignItems="center"
        justifyContent="space-around"
      >
        <Text size="20px" weight="bold" lineHeight="20px">
          Czy chcesz usunąć notatkę?
        </Text>

        <Text size="14px" weight="normal" lineHeight="20px">
          Usuwając notatkę stracisz ją bezpowrotnie. Czy na pewno chcesz to
          zrobić?
        </Text>
        <ModalButtonsWrapper>
          <GhostButton onClick={modalProps.closeModal}>Anuluj</GhostButton>
          <SmallButton onClick={finalDelete}>Tak, usuń</SmallButton>
        </ModalButtonsWrapper>
      </ModalContentWrapper>
    </ModalPortal>
  );
};

export default DeleteNoteModal;
