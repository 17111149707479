import React, { useMemo, useState } from "react";

import { theme } from "common/theme/theme";
import { useGetFileQuery } from "store/upload/uploadApi";

import Image from "../Image";

import {
  ArrowWrapper,
  AttachmentSelectedPhotoWrapper,
  AttachmentSliderBottomWrapper,
  AttachmentSliderWrapper,
  SelectedPhoto,
  SelectedPhotoTitle,
  SliderCloseButton,
} from "./AttachmentSlider.styled";
import AttachmentSliderBottomItem from "./AttachmentSliderBottom/AttachmentSliderBottomItem";

interface AttachmentSliderProps {
  attachments: string[];
  closeModal: () => void;
}

const AttachmentSlider = ({
  attachments,
  closeModal,
}: AttachmentSliderProps) => {
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(0);
  const { data } = useGetFileQuery({
    fileUrl: attachments[selectedAttachmentIndex],
  });
  const [isHover, setIsHover] = useState(false);
  const handleHover = () => {
    setIsHover((isHover) => !isHover);
  };

  const handleSelectAttachment = (index: number) => {
    setSelectedAttachmentIndex(index);
  };

  const moveForward = () => {
    setSelectedAttachmentIndex((attachmentIndex) => ++attachmentIndex);
  };

  const moveBackward = () => {
    setSelectedAttachmentIndex((attachmentIndex) => --attachmentIndex);
  };

  const filename = useMemo(() => {
    const splitAttachment = attachments[selectedAttachmentIndex].split("/");
    return splitAttachment[splitAttachment.length - 1];
  }, [selectedAttachmentIndex, attachments]);

  const closeModalWhenClickOnParent = (event: React.MouseEvent) => {
    event.target === event.currentTarget && closeModal();
  };

  return (
    <AttachmentSliderWrapper
      onClick={(event) => closeModalWhenClickOnParent(event)}
    >
      <AttachmentSelectedPhotoWrapper
        onClick={(event) => closeModalWhenClickOnParent(event)}
      >
        <SelectedPhoto
          photo={data}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        >
          <SliderCloseButton isShown={isHover} onClick={closeModal}>
            <Image width={14} height={14} src="/icons/close_big.svg" />
          </SliderCloseButton>
          <SelectedPhotoTitle
            shouldShowImageTitle={isHover}
            color={theme.color.basicWhite}
          >
            {filename}
          </SelectedPhotoTitle>
          <ArrowWrapper
            isShown={isHover && selectedAttachmentIndex !== 0}
            leftSide={true}
            onClick={moveBackward}
          >
            <Image src="/icons/arrow-left.svg" height={12} width={7} />
          </ArrowWrapper>
          <ArrowWrapper
            isShown={
              isHover && selectedAttachmentIndex !== attachments.length - 1
            }
            leftSide={false}
            onClick={moveForward}
          >
            <Image src="/icons/arrow-right.svg" height={12} width={7} />
          </ArrowWrapper>
        </SelectedPhoto>
      </AttachmentSelectedPhotoWrapper>
      <AttachmentSliderBottomWrapper>
        {attachments.map((attachment, index) => (
          <AttachmentSliderBottomItem
            attachment={attachment}
            isSelected={attachment === attachments[selectedAttachmentIndex]}
            key={attachment}
            selectAttachment={() => handleSelectAttachment(index)}
          />
        ))}
      </AttachmentSliderBottomWrapper>
    </AttachmentSliderWrapper>
  );
};

export default AttachmentSlider;
