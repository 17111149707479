import React, { FunctionComponent, PropsWithChildren } from "react";

import Image from "common/components/Image";

import {
  BodyWithModalStyles,
  CloseButtonStyled,
  ModalContainerStyled,
} from "./modal.styles";
import { ModalProps } from "./types";

export interface ModalContainerProps
  extends PropsWithChildren,
    Omit<ModalProps, "isVisible"> {
  centerFixed: boolean;
}

export const ModalContainer: FunctionComponent<ModalContainerProps> = ({
  children,
  closeModal,
  withCloseButton = true,
  withPadding = true,
  closeButtonPosition,
  centerFixed = false,
}) => (
  <>
    <BodyWithModalStyles />
    <ModalContainerStyled
      withPadding={withPadding}
      centerFixed={centerFixed}
      data-testid="modal-container"
      closeButtonPosition={closeButtonPosition}
    >
      {withCloseButton && (
        <CloseButtonStyled data-testid="close-modal" onClick={closeModal}>
          <Image
            layout="fixed"
            height={14}
            src="/images/close-modal-icon.svg"
            width={14}
            cursor="pointer"
          />
        </CloseButtonStyled>
      )}
      <div>{children}</div>
    </ModalContainerStyled>
  </>
);
