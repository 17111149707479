import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import styled from "styled-components/macro";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

const BorderWrapper = styled.div`
  margin-top: 20px;
  margin-left: 10px;
`;

export default function FontItem() {
  const iconSrc = `/icons/down.svg`;

  return (
    <BorderWrapper>
      <NoteKitBorderWrapper p="8px 11px">
        <Text size="14px">Time New Roman</Text>
        <Text>
          <Image src={iconSrc} alt="icon" width={13} height={13} />
        </Text>
      </NoteKitBorderWrapper>
    </BorderWrapper>
  );
}
