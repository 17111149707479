import React, { useCallback, useEffect, useState } from "react";

import { useGetCommentsQuery } from "store/group/groupApi";
import { IPost } from "store/group/groupApi.types";
import { useAppSelector } from "store/hooks";

import { SubCommentWrapper } from "./PostSubComment.styled";
import SubCommentList from "./SubCommentList/SubCommentList";

interface PostSubCommentProps {
  currentCommentId: string;
}

const PostSubComment = ({ currentCommentId }: PostSubCommentProps) => {
  const [subCommentList, setSubCommentList] = useState<IPost[]>();
  const [page, setPage] = useState(0);
  const [subCommentWasCreated, setValue] = useState(false);
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const { data: subComments } = useGetCommentsQuery(
    {
      groupId: currentGroup?.groupId,
      page: page,
      rows: 4,
      parentId: currentCommentId,
    },
    { skip: currentGroup?.groupId === undefined }
  );

  const {
    refetch: refetchAfterCreation,
    data: commentAfterCreation,
    isSuccess: commentWasSuccessfullyCreated,
  } = useGetCommentsQuery(
    {
      groupId: currentGroup?.groupId,
      page: 0,
      rows: 1,
      parentId: currentCommentId,
    },
    { skip: !subCommentWasCreated }
  );

  const setSubCommentWasCreated = (value: boolean) => {
    setValue(value);
  };

  const handlePageAddition = useCallback(() => {
    setPage((page) => ++page);
  }, []);

  function mergeSubComments(prev: IPost[] | undefined, next: IPost[]): IPost[] {
    const subCommentMap = new Map<string, IPost>();

    if (prev !== undefined) {
      prev.forEach((subComment) => {
        subCommentMap.set(subComment.postId, subComment);
      });
    }

    next.forEach((subComment) => {
      subCommentMap.set(subComment.postId, subComment);
    });

    return Array.from(subCommentMap.values());
  }

  useEffect(() => {
    setSubCommentList((prevComments) => {
      if (prevComments === undefined) {
        return subComments;
      }
      if (page === 0) return prevComments;
      return mergeSubComments(prevComments, subComments || []);
    });
  }, [subComments, page]);

  useEffect(() => {
    setSubCommentList((prevComments) => {
      if (commentWasSuccessfullyCreated) {
        return mergeSubComments(prevComments, commentAfterCreation || []);
      }
      return prevComments;
    });
  }, [commentAfterCreation, commentWasSuccessfullyCreated]);
  return (
    <SubCommentWrapper>
      <SubCommentList
        subCommentList={subCommentList}
        commentId={currentCommentId}
        addPage={handlePageAddition}
        refetchSubComments={refetchAfterCreation}
        setSubCommentWasCreated={setSubCommentWasCreated}
      />
    </SubCommentWrapper>
  );
};

export default PostSubComment;
