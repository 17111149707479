import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getJwtToken } from "common/utils/auth/getToken";
import { convertGroupFromDto, IGroup } from "components/Group/Types/group";
import { IEditGroupStatusRequest } from "services/api/group/editGroup";
import { GroupResponseDto } from "services/api/group/groupApi";
import {
  GroupApiRequest,
  GroupApiResponse,
} from "services/api/shared/apiResponse";

import { GROUP_API_CONSTANTS } from "./groupApi.constants";
import { mapPostDto, mapPostsDto } from "./groupApi.mappers";
import {
  ICreatePostRequest,
  ICreatePostResponse,
  ICreatePostResponseDto,
  IPost,
} from "./groupApi.types";
import { IPostDto } from "./groupApi.types.DTO";

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_NEURON_API_URL_BLUE}/${GROUP_API_CONSTANTS.GROUP_PATH}`,
    prepareHeaders: async (headers) => {
      const token = await getJwtToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [GROUP_API_CONSTANTS.GET_POST_TAG],
  endpoints: (builder) => ({
    getPosts: builder.query<
      IPost[],
      { groupId: string; page: number; rows: number }
    >({
      query: ({ groupId, rows, page }) => {
        const params = new URLSearchParams({
          rows: rows.toString(),
          page: page.toString(),
        });
        return `/${groupId}/${GROUP_API_CONSTANTS.POSTS_PATH}?${params}`;
      },
      providesTags: [GROUP_API_CONSTANTS.GET_POST_TAG],
      transformResponse: (response: GroupApiResponse<IPostDto[]>) => {
        return mapPostsDto(response.body.message);
      },
    }),
    getComments: builder.query<
      IPost[],
      { groupId: string; page: number; rows: number; parentId: string }
    >({
      query: ({ groupId, rows, page, parentId }) => {
        const params = new URLSearchParams({
          rows: rows.toString(),
          page: page.toString(),
          parent_id: parentId,
        });

        return `/${groupId}/${GROUP_API_CONSTANTS.POSTS_PATH}?${params}&comments=true`;
      },
      providesTags: [GROUP_API_CONSTANTS.GET_POST_TAG],
      transformResponse: (response: GroupApiResponse<IPostDto[]>) => {
        return mapPostsDto(response.body.message);
      },
    }),
    getPostById: builder.query<IPost, { groupId: string; postId: string }>({
      query: ({ groupId, postId }) =>
        `/${groupId}/${GROUP_API_CONSTANTS.POSTS_PATH}?post_id=${postId}`,
      transformResponse: (response: GroupApiResponse<IPostDto>) => {
        return mapPostDto(response.body.message[0]);
      },
    }),
    createPost: builder.mutation<
      GroupApiResponse<ICreatePostResponse>,
      {
        postData: GroupApiRequest<ICreatePostRequest>;
      }
    >({
      invalidatesTags: [GROUP_API_CONSTANTS.GET_POST_TAG],
      query: ({ postData }) => ({
        url: `/${GROUP_API_CONSTANTS.POSTS_PATH}`,
        method: "POST",
        body: postData,
      }),
      transformResponse: (
        response: GroupApiResponse<ICreatePostResponseDto>
      ) => {
        const transformed: GroupApiResponse<ICreatePostResponse> = {
          body: {
            header: response.body.header,
            message: {
              attachments: response.body.message.attachments,
              postId: response.body.message.post_id,
              reactions: response.body.message.reactions,
            },
          },
        };
        return transformed;
      },
    }),
    editGroup: builder.mutation<
      {},
      { editGroupData: GroupApiRequest<IEditGroupStatusRequest> }
    >({
      query: ({ editGroupData }) => ({
        url: ``,
        method: "PATCH",
        body: editGroupData,
      }),
    }),
    getGroupInformation: builder.query<IGroup, { groupId: string }>({
      query: ({ groupId }) => ({
        url: `/info/${groupId}`,
        method: "GET",
      }),
      transformResponse: (response: GroupApiResponse<GroupResponseDto>) => {
        const transformed: IGroup = convertGroupFromDto(response.body.message);
        return transformed;
      },
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetPostByIdQuery,
  useGetCommentsQuery,
  useCreatePostMutation,
  useEditGroupMutation,
  useGetGroupInformationQuery,
} = groupApi;
