import React from "react";

import GroupPlainButton from "components/Group/Common/GroupPlainButton/GroupPlainButton";

import {
  GroupCloseButtonsWrapper,
  GroupCloseModalHeader,
} from "../GroupCloseModal.styled";

import {
  GroupCloseModalParagraph,
  GroupCloseModalWrapper,
} from "./GroupCloseModalFirstStage.styled";

interface GroupCloseModalFirstStageProps {
  goToArchiveStage: () => void;
  goToFinalStage: () => void;
}

const GroupCloseModalFirstStage = ({
  goToArchiveStage,
  goToFinalStage,
}: GroupCloseModalFirstStageProps) => {
  return (
    <GroupCloseModalWrapper>
      <GroupCloseModalHeader data-testid="archive-header">
        Czy chcesz zarchiwizować komponenty grupy?
      </GroupCloseModalHeader>
      <GroupCloseModalParagraph>
        Zarchiwizowane komponenty można później przywrócić.
      </GroupCloseModalParagraph>
      <GroupCloseButtonsWrapper>
        <GroupPlainButton
          width="50px"
          height="30px"
          onClick={goToFinalStage}
          isProceedButton={false}
          testId="close-delete-modal-btn"
          buttonText="Nie"
        />
        <GroupPlainButton
          width="50px"
          height="30px"
          onClick={goToArchiveStage}
          isProceedButton={true}
          testId="go-to-archive-stage-btn"
          buttonText="Tak"
        />
      </GroupCloseButtonsWrapper>
    </GroupCloseModalWrapper>
  );
};

export default GroupCloseModalFirstStage;
