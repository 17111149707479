import React, { useState } from "react";

import Button from "common/components/Button";
import Flex from "common/components/Flex";
import Image from "common/components/Image";
import { AbsContainer } from "common/components/Layout/Containers";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import TaskCategory from "common/components/TagsAndLabels/TaskCategory/TaskCategory";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { ICategory } from "store/tasker/models/Category";

import AddCategoriesForm from "./AddCategoriesForm/AddCategoriesForm";

export interface CategoriesFieldProps {
  added: ICategory[];
  closeModalAndAddCategories: (label: ICategory[]) => void;
}

function CategoriesField({
  added,
  closeModalAndAddCategories,
}: CategoriesFieldProps) {
  const [openForm, setOpenForm] = useState(false);

  return (
    <RelativeContainer w="auto">
      {openForm && (
        <AbsContainer top="-190px">
          <AddCategoriesForm
            setOpenForm={() => setOpenForm(!openForm)}
            added={added}
            closeModalAndAddCategories={closeModalAndAddCategories}
          />
        </AbsContainer>
      )}
      <Button
        variant="outlined"
        padding="8px 2px"
        border="none"
        display="flex"
        width="100px"
        onClick={() => setOpenForm(!openForm)}
      >
        <Image
          src="/icons/add_label.svg"
          alt="add_label"
          width={20}
          height={20}
        />
        <Text
          size="14px"
          weight="bold"
          letterSpacing="0.2px"
          color={theme.color.basicBlack}
        >
          {added?.length > 0 ? "Etykiety" : "Dodaj etykietę"}
        </Text>
      </Button>
      <AbsContainer w="auto">
        <Flex>
          {added?.map((l, idx) => (
            <TaskCategory
              key={l.id}
              text={l.name}
              p={"5px 26px"}
              colorIdx={idx}
            />
          ))}
        </Flex>
      </AbsContainer>
    </RelativeContainer>
  );
}

export default CategoriesField;
