import DatePicker from "react-date-picker/dist/entry.nostyle";

import "react-date-picker/dist/DatePicker.css";

import { theme } from "common/theme/theme";
import styled, { css } from "styled-components/macro";

interface DatePickerWrapperProps {
  mt?: string;
  mb?: string;
}

export const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.mt || "0"};
  margin-bottom: ${(props) => props.mb || "0"};
`;

export const ValidationLabel = styled.label<{ isValid: boolean }>`
  position: absolute;
  font-size: 14px;
  color: ${({ isValid }) =>
    !isValid ? theme.color.alertRed : theme.color.basicBlack};
  top: 44px;
  bottom: 0;
  left: 0;
  padding-top: 10px;
`;

export const PlaceholderLabel = styled.label<{ isValid: boolean }>`
  position: absolute;
  transition: all 0.3s;
  // todo: why z-index -1? it cause problem with label visibility
  /* z-index: -1; */
  font-size: 16px;
  color: ${({ isValid }) =>
    !isValid ? theme.color.alertRed : theme.color.basicBlack};
  top: -5px;
  left: 0;
  padding-top: 14px;
`;

interface DatePickerProps {
  isValid: boolean;
  width?: string;
  isActive: boolean;
  isClicked: boolean;
}

export const StyledDatePicker = styled(DatePicker)<DatePickerProps>`
  position: relative;
  width: ${(p) => p.width || "100%"};
  padding: 0px 0px 0px 10px;
  cursor: pointer;
  background: none;
  text-align: left;
  border: none;
  outline: 0;
  border-radius: 4px;
  border: 1px solid ${theme.color.slightlyLightGray};

  border: 1px solid
    ${(p) => {
      if (p.isClicked && p.isValid !== null) {
        if (p.isValid === true && !p.isActive)
          return theme.color.slightlyLightGray;
        if (p.isValid === false && !p.isActive) return theme.color.alertRed;
      }
      if (p.isActive) return theme.color.basicBlack;
    }};

  color: ${theme.color.darkGray};

  /* ------------react-date-picker---------- */

  & .react-date-picker__wrapper {
    border: none;
    padding: 0;
  }

  & .react-date-picker__clear-button {
    display: none;
  }

  & .react-calendar {
    width: 310px;
    height: 310px;
    padding: 8px;
    max-width: 100%;
    background-color: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  box-sizing: border-box;
  & .react-date-picker__wrapper {
    border: none;
    padding: 0;
  }

  & .react-date-picker__clear-button {
    display: none;
  }

  & .react-calendar {
    width: 310px;
    height: 310px;
    padding: 8px;
    max-width: 100%;
    background-color: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    box-sizing: border-box;
  }

  .react-calendar button {
    border: 0;
    outline: none;
  }

  & .react-date-picker__calendar {
    width: 310px;
  }

  & .react-date-picker__calendar-button {
    padding: 0;
    margin-top: 1rem;
    margin-right: 0.25rem;

    & svg {
      height: 18px;
    }
  }

  .react-date-picker__button .react-date-picker__button__icon {
    color: #1d1d1d;
  }

  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: #1d1d1d;
  }

  /* years */

  .react-calendar__decade-view__years__year {
    margin: 12px 0;
    min-width: 70px;
    padding: 12px 0;
    border-radius: 18px;
    font-size: 14px;
    background-color: white;

    &:hover {
      background-color: ${theme.color.veryLightGray};
    }
  }

  .react-calendar__decade-view__years > .react-calendar__tile--hasActive {
    background-color: ${theme.color.darkGray};
    color: white;

    &:hover {
      background-color: ${theme.color.darkGray};
      color: white;
    }
  }

  /* month */

  .react-calendar__year-view__months__month {
    padding: 10px 0;
    background-color: white;

    & abbr {
      display: inline-block;
      min-width: 70px;
      padding: 12px;
      border-radius: 18px;
      font-size: 14px;
      background-color: white;
    }

    &:hover {
      background-color: transparent;
    }

    &:hover > abbr {
      background-color: ${theme.color.veryLightGray};
    }
  }

  .react-calendar__year-view__months > .react-calendar__tile--hasActive {
    background-color: white;

    &:hover abbr,
    & abbr {
      background-color: ${theme.color.darkGray};
      color: white;
    }
  }

  /* day */

  .react-calendar__month-view__days__day {
    padding: 0;
    background-color: white;

    & abbr {
      display: inline-block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      border-radius: 50%;
      font-size: 14px;
    }

    &:hover {
      background-color: transparent;
    }

    &:hover > abbr {
      background-color: ${theme.color.veryLightGray};
    }
  }

  .react-calendar__month-view__days > .react-calendar__tile--active {
    background-color: white;

    &:hover > abbr,
    & abbr {
      background-color: ${theme.color.darkGray};
      color: white;
    }
  }

  /* day names */

  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;

    & abbr {
      text-decoration: none;
      font-family: "Open Sans", sans-serif;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }

  /* today */

  .react-calendar__tile--now {
    background-color: transparent;
  }

  /* ---------- */

  & .react-calendar__tile,
  .react-date-picker__inputGroup {
    color: ${theme.color.mediumGray};

    &:disabled {
      background-color: transparent;
      color: ${theme.color.slightlyLightGray};

      &:hover > abbr {
        background-color: transparent;
      }
    }
  }

  & .react-date-picker__inputGroup__input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero {
    position: relative;
    padding-top: 14px;
    padding-bottom: 10px;
    display: inline-block;
    bottom: 14px;
  }

  .react-date-picker__inputGroup__divider:hover {
    cursor: auto;
  }

  & button {
    outline: none;
    font-family: "Open Sans", sans-serif;
  }

  & .react-date-picker__inputGroup__year,
  .react-date-picker__inputGroup__day,
  .react-date-picker__inputGroup__month {
    outline: none;
    background-color: transparent;
    color: ${theme.color.mediumGray};
  }

  /* color neighboring month days */

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${theme.color.darkerGray};
  }

  /* navigation */

  .react-calendar__navigation {
    margin-bottom: 0px;
    display: flex;
    height: 44px;
    font-family: "Open Sans", sans-serif;

    & button {
      min-width: 44px;
      background: none;
    }

    & button:enabled:hover {
      cursor: pointer;
      background-color: white;
    }
  }

  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    color: ${theme.color.mediumGray};
    padding-left: 0;
    padding-right: 0;

    &:disabled {
      color: ${theme.color.slightlyLightGray};
    }
  }

  //label animat

  .react-date-picker__inputGroup {
    opacity: ${(props) => (props.isActive || props.value ? 1 : 0)};
  }

  ${(props) =>
    (props.isActive || props.value) &&
    css`
      &:not(:placeholder-shown),
      &:focus {
        & + ${PlaceholderLabel} {
          top: -35px;
          font-size: 87.5%;
        }
      }
    `};
`;

export default StyledDatePicker;
