import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const PlaceholderLabel = styled.label<{
  isValid: boolean;
  touched?: boolean;
}>`
  position: absolute;
  transition: all 0.3s;
  font-size: 16px;
  color: ${({ isValid }) =>
    isValid === false ? theme.color.alertRed : theme.color.basicBlack};
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 10px;
  cursor: pointer;
`;

interface InputProps {
  isValid?: boolean;
  width?: string;
  clicked?: boolean;
  noteTitle?: boolean;
}

export const Input = styled.input<InputProps>`
  width: ${(p) => p.width || "100%"};
  padding: 14px 0px;
  cursor: pointer;
  background: none;
  text-align: left;
  border: none;
  outline: 0;
  ${(p) => {
    if (p.noteTitle) {
      return `
        font-weight: bold;
        font-size: 25px
      `;
    }
  }};
  border-bottom: 2px solid
    ${(p) => {
      if (p.noteTitle) {
        return "transparent";
      }
      if (p.clicked && p.isValid !== null) {
        return p.isValid ? theme.color.formGreen : theme.color.alertRed;
      } else {
        return theme.color.darkGray;
      }
    }};
  color: ${theme.color.mediumGray};
  &:focus {
    outline: none;
    border-bottom: 2px solid
      ${(p) =>
        p.isValid === true
          ? theme.color.formGreen
          : p.isValid === false
          ? theme.color.alertRed
          : theme.color.darkGray};
  }
  ${(p) => {
    if (!p.noteTitle) {
      return `
      &::placeholder {
        color: transparent;
      }`;
    }
  }};

  &:not(:placeholder-shown),
  &:focus {
    & + ${PlaceholderLabel} {
      top: -23px;
      font-size: 87.5%;
    }
  }

  &:-webkit-autofill {
    box-shadow: inset 50px 50px 0 ${theme.color.basicWhite},
      inset -50px -50px 0 ${theme.color.basicWhite};
    -webkit-text-fill-color: ${theme.color.mediumGray} !important;
  }
`;

export const ValidationLabel = styled.label<{
  isValid: boolean;
  top?: string;
}>`
  position: absolute;
  font-size: 14px;
  color: ${({ isValid }) =>
    isValid === false ? theme.color.alertRed : theme.color.basicBlack};
  top: ${(p) => p.top ?? "40px"};
  bottom: 0;
  left: 0;
  padding-top: 10px;
`;
