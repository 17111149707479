export const groupConfig = {
  defaultImage: "/images/acornDefault.png",
  descriptionLength: 150,
  groupAvatarUpload: {
    groupAvatarUploadLoadingCounter: 3,
    groupAvatarUploadTimeWindow: 150,
    groupAvatarUploadOneFrameProgress: 27,
    groupAvatarPreUploadStageSubtitle:
      "Przeciągnij plik bezpośrednio na pole poniżej",
    groupAvatarUplaodStageSubtitle:
      "Zdjęcie twojej grupy będzie wyglądało następująco",
    error: {
      exceededLimit:
        "Błędny rozmiar pliku zdjęcia grupy. Wczytaj plik o maksymalnym rozmiarze 2MB.",
      wrongFormat:
        "Błędny format pliku zdjęcia grupy. Wczytaj plik w formacie .png lub .jpg",
      wrongFormatAndExceededLimit:
        "Błędny rozmiar i format pliku. Wczytaj zdjęcie .png lub .jpg w rozmiarze do 2MB.",
    },
  },
};
