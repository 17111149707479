import React from "react";

import { ButtonProps } from "../Button/Button";

import { GhostButton as StyledGhostButton } from "./GhostButton.styled";

const GhostButton = ({ ...props }: ButtonProps) => {
  return <StyledGhostButton {...props} />;
};

export default GhostButton;
