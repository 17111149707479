import React from "react";

import Flex from "common/components/Flex";
import Text from "common/components/Text";

interface EmptyStateProps {
  enteredPhrase: string;
}

const EmptyState = ({ enteredPhrase }: EmptyStateProps) => {
  return (
    <Flex direction="column">
      <Text align="center">
        <Text weight="bold" size="20px">
          Brak wyników
        </Text>
        <Text mt="16px" size="14px" data-testid="empty-state-message">
          Nie udało nam się znaleźć użytkownika {enteredPhrase.trim()}. Sprawdź
          pisownię lub spróbuj poszukać kogoś innego.
        </Text>
      </Text>
    </Flex>
  );
};

export default EmptyState;
