import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { InitialAuthorization } from "./types";

export const authSlice = createSlice({
  name: "auth-slice",
  initialState: {
    isAuthenticated: null,
    isBeingLoggedIn: false,
    loginError: "",
  } as InitialAuthorization,
  reducers: {
    setAuth(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },

    setBeingLoggedIn(state, action: PayloadAction<boolean>) {
      state.isBeingLoggedIn = action.payload;
    },

    setLoginError(state, action) {
      state.loginError = action.payload;
    },

    setUserMailToRemindPassword(state, action) {
      state.userMailToRemindPassword = action.payload;
    },
  },
});

export const authAction = authSlice.actions;
export const authReducer = authSlice.reducer;
