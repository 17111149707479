import React from "react";

import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import styled from "styled-components/macro";

const Styles = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  max-width: 600px;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      margin: 1rem auto;
    }
  }

  main {
    img {
      width: 100%;
      max-width: 20rem;
    }
  }

  footer {
    margin-top: 2rem;
    button {
      padding: 0 1rem;
    }
  }
`;

export const RegistrationFinishedModal = ({
  onClose,
}: {
  onClose?: () => void;
}) => {
  return (
    <Styles>
      <header>
        <Heading>Rejestracja zakończona pomyślnie!</Heading>
        <Text>
          Wkrótce otrzymasz dalsze instrukcje dotyczące <br />
          aktywacji konta na podanego maila
        </Text>
      </header>
      <main>
        <img src="images/rocket.svg" alt="fancy rocket" title="fancy rockets" />
      </main>
      <footer>
        <Button type="button" onClick={onClose}>
          Przejdź do platformy
        </Button>
      </footer>
    </Styles>
  );
};
