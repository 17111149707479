import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Flex from "common/components/Flex";
import CancelAcceptButtons from "common/components/Forms/Buttons/CancelAcceptButtons";
import { Container } from "common/components/Layout/Containers";
import { AbsValidationLabel } from "common/components/Layout/Form/ValidationLabel";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import * as yup from "yup";

import { IList } from "../../../../../store/tasker/models/List";

import { ModalCreateListInput } from "./ModalCreateListForm.styled";

const maxLen = 30;

export interface Props {
  handleCloseModalAndAccept: (name: string, onChange: any) => void;
  handleCloseModalAndCancel: () => void;
  onChange: any;
  value: string;
  alreadyExistingLists: IList[];
}

export interface INewList {
  name: string;
}

const config = {
  nameMinLength: 3,
  nameMaxLength: 30,
  descriptionMaxLength: 500,
};

const TaskListCreationSchema = (alreadyExistingLists: IList[]) =>
  yup.object().shape({
    name: yup
      .string()
      .required("Nazwa listy jest wymagana")
      .min(
        config.nameMinLength,
        `Wpisana nazwa jest za krótka, musi zawierać minimum ${config.nameMinLength} znaki `
      )
      .max(
        config.nameMaxLength,
        `Wpisana nazwa jest za długa, może zawierać maksymalnie ${config.nameMaxLength} znaków`
      )
      .matches(
        /^[a-zA-Z0-9-żźćńółęąśŻŹĆĄŚĘŁÓŃ ]*$/,
        "Nazwa grupy może zawierać tylko litery, cyfry i myślniki"
      )
      .notOneOf(
        alreadyExistingLists.map((l) => l.name),
        "Taka lista już istnieje"
      ),
  });

export default function ModalCreateListForm({
  handleCloseModalAndAccept,
  handleCloseModalAndCancel,
  onChange,
  value,
  alreadyExistingLists,
}: Props) {
  const [firstBlur, setFirstBlur] = useState(false);
  const [firstSubmit, setFirstSubmit] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    getValues,
    trigger,
    setValue,
  } = useForm<INewList>({
    resolver: yupResolver(TaskListCreationSchema(alreadyExistingLists)),
    mode: "all",
    defaultValues: {
      name: value ?? "",
    },
  });

  const lettersLeft = `${maxLen - getValues("name").length}/${maxLen}`;

  const handleAccept = async () => {
    setFirstSubmit(true);
    setFirstBlur(true);
    await trigger();
    if (isValid) {
      handleCloseModalAndAccept(getValues("name"), onChange);
    }
  };

  return (
    <Container w="534px" p="10px">
      <Flex
        width="100%"
        align="center"
        justify="center"
        height="40px"
        mb="20px"
      >
        <Text size="20px" weight="semibold" color={theme.color.darkGray}>
          Utwórz listę
        </Text>
      </Flex>
      <Flex direction="column" position="relative">
        <ModalCreateListInput
          {...register("name")}
          type="text"
          onChange={(e) => {
            setValue("name", e.target.value);
            trigger();
          }}
          id="name"
          name="name"
          width="100%"
          placeholder="Nazwa listy"
          isValid={isValid || (!isValid && !firstBlur)}
          onBlur={() => {
            setFirstBlur(true);
            trigger();
          }}
        />
        {!isValid && (firstBlur || firstSubmit) && (
          <AbsValidationLabel top="55px" isInvalid={false} maxWidth="330px">
            <Text color={theme.color.alertRed} size="14px">
              {errors?.name?.message}
            </Text>
          </AbsValidationLabel>
        )}
        <AbsValidationLabel
          isInvalid={!isValid && (firstBlur || firstSubmit)}
          bottom={"-25px"}
          right="5px"
        >
          <Text
            color={
              !isValid && (firstBlur || firstSubmit)
                ? theme.color.alertRed
                : theme.color.basicBlack
            }
            size="14px"
          >
            {lettersLeft}
          </Text>
        </AbsValidationLabel>
      </Flex>
      <Container m="60px 0 0 0">
        <CancelAcceptButtons
          gap="24px"
          cancelButton={{
            handleCloseModalAndCancel: () => {
              handleCloseModalAndCancel();
            },
            cancelButtonBorder: "none",
          }}
          acceptButton={{
            handleCloseModalAndAccept: () => handleAccept(),
          }}
        />
      </Container>
    </Container>
  );
}
