import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { initTaskerGroupViewAsyncThunk } from "services/api/tasker";
import { useGetGroupInformationQuery } from "store/group/groupApi";
import {
  fetchGroupMembers,
  fetchGroupsList,
  groupActions,
} from "store/groupSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const useInitGroupView = () => {
  const dispatch = useAppDispatch();
  const taskerSelector = useAppSelector((s) => s.tasker);
  const params = useParams();
  const { data: currentGroup } = useGetGroupInformationQuery(
    { groupId: params.id },
    { skip: params.id === null || params.id === undefined }
  );
  const { groupId } = useCurrentGroupId();
  const initTaskerGroupView = useCallback(() => {
    dispatch(initTaskerGroupViewAsyncThunk(groupId));
    dispatch(fetchGroupsList());
  }, [dispatch, groupId]);

  useEffect(() => {
    if (currentGroup) {
      dispatch(groupActions.setChosenGroup(currentGroup));
      dispatch(fetchGroupMembers(currentGroup.groupId));
    }
  }, [currentGroup, dispatch]);

  useEffect(() => {
    if (taskerSelector.MainView === "Group") {
      if (!taskerSelector.groupViewTree.loaded) {
        initTaskerGroupView();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTaskerGroupView, taskerSelector.MainView]);
};
