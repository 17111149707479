import React from "react";

import MakeActionDropdown from "common/components/MakeActionDropdown/MakeActionDropdown";
import { useModal } from "common/components/Modal/use-modal";
import { theme } from "common/theme/theme";
import { getSub } from "store/auth/authUtils";

import DeleteCategoryModal from "../Modals/deleteCategoryModalContent";
import { EditCategoryModal } from "../Modals/editCategoryModalContent";

import { CategoryLabel, SubAccordionWrapper } from "./NotesRightPanel.styled";

interface NotesRightPanelSubCategoryProps {
  title: string;
  isSelected: boolean;
  key: string;
  selectCategory: () => void;
}

interface CategoryOptions {
  testid: string;
  img: string;
  text: string;
  action: any;
}

const NotesRightPanelSubCategory = ({
  title,
  isSelected,
  key,
  selectCategory,
}: NotesRightPanelSubCategoryProps) => {
  const addSubcategoryModal = useModal();
  const ownerId = getSub();
  const deleteSubCategoryModal = useModal();
  const editCategoryModal = useModal();

  const options: CategoryOptions[] = [
    {
      testid: "1",
      text: "Utwórz podkategorię",
      img: "/icons/addSign.svg",
      action: addSubcategoryModal.showModal,
    },
    {
      testid: "2",
      text: "Edytuj kategorię",
      img: "/icons/pen.svg",
      action: editCategoryModal.showModal,
    },
    {
      testid: "3",
      text: "Usuń kategorię",
      img: "/icons/bin.svg",
      action: deleteSubCategoryModal.showModal,
    },
  ];

  return (
    <>
      <DeleteCategoryModal
        ownerId={ownerId}
        itemId={key} // Change to itemId
        modalProps={deleteSubCategoryModal.modalProps}
        itemName={title} // Change to itemName
        isSubCategory={true} // Set to true for subcategory
      />

      <EditCategoryModal
        categoryId={key}
        modalProps={editCategoryModal.modalProps}
        ownerId={ownerId}
        onClose={editCategoryModal.modalProps.closeModal}
      />

      <>
        <SubAccordionWrapper
          tabIndex={0}
          isSelected={isSelected}
          onClick={selectCategory}
        >
          <CategoryLabel
            title={title}
            weight={isSelected ? "bold" : "normal"}
            size="14px"
            color={theme.color.basicBlack}
            mr="12px"
            lineHeight="20px"
            letterSpacing="0.4px"
          >
            {title}
          </CategoryLabel>
          <MakeActionDropdown dropdownMenuOptions={options} left="-200px" />
        </SubAccordionWrapper>
      </>
    </>
  );
};

export default NotesRightPanelSubCategory;
