import React, { ChangeEvent, useEffect, useState } from "react";

import Flex from "common/components/Flex";
import FormInput from "common/components/FormInput";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { ModalProps } from "common/components/Modal/types";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";
import { editCategoryThunk } from "store/categoriesSlice"; // Import the thunk
import { useAppDispatch, useAppSelector } from "store/hooks";

import {
  CategoryNameWrapper,
  Container,
} from "../../NotesPage/NotesRightPanel/AddCategoryModalContent/AddCategoryModalContent.styled";
import { validateCategoryName } from "../../NotesPage/NotesRightPanel/AddCategoryModalContent/helper";
import { NameLengthCounter } from "../../NotesPage/NotesRightPanel/NameCounter/NameCounter";

interface EditCategoryModalContentProps {
  onClose: () => void;
  categoryId: string;
  ownerId: string;
  modalProps: ModalProps;
}

export const EditCategoryModal = ({
  onClose,
  categoryId,
  ownerId,
  modalProps,
}: EditCategoryModalContentProps) => {
  const dispatch = useAppDispatch();
  const [category, setCategory] = useState("");
  const [nameError, setNameError] = useState<string>("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);

  const { categories } = useAppSelector((state) => state.category);
  const [catNames, setCatNames] = useState<string[]>([]);

  useEffect(() => {
    const names = categories.map((item) => item.categoryName);
    setCatNames(names);
    const categoryToEdit = categories.find((item) => item.id === categoryId);
    if (categoryToEdit) {
      setCategory(categoryToEdit.categoryName);
    }
  }, [categories, categoryId]);

  const validate = (values: string) => {
    const categoryNameError = validateCategoryName(values, catNames);
    setNameError(categoryNameError);
    return categoryNameError;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  const handleEditCategory = async () => {
    const error = validate(category);
    if (!error && !isUpdating) {
      setIsUpdating(true);
      try {
        const editCategoryData = {
          id: categoryId,
          category_name: category,
          owner: ownerId,
        };
        await dispatch(editCategoryThunk(editCategoryData)).unwrap();
      } catch (error) {
        console.error("Error updating category:", error);
      } finally {
        setIsUpdating(false);
        setIsModalVisible(false);
        onClose();
      }
    }
  };

  if (!isModalVisible) return null;

  return (
    <ModalPortal {...modalProps}>
      <Flex
        width="494px"
        height="210px"
        direction="column"
        justify="flex-start"
      >
        <Text
          size="20px"
          weight="bold"
          lineHeight="24px"
          letterSpacing="1px"
          mt="12px"
          mb="24px"
        >
          Edytuj kategorię
        </Text>
        <Container>
          <CategoryNameWrapper>
            <FormInput
              id="categoryName"
              placeholder="Podaj nazwę kategorii"
              label="Nazwa kategorii"
              value={category}
              type="text"
              onChange={handleChange}
              errorText={nameError}
              mt="20px"
              mb="40px"
              onBlur={() => validate(category)}
            />
            <NameLengthCounter length={category.length} />
          </CategoryNameWrapper>
          <Flex justify="flex-end" mt="12px" mb="12px">
            <GhostButton onClick={onClose} type="reset" mr="24px">
              Anuluj
            </GhostButton>
            <SmallButton onClick={handleEditCategory} disabled={isUpdating}>
              {isUpdating ? "Zapisywanie..." : "Zapisz zmiany"}
            </SmallButton>
          </Flex>
        </Container>
      </Flex>
    </ModalPortal>
  );
};
