import React from "react";

import styled from "styled-components/macro";

interface ImageProps {
  src: string;
  layout?: string;
  top?: string;
  bottom?: string;
  right?: string;
  left?: string;
  alt?: string;
  width?: number;
  height?: number;
  radius?: string;
  marginLeft?: string;
  marginRight?: string;
  opacity?: string;
  objectFit?: React.CSSProperties["objectFit"];
  cursor?: React.CSSProperties["cursor"];
}

const Image = styled.img<ImageProps>`
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: ${(props) => props.layout};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  border-radius: ${(props) => props.radius};
  object-fit: ${(props) => props.objectFit};
  cursor: ${({ cursor }) => cursor};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  opacity: ${(props) => props.opacity};
`;

export default Image;
