import styled from "styled-components";

interface LabelProps {
  fontSize?: string;
  bgColor?: string;
  m?: string;
  mt?: string;
}

export const Label = styled.label<LabelProps>`
  ${({ m: margin }) => margin && "margin: " + margin};
  ${({ mt }) => mt && "margin-top: " + mt};
  font-size: ${(props) => props.fontSize || "14px"};
  background-color: ${(props) => props.bgColor || "none"};
`;

interface AbsLabelProps {
  width?: string;
  height?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export const AbsLabel = styled(Label)<AbsLabelProps>`
  position: absolute;

  ${({ width }) => width && "width: " + width};
  ${({ height }) => height && "height: " + height};

  ${({ top }) => top && "top: " + top};
  ${({ bottom }) => bottom && "bottom: " + bottom};
  ${({ left }) => left && "left: " + left};
  ${({ right }) => right && "right: " + right};
`;
