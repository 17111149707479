import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";
import { useAppDispatch } from "store/hooks";
import { notesAction } from "store/notes/notesSlice";

import { NoteKitBorderRadiusWrapper } from "../shared/NoteKitStyles";

export default function FontStyleItem() {
  const dispatch = useAppDispatch();

  const underlineIcon = `/icons/noteKit/icon_underline.svg`;
  const boldIcon = `/icons/noteKit/icon_bold.svg`;
  const italicIcon = `/icons/noteKit/icon_italics.svg`;
  const strikethroughIcon = `/icons/noteKit/icon_strikethrough.svg`;

  return (
    <Flex justify="space-between">
      <Wrapper>
        <Text ml="10px" size="14px">
          Tekst
        </Text>
      </Wrapper>
      <Flex gap="4px">
        <NoteKitBorderRadiusWrapper>
          <Image src={underlineIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
        <NoteKitBorderRadiusWrapper
          onClick={() => dispatch(notesAction.setToolboxAction("bold"))}
        >
          <Image src={boldIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
        <NoteKitBorderRadiusWrapper>
          <Image src={italicIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
        <NoteKitBorderRadiusWrapper>
          <Image src={strikethroughIcon} alt="icon" width={32} height={32} />
        </NoteKitBorderRadiusWrapper>
      </Flex>
    </Flex>
  );
}
