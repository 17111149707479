import React from "react";

import Flex from "common/components/Flex";
import styled from "styled-components/macro";

import AlignTextItem from "./NoteKitItems/AlignTextItem";
import BulletPointsItem from "./NoteKitItems/BulletPointsItem";
import FontItem from "./NoteKitItems/FontItem";
import FontStyleItem from "./NoteKitItems/FontStyleItem";
import LineSpacingItem from "./NoteKitItems/LineSpacingItem";
import SpecialCharsItem from "./NoteKitItems/SpecialCharsItem";
import TableItem from "./NoteKitItems/TableItem";
import TextBackgroundItem from "./NoteKitItems/TextBackgroundItem";
import TextColorItem from "./NoteKitItems/TextColorItem";
import TextSizeItem from "./NoteKitItems/TextSizeItem";
import ZoomItem from "./NoteKitItems/ZoomItem";

export const NoteKitWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  min-height: 200px;
`;

export default function NoteKitContainer() {
  return (
    <NoteKitWrapper>
      <Flex direction="column" gap="16px">
        <FontItem />
        <TextColorItem />
        <TextSizeItem />
        <FontStyleItem />
        <AlignTextItem />
        <BulletPointsItem />
        <LineSpacingItem />
        <TextBackgroundItem />
        <TableItem />
        <SpecialCharsItem />
        <ZoomItem />
      </Flex>
    </NoteKitWrapper>
  );
}
