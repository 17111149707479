import { theme } from "common/theme/theme";
import styled from "styled-components";

import Text from "../Text";

interface SelectedPhotoProps {
  photo: string;
}

interface SelectedPhotoTitleProps {
  shouldShowImageTitle: boolean;
}

interface ArrowWrapperProps {
  isShown: boolean;
  leftSide: boolean;
}

interface SliderCloseButtonProps {
  isShown: boolean;
}

export const AttachmentSliderWrapper = styled.div`
  display: grid;
  width: 100%;
`;

export const AttachmentSelectedPhotoWrapper = styled.div`
  display: grid;
  place-items: center;
`;

export const SelectedPhoto = styled.div<SelectedPhotoProps>`
  height: 493px;
  width: 869px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${({ photo }) => photo}");
  position: relative;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
`;

export const SelectedPhotoTitle = styled(Text)<SelectedPhotoTitleProps>`
  position: absolute;
  top: 16px;
  left: 19px;
  opacity: ${({ shouldShowImageTitle }) => (shouldShowImageTitle ? "1" : "0")};
  transition: opacity 0.2s;
`;

export const SliderCloseButton = styled.div<SliderCloseButtonProps>`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 32px;
  height: 32px;
  background-color: ${theme.color.lightGray};
  border-radius: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
  visibility: ${({ isShown }) => (isShown ? "visible" : "hidden")};
  opacity: ${({ isShown }) => (isShown ? "1" : "0")};
  pointer-events: ${({ isShown }) => !isShown && "none"};
  transition: opacity 0.2s;
`;

export const AttachmentSliderBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin-top: 86px;
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  display: grid;
  place-items: center;
  background-color: ${theme.color.whiteGray};
  height: 114px;
  width: 32px;
  justify-self: ${({ leftSide }) => (leftSide ? "start" : "end")};
  margin-right: 25px;
  margin-left: 25px;
  border-radius: 40px;
  cursor: pointer;
  visibility: ${({ isShown }) => (isShown ? "visible" : "hidden")};
  opacity: ${({ isShown }) => (isShown ? "1" : "0")};
  pointer-events: ${({ isShown }) => !isShown && "none"};
  transition: opacity 0.2s;
`;
