import React, { useEffect, useState } from "react";
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";

import Image from "common/components/Image";
import { AbsValidationLabel } from "common/components/Layout/Form/ValidationLabel";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";

import { ITaskInForm } from "./NewTaskView";
import { TaskerInputStyled } from "./TaskerInput.styled";

const fieldName: keyof ITaskInForm = "task_title";
const maxLen = 50;

export interface FormInputProps {
  isFieldValid: boolean;
  register: UseFormRegister<ITaskInForm>;
  trigger: UseFormTrigger<ITaskInForm>;
  setValue: UseFormSetValue<ITaskInForm>;
  firstValidate: boolean;
  errorText?: string;
  value?: string;
}

function TaskerInput({
  isFieldValid,
  register,
  trigger,
  setValue,
  firstValidate,
  errorText,
  value,
}: FormInputProps) {
  const [inputValue, setInputValue] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [firstBlur, setFirstBlur] = useState(false);
  const [initForEdit, setInitForEdit] = useState(false);

  useEffect(() => {
    if (firstValidate) {
      setFirstClick(true);
      setFirstBlur(true);
    }
  }, [firstValidate]);

  function handleClick(): void {
    if (!firstClick) setFirstClick(true);
  }

  function handleFirstBlur(): void {
    setFirstBlur(true);
    trigger(fieldName);
  }

  function initValueForEditTask() {
    if (value && !initForEdit) {
      setInputValue(value);
      setInitForEdit(true);
    }
  }

  initValueForEditTask();

  const lettersLeft = `${maxLen - inputValue.length}/${maxLen}`;

  return (
    <RelativeContainer m="20px 0 15px 0">
      <TaskerInputStyled
        {...register(fieldName)}
        onChange={(e) => {
          setInputValue(e.target.value);
          setValue(fieldName, e.target.value);
          trigger(fieldName);
        }}
        onClick={handleClick}
        isValid={isFieldValid}
        onBlur={handleFirstBlur}
        clicked={firstClick}
        id={fieldName}
        placeholder={"Nazwa"}
      />
      <AbsValidationLabel
        isInvalid={!isFieldValid && firstClick && firstBlur}
        top="-20px"
        left="5px"
      >
        Nazwa
      </AbsValidationLabel>
      <AbsValidationLabel
        isInvalid={!isFieldValid && firstClick && firstBlur}
        fontSize="14px"
        bottom="-20px"
        right="5px"
      >
        {lettersLeft}
      </AbsValidationLabel>
      {!isFieldValid && firstClick && firstBlur && (
        <AbsValidationLabel isInvalid={!isFieldValid} top="50px" left="0px">
          <Image
            src={"/icons/error_outline.svg"}
            alt="Plus"
            width={16}
            height={16}
            marginRight={"3px"}
            layout={"relative"}
            top={"2px"}
          />
          {errorText}
        </AbsValidationLabel>
      )}
    </RelativeContainer>
  );
}

export default TaskerInput;
