import React, { useEffect, useState } from "react";

import { useSub } from "../../../common/hooks/useSub";
import { UserResponseDto } from "../../../common/types/UserResponseDto";
import { getUser } from "../../../services/api/user";

import {
  Body,
  Footer,
  MainHeading,
  RootContainer,
  UserDataContainer,
} from "./Summary.styled";
import UserDescription from "./UserDescription";
import UserImage from "./UserImage/UserImage";

const Summary = () => {
  const [user, setUser] = useState<UserResponseDto>();
  const sub = useSub().sub;

  useEffect(() => {
    async function fetchData() {
      try {
        if (sub) {
          const userData = await getUser(sub);
          userData && setUser(userData);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [sub]);

  return (
    <RootContainer>
      <Body>
        <UserImage />
        <UserDataContainer>
          {user ? (
            <MainHeading>{`${user.firstName} ${user.lastName}`}</MainHeading>
          ) : null}
          <UserDescription />
        </UserDataContainer>
      </Body>
      <Footer>Informacje</Footer>
    </RootContainer>
  );
};

export default Summary;
