import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

const TopMenuPanelBox = styled.div`
  height: 65px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 16px;
`;

const TopMenuButton = styled.button<{ ml?: string; mr?: string }>`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 0 8px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  &:hover {
    background-color: ${theme.color.whiteGray};
  }
`;

export { TopMenuPanelBox, TopMenuButton };
