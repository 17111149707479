import { Language } from "common/types/Language";
import { UpdateUserProfileDto } from "common/types/UpdateUserProfileDto";
import { UserProfileDto } from "common/types/UserProfileDto";
import { UserRequestDto } from "common/types/UserRequestDto";
import { UserResponseDto } from "common/types/UserResponseDto";

import { apiRequest } from "../config/axiosConfig";

interface IUserDto {
  firstName: string;
  lastName: string;
  city: string;
  avatarUrl: string;
  sub: string;
}

interface IProfileDto {
  companyName: string;
}

interface ResetPasswordDto {
  confirmationCode: string;
  email: string;
  password: string;
}

export interface IListUserDto {
  userDto: IUserDto;
  profileDto: IProfileDto;
}

export async function getUserId(email: string): Promise<string> {
  const response = await apiRequest<string>(
    "GET",
    "USER",
    `user/account/${email}`
  );
  return response.data;
}

export async function createUser(userdata: UserRequestDto): Promise<string> {
  const response = await apiRequest<{ id: string }, UserRequestDto>(
    "POST",
    "USER",
    "user/account",
    { data: userdata }
  );
  return response.data.id;
}

export async function updateUser(
  sub: string,
  userData: Partial<UserRequestDto>
): Promise<boolean> {
  try {
    const response = await apiRequest<{}, Partial<UserRequestDto>>(
      "PATCH",
      "USER",
      `user/account/${sub}`,
      { data: userData }
    );
    return response.status === 201;
  } catch (err) {
    if (err.response) {
      throw new Error(`updateUser returned with status ${err.response.status}`);
    } else if (err.request) {
      throw new Error("No response from the server while updating a user");
    }
  }
}

export async function getUser(sub: string): Promise<UserResponseDto> {
  const response = await apiRequest<UserResponseDto>(
    "GET",
    "USER",
    `user/account/bySub/${sub}`
  );
  return response.data;
}

export async function createProfile(
  profileData: UserProfileDto
): Promise<boolean> {
  const response = await apiRequest<{}, UserProfileDto>(
    "PUT",
    "USER",
    "user/profile",
    {
      data: profileData,
    }
  );
  return response.status === 201;
}

export async function getProfile(
  sub: string,
  language: Language
): Promise<UserProfileDto> {
  const response = await apiRequest<UserProfileDto>(
    "GET",
    "USER",
    `user/profile/${sub}/${language}`
  );
  return response.data;
}

export async function updateProfile(
  sub: string,
  language: Language,
  profileData: Partial<UpdateUserProfileDto>
): Promise<boolean> {
  const response = await apiRequest<{}, Partial<UpdateUserProfileDto>>(
    "PATCH",
    "USER",
    `user/profile/${sub}/${language}`,
    { data: profileData }
  );
  return response.status === 200;
}

export async function fetchUsersPossibleToAdd(): Promise<IListUserDto[]> {
  const response = await apiRequest<IListUserDto[]>(
    "GET",
    "USER",
    "user/profile/all"
  );
  return response.data;
}

export async function generateCodeToRemindPassword(email: string) {
  await apiRequest("GET", "USER", `user/account/password/code/${email}`);
}

export async function resetPassword(
  authData: ResetPasswordDto
): Promise<boolean> {
  const response = await apiRequest<{}, ResetPasswordDto>(
    "PATCH",
    "USER",
    "user/account/password/reset",
    {
      data: authData,
    }
  );
  return response.status === 201;
}
