import React from "react";

import Image from "common/components/Image";

import { IconButtonWrapper } from "./IconButton.styled";

type IconButtonProps = {
  width: number;
  height: number;
  imageWidth?: number;
  imageHeight?: number;
  radius?: string;
  imageRadius?: string;
  icon: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  label?: string;
  position?: string;
  marginLeft?: string;
  marginRight?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
};

const IconButton = ({
  width,
  height,
  imageHeight,
  imageWidth,
  icon,
  backgroundColor,
  onClick,
  onMouseOver,
  onMouseLeave,
  marginLeft,
  marginRight,
  radius,
  imageRadius,
  className,
  label,
  position,
  top,
  bottom,
  left,
  right,
}: IconButtonProps) => {
  return (
    <IconButtonWrapper
      width={width}
      height={height}
      radius={radius}
      backgroundColor={backgroundColor}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={className}
      position={position}
      top={top}
      bottom={bottom}
      right={right}
      left={left}
      marginLeft={marginLeft}
      marginRight={marginRight}
    >
      <Image
        src={icon}
        aria-label={label}
        height={imageHeight || height}
        width={imageWidth || width}
        radius={imageRadius || radius}
      />
    </IconButtonWrapper>
  );
};

export default IconButton;
