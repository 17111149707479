import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { useSub } from "common/hooks/useSub";
import { InvitationModal } from "components/Inviation/InvitationModal";
import { logout } from "store/auth/authActions";
import { useAppDispatch } from "store/hooks";
import { useGetUserQuery } from "store/user/userApi";

import DropdownMenuOption from "../../DropdownMenu/DropdownMenuOption";
import LogoutModal from "../LogoutModal/LogoutModal";

import {
  MenuBox,
  MenuButton,
  MenuLine,
  OpenMenuButton,
} from "./ProfileMenu.styled";

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub });
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState<boolean>(false);
  const [isBeingLoggedOut, setIsBeingLoggedOut] = useState(false);
  const invitationModal = useModal({ withCloseButton: false });
  const changeVisibility = (isVisible: boolean) => {
    setIsOpenMenu(isVisible);
  };

  const navigateTo = (route: string) => {
    navigate(route);
    changeVisibility(false);
  };

  const modal = useModal({ withCloseButton: false });

  const handleLogoutProcess = () => {
    modal.showModal();
    setIsOpenLogoutModal(true);
  };

  const logoutUser = async () => {
    setIsBeingLoggedOut(true);
    try {
      await dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };

  const dropdownMenuOptions = [
    {
      action: () => navigateTo("profile"),
      img: "/icons/person.svg",
      text: "Moja wizytówka",
      testid: "edit-popup-button",
    },
    {
      action: () => {},
      img: "/icons/settings.svg",
      text: "Ustawienia",
      testid: "delete-popup-button",
    },
    {
      action: () => {},
      img: "/icons/mail.svg",
      text: "Przekaż opinię",
      testid: "delete-popup-button",
    },
    {
      action: () => {
        invitationModal.showModal();
      },
      img: "/icons/addSign.svg",
      text: "Zaproś do Acorn",
      testid: "delete-popup-button",
    },
    {
      action: () => handleLogoutProcess(),
      img: "/icons/logout.svg",
      text: "Wyloguj się",
      testid: "delete-popup-button",
    },
  ];

  const closeLogoutModal = () => {
    setIsOpenLogoutModal(false);
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => (isOpenMenu ? changeVisibility(false) : null)}
      >
        <OpenMenuButton
          image={user?.avatarUrl || "/icons/user-avatar-placeholder.svg"}
          onClick={() =>
            isOpenMenu ? changeVisibility(false) : changeVisibility(true)
          }
        />
        {isOpenMenu ? (
          <MenuBox>
            {dropdownMenuOptions.map((option, index) => (
              <MenuButton key={index}>
                <DropdownMenuOption
                  img={option.img}
                  text={option.text}
                  action={option.action}
                  testid={option.testid}
                />
                {index === 1 || index === 3 ? <MenuLine /> : null}
              </MenuButton>
            ))}
          </MenuBox>
        ) : null}
      </OutsideClickHandler>
      {isOpenLogoutModal && (
        <ModalPortal {...modal.modalProps}>
          <LogoutModal
            isBeingLoggedOut={isBeingLoggedOut}
            logout={logoutUser}
            closeModal={closeLogoutModal}
          />
        </ModalPortal>
      )}
      <ModalPortal {...invitationModal.modalProps}>
        <InvitationModal onClose={invitationModal.modalProps.closeModal} />
      </ModalPortal>
    </>
  );
};
