import React from "react";

import { ButtonsContainer } from "../AvatarChange/shared/style";
import { BackgroundPreview } from "../Background/BackgroundPreview";
import GhostButton from "../GhostButton/GhostButton";
import SmallButton from "../SmallButton/SmallButton";

import {
  BackgroundChangeContainer,
  BackgroundChangeSubtitle,
  BackgroundChangeTitle,
} from "./BackgroundChange.styled";

type BackgroundRemoveProps = {
  imageDefault: string;
  onCancelRemove: () => void;
  onConfirmRemove: () => void;
  subtitle: string;
  title: string;
};

export const BackgroundRemove = ({
  imageDefault,
  onCancelRemove,
  onConfirmRemove,
  subtitle,
  title,
}: BackgroundRemoveProps) => {
  return (
    <BackgroundChangeContainer>
      <BackgroundChangeTitle>{title}</BackgroundChangeTitle>
      <BackgroundChangeSubtitle>{subtitle}</BackgroundChangeSubtitle>
      <BackgroundPreview backgroundImage={imageDefault} />
      <ButtonsContainer>
        <GhostButton onClick={onCancelRemove}>Anuluj</GhostButton>
        <SmallButton onClick={onConfirmRemove}>Tak, usuń</SmallButton>
      </ButtonsContainer>
    </BackgroundChangeContainer>
  );
};
