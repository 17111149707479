import React, { useCallback, useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { CloseButtonPosition } from "common/components/Modal/types";
import { useModal } from "common/components/Modal/use-modal";
import { useSub } from "common/hooks/useSub";
import PostCreationModal from "components/PostCreation/PostCreationModal/PostCreationModal";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { useCreatePostMutation, useGetPostsQuery } from "store/group/groupApi";
import { ICreatePostRequest } from "store/group/groupApi.types";
import { useAppSelector } from "store/hooks";
import { useUploadFileMutation } from "store/upload/uploadApi";
import { useGetUserQuery } from "store/user/userApi";

import { GroupPostsWrapper } from "../GroupPosts/GroupPosts.styled";

import ConfirmLeavingModal from "./ConfirmLeavingModal/ConfirmLeavingModal";
import GroupPostsList from "./GroupPostsList/GroupPostsList";
import { postConfig } from "./postConfig";
import PostPublicationSettingsModal from "./PostPublicationSettingsModal/PostPublicationSettingsModal";
import PostTextArea from "./PostTextArea/PostTextArea";
import { postCreationSchema } from "./schema";
import { PostCreationForm } from "./types";

const GroupDiscussion = () => {
  const userSub = useSub();
  const [files, setFiles] = useState<File[]>([]);
  const [postsWasCreated, setPostWasCreated] = useState(false);
  const [page, setPage] = useState(0);
  const [createPost, { isError }] = useCreatePostMutation();
  const [uploadFile] = useUploadFileMutation();
  const [confirmLeavingModal, setConfirmLeavingModal] = useState(false);
  const [isPublicationSettingsMode, setIsPublicationSettingsMode] =
    useState(false);
  const postModal = useModal({ withCloseButton: true, withPadding: false });
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const { data: user } = useGetUserQuery({ sub: userSub.sub });
  const { data: posts } = useGetPostsQuery(
    {
      groupId: currentGroup?.groupId,
      page: page,
      rows: 4,
    },
    { skip: currentGroup?.groupId === undefined }
  );

  const {
    refetch: refetchAfterCreation,
    data: postsAfterCreation,
    isSuccess,
  } = useGetPostsQuery(
    {
      groupId: currentGroup?.groupId,
      page: 0,
      rows: 1,
    },
    { skip: !postsWasCreated }
  );

  const turnOnModal = () => {
    postModal.showModal();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PostCreationForm>({
    defaultValues: {
      publicationMode: "Publiczny",
      textContent: "",
    },
    shouldUnregister: false,
    mode: "all",
    resolver: yupResolver(postCreationSchema),
  });

  const formContent = useWatch({ control });

  const tryToLeavePostModal = () => {
    setConfirmLeavingModal(true);
  };

  const doNotLeavePostModal = () => {
    setConfirmLeavingModal(false);
  };

  const goToPostModalFromPublicationOptions = () => {
    setIsPublicationSettingsMode(false);
  };

  const handlePageAddition = useCallback(() => {
    setPage((page) => ++page);
  }, []);

  const turnOffModal = () => {
    postModal.modalProps.closeModal();
    setFiles([]);
    setValue("textContent", "");
    setValue("publicationMode", "Publiczny");
    setTimeout(() => {
      setConfirmLeavingModal(false);
      setIsPublicationSettingsMode(false);
    }, 300);
  };
  const goToPublicationMode = () => {
    setIsPublicationSettingsMode(true);
  };

  const username = useMemo(() => {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
  }, [user]);

  const isValidToPublish = useMemo(
    () =>
      !errors.textContent?.message &&
      formContent.textContent.length >= postConfig.minLength,
    [errors.textContent?.message, formContent.textContent.length]
  );

  const textDidNotExceedLimit = useMemo(
    () =>
      !errors.textContent?.message ||
      errors.textContent?.message === postConfig.minimumValidationText,
    [errors.textContent?.message]
  );

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    clearInput: () => void
  ) => {
    setFiles((files) => {
      const existingFileNames = files.map((file) => file.name);
      const newFiles = Array.from(event.target.files).filter(
        (file) => !existingFileNames.includes(file.name)
      );
      clearInput();
      return files.concat(newFiles);
    });
  };
  const handleFileDeletion = (file: File) => {
    setFiles((files) =>
      files.filter((fileItem) => fileItem.name !== file.name)
    );
  };

  const addPost: SubmitHandler<PostCreationForm> = async (data) => {
    const postData: GroupApiRequest<ICreatePostRequest> = {
      body: {
        header: createGroupRequestHeader(userSub.sub),
        message: {
          object_id: currentGroup.groupId,
          object_type: "GROUP",
          content: data.textContent,
          author: {
            author_id: userSub.sub,
            author_type: "USER",
          },
          created: new Date().toISOString(),
          post_status: "ACTIVE",
          attachments: [],
        },
      },
    };
    const creationData = await createPost({ postData: postData }).unwrap();
    for (let i = 0; i < files.length; i++) {
      await uploadFile({
        uploadFileData: {
          bucket: "smartoakprojects-group-posts",
          folder: `${currentGroup.groupId}/${creationData.body.message.postId}`,
          file: files[i],
        },
      });
    }
    turnOffModal();
    refetchAfterCreation();
    setPostWasCreated(true);
  };

  const closeButtonPositionHandler = (): CloseButtonPosition => {
    if (confirmLeavingModal) {
      return {
        right: "16px",
        top: "16px",
      };
    }
    if (isPublicationSettingsMode) {
      return {
        right: "25px",
        top: "39.5px",
      };
    }
    return {
      right: "24px",
      top: "24px",
    };
  };

  return (
    <GroupPostsWrapper>
      <PostTextArea
        userAvatar={user?.avatarUrl || postConfig.userPlaceholderAvatar}
        turnOnModal={turnOnModal}
      />
      <ModalPortal
        {...postModal.modalProps}
        closeModal={confirmLeavingModal ? turnOffModal : tryToLeavePostModal}
        closeButtonPosition={closeButtonPositionHandler()}
      >
        <PostCreationModal
          headerTitle="Utwórz post"
          display={
            !confirmLeavingModal && !isPublicationSettingsMode ? null : "none"
          }
          userSection={
            <PostCreationModal.PostModalUser
              userAvatar={user?.avatarUrl}
              goToPublicationMode={goToPublicationMode}
              username={username}
              publicationType={formContent.publicationMode}
              displayPublication={true}
            />
          }
          contentSection={
            <PostCreationModal.PostModalTextArea<PostCreationForm>
              handleFileDeletion={handleFileDeletion}
              files={files}
              setValue={setValue}
              isValid={textDidNotExceedLimit}
              registerFieldName="textContent"
              valueCount={formContent.textContent.length}
            />
          }
          optionsSection={
            <PostCreationModal.PostModalOptions
              isCreationError={isError}
              isValid={isValidToPublish}
              publishPost={() => handleSubmit(addPost)()}
              handleFileUpload={handleFileUpload}
            />
          }
        />
        {confirmLeavingModal && (
          <ConfirmLeavingModal
            cancelButtonHandler={doNotLeavePostModal}
            proceedButtonHandler={turnOffModal}
          />
        )}
        {isPublicationSettingsMode && !confirmLeavingModal && (
          <PostPublicationSettingsModal
            setValue={setValue}
            inputName="publicationMode"
            firstRadioButtonValue="Publiczny"
            secondRadioButtonValue="Prywatny"
            selected={formContent.publicationMode}
            shouldTextBeDisplayed={false}
            goBack={goToPostModalFromPublicationOptions}
            saveChanges={goToPostModalFromPublicationOptions}
          />
        )}
      </ModalPortal>
      {currentGroup?.groupId !== undefined && (
        <GroupPostsList
          userAvatar={user?.avatarUrl ?? postConfig.userPlaceholderAvatar}
          groupAvatar={currentGroup?.decodedAvatar}
          groupName={currentGroup?.name}
          groupId={currentGroup?.groupId}
          postsAfterCreation={postsAfterCreation}
          page={page}
          posts={posts}
          postWasSuccessfullyCreated={isSuccess}
          handlePageAddition={handlePageAddition}
        />
      )}
    </GroupPostsWrapper>
  );
};

export default GroupDiscussion;
