import React from "react";

import IconButton from "../IconButton";

type EditIconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const EditIconButton = ({ onClick }: EditIconButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      icon="/icons/pen.svg"
      width={18}
      height={18}
    />
  );
};

export default EditIconButton;
