import React from "react";

import Checkbox from "common/components/Forms/Checkboxes/Checkbox";
import Text from "common/components/Text";

import { GroupCloseArchiveCheckboxWrapper } from "./GroupCloseModalArchiveCheckbox.styled";

interface GroupCloseModalArchiveCheckboxProps {
  checked: boolean;
  onChecked: () => void;
  textContent: string;
}

const GroupCloseModalArchiveCheckbox = ({
  checked,
  onChecked,
  textContent,
}: GroupCloseModalArchiveCheckboxProps) => {
  return (
    <GroupCloseArchiveCheckboxWrapper>
      <Checkbox
        checked={checked}
        width={18}
        height={18}
        checkedIconSrc="/icons/checked.svg"
        uncheckedIconSrc="/icons/unchecked.svg"
        onChecked={onChecked}
      />
      <Text ml="19px">{textContent}</Text>
    </GroupCloseArchiveCheckboxWrapper>
  );
};

export default GroupCloseModalArchiveCheckbox;
