import styled from "styled-components/macro";

export const MainLayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100vh;
  align-items: top;
  justify-items: center;
  width: 100%;
`;
