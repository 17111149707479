import React, { forwardRef } from "react";

import { IconCheckbox } from "common/icons/IconCheckbox";
import { IconCheckboxEmpty } from "common/icons/IconCheckboxEmpty";

import { CheckboxLiftingStyles } from "./CheckboxLifting.styled";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const CheckboxLifting = forwardRef<HTMLInputElement, Props>(
  ({ children, id, className, ...props }, ref) => {
    return (
      <CheckboxLiftingStyles htmlFor={id} className={className}>
        <input type="checkbox" id={id} {...props} ref={ref} />
        <div className="icon_wrapper">
          <IconCheckboxEmpty className="check_icon--empty" />
          <IconCheckbox className="check_icon--filled" />
        </div>
        <span>{children}</span>
      </CheckboxLiftingStyles>
    );
  }
);

CheckboxLifting.displayName = "CheckboxLifting";
