export enum Paths {
  INDEX = "/",
  LOGIN = "/auth?id=login",
  REGISTER = "/auth?id=register",
  AUTHPAGE = "/auth",
  CHANGE_PASSWORD = "/auth?id=change-password",
  NOTES = "/notes",
  CREATE_NOTE = "/create-note",
  REMIND = "/auth?id=remind",
  CREATE_PROFILE = "/create-profile",
  PROFILE = "/profile",
  DASHBOARD = "/dashboard",
  GROUP = "/group",
  NOT_FOUND = "/404",
  CALENDAR = "/calendar",
  GROUP_TASK_BOARD = "/group-task-board",
  USER_TASK_BOARD = "/user-task-board",
  SEARCH_DETAILS = "/search-details",
}
