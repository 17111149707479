import styled from "styled-components";

interface PostCreationModalWrapperProps {
  display: string;
}

export const PostCreationModalWrapper = styled.div<PostCreationModalWrapperProps>`
  display: ${(props) => props.display ?? "grid"};
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 59px;
  min-height: 457px;
  max-height: 660px;
  width: 490px;
`;

export const PostModalUserSection = styled.div`
  margin-top: 33px;
  margin-left: 23px;
`;

export const PostModalTextOptionsWrapper = styled.div`
  height: 100%;
`;
