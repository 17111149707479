import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface SpinnerProps {
  radius: string;
  border: string;
  mt?: string;
}

const Spinner = styled.div<SpinnerProps>`
  border: ${(p) => p.border} solid ${theme.color.backgroundGray};
  border-top: ${(p) => p.border} solid ${theme.color.basicBlack};
  border-radius: 50%;
  width: ${(p) => p.radius};
  height: ${(p) => p.radius};
  animation: spin 1s linear infinite;
  margin-top: ${(p) => p.mt};
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
