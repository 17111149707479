import {
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_FRONTEND_CLIENT_ID,
});

export const userExists = (): boolean => {
  if (userPool.getCurrentUser()) return true;
  return false;
};

export const getUser = () => userPool.getCurrentUser();

export const getSub = () => {
  let sub = null;
  userPool
    .getCurrentUser()
    .getSession((error: Error, session: CognitoUserSession) => {
      if (error) throw error;
      sub = session.getIdToken().decodePayload().sub;
    });
  return sub;
};
