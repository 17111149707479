import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

interface TextAreaProps {
  isValid: boolean;
}

interface CounterProps {
  isValid: boolean;
}

export const GroupDescriptionEditWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 329px;
  min-height: 218px;
`;

export const GroupDescriptionEditHeader = styled(Text)`
  margin-bottom: 24px;
`;

export const GroupDescriptionButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 35px;
`;

export const EditGroupDescriptionTextArea = styled.textarea<TextAreaProps>`
  border: 1px solid
    ${({ isValid }) =>
      isValid ? `${theme.color.lightGray}` : `${theme.color.alertRed}`};
  outline: none;
  resize: none;
  width: 285px;
  max-height: 152px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 36px;
  border-radius: 8px;
  color: ${theme.color.mediumGray};
`;

export const EditDescriptionTextAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Counter = styled(Text)<CounterProps>`
  color: ${({ isValid }) =>
    isValid ? `${theme.color.basicBlack}` : `${theme.color.alertRed}`};
  font-weight: ${theme.fontWeight.bold};
  margin-top: -38px;
  margin-right: 25px;
`;

export const ValidationMessageWrapper = styled.div`
  width: 291px;
  height: 20px;
  margin-top: 41px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
