import { Gender } from "common/types/Gender";
import { Language } from "common/types/Language";
import {
  GroupMemberAvatar,
  GroupMemberRole,
  GroupTypeEnum,
} from "store/groupSlice";

import { apiRequest } from "../config/axiosConfig";
import { GroupApiRequest, GroupApiResponse } from "../shared/apiResponse";

import { IGroupInvitationRequest } from "./types";

type GroupStatusDto = "ACTIVE" | "PASSIVE" | "BLOCKED" | "OTHER";

type GroupAccessDto = "PUBLIC" | "PRIVATE";

export interface GroupLogo {
  bucket: string;
  data: string;
  key: string;
}

export interface GroupResponseDto {
  group_status: GroupStatusDto;
  group_id: string;
  created: Date;
  information: string;
  name: string;
  logo: GroupLogo;
  description: string;
  group_owner: GroupOwnerDto;
  group_access: GroupAccessDto;
  group_type: GroupTypeDto;
  created_by: string;
  tags: string[];
}

export interface GroupListMemberResponseDto {
  group_status: GroupStatusDto;
  group_id: string;
  created: Date;
  name: string;
  logo: GroupLogo;
  description: string;
  group_owner: GroupOwnerDto;
  group_type: GroupTypeDto;
  created_by: string;
  tags: string[];
  picture: string;
}
enum GroupOwnerTypeDto {
  USER = "USER",
  SYSTEM = "SYSTEM",
}

interface GroupOwnerDto {
  group_owner_type: GroupOwnerTypeDto;
  group_owner_id: string;
}

type GroupTypeDto = "DISCUSS" | "PROJECT";

export interface IGroupMemberResponseDto {
  user_id: string;
  group_id: string;
  created: string;
  created_by: string;
  group_type: GroupTypeEnum;
  user_role: GroupMemberRole;
  avatar: GroupMemberAvatar;
  user_status_history: any;
  user: IGroupMemberUserDataDto;
}

interface IGroupMemberUserDataDto {
  sub: string;
  gender: Gender;
  date_of_birth: string;
  last_name: string;
  language: Language;
  first_name: string;
  email: string;
}

export interface IDeleteGroupMessageRequest {
  group_id: string;
  inactivated: string;
  inactivated_by: string;
  group_type: string;
}

export interface IDeleteGroupMessageResponse {}

export interface IGetGroupInfoRequest {
  groupId: string;
  groupType: string;
}

export async function getGroupInfo(
  groupId: string,
  groupType: string
): Promise<GroupResponseDto> {
  try {
    const response = await apiRequest<GroupApiResponse<GroupResponseDto>>(
      "GET",
      "GROUP",
      `/info/${groupId}/${groupType}`
    );
    return response.data.body?.message;
  } catch (err) {
    if (err.response) {
      console.error(
        `GetGroupInfo for group ${groupId} returned with status ${err.response.status}`
      );
    } else if (err.request) {
      console.error("No response from the server while executing GetGroupInfo");
    }
    return null;
  }
}

export async function getGroupList(groupType: GroupTypeDto): Promise<any> {
  try {
    const response = await apiRequest<
      GroupApiResponse<GroupListMemberResponseDto[]>
    >("GET", "GROUP", `/list/${groupType}?rows=5&page=0`);
    return response.data.body.message;
  } catch (err) {
    if (err.response) {
      console.error(
        `GetGroupList for type ${groupType} returned with status ${err.response.status}`
      );
    } else if (err.request) {
      console.error("No response from the server while executing GetGroupList");
    }
    return null;
  }
}

export async function getGroupMembers(
  groupId: string
): Promise<IGroupMemberResponseDto[]> {
  try {
    const response = await apiRequest<
      GroupApiResponse<IGroupMemberResponseDto[]>
    >("GET", "GROUP", `/user/${groupId}`);
    return response.data.body.message;
  } catch (err) {
    if (err.response) {
      console.error(
        `getGroupMembers for users of group ${groupId} returned with status ${err.response.status}`
      );
    }
    if (err.request) {
      console.error(
        "No response from the server while executing getGroupMembers"
      );
    }
    return null;
  }
}

export async function getDiscussGroups(): Promise<
  GroupListMemberResponseDto[]
> {
  return getGroupList("DISCUSS");
}

export async function getProjectGroups(): Promise<
  GroupListMemberResponseDto[]
> {
  return getGroupList("PROJECT");
}

export async function closeGroup(
  data: GroupApiRequest<IDeleteGroupMessageRequest>
) {
  const response = await apiRequest("DELETE", "GROUP", "/delete", { data });
  return response.data;
}

export async function inviteToGroup(
  data: GroupApiRequest<IGroupInvitationRequest>
) {
  const response = await apiRequest("POST", "INVITATION", "", {
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return response.data;
}

export async function uploadToBucket(
  bucketFolder: string,
  fileName: string,
  formData: Blob
) {
  try {
    const response = await apiRequest(
      "PUT",
      "UPLOAD",
      `/${bucketFolder}/${fileName}`,
      {
        data: formData,
        headers: {
          "Content-Type": "image/jpeg",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
