import React, { useState } from "react";

import { IListUser } from "store/groupCreation/types";

import { GroupInvitationWrapper } from "./GroupInvitation.styled";
import GroupInvitationSearch from "./InvitationSearch/InvitationSearch";
import UsersTables from "./InvitationUsersTable/UsersTables";

interface GroupInvitationProps {
  addUserHandler: (userId: string) => void;
  deleteUserHandler: (userId: string) => void;
  chosenUsers: IListUser[];
}

const GroupInvitation = ({
  addUserHandler,
  deleteUserHandler,
  chosenUsers,
}: GroupInvitationProps) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <GroupInvitationWrapper>
      <GroupInvitationSearch
        chosenUsersCount={chosenUsers.length}
        setSearchValue={handleSearchValue}
      />
      <UsersTables
        addUserHandler={addUserHandler}
        deleteUserHandler={deleteUserHandler}
        searchValue={searchValue}
        chosenUsers={chosenUsers}
      />
    </GroupInvitationWrapper>
  );
};

export default GroupInvitation;
