import React, { useMemo, useState } from "react";

import Image from "common/components/Image/Image";
import { Language } from "common/types/Language";
import { GroupMemberRole, IGroupMember } from "store/groupSlice";

import {
  GroupMembersInput,
  GroupMembersInputWrapper,
  GroupMembersTableWrapper,
  GroupMembersWrapper,
  MembersSearchIconWrapper,
} from "./GroupMembers.styled";
import GroupMembersTable from "./GroupMemberTable/GroupMembersTable";

export const GroupMembers = () => {
  const [searchedUser, setSearchedUser] = useState("");

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchedUser(event.target.value);
  };

  const admins: IGroupMember[] = useMemo(() => {
    return [
      {
        avatar: "/icons/user-avatar-placeholder.png",
        groupId: "132",
        userId: "123",
        userRole: GroupMemberRole.Admin,
        userStatusHistory: [],
        user: {
          sub: "123",
          gender: "Male",
          dateOfBirth: "",
          firstName: "FIRSTNAME",
          lastName: "LASTNAME",
          language: Language.Polish,
          email: "mock@gmail.com",
        },
      },
    ];
  }, []);

  const nonAdminUsers: IGroupMember[] = useMemo(() => {
    return [
      {
        avatar: "/icons/user-avatar-placeholder.png",
        groupId: "132",
        userId: "1234",
        userRole: GroupMemberRole.Member,
        userStatusHistory: [],
        user: {
          sub: "1234",
          gender: "Male",
          dateOfBirth: "",
          firstName: "Kordian",
          lastName: "Mock",
          language: Language.Polish,
          email: "mock@gmail.com",
        },
      },
      {
        avatar: "/icons/user-avatar-placeholder.png",
        groupId: "132",
        userId: "1235",
        userRole: GroupMemberRole.Member,
        userStatusHistory: [],
        user: {
          sub: "12345",
          gender: "Male",
          dateOfBirth: "",
          firstName: "Shrek",
          lastName: "Shrek",
          language: Language.Polish,
          email: "mock@gmail.com",
        },
      },
    ];
  }, []);

  return (
    <GroupMembersWrapper>
      <GroupMembersInputWrapper>
        <GroupMembersInput
          onChange={onChangeHandler}
          placeholder="Szukaj członków..."
          name="username"
          value={searchedUser}
        />
        <MembersSearchIconWrapper>
          <Image src="/icons/search.svg" />
        </MembersSearchIconWrapper>
        <GroupMembersInputWrapper />
      </GroupMembersInputWrapper>
      <GroupMembersTableWrapper>
        <GroupMembersTable tableTitle="Administratorzy" users={admins} />
        <GroupMembersTable tableTitle="Użytkownicy" users={nonAdminUsers} />
      </GroupMembersTableWrapper>
    </GroupMembersWrapper>
  );
};
