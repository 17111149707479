import React from "react";

import Flex from "common/components/Flex";
import { FlexPart } from "common/components/Flex/FlexPart";
import { Container } from "common/components/Layout/Containers";
import { useAppSelector } from "store/hooks";

export interface TaskerSwitchViewProps {
  handleSetTodoTaskerView: () => void;
  handleSetDoneTaskerView: () => void;
}

export default function TaskerSwitchView({
  handleSetDoneTaskerView,
  handleSetTodoTaskerView,
}: TaskerSwitchViewProps) {
  const taskerSlice = useAppSelector((state) => state.tasker);

  return (
    <Flex direction="row">
      <FlexPart flex="1">
        <Container
          cursor="pointer"
          onClick={handleSetTodoTaskerView}
          w="100%"
          bgColor="white"
          boxShadow={
            taskerSlice.taskerView.BoardView === "Todo" &&
            "0px -5px 0px 0px #FEE542 inset"
          }
        >
          <Flex justify="center" align="center" p="12px">
            Do zrobienia
          </Flex>
        </Container>
      </FlexPart>
      <FlexPart flex="1">
        <Container
          cursor="pointer"
          onClick={handleSetDoneTaskerView}
          w="100%"
          bgColor="white"
          boxShadow={
            taskerSlice.taskerView.BoardView === "Done" &&
            "0px -5px 0px 0px #FEE542 inset"
          }
        >
          <Flex justify="center" align="center" p="12px">
            Ukończone
          </Flex>
        </Container>
      </FlexPart>
    </Flex>
  );
}
