import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmButton from "common/components/ConfirmButton";
import Text from "common/components/Text";
import UserCredentialsArea from "common/components/UserCredentialsArea";
import { Paths } from "common/constants/paths";
import { theme } from "common/theme/theme";
import { ICredentials } from "common/types/ICredentials";
import { getUser } from "services/api/user";
import { login } from "store/auth/authActions";
import { authAction } from "store/auth/authSlice";
import { getSub } from "store/auth/authUtils";
import { InitialAuthorization } from "store/auth/types";
import { useAppDispatch } from "store/hooks";

import { ButtonWrapper, Form, FormInputWrapper } from "./LoginForm.styled";
import { loginValidationSchema } from "./loginValidationSchema";

interface StoreType {
  auth: InitialAuthorization;
}

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const redirectLocation = location.state
    ? (location.state as { redirectTo?: Location }).redirectTo
    : null;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid: formIsValid, touchedFields },
  } = useForm<ICredentials>({
    resolver: yupResolver(loginValidationSchema),
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigateUser = async () => {
    const sub = getSub();
    const user = await getUser(sub);
    if (user.profileFormSubmitted) {
      navigate(redirectLocation?.pathname || Paths.DASHBOARD);
    } else {
      navigate(Paths.CREATE_PROFILE, {
        state: { redirectTo: redirectLocation },
      });
    }
  };

  const loginError = useSelector((item: StoreType) => item.auth.loginError);

  const onClickHandler = handleSubmit(async (data, event) => {
    event.preventDefault();
    if (formIsValid)
      try {
        await dispatch(login(data.email, data.password));
        navigateUser();
      } catch (error) {
        console.log(error);
      }
    dispatch(authAction.setLoginError(""));
  });

  return (
    <Form>
      <FormInputWrapper>
        <UserCredentialsArea
          register={register}
          passwordErrors={errors.password?.message.toString()}
          emailErrors={errors.email?.message.toString()}
          passwordTouched={touchedFields.password}
          emailTouched={touchedFields.email}
        />
        <Text
          align="right"
          size="14px"
          font="primary"
          mt="24px"
          color={theme.color.darkGray}
        >
          <Link to={Paths.REMIND}>Nie pamiętam hasła</Link>
        </Text>
        <Text mt="24px" color={theme.color.alertRed}>
          {loginError}
        </Text>
      </FormInputWrapper>
      <ButtonWrapper>
        <ConfirmButton
          type="submit"
          disabled={!formIsValid}
          onClick={onClickHandler}
        >
          Zaloguj się
        </ConfirmButton>
      </ButtonWrapper>
    </Form>
  );
};

export default LoginForm;
