import React from "react";

import Flex from "common/components/Flex";
import { Container } from "common/components/Layout/Containers";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
// import { initTaskerUserViewAsyncThunk } from "services/api/tasker";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { taskerAction } from "store/tasker/taskerSlice";

import TaskerSwitchView from "../utils/TaskerSwitchView";

import TaskerUserDone from "./TaskerUserDone/TaskerUserDone";
import TaskerUserTodo from "./TaskerUserTodo/TaskerUserTodo";
import { useInitUserView } from "./useInitUserView";
// import { useInitUserView } from "./useInitUserView";

export default function UserView() {
  // const userId = getSub();
  const dispatch = useAppDispatch();
  const taskerSlice = useAppSelector((state) => state.tasker);
  // const initTaskerUserView = useCallback(() => {
  //   if (!taskerSlice.userViewTree.loaded)
  //     dispatch(initTaskerUserViewAsyncThunk(userId));
  // }, [dispatch, userId, taskerSlice.userViewTree.loaded]);
  useInitUserView();

  // useEffect(() => {
  //   initTaskerUserView();
  // }, [dispatch, initTaskerUserView]);

  const handleSetTodoTaskerView = () => {
    dispatch(taskerAction.setTodoTaskerView());
  };

  const handleSetDoneTaskerView = () => {
    dispatch(taskerAction.setDoneTaskerView());
  };

  return (
    <Container w="90%" p="40px" bgColor={theme.color.backgroundGray}>
      <Flex justify="flex-start" width="100%" p="0 0 16px 0">
        <Text size="24px" weight="bold" color={theme.color.basicBlack}>
          Tasker
        </Text>
      </Flex>
      <TaskerSwitchView
        handleSetTodoTaskerView={handleSetTodoTaskerView}
        handleSetDoneTaskerView={handleSetDoneTaskerView}
      />
      {taskerSlice.taskerView.BoardView === "Todo" && <TaskerUserTodo />}
      {taskerSlice.taskerView.BoardView === "Done" && <TaskerUserDone />}
    </Container>
  );
}
