import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const UserPopUp = styled.div`
  position: absolute;
  overflow-wrap: break-word;
  top: 33.76px;
  display: grid;
  place-items: center;
  width: auto;
  padding-right: 11px;
  padding-left: 10px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 999;
`;
export const DeleteButton = styled.a`
  position: absolute;
  display: grid;
  border-radius: 100%;
  place-items: center;
  left: 24px;
  top: -3px;
  z-index: 10;
  height: 24px;
  width: 24px;
  background-color: ${theme.color.veryLightGray};
  cursor: pointer;
`;
export const IconWrapper = styled.div`
  position: absolute;
  width: 7px;
  height: 7px;
  top: 20.62px;
  right: 13px;
`;
export const UserNamePopUp = styled.div`
  background: ${theme.color.basicWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 4px;
  gap: 10px;
  position: absolute;
  width: 92px;
  height: auto;
  left: calc(50% - 100px / 2);
  top: 45px;
  opacity: 0.9;
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  z-index: 999;
  p {
    text-align: center;
  }
`;
