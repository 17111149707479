import styled from "styled-components/macro";

type OuterContainerProps = { backgroundUrl: string };

export const OuterContainer = styled.div<OuterContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 920px;
  height: 280px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 180px 45px 0;
  box-sizing: border-box;
  background-image: url(${(props) => props.backgroundUrl});
  position: relative;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 684px;
`;

export const EditBackgroundButton = styled.button`
  position: absolute;
  top: 242px;
  right: 13px;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
