import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchUsersPossibleToAdd,
  IListUserDto,
} from "services/api/user/userApi";

import { IListUser } from "./types";

interface DefaultState {
  availableUsers: IListUser[];
  chosenUsers: IListUser[];
  isLoading: boolean;
}

export const convertUserListResponse = (users: IListUserDto[]): IListUser[] => {
  return users.map((user) => {
    return {
      userData: {
        avatarUrl:
          user.userDto?.avatarUrl ?? "/icons/user-avatar-placeholder.svg",
        city: user.userDto?.city,
        firstName: user.userDto?.firstName,
        fullName: `${user.userDto?.firstName} ${user.userDto.lastName}`,
        lastName: user.userDto?.lastName,
        sub: user.userDto?.sub,
      },
      profileData: {
        companyName: user.profileDto?.companyName,
      },
    };
  });
};

export const getPossibleUsersToAdd = createAsyncThunk(
  "/profile/all",
  async () => {
    return await fetchUsersPossibleToAdd();
  }
);

const groupCreationSlice = createSlice({
  name: "groupCreation",
  initialState: {
    availableUsers: [],
    chosenUsers: [],
    isLoading: false,
  } as DefaultState,
  reducers: {
    addChosenUser(state, action) {
      state.chosenUsers = state.chosenUsers.concat(
        state.availableUsers.find(
          (user) => user.userData.sub === action.payload
        )
      );
    },

    deleteChosenUser(state, action) {
      state.chosenUsers = state.chosenUsers.filter(
        (user) => user.userData.sub !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPossibleUsersToAdd.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPossibleUsersToAdd.fulfilled, (state, action) => {
      state.availableUsers = [...convertUserListResponse(action.payload)];
      state.isLoading = false;
    });
  },
});

export const groupCreationReducer = groupCreationSlice.reducer;
export const groupCreationAction = groupCreationSlice.actions;
