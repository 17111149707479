import Button from "common/components/Button";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

import { GhostButtonProps } from "./types";

export const GhostButton = styled(Button)<GhostButtonProps>`
  height: 30px;
  width: 126px;
  font-family: "Inter";
  font-size: 14px;
  color: ${theme.color.basicBlack};
  background-color: ${theme.color.basicWhite};
  letter-spacing: 0.4px;
  margin-right: ${({ mr }) => mr};
  &:hover {
    color: ${theme.color.basicBlack};
    background-color: ${theme.color.lightGray};
  }
`;
