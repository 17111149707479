import styled from "styled-components";

export const ResultDetailsListStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .group {
    background-color: white;
    &:first-of-type {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    box-shadow: -2px 2px 20px 0px rgba(0, 0, 0, 0.05);

    h2 {
      padding: 24px 0px 0px 24px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    overflow: hidden;
  }
`;
