import React, { PropsWithChildren } from "react";

import { StyledRouterLink, Tab } from "./TabNavigation.styled";

interface TabNavigationProps {
  id: string;
  path: string;
  isActive: boolean;
  activeColor: string;
  paddingBottom: number;
  borderHeight: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const TabNavigation = ({
  id,
  path,
  isActive,
  activeColor,
  paddingBottom,
  borderHeight,
  onClick,
  children,
}: PropsWithChildren<TabNavigationProps>) => {
  return (
    <StyledRouterLink to={path}>
      <Tab
        isActive={isActive}
        activeColor={activeColor}
        paddingBottom={paddingBottom}
        borderHeight={borderHeight}
        onClick={onClick}
        id={id}
      >
        {children}
      </Tab>
    </StyledRouterLink>
  );
};

export default TabNavigation;
