import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const PanelsContentWrapper = styled.div`
  display: grid;
  grid-template-areas: "leftSideBar mainContent rightSideBar";
  grid-template-columns: 260px 5fr 260px;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  background-color: ${theme.color.backgroundGray};
`;
