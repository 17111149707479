import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Paths } from "common/constants";
import { handleUserSession } from "store/auth/authActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const PrivateRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isBeingLoggedIn = useAppSelector((state) => state.auth.isBeingLoggedIn);

  useEffect(() => {
    if (!isBeingLoggedIn) dispatch(handleUserSession());
  }, [dispatch, isBeingLoggedIn]);

  if (isAuthenticated === null || isBeingLoggedIn) return <p>Loading...</p>;
  if (isAuthenticated === false)
    return (
      <Navigate to={Paths.LOGIN} replace state={{ redirectTo: location }} />
    );
  return <Outlet />;
};
