import React from "react";
import { useLocation } from "react-router-dom";

import { SearchFilters } from "common/components/TopMenuPanel/Search/SearchFilters";
import { Paths } from "common/constants";

import NotesRightPanel from "../../NotesPage/NotesRightPanel/NotesRightPanel";

import { RightPanelWrapper } from "./RightPanel.styled";

const RightPanel = () => {
  const { pathname } = useLocation();
  const project = () => {
    switch (pathname) {
      case Paths.NOTES:
      case Paths.CREATE_NOTE:
        return <NotesRightPanel />;
      case Paths.SEARCH_DETAILS:
        return <SearchFilters />;
      default:
        return <p>Right Panel</p>;
    }
  };
  return <RightPanelWrapper>{project()}</RightPanelWrapper>;
};

export default RightPanel;
