import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { theme } from "common/theme/theme";
import { useAppSelector } from "store/hooks";

import { Description, NameWrapper } from "../LeftPanel.styled";

import { MainHeaderWrapper } from "./LeftPanelHeader.styled";

const LeftPanelGroupHeader = () => {
  const { groupId } = useCurrentGroupId();
  const currentGroup = useAppSelector((state) =>
    state.group.groups.find((group) => group.id === groupId)
  );

  if (!currentGroup) {
    return null;
  }

  return (
    <MainHeaderWrapper>
      <Image
        src={currentGroup.picture}
        alt="logo group"
        width={42}
        height={42}
        radius="100%"
      />
      <Description>
        <NameWrapper>
          <Text size="18px" weight="bold" color={theme.color.basicBlack}>
            {currentGroup.name}
          </Text>
        </NameWrapper>
        <Text size="12" color={theme.color.darkGray}>
          {currentGroup.role}
        </Text>
      </Description>
    </MainHeaderWrapper>
  );
};

export default LeftPanelGroupHeader;
