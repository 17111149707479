import * as React from "react";

import { theme } from "common/theme/theme";
import styled from "styled-components";

const ModalLabelStyled = styled.p`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.darkGray};
  letter-spacing: 0.2px;
  text-align: left;
`;

export function ModalLabel({
  ...props
}: React.ComponentProps<typeof ModalLabelStyled>) {
  return <ModalLabelStyled {...props} />;
}
