import { theme } from "common/theme/theme";
import styled from "styled-components";

interface InputProps {
  isValid?: boolean;
}

export const ModalCreateListInput = styled.input<InputProps>`
  width: 100%;
  padding: 14px 0px;
  cursor: pointer;
  background: none;
  text-align: left;
  border: none;
  outline: 0;
  border-bottom: 1px solid
    ${(p) => {
      return p.isValid ? theme.color.darkGray : theme.color.alertRed;
    }};
  color: ${theme.color.mediumGray};
  &:focus {
    outline: none;
    border-bottom: 1px solid
      ${(p) => (p.isValid ? theme.color.darkGray : theme.color.alertRed)}
  ${() => {
    return `
    &::placeholder {
      color: transparent;
    }`;
  }};

  &:-webkit-autofill {
    box-shadow: inset 50px 50px 0 ${theme.color.basicWhite},
      inset -50px -50px 0 ${theme.color.basicWhite};
    -webkit-text-fill-color: ${theme.color.mediumGray} !important;
  }
`;
