const createRootElement = (id: string): HTMLDivElement => {
  const rootContainer = document.createElement("div");

  rootContainer.setAttribute("id", id);

  return rootContainer;
};

const addRootElement = (rootElem: Element) => {
  const elements = document.body.querySelectorAll("body > div");

  if (elements.length > 0) {
    const lastElement = elements[elements.length - 1].nextElementSibling;

    document.body.insertBefore(rootElem, lastElement);
  } else {
    document.body.appendChild(rootElem);
  }
};

export const usePortal = (id: string) => {
  const existingPortalContainer = document.body.querySelector(`#${id}`);

  const portalContainer = existingPortalContainer || createRootElement(id);

  if (!existingPortalContainer) {
    addRootElement(portalContainer);
  }

  return portalContainer;
};
