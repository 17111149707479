import React, { ChangeEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import Flex from "common/components/Flex";
import FormInput from "common/components/FormInputHookForms";
import FormSelect from "common/components/FormSelect/FormSelect";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import Text from "common/components/Text";
import { useSub } from "common/hooks/useSub";
import { categoryCreate } from "store/categoriesSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { CategoryNameWrapper } from "../AddCategoryModalContent/AddCategoryModalContent.styled";
import { NameLengthCounter } from "../NameCounter/NameCounter";

import { ModalFormWrapper } from "./AddSubcategoryModal.styled";
import { validationSchema } from "./validationSchema";

export interface AddSubcategoryData {
  subcategoryName: string;
  parent: {
    text: string;
    value: string;
  };
}

interface AddSubcategoryModalProps {
  onClose: () => void;
  categoryId?: string; // change to obligatory when create deleting categories
}

export const AddSubcategoryModal = ({
  onClose,
  categoryId,
}: AddSubcategoryModalProps) => {
  const { sub } = useSub();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.category);
  const [activeCategoryName, setActiveCategoryName] = useState("");
  const [subcategoryLength, setSubcategoryLength] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const category = categories.find((item) => item.id === categoryId);
  const initialParent = category
    ? { text: category.categoryName, value: categoryId }
    : { text: "", value: "" };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    trigger,
    formState: { errors, touchedFields, isValidating },
  } = useForm<AddSubcategoryData>({
    resolver: yupResolver(validationSchema(activeCategoryName, categories)),
    mode: "all",
    defaultValues: {
      subcategoryName: "",
      parent: initialParent,
    },
  });

  const [categoriesOptions, setCategoriesOptions] = useState([
    {
      text: "",
      value: "",
    },
  ]);

  useEffect(() => {
    const arr = categories.map((category) => ({
      value: category.id,
      text: category.categoryName,
    }));
    setCategoriesOptions(arr);
  }, [categories]);

  const onSubmit: SubmitHandler<AddSubcategoryData> = async (data) => {
    if (!isAdding) {
      setIsAdding(true);
      const newCategory = {
        owner: sub,
        category_name: data.subcategoryName,
        parent: data.parent.value,
      };
      await dispatch(categoryCreate(newCategory));
      onClose();
      setIsAdding(false);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "subcategoryName")
      setSubcategoryLength(e.target.value.length);
    trigger();
  };

  const parent = getValues("parent");
  useEffect(() => {
    setActiveCategoryName(parent.text);
  }, [parent]);

  useEffect(() => {
    if (touchedFields.subcategoryName) trigger();
  }, [trigger, activeCategoryName, touchedFields.subcategoryName]);

  return (
    <ModalFormWrapper onChange={onChange}>
      <Text
        size="20px"
        weight="bold"
        lineHeight="24px"
        letterSpacing="1px"
        mt="12px"
        mb="24px"
      >
        Utwórz podkategorię
      </Text>
      <CategoryNameWrapper>
        <FormInput
          id="subcategoryName"
          placeholder="Podaj nazwę podkategorii"
          label="Nazwa podkategorii"
          type="text"
          isValid={
            !touchedFields.subcategoryName ||
            (!errors.subcategoryName && !isValidating)
          }
          touched={touchedFields.subcategoryName}
          register={register}
          inputName="subcategoryName"
          errorText={errors?.subcategoryName?.message as string}
          mt="20px"
          mb="18px"
          width="494px"
        />
        <NameLengthCounter length={subcategoryLength} bottom="-12px" />
      </CategoryNameWrapper>
      <FormSelect
        id="parent"
        options={categoriesOptions}
        label="Kategoria nadrzędna"
        control={control}
        type="text"
        isValid={!errors.parent?.text}
        errorText={errors.parent?.text?.message}
        mb="24px"
        mt="26px"
        width="494px"
      />
      <Flex justify="flex-end" width="494px">
        <GhostButton mr="12px" type="reset" onClick={onClose}>
          Anuluj
        </GhostButton>
        <SmallButton onClick={handleSubmit(onSubmit)} type="submit">
          Utwórz
        </SmallButton>
      </Flex>
    </ModalFormWrapper>
  );
};
