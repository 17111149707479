import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

import Text from "../Text";

interface ICircularIconWrapperProps {
  backgroundColor: string;
  cursorPointer: boolean;
  height: string;
  width: string;
}

interface IHeaderText {
  bold: boolean;
}

export const HeaderIconWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  margin-right: 34px;
`;

export const CircularIconWrapper = styled.div<ICircularIconWrapperProps>`
  display: grid;
  place-items: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid ${theme.color.lightGray};
  background-color: ${(props) => props.backgroundColor};
  cursor: ${(props) => (props.cursorPointer ? "pointer" : "")};
  border-radius: 100%;
`;

export const IconTextWrapper = styled.div`
  margin-left: 25px;
`;

export const Splitter = styled.div`
  width: 144px;
  margin-top: 4px;
  border-bottom: 1px solid ${theme.color.lightGray};
`;

export const HeaderIconText = styled(Text)<IHeaderText>`
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? "700" : "")};
`;
