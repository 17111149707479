import React from "react";

import { useGetFileQuery } from "store/upload/uploadApi";

import { AttachmentSliderBottomItemWrapper } from "./AttachmentSliderBottomItem.styled";

interface AttachmentSliderBottomProps {
  attachment: string;
  isSelected: boolean;
  selectAttachment: () => void;
}

const AttachmentSliderBottomItem = ({
  attachment,
  isSelected,
  selectAttachment,
}: AttachmentSliderBottomProps) => {
  const { data } = useGetFileQuery({ fileUrl: attachment });
  return (
    <AttachmentSliderBottomItemWrapper
      onClick={selectAttachment}
      isSelected={isSelected}
      imageUrl={data}
    />
  );
};

export default AttachmentSliderBottomItem;
