import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { CheckboxLifting } from "common/components/CheckboxLifting/CheckboxLifting";
import DatePicker from "common/components/DatePickerHookForms";
import FormInput from "common/components/FormInputHookForms";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import { educationSchema } from "common/constants/educationSchema";
import { useSub } from "common/hooks/useSub";
import {
  useGetProfileQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from "store/user/userApi";

import {
  ModalForm,
  ModalFormButtons,
  ModalFormFooter,
  ModalFormTitle,
} from "../ModalForm/ModalForm.styled";

interface Props {
  onClose: () => void;
  titlePrefix: string;
}

export const ModalEducation = ({ onClose, titlePrefix }: Props) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });

  const { data: profile } = useGetProfileQuery(
    {
      sub,
      language: user?.language,
    },
    {
      skip: !sub || !user?.language,
    }
  );

  const [updateProfile] = useUpdateProfileMutation();

  const {
    control,
    register,
    handleSubmit: handleContactFormSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: {
      schoolName: profile?.schoolName ?? "",
      educationStarted: profile?.educationStarted
        ? new Date(profile.educationStarted)
        : "",
      educationFinished: profile?.educationFinished
        ? new Date(profile.educationFinished)
        : "",
      currentlyInSchool: profile?.currentlyInSchool ?? false,
    },
  });

  // WARNING: Yes, default values are declared in useForm, but sometimes they are not set in the form, so I have to set them manually here
  useEffect(() => {
    if (!profile) {
      return;
    }
    if (profile.currentlyInSchool) {
      setValue("currentlyInSchool", profile.currentlyInSchool);
    }
    if (profile.educationStarted) {
      setValue("educationStarted", new Date(profile.educationStarted));
    }
    if (profile.schoolName) {
      setValue("schoolName", profile.schoolName);
    }
    if (profile.educationFinished) {
      setValue("educationFinished", new Date(profile.educationFinished));
    }
  }, [profile, setValue]);

  const onContactFormSubmit = handleContactFormSubmit(async (profileData) => {
    const parsed = {
      ...profileData,
      educationStarted: new Date(profileData.educationStarted).toISOString(),
      educationFinished:
        profileData.educationFinished && !profileData.currentlyInSchool
          ? new Date(profileData.educationFinished).toISOString()
          : "",
    };

    await updateProfile({ sub, language: user.language, profileData: parsed });
    onClose();
  });

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 100, 0, 1);
  const minEndData = new Date(watch("educationStarted")) || new Date();

  return (
    <ModalForm>
      <ModalFormTitle>{titlePrefix} Wykształcenie</ModalFormTitle>
      <FormInput
        mt="1rem"
        mb="2rem"
        label="Nazwa uczelni"
        touched={true}
        type="schoolName"
        inputName="schoolName"
        register={register}
        id="schoolName"
        placeholder="Podaj nazwę uczelni"
        isValid={errors.schoolName === undefined}
        errorText={errors.schoolName?.message}
      />
      <DatePicker
        mb="2rem"
        label="Data rozpoczęcia"
        control={control}
        id="educationStarted"
        name="educationStarted"
        isValid={errors.educationStarted === undefined}
        errorText={errors.educationStarted?.message}
        maxDate={new Date()}
        minDate={minDate}
        format="MM.yyyy"
      />
      {!watch("currentlyInSchool") && (
        <DatePicker
          mb="2rem"
          label="Data zakończenia"
          control={control}
          id="educationFinished"
          name="educationFinished"
          isValid={errors.educationFinished === undefined}
          errorText={errors.educationFinished?.message}
          maxDate={new Date()}
          minDate={minEndData}
          format="MM.yyyy"
        />
      )}
      <ModalFormFooter>
        <CheckboxLifting {...register("currentlyInSchool")}>
          Nadal się uczę
        </CheckboxLifting>
        <ModalFormButtons>
          <GhostButton
            onClick={onClose}
            width="max-content !important"
            padding="1rem"
            display="flex"
            margin="0 .5rem 0 0"
            type="button"
          >
            Anuluj
          </GhostButton>
          <SmallButton type="button" onClick={onContactFormSubmit}>
            Zapisz
          </SmallButton>
        </ModalFormButtons>
      </ModalFormFooter>
    </ModalForm>
  );
};
