import React from "react";

import PostContentAttachment from "./PostContentAttachment/PostContentAttachment";
import {
  PostAttachmentsWrapper,
  ThreePostAttachmentsGrid,
  ThreePostAttachmentsRightGrid,
  TwoPostAttachmentsGrid,
} from "./PostContentAttachments.styled";

interface PostAttachmentsProps {
  attachments: string[];
  onClick: () => void;
}

const PostAttachments = ({ attachments, onClick }: PostAttachmentsProps) => {
  return (
    <PostAttachmentsWrapper onClick={onClick}>
      {attachments?.length === 1 &&
        attachments?.map((attachment, index) => (
          <PostContentAttachment attachment={attachment} key={index} />
        ))}
      {attachments?.length === 2 && (
        <TwoPostAttachmentsGrid>
          {attachments?.map((attachment, index) => (
            <PostContentAttachment attachment={attachment} key={index} />
          ))}
        </TwoPostAttachmentsGrid>
      )}
      {attachments?.length >= 3 && (
        <ThreePostAttachmentsGrid>
          <PostContentAttachment attachment={attachments[0]} key={0} />
          <ThreePostAttachmentsRightGrid>
            {attachments
              ?.filter((_, index) => index !== 0)
              .map((attachment, index) => (
                <PostContentAttachment
                  attachment={attachment}
                  key={index + 1}
                  moreImagesCount={
                    attachment.length > 3 && index === 1
                      ? attachments.length - 3
                      : null
                  }
                />
              ))}
          </ThreePostAttachmentsRightGrid>
        </ThreePostAttachmentsGrid>
      )}
    </PostAttachmentsWrapper>
  );
};

export default PostAttachments;
