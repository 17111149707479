import React from "react";

import Button from "common/components/Button";
import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

export default function AttachmentsField() {
  return (
    <Flex>
      <Button
        variant="outlined"
        padding="8px 2px"
        border="none"
        display="flex"
        width="150px"
      >
        <Image src="/icons/add_annex.svg" alt="Plus" width={20} height={20} />
        <Text
          ml="8px"
          size="14px"
          weight="bold"
          letterSpacing="0.2px"
          color={theme.color.basicBlack}
        >
          Dodaj załącznik
        </Text>
      </Button>
    </Flex>
  );
}
