import React from "react";

import LocalizationIcon from "common/icons/LocalizationIcon";
import { theme } from "common/theme/theme";

import Image from "../Image/Image";
import { Container } from "../Layout/Containers";
import Text from "../Text/Text";

import { LocalizationWrapper, UserInfoWrapper } from "./UserListItem.styled";

interface UserListItemProps {
  avatarUrl: string;
  fullName: string;
  occupation: string;
  localization: string;
}

const UserListItem = ({
  avatarUrl,
  fullName,
  occupation,
  localization,
}: UserListItemProps) => {
  return (
    <UserInfoWrapper>
      <Image src={avatarUrl} radius="100%" width={48} height={48} />
      <Container m="0 0 0 16px" w="371px">
        <Text
          weight="bold"
          size="16px"
          color={theme.color.basicBlack}
          lineHeight="24px"
          data-testid="user-fullname"
        >
          {fullName}
        </Text>
        <Text
          color={theme.color.basicBlack}
          size="14px"
          lineHeight="20px"
          data-testid="user-occupation"
        >
          {occupation}
        </Text>
        <LocalizationWrapper>
          <LocalizationIcon
            width="16px"
            height="16px"
            color={theme.color.mediumGray}
            data-testid="user-localization"
          />
          <Text size="12px" color={theme.color.mediumGray} lineHeight="20px">
            {localization}
          </Text>
        </LocalizationWrapper>
      </Container>
    </UserInfoWrapper>
  );
};

export default UserListItem;
