import React from "react";

import Avatar from "common/components/Avatar/Avatar";
import GhostButton from "common/components/GhostButton/GhostButton";

import SmallButton from "../SmallButton/SmallButton";

import { ButtonsContainer, Container, Subtitle, Title } from "./shared/style";

type AvatarRemoveProps = {
  height?: string;
  imageSrc: string;
  onCancel: () => void;
  onRemove: () => void;
  subtitle: string;
  title: string;
  width?: string;
};

const AvatarRemove = ({
  height,
  imageSrc,
  onCancel,
  onRemove,
  subtitle,
  title,
  width,
}: AvatarRemoveProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Avatar
        editable={false}
        height={height}
        imageSrc={imageSrc}
        width={width}
      />
      <ButtonsContainer>
        <GhostButton onClick={onCancel}>Anuluj</GhostButton>
        <SmallButton onClick={onRemove}>Tak, usuń</SmallButton>
      </ButtonsContainer>
    </Container>
  );
};

export default AvatarRemove;
