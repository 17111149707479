import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const GroupCloseModalWrapper = styled.div`
  width: 305px;
  height: 158px;
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const GroupCloseModalParagraph = styled(Text)`
  width: 240px;
  font-size: 14px;
  font-family: ${theme.fontFamily.secondary};
  margin-top: 16px;
  text-align: center;
`;
