import React from "react";
import { Path, UseFormSetValue } from "react-hook-form";

import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";

import GroupCreationRadioButton from "./GroupRadioButton/GroupRadioButton";
import {
  GroupCreationRadioButtonsWrapper,
  SpaceBetweenRadioButtons,
} from "./GroupRadioButtons.styled";

interface GroupRadioButtonsProps<T> {
  inputName: Path<T>;
  header: string;
  firstDescription: string;
  secondDescription: string;
  setValue: UseFormSetValue<T>;
  selected: string;
  firstButtonValue: string;
  secondButtonValue: string;
  firstButtonText: string;
  secondButtonText: string;
}

const GroupRadioButtons = <T,>({
  firstButtonValue,
  firstButtonText,
  secondButtonText,
  firstDescription,
  inputName,
  header,
  secondButtonValue,
  secondDescription,
  setValue,
  selected,
}: GroupRadioButtonsProps<T>) => {
  return (
    <GroupCreationRadioButtonsWrapper>
      <Text size="14px" weight="semibold" align="left">
        {header}
      </Text>
      <Wrapper>
        <GroupCreationRadioButton
          description={firstDescription}
          inputName={inputName}
          selected={selected}
          setValue={setValue}
          value={firstButtonValue}
          radioButtonText={firstButtonText}
          data-testid={`${inputName}-radio-btn`}
        />
        <SpaceBetweenRadioButtons />
        <GroupCreationRadioButton
          description={secondDescription}
          inputName={inputName}
          selected={selected}
          setValue={setValue}
          value={secondButtonValue}
          radioButtonText={secondButtonText}
          data-testid={`${inputName}-radio-btn`}
        />
      </Wrapper>
    </GroupCreationRadioButtonsWrapper>
  );
};

export default GroupRadioButtons;
