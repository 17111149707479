import React, { useMemo, useRef, useState } from "react";

import { IListUser } from "store/groupCreation/types";

import {
  RightTableWrapper,
  RightUsersTableNavigationDown,
  RightUsersTableNavigationUp,
  RightUsersTableWrapper,
} from "./RightUsersTable.styled";
import RightUsersTableItem from "./RightUsersTableItem/RightUsersTableItem";

interface RightUsersTableProps {
  chosenUsers: IListUser[];
  deleteUserHandler: (userId: string) => void;
}

const RightUsersTable = ({
  chosenUsers,
  deleteUserHandler,
}: RightUsersTableProps) => {
  const [scrollTopHeight, setScrollTopHeight] = useState(0);
  const usersTableRef = useRef<HTMLDivElement>();
  const scrollUp = () => {
    setScrollTopHeight(usersTableRef.current.scrollTop);
    usersTableRef.current.scrollBy(0, -50);
  };
  const scrollDown = () => {
    setScrollTopHeight(usersTableRef.current.scrollTop);
    usersTableRef.current.scrollBy(0, 50);
  };

  const isAtTop = useMemo(() => scrollTopHeight === 0, [scrollTopHeight]);
  const isAtBottom = useMemo(
    () =>
      usersTableRef.current?.scrollHeight -
        scrollTopHeight -
        usersTableRef.current?.clientHeight <
      1,
    [scrollTopHeight]
  );

  return (
    <RightTableWrapper>
      {chosenUsers.length > 7 && !isAtTop ? (
        <RightUsersTableNavigationUp onClick={scrollUp}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.075 7.075L0 6L6 0L12 6L10.925 7.075L6 2.15L1.075 7.075Z"
              fill="#4A4A4A"
            />
          </svg>
        </RightUsersTableNavigationUp>
      ) : null}
      <RightUsersTableWrapper ref={usersTableRef}>
        {chosenUsers
          .slice(0)
          .reverse()
          .map((user) => (
            <RightUsersTableItem
              name={user.userData.fullName}
              key={user.userData.sub}
              avatar={user.userData.avatarUrl}
              deleteUser={() => deleteUserHandler(user.userData.sub)}
            />
          ))}
      </RightUsersTableWrapper>
      {chosenUsers.length > 7 && !isAtBottom ? (
        <RightUsersTableNavigationDown onClick={scrollDown}>
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.075 7.075L0 6L6 0L12 6L10.925 7.075L6 2.15L1.075 7.075Z"
              fill="#4A4A4A"
            />
          </svg>
        </RightUsersTableNavigationDown>
      ) : null}
    </RightTableWrapper>
  );
};

export default RightUsersTable;
