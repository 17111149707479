import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export default function SpecialCharsItem() {
  const iconSrc = `/icons/down.svg`;
  const iconSpecialCar = `/icons/noteKit/icon_special_car.svg`;

  return (
    <Flex align="center" justify="space-between">
      <Text ml="10px" size="14px">
        Znaki specjalne
      </Text>
      <NoteKitBorderWrapper p="6px 11px">
        <Text>
          <Image
            src={iconSpecialCar}
            alt="icon"
            width={15}
            height={22}
            top="2px"
          />
        </Text>
        <Text ml="10px">
          <Image src={iconSrc} alt="icon" width={13} height={9} top="2px" />
        </Text>
      </NoteKitBorderWrapper>
    </Flex>
  );
}
