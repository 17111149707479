import styled from "styled-components";

export const PostModalUserWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  margin-top: 20px;
`;

export const PostModalUserRightTable = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
`;

export const VisibilityDropdown = styled.div`
  display: flex;
  border: 1px solid;
  border-radius: 40px;
  width: 87px;
  height: 20px;
  justify-content: space-around;
  padding-left: 6.33px;
  padding-right: 8px;
  align-items: center;
  cursor: pointer;
`;
