import React from "react";

import axios, { AxiosError, AxiosResponse } from "axios";
import { mapTaskToUpdateTaskCompletedPayload } from "services/api/tasker/tasker.mappers";
import { taskerUpdateTaskRq } from "services/api/tasker/taskerUpdateTaskRq";
import { useAppDispatch } from "store/hooks";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

import Checkbox from "../Checkbox";

interface TaskCompletedProps {
  task: Task;
}

export default function TaskCompletedGroupView({ task }: TaskCompletedProps) {
  const dispatch = useAppDispatch();
  const toggleTaskCompleted = async (completed: boolean) => {
    const payload = mapTaskToUpdateTaskCompletedPayload(
      { ...task, completed },
      task.group_id
    );
    const response: AxiosResponse | AxiosError = await taskerUpdateTaskRq(
      payload
    );

    if (axios.isAxiosError(response)) {
      console.log(response);
    } else {
      if (response.status === 200) {
        console.log("Task Completed: Updated");
        dispatch(
          taskerAction.setTaskCompletedInGroupView({
            listId: task.list_id,
            taskId: task.id,
            completed,
          })
        );
      }
    }
  };

  return (
    <Checkbox
      checked={task.completed}
      width={16}
      height={16}
      uncheckedIconSrc="/icons/rectangle_checkbox_empty.svg"
      checkedIconSrc="/icons/rectangle_checkbox_filled.png"
      onChecked={() => toggleTaskCompleted(!task.completed)}
    />
  );
}
