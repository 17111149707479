import { IListResponse } from "services/api/tasker/tasker.responses";

import { IList } from "./../models/List";

export function createTasksListFromTasksListResponse(
  task: IListResponse
): IList {
  return {
    id: task.id,
    group_id: task.group_id,
    name: task.name,
    last_update_date: task.last_update_date,
    tasks: [],
  };
}
