import Button from "common/components/Button";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface GroupCreationButtonStyledProps {
  height: string;
  width: string;
  light: boolean;
}

export const GroupButtonStyled = styled(Button)<GroupCreationButtonStyledProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;
  color: ${(props) =>
    props.light ? theme.color.basicBlack : theme.color.basicWhite};
  background-color: ${(props) =>
    props.light ? theme.color.basicWhite : theme.color.basicBlack};
  ${(props) =>
    props.light ? `border: solid 2px ${theme.color.basicBlack}` : ""};
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
