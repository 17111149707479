import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from "react";

import { RegistrationStep } from "./types";

type RegistrationStepContextValue = {
  currentStep: RegistrationStep;
  setCurrentStep: Dispatch<SetStateAction<RegistrationStep>>;
};
type RegistrationStepProviderProps = RegistrationStepContextValue & {
  children: ReactNode;
};

const RegistrationStepContext = createContext<RegistrationStepContextValue>({
  currentStep: null,
  setCurrentStep: (prev) => prev,
});

export function RegistrationStepProvider(props: RegistrationStepProviderProps) {
  return (
    <RegistrationStepContext.Provider value={props}>
      {props.children}
    </RegistrationStepContext.Provider>
  );
}

export function useRegistrationStepContext() {
  const context = useContext(RegistrationStepContext);
  if (context) return context;
  throw new Error(
    `${useRegistrationStepContext.name} must be used within a ${RegistrationStepProvider.name}`
  );
}
