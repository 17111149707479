import styled from "styled-components/macro";

const StyledSuccessBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 668px;
  max-width: 100%;
  height: 508px;
  max-height: 100%;
`;

export default StyledSuccessBlock;
