import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const NameLengthCounterWrapper = styled.p<{
  isValid: boolean;
  bottom: string;
}>`
  position: absolute;
  bottom: ${(props) => props.bottom || "-30px"};
  right: 0;
  color: ${(props) =>
    props.isValid ? theme.color.mediumGray : theme.color.alertRed};
  text-align: right;
  font-size: 12px;
`;
