import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const OptionsWrapper = styled.div`
  position: relative;
`;

export const ImageButton = styled.button`
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SubImageButton = styled.button`
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  background-color: ${theme.color.basicWhite};
  width: 260px;
  height: 64px;
  right: 0%;
  box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px;
  cursor: auto;
`;

export const DropdownButton = styled.div`
  border-radius: 8px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${theme.color.veryLightGray};
  }
`;
