import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Buffer } from "buffer";
import { ResultDetailsList } from "common/components/TopMenuPanel/Search/ResultDetailsList";
import { localStorageKeys } from "common/constants";
import { Paths } from "common/constants/paths";
import { PrivateRoutes } from "common/utils/wrappers/PrivateRoutes";
import Authentication from "components/Authentication";
import ChangePasswordForm from "components/Authentication/ChangePasswordForm/ChangePasswordForm";
import Dashboard from "components/Dashboard";
import Group from "components/Group/Group";
import BasicLayout from "components/Layout/BasicLayout/BasicLayout";
import PanelsLayout from "components/Layout/PanelsLayout/PanelsLayout";
import { NotesPage } from "components/NotesPage";
import NewNote from "components/NotesPage/Note/NewNote";
import { INoteLocalStorage } from "components/NotesPage/Types/notes";
import Page404 from "components/Page404/Page404";
import Profile from "components/Profile/Profile";
import { ProfileCreation } from "components/ProfileCreation/index";
import TaskerPage from "components/TaskerPage/TaskerPage";
import { createNote, updateNote } from "services/api/note/noteApi";
import { notesAction } from "store/notes/notesSlice";

function RoutePlaceholder() {
  return <PanelsLayout />;
}

function AppRouter() {
  /** Auto save note */
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const handleNotes = async () => {
      const localStorageNote = localStorage.getItem(localStorageKeys.note);
      if (localStorageNote) {
        const note = JSON.parse(localStorageNote) as INoteLocalStorage;
        if (!note.edit) {
          await createNote({
            note_contents:
              note.noteContents.length === 0
                ? Buffer.from(
                    '[{"type":"paragraph","children":[{"text":"Your note"}]}]'
                  ).toString("base64")
                : Buffer.from(note.noteContents).toString("base64"),
            title: note.title.length === 0 ? "Auto save" : note.title,
            owner: note.owner,
            category: note.category.length === 0 ? null : note.category,
          });
        } else {
          await updateNote({
            note_contents: note.noteContents,
            title: note.title,
            id: note.id,
          });
        }
        dispatch(notesAction.setAutoSave(false));

        localStorage.removeItem(localStorageKeys.note);
        if (location.pathname.includes(Paths.CREATE_NOTE)) {
          navigate(Paths.NOTES);
        }
      }
    };
    handleNotes();
  }, [location.pathname, navigate, dispatch]);
  return (
    <Routes>
      <Route element={<BasicLayout />}>
        <Route path={Paths.AUTHPAGE} element={<Authentication />} />
        <Route path={Paths.CHANGE_PASSWORD} element={<ChangePasswordForm />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route element={<BasicLayout />}>
          <Route path={Paths.CREATE_PROFILE} element={<ProfileCreation />} />
          <Route path={Paths.CALENDAR} element={<RoutePlaceholder />} />
        </Route>
        <Route element={<PanelsLayout />}>
          <Route
            path={Paths.INDEX}
            element={<Navigate to={Paths.DASHBOARD} replace />}
          />
          <Route path={Paths.DASHBOARD} element={<Dashboard />} />
          <Route path={Paths.GROUP} element={<Group />} />
          <Route path={Paths.GROUP + "/:id"} element={<Group />} />
          <Route path={Paths.NOTES} element={<NotesPage />} />
          <Route path={Paths.CREATE_NOTE} element={<NewNote />} />
          <Route path={Paths.PROFILE} element={<Profile />} />
          <Route
            path={Paths.GROUP_TASK_BOARD + "/:id"}
            element={<TaskerPage />}
          />
          <Route path={Paths.USER_TASK_BOARD} element={<TaskerPage />} />
          <Route path={Paths.SEARCH_DETAILS} element={<ResultDetailsList />} />
        </Route>
      </Route>

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default AppRouter;
