import { DropdownItemsWrapper } from "common/components/Forms/Dropdown/DropdownItemsWrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components";

export const FormSelectScrollbarWrapper = styled.div<{
  scrollBarPadding?: string;
}>`
  height: 100%;
  max-height: 150px;
  overflow-y: scroll;
  padding: ${(props) => props.scrollBarPadding};
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 89px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;

export const WhiteDropdownItemsWrapper = styled(DropdownItemsWrapper)`
  border: 1px solid ${theme.color.basicWhite};
`;
