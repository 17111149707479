import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Text from "common/components/Text";
import { localStorageKeys, Paths } from "common/constants";
import { useSub } from "common/hooks/useSub";
import { theme } from "common/theme/theme";
import { getNotesList } from "services/api/note/noteApi";
import { fetchCategories } from "store/categoriesSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { notesAction } from "store/notes/notesSlice";

import NoteHeader from "./Note/Header/NoteHeader/NoteHeader";
import NoNotesInfo from "./Note/NoNotesInfo";
import Note from "./Note/Note";
import { serializeText } from "./Note/TextEditor/EditorUtils";
import {
  NotesContainer,
  NotesPageContainer,
  NotesPageDivider,
  NotesPageHeader,
} from "./NotePage.styled";
import { convertNoteFromDto, ICategory, INote } from "./Types/notes";

const NotesPage = () => {
  const dispatch = useAppDispatch();
  const notesSlice = useAppSelector((state) => state.note);
  const navigate = useNavigate();
  const sub = useSub().sub;

  const [notes, setNotes] = useState<INote[]>([]);

  const [notesWithCategory, setNotesWithCategory] = useState<INote[]>([]);

  const { categories } = useAppSelector((state) => state.category);
  const [categoryList, setCategoryList] = useState<ICategory[]>(categories);
  useEffect(() => {
    dispatch(fetchCategories(sub));
  }, [dispatch, sub]);

  useEffect(() => {
    const getNotes = async () => {
      await getNotesList(sub).then((items) => {
        const mapedNotes = items?.map(convertNoteFromDto);
        const sortedByDate = mapedNotes.sort(
          (a: INote, b: INote) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        setNotes(sortedByDate);
      });
    };

    const localStorageItem = localStorage.getItem(localStorageKeys.note);
    if (localStorageItem) {
      const intervalId = setInterval(() => {
        if (!localStorage.getItem(localStorageKeys.note)) {
          clearInterval(intervalId);
          getNotes();
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      getNotes();
    }
  }, [sub]);
  useEffect(() => {
    setCategoryList(categories);
  }, [categories]);

  useEffect(() => {
    const notesWithCategory = notes.map((note) => {
      const findingCategory = categoryList.find((category) => {
        return category.id === note.category;
      });
      return { ...note, categoryName: findingCategory?.categoryName };
    });
    setNotesWithCategory(notesWithCategory);
  }, [notes, categoryList]);

  const editNote = (id: string) => {
    const note = notesWithCategory.find((n) => n.id === id)!;
    dispatch(notesAction.openEditNote(note));
    navigate(Paths.CREATE_NOTE);
  };

  const deleteNoteFromState = (id: string) => {
    const filteredNotes = notes.filter((note) => note.id !== id);
    setNotes(filteredNotes);
  };

  useEffect(() => {
    if (
      !notesSlice.noteAutoSave &&
      localStorage.getItem(localStorageKeys.note)
    ) {
      localStorage.removeItem(localStorageKeys.note);
    }
  }, [notesSlice.noteAutoSave]);
  if (notes.length === 0) {
    return (
      <NotesPageContainer>
        <NotesPageHeader>
          <Text size="24px" weight="bold" color={theme.color.basicBlack}>
            Notatnik
          </Text>
        </NotesPageHeader>
        <NotesPageDivider />
        <NoNotesInfo />
        <NotesContainer />
      </NotesPageContainer>
    );
  }
  return (
    <NotesPageContainer>
      <NotesPageHeader>
        <NoteHeader />
      </NotesPageHeader>
      <NotesPageDivider />
      <NotesContainer>
        {(notesWithCategory || []).map((item) => (
          <Note
            key={item.id}
            header={item.title}
            text={serializeText(item.noteContents)}
            date={item.created}
            categoryName={item?.categoryName || null}
            id={item.id}
            deleteNoteFromState={() => deleteNoteFromState(item.id)}
            editNote={editNote}
          />
        ))}
      </NotesContainer>
    </NotesPageContainer>
  );
};

export default NotesPage;
