import React from "react";

import { theme } from "common/theme/theme";
import { CSSProp, DefaultTheme } from "styled-components";
import styled from "styled-components/macro";

export type ButtonVariant = "primary" | "outlined";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  width?: string;
  display?: string;
  backgroundColor?: string;
  radius?: string;
  height?: string;
  border?: string;
  fontSize?: string;
  padding?: string;
  margin?: string;
  letterSpacing?: string;
  hover?: CSSProp<DefaultTheme>;
  type?: "button" | "submit" | "reset" | undefined;
}

const defaultProps: ButtonProps = {
  variant: "primary",
  width: "inherit",
  display: "block",
  radius: "32px",
  height: "56px",
  border: "default",
  fontSize: "24px",
};

const buttonColors = (
  color: string,
  variant: string,
  border: string
): string => {
  return `background-color: ${
    variant === "outlined" ? theme.color.basicWhite : color
  };
  color: ${variant === "outlined" ? color : theme.color.basicWhite};
  border: ${
    variant === "outlined" && border !== "none" ? "2px solid " + color : "none"
  };`;
};

const Button = styled.button<ButtonProps>`
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.padding || "0"};
  font-weight: 700;
  cursor: pointer;
  display: ${({ display }) => display};
  justify-content: space-around;
  align-items: center;
  font-family: ${theme.fontFamily.secondary};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  ${(props) =>
    buttonColors(theme.color.basicBlack, props.variant, props.border)}

  &:hover {
    ${(props) =>
      buttonColors(theme.color.darkGray, props.variant, props.border)}
    ${(props) => props.hover}
  }

  &:disabled {
    color: ${theme.color.disabledPrimary};
    background-color: ${theme.color.disabledSecondary};
  }
`;

Button.defaultProps = defaultProps;

export default Button;
