import Text from "common/components/Text";
import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5%;
`;

export const Content = styled(Text)`
  font-size: 14px;
  overflow-wrap: anywhere;
`;
