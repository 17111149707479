import React from "react";

import GroupButton from "../../GroupButton/GroupButton";
import GroupPlainButton from "../../GroupPlainButton/GroupPlainButton";

import { GroupModalButtonsWrapper } from "./GroupModalButtons.styled";

interface GroupModalButtonsProps {
  cancelButtonText: string;
  cancelButtonHandler: () => void;
  cancelButtonHeight: string;
  cancelButtonWidth: string;
  proceedButtonHandler: () => void;
  proceedButtonText: string;
  proceedButtonHeight?: string;
  proceedButtonWidth?: string;
}

const GroupModalButtons = ({
  cancelButtonText,
  cancelButtonHandler,
  cancelButtonHeight,
  cancelButtonWidth,
  proceedButtonHandler,
  proceedButtonHeight,
  proceedButtonWidth,
  proceedButtonText,
}: GroupModalButtonsProps) => {
  return (
    <GroupModalButtonsWrapper>
      <GroupPlainButton
        buttonText={cancelButtonText}
        onClick={cancelButtonHandler}
        width={cancelButtonWidth}
        height={cancelButtonHeight}
        testId="cancel-button"
      />
      <GroupButton
        buttonLight={false}
        clickHandler={proceedButtonHandler}
        buttonText={proceedButtonText}
        width={proceedButtonWidth}
        height={proceedButtonHeight}
        testId="proceed-button"
      />
    </GroupModalButtonsWrapper>
  );
};

export default GroupModalButtons;
