import styled from "styled-components";

export const SearchItemCardStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  .text {
    width: calc(100% - 40px - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    h3 {
      color: #000;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
    p {
      margin-top: -1.5rem;
      font-size: 1rem;
      color: ${(props) => props.theme.color.darkGray};
    }
  }
  .text-sm {
    width: calc(100% - 50px - 1rem);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    h3 {
      font-size: 1rem;
      font-weight: 300;
    }
  }
`;
