import styled from "styled-components/macro";

export const Loader = styled.div`
  position: relative;
  margin: 12px auto 38px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #1d1d1d;
  width: 68px;
  height: 68px;
  animation: spinner 2s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledLogoutModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px;
  width: 283px;
  min-height: 263px;
`;
export default StyledLogoutModal;
