import styled from "styled-components/macro";

interface BorderProps {
  p?: string;
}

const defaultProps: BorderProps = {
  p: "0",
};

const NoteKitBorderWrapper = styled.div<BorderProps>`
  display: flex;
  justify-content: space-between;
  border: 1px solid #eceeef;
  border-radius: 4px;
  padding: ${(prop) => prop.p};
`;

NoteKitBorderWrapper.defaultProps = defaultProps;

const NoteKitBorderRadiusWrapper = styled.div`
  border-radius: 4px;
`;

export { NoteKitBorderWrapper, NoteKitBorderRadiusWrapper };
