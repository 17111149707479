import styled from "styled-components/macro";

const NotesPageContainer = styled.div`
  height: 100%;
  padding: 40px 0;
  background-color: ${({ theme }) => theme.color.backgroundGray};
`;

const NotesPageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const NotesPageDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.mediumGray};
  opacity: 0.2;
`;

const EmptyNotePage = styled.div`
  position: fixed;
  top: 316px;
  left: 566px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const NotesContainer = styled.div`
  width: -webkit-fill-available;
  display: grid;
  grid-template-columns: 212px 212px 212px;
  grid-template-rows: 212px;
  justify-content: center;
  padding-top: 16px;
  gap: 24px;
  background-color: ${({ theme }) => theme.color.backgroundGray};
`;

export {
  NotesPageContainer,
  NotesPageHeader,
  EmptyNotePage,
  NotesContainer,
  NotesPageDivider,
};
