import React, { useState } from "react";

import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import CommentCreationModal from "components/Post/PostComment/CommentCreation/CommentCreationModal";

import { CommentReplyButton, CommentReplyWrapper } from "./CommentReply.styled";

interface CommentReplyProps {
  currentCommentId: string;
  refetchComments: () => void;
  setCommentWasCreated: (value: boolean) => void;
}

const CommentReply = ({
  currentCommentId,
  refetchComments,
  setCommentWasCreated,
}: CommentReplyProps) => {
  const [turnModal, setTurnModal] = useState(false);

  const turnOnModal = () => {
    setTurnModal(true);
  };
  const turnOffModal = () => {
    setTurnModal(false);
  };
  return (
    <CommentReplyWrapper>
      <CommentReplyButton onClick={turnOnModal}>
        <Text weight="bold" size="14px" color={theme.color.mediumGray}>
          Odpowiedz
        </Text>
      </CommentReplyButton>
      <CommentCreationModal
        parentId={currentCommentId}
        refetchPosts={refetchComments}
        setPostWasCreated={setCommentWasCreated}
        setTurnOffModal={turnOffModal}
        turnModal={turnModal}
      />
    </CommentReplyWrapper>
  );
};

export default CommentReply;
