import { theme } from "common/theme/theme";
import styled from "styled-components";

interface PostAttachmentImageProps {
  imageUrl: string;
}

export const PostContentAttachmentWrapper = styled.div`
  display: grid;
  height 100%;
`;

export const PostAttachmentImage = styled.div<PostAttachmentImageProps>`
  width: 100%;
  cursor: pointer;
  background-image: url("${(props) => props.imageUrl}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const PostAttachmentImageOverlay = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.basicBlack};
  opacity: 0.2;
`;

export const PostAttachmentImageOverlayWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const PostAttachmentImageOverlayText = styled.div`
  position: absolute;
  width: 195px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${theme.color.basicWhite};
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
`;
