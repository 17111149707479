import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const GroupCreationSearchBar = styled.input`

  border: 1px solid #eceeef;
  padding-left: 16px;
  border-radius: 8px;
  color: ${theme.color.mediumGray}
  outline: none;
  height: 40px;
  width: 333px;
  &:focus {
    outline: none;
  }
  /* margin-right: 46px; */

`;

export const SearchInputSection = styled.div`
  display: grid;
  box-sizing: border-box;
  justify-content: space-between;
  width: 373px;
  border-right: 2px solid ${theme.color.lightGray};
  height: 40px;
  grid-template-columns: repeat(4, min-content);
  align-items: center;
`;
