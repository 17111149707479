import React, { useMemo } from "react";

import { IPost } from "store/group/groupApi.types";

import { CommentListWrapper } from "../../../CommentsList.styled";
import CommentListItem from "../../CommentListItem";

interface CommentListProps {
  subCommentList: IPost[];
  commentId: string;
  addPage: () => void;
  refetchSubComments?: () => void;
  setSubCommentWasCreated: (value: boolean) => void;
}

const SubCommentList = ({
  subCommentList,
  commentId,
  addPage,
  refetchSubComments,
  setSubCommentWasCreated,
}: CommentListProps) => {
  const subCommentItems = useMemo(
    () =>
      subCommentList?.map((comment, index) => ({
        content: <CommentListItem.Content content={comment.content} />,
        footer: (
          <CommentListItem.Footer
            currentCommentId={commentId}
            refetchComments={refetchSubComments}
            setCommentWasCreated={setSubCommentWasCreated}
          />
        ),
        userIcon: <CommentListItem.UserIcon />,
        key: comment.postId,
        isLastComment: index === subCommentList.length - 1,
        addPage: addPage,
        currentCommentId: comment.postId,
        isComment: false,
      })),
    [
      subCommentList,
      commentId,
      addPage,
      refetchSubComments,
      setSubCommentWasCreated,
    ]
  );
  return (
    <CommentListWrapper>
      {subCommentItems?.map((item) => (
        <CommentListItem key={item.key} {...item} />
      ))}
    </CommentListWrapper>
  );
};

export default SubCommentList;
