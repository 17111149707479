import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  display: flex;
  width: 371px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemAddButton = styled.a`
  cursor: pointer;
  margin-right: 37px;
`;

export const LocalizationWrapper = styled.div`
  display: flex;
  width: 371px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 5.33px;
`;
