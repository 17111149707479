import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getJwtToken } from "common/utils/auth/getToken";

export type TRequestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
type TService =
  | "GROUP"
  | "INVITATION"
  | "USER"
  | "NOTE"
  | "TASK"
  | "TASKLISTS"
  | "TASKCATEGORIES"
  | "NOTECATEGORIES"
  | "UPLOAD"
  | "MAIL";

export const apiRequest = async <T, U = void>(
  type: TRequestMethod,
  service: TService,
  url: string,
  requestConfig?: AxiosRequestConfig<U>
): Promise<AxiosResponse<T>> => {
  const serviceType = service.toLowerCase();
  const lowercasedType = type.toLowerCase();

  const endpoints = {
    group: `${process.env.REACT_APP_NEURON_API_URL_BLUE}/${serviceType}${url}`,
    invitation: `${process.env.REACT_APP_NEURON_API_URL_BLUE_INVITATION}/${serviceType}${url}`,
    note: `${process.env.REACT_APP_NEURON_API_URL_GREEN}/Notes${url}`,
    task: `${process.env.REACT_APP_NEURON_API_URL_GREEN}/Tasks${url}`,
    user: `${process.env.REACT_APP_NEURON_API_URL_RED}/${url}`,
    tasklists: `${process.env.REACT_APP_NEURON_API_URL_GREEN}/TaskLists${url}`,
    taskcategories: `${process.env.REACT_APP_NEURON_API_URL_GREEN}/TaskCategories${url}`,
    notecategories: `${process.env.REACT_APP_NEURON_API_URL_GREEN}/NotesCategories${url}`,
  };
  const fullUrl =
    endpoints[serviceType] ??
    `${process.env.REACT_APP_NEURON_API_URL}/${serviceType}${url}`;

  const token = await getJwtToken();
  const headersWithAuthorization: AxiosRequestConfig<U> = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...requestConfig?.headers,
    },
  };
  if (type === "DELETE") {
    return await axios[lowercasedType](fullUrl, {
      data: requestConfig.data,
      headers: { ...headersWithAuthorization.headers },
    });
  }
  if (requestConfig?.data) {
    return await axios[lowercasedType](
      fullUrl,
      requestConfig.data,
      headersWithAuthorization
    );
  }
  return await axios[lowercasedType](fullUrl, headersWithAuthorization);
};
