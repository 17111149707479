import { theme } from "common/theme/theme";
import styled from "styled-components";

export const ScrollbarWrapper = styled.div<{
  padding?: string;
}>`
  height: 100%;
  width: 100%:
  max-height: 150px;
  overflow-y: scroll;
  padding: ${(props) => {
    if (props.padding) return "padding: " + props.padding;
  }};
  &::-webkit-scrollbar {
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 89px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;
