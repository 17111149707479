import React from "react";
import { Link } from "react-router-dom";

import Spinner from "common/components/Spinner";
import { Paths } from "common/constants";
import { SpinnerWrapper } from "components/Layout/LeftPanel/LeftPanelGroup/LeftPanelGroup.styled";
import {
  DEFAULT_POPPER_LIMIT,
  DEFAULT_TYPE,
} from "services/api/search/index.constants";

import { ResultDetailsListStyled } from "./ResultDetailsList.styled";
import { SearchResultCard } from "./SearchResultCard";
import { ShowMoreButtonStyled } from "./ShowMoreButton.styled";
import { useSearch } from "./useSearch";

const BUTTONS_TEXT_MAP = {
  "Grupy Dyskusyjne": {
    text: "Zobacz wszystkie grupy dyskusyjne",
    type: "discuss",
  },
  "Grupy Projektowe": {
    text: "Zobacz wszystkie grupy projektowe",
    type: "projects",
  },
  Użytkownicy: { text: "Zobacz wszystkich użytkowników", type: "users" },
} as const;

const ACTION_BUTTON_MAP = {
  none: "＋ Dołącz",
  requestSent: "Wysłano prośbę",
};

type ButtonTextMapKey = keyof typeof BUTTONS_TEXT_MAP;

export const ResultDetailsList = () => {
  const { displayItems, query, type, isLoading } = useSearch({ details: true });

  return (
    <ResultDetailsListStyled>
      {isLoading && (
        <SpinnerWrapper margin="0 auto">
          <Spinner radius="35px" border="5px" mt="2rem" />
        </SpinnerWrapper>
      )}
      {!isLoading && !displayItems.length && <h3>Nie znaleziono</h3>}
      {displayItems.map((item) => (
        <div key={item.title}>
          <div className="group">
            <h2>{item.title}</h2>
            {item.items.map((card, index) => (
              <SearchResultCard
                key={card.title}
                {...card}
                actionButton={
                  card.groupStatus !== "member" &&
                  item.title.toLowerCase().includes("grupy") && (
                    <Link to={`${Paths.GROUP}/${card.id}`}>
                      {ACTION_BUTTON_MAP[card.groupStatus]}
                    </Link>
                  )
                }
                showMoreButton={
                  index === item.items.length - 1 &&
                  type === DEFAULT_TYPE &&
                  item.count > DEFAULT_POPPER_LIMIT && (
                    <ShowMoreButtonStyled
                      to={{
                        pathname: Paths.SEARCH_DETAILS,
                        search: `?${new URLSearchParams({
                          query,
                          type: BUTTONS_TEXT_MAP[item.title as ButtonTextMapKey]
                            .type,
                        })}`,
                      }}
                    >
                      {BUTTONS_TEXT_MAP[item.title as ButtonTextMapKey].text} (
                      {item.count})
                    </ShowMoreButtonStyled>
                  )
                }
              />
            ))}
          </div>
        </div>
      ))}
    </ResultDetailsListStyled>
  );
};
