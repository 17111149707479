import { useLocation } from "react-router-dom";

export const useCurrentGroupId = () => {
  const location = useLocation();

  const groupId = location.pathname.includes("group")
    ? location.pathname.split("/")[2]
    : "";

  return { groupId };
};
