import styled from "styled-components/macro";

export const NewNoteWrapper = styled.div`
  margin-top: 15px;
  width: 636px;
  height: 482px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(19, 20, 21, 0.1);
`;

export const NewNoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
`;

export const NewNoteBottomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 15px 15px;
  margin-bottom: 10px;
`;

export const NewNoteTitle = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  font-size: 25px;
  margin-bottom: 25px;
  outline: 0px solid transparent;
`;

export const NewNoteContent = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  height: 100px;
  outline: 0px solid transparent;
  &:[placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    left: 5px;
    font-size: 13px;
    color: #aaa;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    direction: ltr;
  }
}
`;

export const NewNoteHeaderTitle = styled.div`
  position: relative;
  top: 8px;
`;

export const NewNoteAutosaveWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  width: 140px;
`;

export const TitleWrapper = styled.div`
  margin: 0 20px 25px 20px;
`;
