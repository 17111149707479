import React, { useCallback, useEffect, useState } from "react";

import { IPost } from "store/group/groupApi.types";

import GroupPostListItem from "../GroupPostListItem/GroupPostListItem";

import { GroupPostsListWrapper } from "./GroupPostsList.styled";

interface GroupPostsListProps {
  groupAvatar: string;
  groupId: string;
  groupName: string;
  handlePageAddition: () => void;
  page: number;
  postsAfterCreation: IPost[];
  postWasSuccessfullyCreated: boolean;
  posts: IPost[];
  userAvatar: string;
}

const GroupPostsList = ({
  groupAvatar,
  groupName,
  handlePageAddition,
  page,
  postsAfterCreation,
  postWasSuccessfullyCreated,
  posts,
  userAvatar,
}: GroupPostsListProps) => {
  const [postsList, setPostsList] = useState<IPost[]>();
  const mergePosts = useCallback(
    (prev: IPost[] | undefined, next: IPost[]): IPost[] => {
      const postMap = new Map<string, IPost>();

      if (prev !== undefined) {
        prev.forEach((post) => {
          postMap.set(post.postId, post);
        });
      }

      next.forEach((post) => {
        postMap.set(post.postId, post);
      });

      return Array.from(postMap.values());
    },
    []
  );
  useEffect(() => {
    setPostsList((prevPosts) => {
      if (prevPosts === undefined) {
        return posts;
      }
      if (page === 0) return prevPosts;
      const postMap = new Map();
      prevPosts.forEach((post) => {
        postMap.set(post.postId, post);
      });
      posts.forEach((post) => {
        postMap.set(post.postId, post);
      });

      const arrayValues = Array.from(postMap.values());
      return arrayValues;
    });
  }, [posts, page, mergePosts]);

  useEffect(() => {
    setPostsList((prevPosts) => {
      if (postWasSuccessfullyCreated) {
        const postMap = new Map();
        postsAfterCreation.forEach((post) => {
          postMap.set(post.postId, post);
        });
        prevPosts.forEach((post) => {
          postMap.set(post.postId, post);
        });
        const arrayValues = Array.from(postMap.values());
        return arrayValues;
      }
      return prevPosts;
    });
  }, [postsAfterCreation, postWasSuccessfullyCreated, mergePosts]);

  return (
    <GroupPostsListWrapper>
      {postsList?.map((post, index) => {
        return (
          <GroupPostListItem
            key={post.postId}
            groupAvatar={groupAvatar}
            groupName={groupName}
            userAvatar={userAvatar}
            post={post}
            addPage={handlePageAddition}
            lastPost={index === postsList?.length - 1}
          />
        );
      })}
    </GroupPostsListWrapper>
  );
};

export default GroupPostsList;
