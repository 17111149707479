import React from "react";

import { GhostButton } from "common/components/GhostButton/GhostButton.styled";

type Props = React.ComponentProps<typeof GhostButton>;

export const ModalDeleteCancelButton = (props: Props) => (
  <GhostButton
    width="max-content !important"
    padding="1rem"
    display="flex"
    margin="0 .5rem 0 0"
    onClick={close}
    {...props}
  />
);
