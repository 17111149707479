import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { GroupType } from "components/Group/Types/group";
import {
  createGroup,
  ICreateGroupRequestDtoMessage,
} from "services/api/group/createGroup";
import { inviteToGroup } from "services/api/group/groupApi";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { fetchGroupsList } from "store/groupSlice";

import { IListUser } from "./types";

export const groupCreate = (
  groupData: GroupApiRequest<ICreateGroupRequestDtoMessage>,
  usersToInvite: IListUser[]
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const groupResponse = await createGroup(groupData);

    dispatch(fetchGroupsList());

    usersToInvite.forEach(
      async (user) =>
        await inviteToGroup({
          body: {
            header: createGroupRequestHeader(user.userData.sub),
            message: {
              group_id: groupResponse.body.message.group_id,
              member_id: user.userData.sub,
              owner_id: groupData.body.message.group_owner.group_owner_id,
              group_status: "ACTIVE",
              group_type: groupData.body.message.group_type as GroupType,
              created: new Date().toISOString(),
              created_by: groupData.body.message.group_owner.group_owner_id,
            },
          },
        })
    );
  };
};
