import { useModal } from "common/components/Modal/use-modal";

import { deleteNote } from "../Note";

export const useDeleteNote = (userId: string, noteId: string) => {
  const deleteNoteModal = useModal();

  const performDeleteNote = () => {
    deleteNoteModal.modalProps.closeModal();
    deleteNote(userId, noteId);
  };

  return { performDeleteNote, deleteNoteModal };
};
