import { theme } from "common/theme/theme";
import styled from "styled-components";

export const GroupSettingsModalWrapper = styled.div`
  width: 514px;
  height: 369px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`;

export const GroupSettingsModalHeader = styled.h3`
  font-size: 20px;
  font-weight: ${theme.fontWeight.bold};
  margin: 0;
  color: ${theme.color.basicBlack};
`;
