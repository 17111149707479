import React from "react";

import Card from "common/components/Card/Card";

import Details from "./Details/Details";
import Layout from "./Layout/Layout";
import { HeadingCard } from "./Profile.styled";
import Summary from "./Summary/Summary";

const Profile = () => {
  return (
    <Layout>
      <HeadingCard>
        <Summary />
      </HeadingCard>
      <Card>
        <Details />
      </Card>
    </Layout>
  );
};

export default Profile;
