import React from "react";
import { FieldValues, Path, UseFormSetValue } from "react-hook-form";

import RadioButton from "common/components/RadioButtonHookForms";

import {
  Label,
  RadioButtonGroupWrapper,
  RadioButtonRowWrapper,
} from "./RadioButtonGroup.styled";
interface Option {
  value: string;
  text: string;
}

interface RadionButtonGroupProps {
  id: string;
  inputName?: Path<FieldValues>;
  label: string;
  options: Option[];
  mt?: string;
  mb?: string;
  selected: string;
  setValue?: UseFormSetValue<any>;
}

function RadioButtonGroup({
  id,
  inputName,
  label,
  options,
  mt,
  mb,
  selected,
  setValue,
}: RadionButtonGroupProps) {
  return (
    <RadioButtonGroupWrapper id={id} mt={mt} mb={mb}>
      <Label>{label}</Label>
      <RadioButtonRowWrapper>
        {options.map((option) => (
          <RadioButton
            setValue={setValue}
            inputName={inputName}
            selected={selected}
            key={option.value}
            value={option.value}
            text={option.text}
          />
        ))}
      </RadioButtonRowWrapper>
    </RadioButtonGroupWrapper>
  );
}

export default RadioButtonGroup;
