import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import TaskerDatePicker from "common/components/DataPicker2/TaskerDatePicker";
import Flex from "common/components/Flex";
import { FlexPart } from "common/components/Flex/FlexPart";
import CancelAcceptButtons from "common/components/Forms/Buttons/CancelAcceptButtons";
import { Container } from "common/components/Layout/Containers";
import { useCurrentGroupId } from "common/hooks/useCurrentGroupId";
import { theme } from "common/theme/theme";
import ListSelect from "components/TaskerPage/NewTaskView/ListSelect/ListSelect";
import ResponsiblePersons from "components/TaskerPage/NewTaskView/ResponsiblePersons/ResponsiblePersons";
import TaskerInput from "components/TaskerPage/NewTaskView/TaskerInput";
import TaskerTextArea from "components/TaskerPage/NewTaskView/TaskerTextArea";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { ICategory } from "store/tasker/models/Category";
import { GroupUser } from "store/tasker/models/GroupUser";
import { taskerAction } from "store/tasker/taskerSlice";

import AttachmentsField from "./AttachmentsField";
import CategoriesField from "./CategoriesField/CategoriesField";
import NewTaskHeader from "./NewTaskHeader";
import { TaskCreationSchema } from "./NewTaskValidation";
import { NameListFlex, RealisationReminderFlex } from "./NewTaskView.styled";
import ReminderSelect, {
  ReminderOptionName,
  reminderOptions,
} from "./ReminderSelect";
import { useNewTaskSubmit } from "./useNewTaskSubmit";

export interface ITaskInForm {
  task_title: string;
  description: string;
  listName: string;
  realisationDate: Date;
  reminder_date: ReminderOptionName;
  assignee: string | null;
  category_ids: ICategory[];
  priority: boolean;
  groupId: string;
  completed: boolean;
}

export default function NewTaskView() {
  const dispatch = useAppDispatch();
  const [Assignee, setAssignee] = useState<GroupUser | null>(null);
  const [firstValidate, setFirstValidate] = useState(false);
  const taskerSelector = useAppSelector((s: RootState) => s.tasker);
  const { groupId } = useCurrentGroupId();
  const userGroupId = taskerSelector.userViewTree.taskFromGroupId;
  const {
    register,
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
    trigger,
  } = useForm<ITaskInForm>({
    resolver: yupResolver(TaskCreationSchema()),
    mode: "all",
    defaultValues: {
      reminder_date: reminderOptions[0].value,
      realisationDate: new Date(),
      category_ids: [],
      priority: false,
    },
  });

  const { handleSubmit } = useNewTaskSubmit({
    getValues,
    trigger,
    setFirstValidate,
    isValid,
    assignee: Assignee,
    errors,
  });

  useEffect(() => {
    if (taskerSelector.MainView === "Group") {
      setValue("groupId", groupId);
    } else if (taskerSelector.MainView === "User") {
      setValue("groupId", userGroupId);
    }
    trigger("groupId");
  }, [groupId, userGroupId, taskerSelector.MainView, trigger, setValue]);

  useEffect(() => {
    const view = taskerSelector.MainView;
    if (view !== "Group") {
      return;
    }

    const task = taskerSelector.taskerView.editTaskData?.task;
    if (task) {
      setValue("completed", task.completed);
      setValue("task_title", task.task_title);
      setValue("description", task.description ?? "");
      setValue("realisationDate", new Date(task.date));

      const d = task.reminder_date;
      if (d) {
        setValue("reminder_date", reminderOptions[1].value);
      }

      setValue("assignee", task.assignee?.user_id ?? null);
      setAssignee(task.assignee);
      trigger("assignee");
      setValue("priority", task.priority);

      if (task.category_ids) {
        const categories = taskerSelector.allCategories.filter((l) => {
          return task.category_ids.some((l2) => l2 === l.id);
        });
        setValue("category_ids", categories);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskerSelector.taskerView.editTaskData?.task]);

  useEffect(() => {
    const view = taskerSelector.MainView;
    if (view !== "User") {
      return;
    }
    const task = taskerSelector.taskerView.editTaskData?.task;
    if (task) {
      setValue("completed", task.completed);
      setValue("task_title", task.task_title);
      setValue("description", task.description);
      setValue("assignee", task.assignee?.user_id ?? null);
      setValue("realisationDate", new Date(task.date));

      const d = task.reminder_date;
      if (d) {
        setValue("reminder_date", reminderOptions[1].value);
      }

      setAssignee(task.assignee);
      trigger("assignee");
      setValue("priority", task.priority);
      const nowGroupId = userGroupId;
      const nowGroup = taskerSelector.userViewTree.groups.find(
        (g) => g.group.id === nowGroupId
      );

      if (task.category_ids) {
        const categories = nowGroup.allCategories.filter((l) => {
          return task.category_ids.some((l2) => l2 === l.id);
        });
        setValue("category_ids", categories);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskerSelector.taskerView.editTaskData?.task]);

  const setPriorityFn = () => {
    const bool = getValues("priority");
    setValue("priority", !bool);
    trigger("priority");
  };

  const closeModalAndAddCategories = (labels: ICategory[]) => {
    setValue("category_ids", labels);
    trigger("category_ids");
  };

  const handleCloseNewTask = () => {
    dispatch(taskerAction.setCloseNewTaskView());
  };
  return (
    <Container p="40px" bgColor={theme.color.backgroundGray}>
      <NewTaskHeader
        setPriorityFn={setPriorityFn}
        priority={getValues().priority}
      />
      <Container
        m="15px 0 0 0"
        bgColor={theme.color.basicWhite}
        h="auto"
        bRadius="24px"
        p="24px"
        w="auto"
      >
        <Flex direction="column" gap="16px">
          <NameListFlex gap="16px">
            <FlexPart flex="2">
              <Flex direction="column">
                <TaskerInput
                  isFieldValid={!errors.task_title}
                  errorText={errors.task_title?.message || ""}
                  register={register}
                  trigger={trigger}
                  setValue={setValue}
                  firstValidate={firstValidate}
                  value={getValues().task_title}
                />
              </Flex>
            </FlexPart>
            <FlexPart flex="1">
              <Flex direction="column">
                <ListSelect
                  control={control}
                  register={register}
                  isValid={!errors.listName}
                  errorText={errors.listName?.message}
                />
              </Flex>
            </FlexPart>
          </NameListFlex>
          <Flex direction="column">
            <TaskerTextArea
              isValid={!errors.description}
              errorText={errors.description?.message}
              register={register}
              trigger={trigger}
              firstValidate={firstValidate}
              value={getValues().description}
            />
          </Flex>
          <Flex>
            <ResponsiblePersons
              assignee={Assignee}
              setValue={setValue}
              setAssignee={setAssignee}
              trigger={trigger}
            />
          </Flex>
          <RealisationReminderFlex>
            <FlexPart flex="1">
              <div style={{ marginTop: "20px" }}>
                <TaskerDatePicker
                  mb="2rem"
                  id={"realisationDate" as keyof ITaskInForm}
                  name={"realisationDate" as keyof ITaskInForm}
                  control={control}
                  isValid={!errors.realisationDate}
                  errorText={errors.realisationDate?.message}
                  label={"Termin realizacji"}
                  format="dd.MM.yyyy"
                />
              </div>
            </FlexPart>
            <FlexPart flex="1">
              <Flex direction="column">
                <ReminderSelect control={control} register={register} />
              </Flex>
            </FlexPart>
          </RealisationReminderFlex>
          <Flex gap="60px">
            <CategoriesField
              closeModalAndAddCategories={closeModalAndAddCategories}
              added={getValues("category_ids")}
            />
            <AttachmentsField />
          </Flex>
          <CancelAcceptButtons
            flexJustifyType="flex-end"
            loading={taskerSelector.loading}
            cancelButton={{
              handleCloseModalAndCancel: handleCloseNewTask,
              cancelButtonBorder: "none",
            }}
            acceptButton={{
              handleCloseModalAndAccept: handleSubmit,
            }}
          />
        </Flex>
      </Container>
    </Container>
  );
}
