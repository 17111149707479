import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface InputProps {
  isValid?: boolean;
  width?: string;
  clicked?: boolean;
  paddings?: string;
}

export const TaskerInputStyled = styled.input<InputProps>`
  width: 100%;
  padding: 14px 10px;
  color: ${theme.color.mediumGray};
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid
    ${(p) => {
      if (p.isValid) {
        return theme.color.slightlyLightGray;
      } else {
        return theme.color.alertRed;
      }
    }};
  &:focus {
    border: 1px solid
      ${(p) =>
        p.isValid === true
          ? theme.color.slightlyLightGray + "!important"
          : theme.color.alertRed + "!important"};
  }
  &:-webkit-autofill {
    box-shadow: inset 50px 50px 0 ${theme.color.basicWhite},
      inset -50px -50px 0 ${theme.color.basicWhite};
    -webkit-text-fill-color: ${theme.color.mediumGray} !important;
  }
`;
