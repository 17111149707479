import React from "react";

import { SecondStageWrapper } from "./CreationSecondStage.styled";
import GroupCreationSearch from "./GroupCreationSearch/GroupCreationSearch";
import UsersTables from "./UsersTables/UsersTables";

interface CreationSecondStageProps {
  setSearchValue: (val: string) => void;
  searchValue: string;
}

const GroupCreationSecondStage = ({
  setSearchValue,
  searchValue,
}: CreationSecondStageProps) => {
  return (
    <SecondStageWrapper>
      <GroupCreationSearch setSearchValue={setSearchValue} />
      <UsersTables searchValue={searchValue} />
    </SecondStageWrapper>
  );
};

export default GroupCreationSecondStage;
