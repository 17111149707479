import React from "react";

import IconButton from "common/components/Forms/Common/IconButton";
import Text from "common/components/Text";

import { CategoryText, CategoryWrapper } from "./TaskCategory.styled";

type Props = {
  text: string;
  textSize?: string;
  handleRemove?: () => void;
  p?: string;
  h?: string;
  colorIdx: number;
};

export default function TaskCategory({
  handleRemove,
  text,
  textSize = "16px",
  p,
  h,
  colorIdx,
}: Props) {
  return (
    <CategoryWrapper p={p} h={h} colorIdx={colorIdx}>
      <CategoryText>
        <Text size={textSize}>{text}</Text>
      </CategoryText>
      {handleRemove && (
        <IconButton
          onClick={handleRemove}
          width={8}
          height={8}
          marginLeft={"5px"}
          marginRight={"3px"}
          icon="/icons/close_big.svg"
        />
      )}
    </CategoryWrapper>
  );
}
