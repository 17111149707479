import { theme } from "common/theme/theme";
import styled from "styled-components";

export const PostModalTextAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-top: 0.5px solid;
  border-color: ${theme.color.mediumGray};
  box-sizing: border-box;
`;

export const PostInput = styled.input`
  width: 226px;
  height: auto;
  outline: none;
  border: none;
  :focus {
    outline: none;
  }
`;

export const PublishButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 106px;
  height: 35px;
  padding: 8px 20px;
  border: none;
  border-radius: 40px;
  margin-left: 22px;
  cursor: pointer;
  color: ${theme.color.basicWhite};
  background-color: ${theme.color.basicBlack};
  font-size: 14px;
  :disabled {
    background: ${theme.color.darkerGray};
    cursor: default;
  }
`;

export const PostFileInput = styled.input``;

export const ImageLabel = styled.label``;

export const RightOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 34px;
`;
