import type { FunctionComponent, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import type { PortalRootId } from "./types";
import { usePortal } from "./use-portal";

export interface PortalProps extends PropsWithChildren {
  rootId: PortalRootId;
}

export const Portal: FunctionComponent<PortalProps> = ({
  children,
  rootId,
}) => {
  const portalContainer = usePortal(rootId);

  return createPortal(children, portalContainer);
};
