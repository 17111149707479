import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { CheckboxLifting } from "common/components/CheckboxLifting/CheckboxLifting";
import DatePicker from "common/components/DatePickerHookForms";
import FormInput from "common/components/FormInputHookForms";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import SmallButton from "common/components/SmallButton/SmallButton";
import { jobSchema } from "common/constants/jobSchema";
import { useSub } from "common/hooks/useSub";
import {
  useGetProfileQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from "store/user/userApi";

import {
  ModalForm,
  ModalFormButtons,
  ModalFormFooter,
  ModalFormTitle,
} from "../ModalForm/ModalForm.styled";

interface Props {
  onClose: () => void;
  titlePrefix: string;
}

export const ModalJob = ({ onClose, titlePrefix }: Props) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });

  const { data: profile } = useGetProfileQuery(
    {
      sub,
      language: user?.language,
    },
    { skip: !sub || !user?.language }
  );

  const [updateProfile] = useUpdateProfileMutation();

  const {
    control,
    register,
    handleSubmit: handleContactFormSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(jobSchema),
    defaultValues: {
      jobName: profile?.jobName ?? "",
      companyName: profile?.companyName ?? "",
      jobStarted: profile?.jobStarted ? new Date(profile?.jobStarted) : "",
      jobFinished: profile?.jobFinished ? new Date(profile?.jobFinished) : "",
      currentlyInCompany: profile?.currentlyInCompany ?? false,
    },
  });

  // WARNING: Yes, default values are declared in useForm, but sometimes they are not set in the form, so I have to set them manually here
  useEffect(() => {
    if (!profile) {
      return;
    }

    if (profile.currentlyInCompany) {
      setValue("currentlyInCompany", profile.currentlyInCompany);
    }
    if (profile.jobName) {
      setValue("jobName", profile.jobName);
    }
    if (profile.jobFinished) {
      setValue("jobFinished", new Date(profile.jobFinished));
    }
    if (profile.jobStarted) {
      setValue("jobStarted", new Date(profile.jobStarted));
    }
  }, [profile, setValue]);

  const onContactFormSubmit = handleContactFormSubmit(async (profileData) => {
    const parsed = {
      ...profileData,
      jobStarted: new Date(profileData.jobStarted).toISOString(),
      jobFinished:
        profileData.jobFinished && !profileData.currentlyInCompany
          ? new Date(profileData.jobFinished).toISOString()
          : "",
    };

    await updateProfile({
      sub,
      language: profile.language,
      profileData: parsed,
    });
    onClose();
  });

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 100, 0, 1);
  const minEndDate = new Date(watch("jobStarted")) || minDate;

  return (
    <ModalForm onSubmit={onContactFormSubmit}>
      <ModalFormTitle>{titlePrefix} Pracę</ModalFormTitle>
      <FormInput
        type="text"
        mb="2rem"
        label="Stanowisko"
        touched={true}
        inputName="jobName"
        register={register}
        id="jobName"
        placeholder="Stanowisko"
        isValid={errors.jobName === undefined}
        errorText={errors.jobName?.message}
      />
      <FormInput
        type="text"
        mb="2rem"
        label="Firma"
        touched={true}
        inputName="companyName"
        register={register}
        id="companyName"
        placeholder="Firma"
        isValid={errors.companyName === undefined}
        errorText={errors.companyName?.message}
      />
      <DatePicker
        mb="2rem"
        label="Data rozpoczęcia"
        control={control}
        id="jobStarted"
        name="jobStarted"
        isValid={errors.jobStarted === undefined}
        errorText={errors.jobStarted?.message}
        maxDate={new Date()}
        minDate={minDate}
        format="MM.yyyy"
      />
      {!watch("currentlyInCompany") && (
        <DatePicker
          mb="2rem"
          label="Data zakończenia"
          control={control}
          id="jobFinished"
          name="jobFinished"
          isValid={errors.jobFinished === undefined}
          errorText={errors.jobFinished?.message}
          maxDate={new Date()}
          minDate={minEndDate}
          format="MM.yyyy"
        />
      )}
      <ModalFormFooter>
        <CheckboxLifting {...register("currentlyInCompany")}>
          Nadal tutaj pracuję
        </CheckboxLifting>
        <ModalFormButtons>
          <GhostButton
            onClick={onClose}
            width="max-content !important"
            padding="1rem"
            display="flex"
            margin="0 .5rem 0 0"
            type="button"
          >
            Anuluj
          </GhostButton>
          <SmallButton type="button" onClick={onContactFormSubmit}>
            Zapisz
          </SmallButton>
        </ModalFormButtons>
      </ModalFormFooter>
    </ModalForm>
  );
};
