import React from "react";

import Flex from "common/components/Flex";
import { FlexPart } from "common/components/Flex/FlexPart";
import Image from "common/components/Image";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import DeleteIcon from "common/icons/DeleteIcon";
import { FirstChildMarginFlex } from "components/Group/Common/GroupInvitation/InvitationUsersTable/RightUsersTable/RightUsersTableItem/RightUsersTableItem.styled";
import { GroupUser } from "store/tasker/models/GroupUser";
import styled from "styled-components";

export const RightUsersTableWrapper = styled(Flex)`
  position: relative;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;

interface RightSidePanelProps {
  assignee: GroupUser | null;
  handleRemoveUser: () => void;
}

export default function RightSidePanel({
  handleRemoveUser,
  assignee,
}: RightSidePanelProps) {
  return (
    <FlexPart flex="1">
      <RightUsersTableWrapper
        height="411px"
        width="129px"
        gap="10px"
        align="center"
        direction="column"
        id="chosenMembers"
      >
        {assignee && (
          <FirstChildMarginFlex
            key={assignee.user_id}
            align="center"
            gap="10.95px"
            firstChildMargin="3px 0 0 0"
          >
            <RelativeContainer w="45px" h="45px">
              <Image
                src={
                  assignee.user?.avatarUrl || "/icons/profileIconExample.svg"
                }
                width={40}
                height={40}
                radius="100%"
              />
              <DeleteIcon top="-9px" left="26px" onClick={handleRemoveUser} />
            </RelativeContainer>
          </FirstChildMarginFlex>
        )}
      </RightUsersTableWrapper>
    </FlexPart>
  );
}
