import styled from "styled-components";

import { theme } from "../../theme/theme";
import Heading from "../Heading";
import Text from "../Text";

interface BackgroundImageAddProps {
  backgroundImage: string;
}

interface BackgroundChangeSubtitleProps {
  ml?: string;
  mr?: string;
}

export const defaultProps: BackgroundChangeSubtitleProps = {
  ml: "10px",
  mr: "10px",
};

export const BackgroundChangeContainer = styled.div`
  padding: 13px 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 373px;
`;

export const BackgroundChangeTitle = styled(Heading)`
  width: 100%;
  font-size: 20px;
  text-align: center;
`;

export const BackgroundChangeSubtitle = styled.p<BackgroundChangeSubtitleProps>`
  font-size: 14px;
  margin: 16px ${(props) => props.ml} 24px ${(props) => props.mr};
  text-align: center;
  line-height: 20px;
`;

export const BackgroundChangeDescription = styled.p`
  font-size: 14px;
  margin: 25px 10px 0 10px;
  text-align: center;
  line-height: 25px;
  color: ${theme.color.mediumGray};
`;

export const BackgroundImagePreview = styled.img`
  height: 120px;
  width: 365px;
  object-fit: cover;
  object-position: top center;
`;

export const BackgroundImageAdd = styled.div<BackgroundImageAddProps>`
  height: 120px;
  width: 365px;
  background-image: ${(props) => props.backgroundImage};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

BackgroundChangeSubtitle.defaultProps = defaultProps;

export const BackgroundImageAddIcon = styled.img`
  height: 100%;
  width: 100%;
`;

export const BackgroundErrorContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 10px;
`;

export const BackgroundErrorIcon = styled.img`
  height: 24px;
  width: 24px;
`;

export const BackgroundErrorMessage = styled(Text)`
  font-size: 12px;
  color: ${theme.color.alertRed};
  font-weight: ${theme.fontWeight.normal};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
`;
