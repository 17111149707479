import React from "react";

import Button from "common/components/Button";
import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

interface CreateNoteButtonProps {
  setNewNote: () => void;
}

export default function CreateNoteButton({
  setNewNote,
}: CreateNoteButtonProps) {
  return (
    <Button
      display="flex"
      width="182px"
      height="36px"
      radius="40px"
      onClick={setNewNote}
    >
      <Text size="16px" color={theme.color.basicWhite} weight="bold">
        Utwórz notatkę
      </Text>
      <Image
        src="/icons/plus_white.svg"
        alt="add note"
        width={14}
        height={14}
      />
    </Button>
  );
}
