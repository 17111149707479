import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { CloseButtonPosition } from "common/components/Modal/types";
import { useModal } from "common/components/Modal/use-modal";
import { useSub } from "common/hooks/useSub";
import { postConfig } from "components/Group/GroupDiscussion/postConfig";
import { postCreationSchema } from "components/Group/GroupDiscussion/schema";
import { PostCreationForm } from "components/Group/GroupDiscussion/types";
import PostCreationModal from "components/PostCreation/PostCreationModal/PostCreationModal";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { useCreatePostMutation } from "store/group/groupApi";
import { ICreatePostRequest } from "store/group/groupApi.types";
import { useAppSelector } from "store/hooks";
import { useUploadFileMutation } from "store/upload/uploadApi";
import { useGetUserQuery } from "store/user/userApi";

import ConfirmLeavingModal from "./ConfirmLeavingModal/ConfirmLeavingModal";

interface CommentCreationModalProps {
  parentId: string;
  turnModal: boolean;
  setTurnOffModal: () => void;
  refetchPosts: () => void;
  setPostWasCreated: (value: boolean) => void;
}

const CommentCreationModal = ({
  parentId,
  turnModal,
  setTurnOffModal,
  refetchPosts,
  setPostWasCreated,
}: CommentCreationModalProps) => {
  const { sub } = useSub();
  const { data: user } = useGetUserQuery({ sub });
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const postModal = useModal({ withCloseButton: true, withPadding: false });
  const [createPost, { isError }] = useCreatePostMutation();
  const [uploadFile] = useUploadFileMutation();
  const [confirmLeavingModal, setConfirmLeavingModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<PostCreationForm>({
    defaultValues: {
      publicationMode: "Publiczny",
      textContent: "",
    },
    shouldUnregister: false,
    mode: "all",
    resolver: yupResolver(postCreationSchema),
  });

  useEffect(() => {
    if (turnModal) {
      postModal.showModal();
    }
  }, [turnModal, postModal]);

  const formContent = useWatch({ control });

  const tryToLeavePostModal = () => {
    setConfirmLeavingModal(true);
  };

  const doNotLeavePostModal = () => {
    setConfirmLeavingModal(false);
  };

  const turnOffModal = () => {
    setTurnOffModal();
    postModal.modalProps.closeModal();
    setFiles([]);
    setValue("textContent", "");
    setValue("publicationMode", "Publiczny");
    setTimeout(() => {
      setConfirmLeavingModal(false);
    }, 300);
  };

  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    clearInput: () => void
  ) => {
    setFiles((files) => {
      const existingFileNames = files.map((file) => file.name);
      const newFiles = Array.from(event.target.files).filter(
        (file) => !existingFileNames.includes(file.name)
      );
      clearInput();
      return files.concat(newFiles);
    });
  };
  const handleFileDeletion = (file: File) => {
    setFiles((files) =>
      files.filter((fileItem) => fileItem.name !== file.name)
    );
  };

  const addPost: SubmitHandler<PostCreationForm> = async (data) => {
    const postData: GroupApiRequest<ICreatePostRequest> = {
      body: {
        header: createGroupRequestHeader(sub),
        message: {
          parent_id: parentId,
          object_id: currentGroup.groupId,
          object_type: "GROUP",
          content: data.textContent,
          author: {
            author_id: sub,
            author_type: "USER",
          },
          created: new Date().toISOString(),
          post_status: "ACTIVE",
          attachments: [],
        },
      },
    };
    const creationData = await createPost({ postData: postData }).unwrap();
    for (let i = 0; i < files.length; i++) {
      await uploadFile({
        uploadFileData: {
          bucket: "smartoakprojects-group-posts",
          folder: `${currentGroup.groupId}/${creationData.body.message.postId}`,
          file: files[i],
        },
      });
    }
    turnOffModal();
    refetchPosts();
    setPostWasCreated(true);
  };

  const closeButtonPositionHandler = (): CloseButtonPosition => {
    if (confirmLeavingModal) {
      return {
        right: "16px",
        top: "16px",
      };
    }

    return {
      right: "24px",
      top: "24px",
    };
  };

  const username = useMemo(() => {
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    }
  }, [user]);

  const isValidToPublish = useMemo(
    () =>
      !errors.textContent?.message &&
      formContent.textContent.length >= postConfig.minLength,
    [errors.textContent?.message, formContent.textContent.length]
  );

  const textDidNotExceedLimit = useMemo(
    () =>
      !errors.textContent?.message ||
      errors.textContent?.message === postConfig.minimumValidationText,
    [errors.textContent?.message]
  );

  return (
    <ModalPortal
      {...postModal.modalProps}
      closeModal={confirmLeavingModal ? turnOffModal : tryToLeavePostModal}
      closeButtonPosition={closeButtonPositionHandler()}
    >
      <PostCreationModal
        display={!confirmLeavingModal ? null : "none"}
        headerTitle="Utwórz komentarz"
        userSection={
          <PostCreationModal.PostModalUser
            userAvatar={user?.avatarUrl}
            username={username}
            publicationType={"Publiczny"}
            displayPublication={false}
          />
        }
        contentSection={
          <PostCreationModal.PostModalTextArea<PostCreationForm>
            handleFileDeletion={handleFileDeletion}
            files={files}
            setValue={setValue}
            isValid={textDidNotExceedLimit}
            registerFieldName="textContent"
            valueCount={formContent.textContent.length}
          />
        }
        optionsSection={
          <PostCreationModal.PostModalOptions
            isCreationError={isError}
            isValid={isValidToPublish}
            publishPost={() => handleSubmit(addPost)()}
            handleFileUpload={handleFileUpload}
          />
        }
      />
      {confirmLeavingModal && (
        <ConfirmLeavingModal
          cancelButtonHandler={doNotLeavePostModal}
          proceedButtonHandler={turnOffModal}
        />
      )}
    </ModalPortal>
  );
};

export default CommentCreationModal;
