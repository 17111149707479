import React from "react";

import GroupButton from "components/Group/Common/GroupButton/GroupButton";

import { GroupCreationButtonsWrapper } from "./GroupCreationButtons.styled";

interface GroupCreationButtonsProps {
  closeModal: () => void;
  firstStage: boolean;
  goToFirstStage: () => void;
  goToSecondStage: () => void;
  goToFinalStage: () => void;
  isProceedButtonDisabled: boolean;
}

const GroupCreationButtons = ({
  closeModal,
  goToFirstStage,
  firstStage,
  goToSecondStage,
  isProceedButtonDisabled,
  goToFinalStage,
}: GroupCreationButtonsProps) => {
  const buttonHeight = "36px";
  return (
    <GroupCreationButtonsWrapper>
      <GroupButton
        buttonText={firstStage ? "Anuluj" : "Wróć"}
        buttonLight={true}
        height={buttonHeight}
        clickHandler={firstStage ? closeModal : goToFirstStage}
        width="160px;"
        testId={"creation-backward-btn"}
      />

      <GroupButton
        buttonText={firstStage ? "Dalej" : "Utwórz grupę"}
        disabled={isProceedButtonDisabled}
        buttonLight={false}
        clickHandler={firstStage ? goToSecondStage : goToFinalStage}
        width="160px"
        height={buttonHeight}
        testId={"creation-forward-btn"}
      />
    </GroupCreationButtonsWrapper>
  );
};

export default GroupCreationButtons;
