import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "common/components/Button";
import { CheckboxLifting } from "common/components/CheckboxLifting/CheckboxLifting";
import DatePicker from "common/components/DatePickerHookForms";
import FormInput from "common/components/FormInputHookForms";
import { GhostButton } from "common/components/GhostButton/GhostButton.styled";
import { educationSchema } from "common/constants/educationSchema";
import { useSub } from "common/hooks/useSub";
import {
  useGetProfileQuery,
  useGetUserQuery,
  useUpdateProfileMutation,
} from "store/user/userApi";
import styled from "styled-components/macro";

import { useStepper } from "../Stepper";

const ProfileCreationEducationStyles = styled.form`
  margin: 2rem 0;
  max-width: 600px;
  width: 100%;
  gap: 2rem;
  display: flex;
  height: 500px;
  flex-direction: column;
  div:first-of-type:not(:last-of-type) {
    width: 100%;
  }

  .checkbox {
    margin-top: 2rem;
  }

  button {
    height: max-content;
    width: max-content;
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    a {
      text-decoration: none;
      color: inherit;
    }

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const ProfileCreationEducation = () => {
  const { nextStep } = useStepper();
  const { sub } = useSub();

  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });
  const { data: profile } = useGetProfileQuery(
    {
      sub,
      language: user?.language,
    },
    {
      skip: !sub || !user?.language,
    }
  );
  const [updateProfile] = useUpdateProfileMutation();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(educationSchema),
    defaultValues: {
      schoolName: "",
      educationStarted: "" as string | Date,
      educationFinished: "" as string | Date,
      currentlyInSchool: false,
    },
  });

  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 100, 0, 1);

  const onSubmit = handleSubmit(async (profileData) => {
    const parsed = {
      ...profileData,
      educationStarted: new Date(profileData.educationStarted).toISOString(),
      educationFinished:
        profileData.educationFinished && !profileData.currentlyInSchool
          ? new Date(profileData.educationFinished).toISOString()
          : "",
    };

    await updateProfile({ sub, language: user.language, profileData: parsed });
    nextStep();
  });

  useEffect(() => {
    if (!profile) {
      return;
    }
    if (profile.currentlyInSchool) {
      setValue("currentlyInSchool", profile.currentlyInSchool);
    }
    if (profile.educationStarted) {
      setValue("educationStarted", new Date(profile.educationStarted));
    }
    if (profile.schoolName) {
      setValue("schoolName", profile.schoolName);
    }
    if (profile.educationFinished) {
      setValue("educationFinished", new Date(profile.educationFinished));
    }
  }, [profile, setValue]);

  return (
    <ProfileCreationEducationStyles onSubmit={onSubmit}>
      <FormInput
        label="Uczelnia"
        touched={true}
        type="text"
        inputName="schoolName"
        register={register}
        id="schoolName"
        placeholder="Uczelnia"
        isValid={errors.schoolName === undefined}
        errorText={errors.schoolName?.message}
      />
      <DatePicker
        label="Data rozpoczęcia"
        control={control}
        id="educationStarted"
        name="educationStarted"
        isValid={errors.educationStarted === undefined}
        errorText={errors.educationStarted?.message}
        maxDate={new Date()}
        minDate={minDate}
      />
      {!watch("currentlyInSchool") && (
        <DatePicker
          label="Data zakończenia"
          control={control}
          id="educationFinished"
          name="educationFinished"
          isValid={errors.educationFinished === undefined}
          errorText={errors.educationFinished?.message}
          maxDate={new Date()}
          minDate={new Date(watch("educationStarted"))}
        />
      )}
      <CheckboxLifting {...register("currentlyInSchool")} className="checkbox">
        Nadal się uczę
      </CheckboxLifting>
      <footer>
        <Link to="/create-profile?step=0">
          <Button type="button">Wstecz</Button>
        </Link>
        <div>
          <Link to="/create-profile?step=2">
            <GhostButton type="button">Pomiń krok</GhostButton>
          </Link>
          <Button type="submit">Dalej</Button>
        </div>
      </footer>
    </ProfileCreationEducationStyles>
  );
};
