import React, { useEffect, useState } from "react";
import { UseFormRegister, UseFormTrigger } from "react-hook-form";

import Image from "common/components/Image";
import { AbsValidationLabel } from "common/components/Layout/Form/ValidationLabel";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";

import { ITaskInForm } from "./NewTaskView";
import { TaskerTextArea as TaskerTextAreaStyled } from "./TaskerTextArea.styled";

const maxLen = 500;
const fieldName: keyof ITaskInForm = "description";

export interface TaskerTextAreaProps {
  isValid?: boolean;
  firstValidate: boolean;
  errorText?: string;
  register: UseFormRegister<ITaskInForm>;
  trigger: UseFormTrigger<ITaskInForm>;
  value?: string;
}

const TaskerTextArea = ({
  isValid,
  firstValidate,
  register,
  trigger,
  errorText,
  value,
}: TaskerTextAreaProps) => {
  const [clicked, setClicked] = useState(false);
  const [firstBlur, setFirstBlur] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [initForEdit, setInitForEdit] = useState(false);

  useEffect(() => {
    if (firstValidate) {
      setFirstBlur(true);
      setClicked(true);
    }
  }, [firstValidate]);

  function onClick(): void {
    if (clicked === false) setClicked(true);
  }

  function handleFirstBlur(): void {
    if (!firstBlur) {
      setFirstBlur(true);
      trigger(fieldName);
    }
  }

  function initValueForEditTask() {
    if (value && !initForEdit) {
      setInputValue(value);
      setInitForEdit(true);
    }
  }

  initValueForEditTask();
  const lettersLeft = value
    ? `${maxLen - inputValue.length}/${maxLen}`
    : `${maxLen}/${maxLen}`;

  return (
    <RelativeContainer m="30px 0 25px 0">
      <TaskerTextAreaStyled
        {...register(fieldName, {
          onChange: (e) => {
            setInputValue(e.target.value);
            trigger(fieldName);
          },
        })}
        id={fieldName}
        name={fieldName}
        onClick={onClick}
        onBlur={handleFirstBlur}
        isValid={isValid}
        placeholder="Dodaj opis"
        clicked={clicked}
      />
      <AbsValidationLabel
        isInvalid={!isValid}
        fontSize="14px"
        bottom="-20px"
        right="5px"
      >
        {lettersLeft}
      </AbsValidationLabel>
      <AbsValidationLabel
        isInvalid={!isValid}
        fontSize="15px"
        top="-23px"
        left="5px"
      >
        Opis
      </AbsValidationLabel>
      {!isValid && clicked && (
        <AbsValidationLabel isInvalid={!isValid} bottom="-20px" left="0px">
          <Image
            src={"/icons/error_outline.svg"}
            alt="Plus"
            width={16}
            height={16}
            marginRight={"3px"}
            layout={"relative"}
            top={"2px"}
          />
          {errorText}
        </AbsValidationLabel>
      )}
    </RelativeContainer>
  );
};

export default TaskerTextArea;
