import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

type InputMessageVariant = "info" | "success" | "warning" | "error";

interface InputMessageProps {
  variant?: InputMessageVariant;
}

const defaultProps: InputMessageProps = { variant: "info" };

const InputMessage = styled.p<InputMessageProps>`
  margin: 0;
  font-size: 14px;
  color: ${({ variant }) =>
    variant === "success"
      ? theme.color.formGreen
      : variant === "warning"
      ? theme.color.formYellow
      : variant === "error"
      ? theme.color.alertRed
      : theme.color.basicBlack};
`;

InputMessage.defaultProps = defaultProps;

export default InputMessage;
