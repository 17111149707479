import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const UsersTableWrapper = styled.div`
  width: 371px;
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const UsersScrollbarWrapper = styled.div`
  width: 403px;
  /* width: 100%; */
  height: 411px;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /* height: 402.44px; */
    width: 4px;
    background-color: ${theme.color.lightGray};
  }
  ::-webkit-scrollbar-thumb {
    height: 259.08px;
    border-radius: 5px;
    background-color: ${theme.color.slightlyLightGray};
  }
`;
