import axios, { AxiosError, AxiosResponse } from "axios";

import { apiRequest } from "../config/axiosConfig";

export interface TaskerUpdateTaskPayload {
  task_id: string;
  task_title: string;
  group_id: string;
  assignee: string | null;
  created_by: string;
  deadline_date: string;
  reminder_date: string;
  completed: boolean;
  description: string;
  priority: boolean;
  list_id: string;
  attachments: string[] | null;
  category_ids?: string[];
}

export async function taskerUpdateTaskRq(
  dto: TaskerUpdateTaskPayload
): Promise<AxiosResponse | AxiosError> {
  try {
    const response: AxiosResponse = await apiRequest<
      AxiosResponse,
      TaskerUpdateTaskPayload
    >("PUT", "TASK", "/update", {
      data: dto,
    });

    console.log(response);
    return response;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        console.log("Error response data:", err.response.data);
      } else {
        console.log("Error without response:", err);
      }
      return err.response.data;
    } else {
      console.log("Non-Axios error:", err);
    }
    if (err.response) {
      console.error(`UpdateTask returned with status ${err.response.status}`);
    }
  }
}
