import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface ProgressProps {
  mt?: string;
  mb?: string;
  width?: string;
}

export const ProgressWrapper = styled.div<ProgressProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};

  progress[value] {
    width: ${({ width = "362px" }) => width};
    -webkit-appearance: none;
    appearance: none;
    height: 7px;
    border-radius: 8px;
    border: none;
    background-color: ${theme.color.blueGray};
  }

  progress[value]::-webkit-progress-bar {
    border-radius: 8px;
    background-color: ${theme.color.blueGray};
  }

  progress[value]::-webkit-progress-value {
    border-radius: 8px;
    background-color: ${theme.color.darkBlue};
  }

  progress[value]::-moz-progress-bar {
    border-radius: 8px;
    background-color: ${theme.color.darkBlue};
  }
`;

export const Progress = styled.progress`
  width: 362px;
`;
