import React, { useCallback, useEffect, useState } from "react";

import { TaskCardUserView } from "common/components/Card/TaskCard/TaskCardUserVIew";
import Flex from "common/components/Flex";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import MainViewLoader from "components/TaskerPage/utils/MainViewLoader";
import { getAllGroupDataForUserView } from "services/api/tasker/initTaskerGroupView";
import { getSub } from "store/auth/authUtils";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IGroup } from "store/tasker/models/Group";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

export interface TaskerUserColumnWrapperProps {
  group: IGroup;
}

export default function TaskerUserColumnWrapper({
  group,
}: TaskerUserColumnWrapperProps) {
  const dispatch = useAppDispatch();
  const tasker = useAppSelector((s) => s.tasker);
  const groupData = tasker.userViewTree.groups.find(
    (t) => t.group.id === group.id
  )!;
  const groupLoaded = groupData.loaded;
  const sub = getSub();
  const [loading, setLoading] = useState(false);

  const initData = useCallback(
    async (groupId: string) => {
      setLoading(true);
      const data = await getAllGroupDataForUserView(groupId);
      dispatch(
        taskerAction.setUserViewDataForGroup({
          groupId,
          sub,
          lists: data.allLists,
          users: data.allUsers,
          categories: data.allCategories,
        })
      );
      setLoading(false);
    },
    [dispatch, sub]
  );

  useEffect(() => {
    if (group.id && !groupLoaded) {
      initData(group.id);
    }
  }, [group.id, groupLoaded, initData]);

  const showCompletedTasks = ({ completed }: Task): boolean => {
    return tasker.taskerView.BoardView === "Done" ? completed : !completed;
  };

  return (
    <Flex direction="column">
      <RelativeContainer
        bgColor={theme.color.veryVeryLightGray}
        w="280px"
        p="12px"
        bRadius="8px"
        h="auto"
      >
        <Flex direction="row" justify="flex-start">
          {!group.name ? (
            <Text size="14px" weight="semibold" color={theme.color.alertRed}>
              GROUP NAME ERROR!?
            </Text>
          ) : (
            <Text size="14px" weight="semibold">
              {group.name}
            </Text>
          )}
        </Flex>
        {loading ? (
          <MainViewLoader />
        ) : (
          <Flex direction="column">
            {group.tasks.filter(showCompletedTasks).map((t, idx) => (
              <TaskCardUserView
                key={idx}
                task={t}
                category={
                  groupData.allCategories?.find(
                    (c) => c.id === t.category_ids?.[0]
                  ) ?? null
                }
              />
            ))}
          </Flex>
        )}
      </RelativeContainer>
    </Flex>
  );
}
