import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getUser } from "store/auth/authUtils";

import { Token } from "./Token";

export const getJwtToken = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const user = getUser();
    if (!user) {
      return resolve(null);
    }
    return user.getSession((err: Error, session: CognitoUserSession) => {
      if (err) {
        return reject(err);
      }
      return resolve(session.getIdToken().getJwtToken());
    });
  });
};

export const getToken = (token: Token) => {
  const localStorageItems = Object.keys(localStorage);
  const foundItemKey = localStorageItems.find((key) => key.endsWith(token));
  return localStorage.getItem(foundItemKey);
};
