import { useState } from "react";

import { ModalProps, ModalVisibilityHandler } from "./types";

export interface UseModalResult {
  modalProps: ModalProps;
  showModal: ModalVisibilityHandler;
}

export interface UseModalParams {
  withCloseButton?: boolean;
  withPadding?: boolean;
}

export function useModal(
  params: UseModalParams = {
    withCloseButton: true,
    withPadding: true,
  }
): UseModalResult {
  const [isVisible, setModalVisibility] = useState(false);

  const closeModal = () => {
    setModalVisibility(false);
  };

  const showModal = () => {
    setModalVisibility(true);
  };

  return {
    modalProps: {
      closeModal,
      isVisible,
      withCloseButton: params.withCloseButton,
      withPadding: params.withPadding,
      closeButtonPosition: {
        right: "16px",
        top: "16px",
      },
    },
    showModal,
  };
}
