import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface TextProps {
  size?: string;
  align?: "center" | "inherit" | "justify" | "left" | "right";
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  font?: "primary" | "secondary";
  weight?: "thin" | "normal" | "semibold" | "bold" | "extraBold";
  color?: string;
  lineHeight?: string;
  wordWrap?: "break-word" | "normal" | "break-all" | "initial" | "inherit";
  lineClamp?: number;
  overflow?: "hidden" | "visible" | "scroll" | "auto" | "initial" | "inherit";
  display?: string;
  boxOrient?: string;
  letterSpacing?: string;
}

const defaultProps: TextProps = {
  size: "16px",
  align: "inherit",
  mt: "0",
  mb: "0",
  mr: "0",
  ml: "0",
  font: "primary",
  weight: "normal",
  color: theme.color.darkGray,
  lineHeight: "normal",
};

const Text = styled.p<TextProps>`
  font-size: ${(props) => props.size};
  text-align: ${(props) => props.align};
  font-weight: ${(props) => theme.fontWeight[props.weight]};
  font-family: ${(props) => props.theme.fontFamily[props.font]};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-right: ${(props) => props.mr};
  margin-left: ${(props) => props.ml};
  letter-spacing: ${(props) => props.letterSpacing};
  a {
    color: ${theme.color.darkGray};
    font-size: ${(props) => props.theme.fontSize[props.size]};
    font-weight: ${(props) => theme.fontWeight[props.weight]};
    font-family: ${(props) => props.theme.fontFamily[props.font]};
  }
  word-wrap: ${(props) => props.wordWrap};
  -webkit-line-clamp: ${(props) => props.lineClamp};
  overflow: ${({ overflow }) => overflow && overflow};
  display: ${({ display }) => display && display};
  -webkit-box-orient: ${({ boxOrient }) => boxOrient && boxOrient};
`;

Text.defaultProps = defaultProps;

export default Text;
