import React from "react";

import Text from "common/components/Text/Text";
import { theme } from "common/theme/theme";

import { GroupModalDescriptionWrapper } from "./GroupModalDescription.styled";

interface GroupModalDescriptionProps {
  descriptionText: string;
  descriptionMarginTop?: string;
}

const GroupModalDescription = ({
  descriptionText,
  descriptionMarginTop,
}: GroupModalDescriptionProps) => {
  return (
    <GroupModalDescriptionWrapper marginTop={descriptionMarginTop}>
      <Text
        align="center"
        size="14px"
        lineHeight="20px"
        color={theme.color.veryDarkGray}
      >
        {descriptionText}
      </Text>
    </GroupModalDescriptionWrapper>
  );
};

export default GroupModalDescription;
