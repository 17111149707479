import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { useDebounce } from "common/hooks/useDebounce";
import { useSub } from "common/hooks/useSub";
import { useGetSearchQuery } from "services/api/search";
import {
  DEFAULT_TYPE,
  IMG_PLACEHOLDER,
  MIN_QUERY_LENGTH,
} from "services/api/search/index.constants";
import { Type } from "services/api/search/index.types";

export function useSearch(
  { details }: { details: boolean } = { details: false }
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { sub } = useSub();
  const query = searchParams.get("query") ?? "";
  const type = (searchParams.get("type") ?? DEFAULT_TYPE) as Type;
  const debouncedQuery = useDebounce(query, 300);

  const {
    data: items,
    isLoading,
    isFetching,
  } = useGetSearchQuery(
    { query: debouncedQuery, type: details ? type : DEFAULT_TYPE, userId: sub }, // show all items for mini search popper
    {
      skip: !debouncedQuery || debouncedQuery.length < MIN_QUERY_LENGTH,
    }
  );

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const currentSearch = Object.fromEntries(searchParams.entries());
      const query = typeof e === "string" ? e : e.target.value;
      setSearchParams({ ...currentSearch, query });
    },
    [searchParams, setSearchParams]
  );

  const displayItems = React.useMemo(() => {
    if (!items) return [];
    const filtered = Object.keys(items).filter((key) => {
      return items[key].data.length > 0;
    });
    return filtered.map((key) => ({
      title: key,
      items: items[key].data.map((item) => ({
        ...item,
        imageUrl: item.imageUrl ?? IMG_PLACEHOLDER,
      })),
      count: items[key].meta.count,
    }));
  }, [items]);

  const totalCount = React.useMemo(() => {
    return displayItems.reduce((acc, group) => {
      return acc + group.count;
    }, 0);
  }, [displayItems]);

  const setType = React.useCallback(
    (type: Type) => {
      const currentSearch = Object.fromEntries(searchParams.entries());
      setSearchParams({ ...currentSearch, type, query });
    },
    [query, searchParams, setSearchParams]
  );

  return {
    onChange,
    query,
    items,
    isLoading: isFetching || isLoading,
    displayItems,
    totalCount,
    setType,
    type,
  };
}
