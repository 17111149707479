import { theme } from "common/theme/theme";
import styled from "styled-components";

import Image from "../Image";

export const ToggleContainer = styled.div`
  display: block;
  cursor: pointer;
`;

export const ToggleSwitch = styled.div<{ isActive: boolean }>`
  display: grid;
  align-items: center;
  justify-content: start;
  width: 36px;
  height: 16px;
  border-radius: 10px;
  background-color: ${({ isActive }) =>
    isActive ? theme.color.basicBlack : theme.color.mediumGray};
  position: relative;
  transition: background-color 0.3s ease;

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    left: ${({ isActive }) => (isActive ? "20px" : "2px")};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    transition: left 0.3s ease;
  }
`;

export const ToggleImage = styled(Image)`
  margin-left: 7.7px;
`;
