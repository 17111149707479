import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

const MainContent = styled.div`
  grid-area: "mainContent";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: ${theme.color.backgroundGray};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default MainContent;
