import React, { ReactNode } from "react";

import EditIconButton from "common/components/EditIconButton/EditIconButton";

import {
  Content,
  Header,
  RootContainer,
  Separator,
  Title,
} from "./DetailsGroup.styled";

type Props = {
  title: string;
  onOpen: () => void;
  children: ReactNode;
};

const DetailsGroup = ({ onOpen, title, children }: Props) => {
  return (
    <RootContainer>
      <Header>
        <Title>{title}</Title>
        <EditIconButton onClick={onOpen} />
      </Header>
      <Separator />
      <Content>{children}</Content>
    </RootContainer>
  );
};

export default DetailsGroup;
