import styled from "styled-components/macro";

export const ModalForm = styled.form`
  display: flex;
  min-width: 30rem;
  flex-direction: column;
  padding: calc(2rem - 20px);
  position: relative;

  .react-calendar {
    position: fixed !important;
  }
`;

export const ModalFormButtons = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: auto;
`;

export const ModalFormFooter = styled.footer`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: space-between;
`;

export const ModalFormTitle = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.color.darkGray};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
  justify-content: space-between;
`;
