import React from "react";
import { Outlet } from "react-router-dom";

import { BasicContentWrapper } from "./BasicLayout.styled";

const BasicLayout = () => {
  return (
    <BasicContentWrapper>
      <Outlet />
    </BasicContentWrapper>
  );
};

export default BasicLayout;
