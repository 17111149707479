import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import Button from "common/components/Button";
import Flex from "common/components/Flex";
import FormInput from "common/components/FormInputHookForms";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import PasswordHints from "common/components/PasswordHints";
import PasswordInputField from "common/components/PasswordInputField/PasswordInputField";
import SuccessBlock from "common/components/SuccessBlock/SuccessBlock";
import Text from "common/components/Text";
import { Paths } from "common/constants/paths";
import { theme } from "common/theme/theme";
import * as userApi from "services/api/user/userApi";
import { useAppSelector } from "store/hooks";

import { validationSchema } from "./validationSchema";

interface RecoveryPassword {
  code: string;
  password: string;
}

const ChangePasswordForm = () => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const userEmail = useAppSelector(
    (state) => state.auth.userMailToRemindPassword
  );
  const [formError, setFormError] = useState<string>("");
  const [blockade, setBlockade] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
  } = useForm<RecoveryPassword>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      code: "",
      password: "",
    },
  });

  const passwordErrors = {
    smallLetter:
      errors.password !== undefined && !errors.password?.types?.smallLetter,
    bigLetter:
      errors.password !== undefined && !errors.password?.types?.bigLetter,
    digit: errors.password !== undefined && !errors.password?.types?.digit,
    specialChar:
      errors.password !== undefined && !errors.password?.types?.specialChar,
    minLength:
      errors.password !== undefined && !errors.password?.types?.minLength,
  };

  const modal = useModal({ withCloseButton: false });

  const handleAddGroup = () => {
    modal.showModal();
  };

  const onClickHandler = handleSubmit(async (data, event) => {
    event.preventDefault();
    if (isValid) {
      try {
        await userApi.resetPassword({
          confirmationCode: data.code,
          email: userEmail,
          password: data.password,
        });
        setIsPasswordChanged(true);
        setFormError("");
        setBlockade(false);
        handleAddGroup();
      } catch (error) {
        const message = error.response?.data?.message;
        if (message.startsWith("Password reset code incorrect.")) {
          setFormError("Niewłaściwy kod.");
        } else if (message.startsWith("Password does not meet requirements")) {
          setFormError("Wprowadzone hasło nie spełnia wszystkich wymagań.");
        } else if (
          message.startsWith("Attempt limit exceeded, please try again later.")
        ) {
          setFormError("Niewłaściwy kod");
          setBlockade(true);
        } else if (message.startsWith("Password reset code expired")) {
          setFormError("Ważność kodu wygasła");
        } else {
          setFormError(`Zmiana hasła nie powiodła się. Szczegóły: ${message}`);
        }
      }
    }
  });

  return (
    <>
      {userEmail && !isPasswordChanged && (
        <>
          <div>
            <Text size="30px" weight="bold" mb="24px">
              Zmiana hasła
            </Text>
            <Text size="18px">{`Wysłaliśmy kod na adres: ${userEmail}`}</Text>
            <Text size="18px">Wpisz go poniżej i ustaw nowe hasło.</Text>
          </div>
          <form>
            <FormInput
              errorText={formError}
              id="code"
              placeholder="Kod aktywacyjny"
              label="Kod aktywacyjny"
              type="text"
              isValid={!formError}
              register={register}
              touched={touchedFields.code}
              inputName="code"
              mb="48px"
              mt="24px"
            />
            <PasswordInputField
              id="password"
              inputName="password"
              label="Nowe hasło"
              placeholder="Nowe hasło"
              register={register}
              passwordErrors={errors.password?.message as string}
              passwordTouched={touchedFields.password}
              mb="24px"
            />
            <PasswordHints errorDetails={passwordErrors} />
            <Flex direction="column" justify="center" align="center" mt=" 40px">
              {blockade && (
                <Text
                  weight="bold"
                  color={theme.color.alertRed}
                  mb="20px"
                  size="14px"
                >
                  Zbyt dużo prób przypominania hasła. Spróbuj ponownie później.
                </Text>
              )}
              <Button
                type="submit"
                width="272px"
                height="56px"
                onClick={onClickHandler}
                disabled={!isValid || blockade}
              >
                Zmień hasło
              </Button>
            </Flex>
            {!blockade && (
              <Text size="16px" lineHeight="150%" align="center" mt="24px">
                Kod nie dotarł?{" "}
                <Link to={Paths.REMIND}>
                  <strong>Wyślij ponownie.</strong>
                </Link>
              </Text>
            )}
          </form>
        </>
      )}
      {userEmail && isPasswordChanged && (
        <ModalPortal {...modal.modalProps}>
          <SuccessBlock
            title="Twoje hasło zostało zmienione"
            path={Paths.LOGIN}
          >
            <Text align="center" size="18px" mb="40px"></Text>
          </SuccessBlock>
        </ModalPortal>
      )}
      {!userEmail && <Navigate to={Paths.REMIND} />}
    </>
  );
};

export default ChangePasswordForm;
