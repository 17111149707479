import React from "react";
import { Link } from "react-router-dom";

import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Text from "common/components/Text";
import { Paths } from "common/constants";
import { useSub } from "common/hooks/useSub";
import { useGetUserQuery } from "store/user/userApi";

import { useStepper } from "../Stepper";

import { ProfileCreationGreetingsStyles } from "./ProfileCreationGreetingsStyles";

export const ProfileCreationGreetings = () => {
  const { nextStep } = useStepper();
  const { sub } = useSub();

  const { data: user } = useGetUserQuery({ sub }, { skip: !sub });

  return (
    <ProfileCreationGreetingsStyles>
      <header>
        <Heading>Cześć {user?.firstName || "placeholder"}!</Heading>
        <Text mt="1rem">
          Przedstaw się i pokaż czym się obecnie zajmujesz, abyśmy mogli Cię
          lepiej poznać.
        </Text>
      </header>
      <main>
        <img
          src="images/bird.svg"
          loading="lazy"
          alt="Sweet bird"
          title="Sweet bird"
        />
      </main>
      <footer>
        <Link to={Paths.DASHBOARD}>
          <Button variant="outlined">Nie teraz</Button>
        </Link>
        <Button onClick={nextStep}>Dalej</Button>
      </footer>
    </ProfileCreationGreetingsStyles>
  );
};
