import React from "react";

export const IconCountry = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.304199 12.0003V0.666992H5.97116L6.28507 2.10033H10.2172V8.26699H5.98768L5.67376 6.85033H1.2955V12.0003H0.304199ZM6.83029 7.26699H9.22594V3.10033H5.44246L5.12855 1.66699H1.2955V5.85033H6.51637L6.83029 7.26699Z"
      fill="#323232"
    />
  </svg>
);
