import styled from "styled-components/macro";

export const SearchListResultStyled = styled.div`
  padding-left: 3.25rem;
  position: fixed;
  top: 4.5rem;

  .container {
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.color.basicWhite};
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 225px;
    box-shadow: -2px 2px 20px 0px rgba(0, 0, 0, 0.2);
    gap: 1rem;
    .title {
      color: #000;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`;
