import Heading from "common/components/Heading";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
`;

export const MainHeading = styled(Heading)`
  font-size: 24px;
  margin-bottom: 8px;
`;

export const UserDataContainer = styled.div`
  width: 100%;
  margin-left: 16px;
`;

export const Footer = styled(Text).attrs({ weight: "semibold", size: "12px" })`
  width: 62px;
  padding: 1.8%;
  box-shadow: inset 0 -5px 0 ${theme.color.formYellow};
`;
