import React, { ReactNode } from "react";

import {
  PostDataWrapper,
  PostReactionWrapper,
  PostWrapper,
} from "./Post.styled";
import PostComment from "./PostComment/PostComment";
import PostContent from "./PostContent/PostContent";
import PostAttachments from "./PostContent/PostContentAttachments/PostContentAttachments";
import PostGroupUserSection from "./PostGroupUserSection/PostGroupUserSection";
import PostReaction from "./PostReaction/PostReaction";
import PostReactionCounter from "./PostReactionsCounter/PostReactionCounter";

interface PostProps {
  postReactions?: ReactNode;
  postHeading?: ReactNode;
  postContent?: ReactNode;
  postAttachments?: ReactNode;
  postFooter?: ReactNode;
  postComment?: ReactNode;
}

const Post = ({
  postHeading,
  postContent,
  postAttachments,
  postReactions,
  postFooter,
  postComment,
}: PostProps) => {
  return (
    <PostWrapper>
      <PostDataWrapper>
        {postHeading}
        {postContent}
      </PostDataWrapper>
      {postAttachments}
      <PostReactionWrapper>{postReactions}</PostReactionWrapper>
      {postFooter}
      {postComment}
    </PostWrapper>
  );
};

Post.GroupUserSection = PostGroupUserSection;
Post.Content = PostContent;
Post.ReactionCounter = PostReactionCounter;
Post.Attachments = PostAttachments;
Post.Reaction = PostReaction;
Post.Comment = PostComment;

export default Post;
