import React, { useEffect, useState } from "react";

import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { IGroupMember } from "store/groupSlice";
import { useAppSelector } from "store/hooks";

import {
  MemberItemWrapper,
  MembersItemsWrapper,
  MembersWrapper,
} from "./MembersBanner.styled";
import MembersCounter from "./MembersCounter/MembersCounter";
import MembersItem from "./MembersItem/MembersItem";

const MembersBanner = () => {
  const [shownMembers, setShownMembers] = useState<IGroupMember[]>();
  const members = useAppSelector((state) => state.group.groupMembers);

  useEffect(() => {
    setShownMembers(members?.length > 5 ? members.slice(0, 4) : members);
  }, [members]);

  return (
    <MembersWrapper>
      <MembersCounter />
      <MembersItemsWrapper>
        {members?.length > 5 && (
          <MemberItemWrapper zIndex="4">
            <Text color={theme.color.basicWhite}>...</Text>
          </MemberItemWrapper>
        )}
        {shownMembers?.map((member, index) => {
          return (
            <MembersItem
              memberImg={member?.avatar}
              key={index}
              zIndex={shownMembers?.length - 1 - index}
            />
          );
        })}
      </MembersItemsWrapper>
    </MembersWrapper>
  );
};

export default MembersBanner;
