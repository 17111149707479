import styled from "styled-components/macro";

export const GroupCreationRadioButtonsWrapper = styled.div`
  display: grid;
  margin-top: 24px;
  gap: 19px;
`;
export const SpaceBetweenRadioButtons = styled.div`
  width: 49px;
`;
