import { useDispatch } from "react-redux";

import { deleteCategoryThunk } from "store/categoriesSlice";
import { AppDispatch } from "store/store"; // Adjust the import path as necessary

import { deleteSubCategory } from "../../../services/api/note/noteApi";

export const useDeleteCategory = (userId: string, categoryId: string) => {
  const dispatch: AppDispatch = useDispatch();

  const performDeleteCategory = async () => {
    try {
      await dispatch(deleteCategoryThunk({ userId, categoryId })).unwrap();
      return true;
    } catch (err) {
      console.error("Failed to delete category", err);
      return false;
    }
  };

  return { performDeleteCategory };
};

export const useDeleteSubCategory = (userId: string, subCategoryId: string) => {
  const performDeleteSubCategory = async () => {
    try {
      await deleteSubCategory(userId, subCategoryId);
      return true;
    } catch (err) {
      console.error("Failed to delete sub-category", err);
      return false;
    }
  };

  return { performDeleteSubCategory };
};
