import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  initTaskerGroupViewAsyncThunk,
  initTaskerUserViewAsyncThunk,
} from "services/api/tasker";
import {
  IListResponse,
  ITaskResponse,
} from "services/api/tasker/tasker.responses";
import { getSub } from "store/auth/authUtils";

import { updateAllTaskFields } from "./mappers/updateAllTaskFields";
import { ICategory } from "./models/Category";
import { GroupUser } from "./models/GroupUser";
import { IList } from "./models/List";
import { Task } from "./models/Task";
import { DefaultState, GroupData, TaskerMainView } from "./taskerSlice.types";
import { buildGroupTasksByGroup, buildListWithTasks } from "./taskerUtil";

const taskerSlice = createSlice({
  name: "tasker",
  initialState: {
    loading: false,
    MainView: "Group",
    groupId: null,
    taskerView: {
      BoardView: "Todo",
      taskView: "Board",
      NewTaskListId: null,
      editTaskData: null,
    },
    groupViewTree: {
      loaded: false,
      lists: [],
    },
    userViewTree: {
      loaded: false,
      taskFromGroupId: null,
      groups: [],
    },
    allCategories: [],
    allUsers: [],
  } as DefaultState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMainView(state, action: PayloadAction<TaskerMainView>) {
      state.MainView = action.payload;
    },
    test(state, action: PayloadAction<string | number>) {
      console.log(action.payload);
    },
    setOpenNewTaskView(state, action: PayloadAction<string | null>) {
      state.taskerView.taskView = "New";
      state.taskerView.NewTaskListId = action.payload;
    },
    setOpenEditTaskView(
      state,
      {
        payload: { task, listId },
      }: PayloadAction<{ task: Task; listId: string } | null>
    ) {
      state.taskerView.taskView = "Edit";
      state.taskerView.editTaskData = { task, listId };
    },
    setOpenDetailsTaskView(
      state,
      {
        payload: { task, listId },
      }: PayloadAction<{ task: Task; listId: string } | null>
    ) {
      state.taskerView.taskView = "Details";
      state.taskerView.editTaskData = { task, listId };
      if (state.MainView === "User") {
        state.userViewTree.taskFromGroupId = task.group_id;
      }
    },
    setCloseDetailsTaskView(state) {
      state.taskerView.taskView = "Board";
      state.taskerView.editTaskData = null;
      state.taskerView.NewTaskListId = null;
    },
    setCloseNewTaskView(state) {
      state.taskerView.taskView = "Board";
      state.taskerView.editTaskData = null;
      state.taskerView.NewTaskListId = null;
    },
    setTodoTaskerView(state) {
      state.taskerView.taskView = "Board";
      state.taskerView.BoardView = "Todo";
    },
    setDoneTaskerView(state) {
      state.taskerView.taskView = "Board";
      state.taskerView.BoardView = "Done";
    },
    addNewTask(state, { payload: task }: PayloadAction<Task>) {
      const groupList = state.groupViewTree.lists;
      const group = groupList.find((t) => t.id === task.list_id);
      if (group) {
        const tasks = group.tasks;
        tasks.push(task);
      }
      state.taskerView.taskView = "Board";
    },
    updateTask(state, { payload: task }: PayloadAction<Task | null>) {
      if (state.MainView === "Group") {
        const prevListId = state.taskerView.editTaskData.listId;
        const lists = state.groupViewTree.lists;

        const nowList = lists.find((t) => t.id === prevListId);
        if (nowList) {
          const taskIndex = nowList.tasks.findIndex((t) => t.id === task.id)!;

          if (task.list_id === prevListId) {
            const t = nowList.tasks[taskIndex];
            updateAllTaskFields(t, task);
          } else {
            const listToPut = lists.find((t) => t.id === task.list_id)!;
            listToPut.tasks.push(task);
            nowList.tasks.splice(taskIndex, 1);
          }
          state.taskerView.taskView = "Board";
          state.taskerView.editTaskData = null;
        }
      } else if (state.MainView === "User") {
        const nowGroupId = state.userViewTree.taskFromGroupId;
        const group = state.userViewTree.groups.find(
          (g) => g.group.id === nowGroupId
        )!;
        const tasks = group.group.tasks;
        const nowTaskIndex = tasks.findIndex((t) => t.id === task.id)!;
        updateAllTaskFields(tasks[nowTaskIndex], task);
        const sub = getSub();
        const isUserAssigned = tasks[nowTaskIndex].assignee?.user_id === sub;
        if (!isUserAssigned) {
          tasks.splice(nowTaskIndex, 1);
        }

        state.taskerView.taskView = "Board";
        state.taskerView.editTaskData = null;
      }
    },
    createNewList(
      state,
      { payload: { id, name } }: PayloadAction<{ id: string; name: string }>
    ) {
      state.groupViewTree.lists.push(
        { id, name, group_id: "", last_update_date: "", tasks: [] } // TODO
      );
    },
    setTaskCompletedInGroupView(
      state,
      {
        payload: { listId, taskId, completed },
      }: PayloadAction<{ listId: string; taskId: string; completed: boolean }>
    ) {
      const foundList = state.groupViewTree.lists.find((l) => l.id === listId)!;
      const foundTask: Task = foundList.tasks.find((t) => t.id === taskId)!;
      foundTask.completed = completed;
    },
    setTaskCompletedInUserView(
      state,
      {
        payload: { task, completed },
      }: PayloadAction<{ task: Task; completed: boolean }>
    ) {
      const group = state.userViewTree.groups.find(
        (g) => g.group.id === task.group_id
      )!;
      const nowTask = group.group.tasks.find((t) => t.id === task.id)!;
      nowTask.completed = completed;
    },
    addNewCategory(state, { payload }: PayloadAction<ICategory>) {
      state.allCategories.push(payload);
    },
    addTaskList(state, { payload }: PayloadAction<IList>) {
      state.groupViewTree.lists.push(payload);
    },
    setUserViewDataForGroup(
      state,
      {
        payload: { groupId, sub, categories, users, lists },
      }: PayloadAction<{
        groupId: string;
        sub: string;
        lists: IList[];
        users: GroupUser[];
        categories: ICategory[];
      }>
    ) {
      const group = state.userViewTree.groups.find(
        (g) => g.group.id === groupId
      );
      if (users) {
        const myUser = users.find((u) => u.user_id === sub);
        if (myUser) {
          group.group.tasks.forEach((t) => {
            t.assignee = myUser;
          });
        }
      }
      group.allCategories = categories;
      group.allUsers = users;
      group.allLists = lists;
      group.loaded = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initTaskerGroupViewAsyncThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      initTaskerGroupViewAsyncThunk.fulfilled,
      (state, action) => {
        const allTasks: ITaskResponse[] = action.payload.allTasks;
        const allLists: IListResponse[] = action.payload.allLists;
        const allUsers: GroupUser[] = action.payload.allUsers;
        const allCategories: ICategory[] = action.payload.allCategories;
        const data = buildListWithTasks(allTasks, allLists, allUsers);

        state.allUsers = allUsers;
        state.groupViewTree.loaded = true;
        state.groupViewTree.lists = data;
        state.allCategories = allCategories;
        state.loading = false;

        state.MainView = "Group";
      }
    );
    builder.addCase(initTaskerUserViewAsyncThunk.pending, (state) => {
      state.loading = true;
      state.MainView = "User";
    });
    builder.addCase(initTaskerUserViewAsyncThunk.fulfilled, (state, action) => {
      const groups = buildGroupTasksByGroup(
        action.payload.allTasksByGroup,
        action.payload.allProjectsByGroup
      );

      const groups1: GroupData[] = groups.map((g) => {
        return {
          allCategories: [],
          allUsers: [],
          allLists: [],
          group: g,
        } as GroupData;
      });

      state.userViewTree.groups = groups1;
      state.loading = false;
      state.userViewTree.loaded = true;
    });
  },
});

export const taskerReducer = taskerSlice.reducer;
export const taskerAction = taskerSlice.actions;
