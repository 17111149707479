import React from "react";

import Image from "common/components/Image";
import { Container } from "common/components/Layout/Containers";
import Text from "common/components/Text";
import LocalizationIcon from "common/icons/LocalizationIcon";
import { theme } from "common/theme/theme";

import {
  ItemAddButton,
  LocalizationWrapper,
  UserInfoWrapper,
  UsersTableItemWrapper,
} from "./UsersTableItem.styled";

interface UsersTableItemProps {
  avatar: string;
  chooseHandler: (userId: string) => void;
  localization: string;
  fullName: string;
  userId: string;
  occupation: string;
}

const UsersTableItem = ({
  avatar,
  chooseHandler,
  localization,
  fullName,
  userId,
  occupation,
}: UsersTableItemProps) => {
  return (
    <UsersTableItemWrapper>
      <UserInfoWrapper>
        <Image src={avatar} radius="100%" width={48} height={48} />
        <Container m="0 0 0 16px" w="371px">
          <Text
            weight="bold"
            size="16px"
            color={theme.color.basicBlack}
            lineHeight="24px"
            data-testid="user-fullname"
          >
            {fullName}
          </Text>
          <Text
            color={theme.color.basicBlack}
            size="14px"
            lineHeight="20px"
            data-testid="user-occupation"
          >
            {occupation}
          </Text>
          <LocalizationWrapper>
            <LocalizationIcon
              width="16px"
              height="16px"
              color={theme.color.mediumGray}
              data-testid="user-localization"
            />
            <Text size="12px" color={theme.color.mediumGray} lineHeight="20px">
              {localization}
            </Text>
          </LocalizationWrapper>
        </Container>
      </UserInfoWrapper>
      <ItemAddButton
        onClick={() => chooseHandler(userId)}
        data-testid="add-btn"
      >
        <Image src="/icons/addSign.svg" />
      </ItemAddButton>
    </UsersTableItemWrapper>
  );
};

export default UsersTableItem;
