import React from "react";

import Image from "common/components/Image";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";

import { AccordionWrapper } from "./NotesRightPanel.styled";

interface NotesRightPanelHeaderProps {
  handleClick: () => void;
  show: boolean;
  size: string;
  title: string;
}

const NotesRightPanelHeader = ({
  handleClick,
  show,
  size,
  title,
}: NotesRightPanelHeaderProps) => {
  const iconSrc = show ? `/icons/up.svg` : `/icons/down.svg`;

  return (
    <>
      <AccordionWrapper onClick={handleClick} tabIndex={0}>
        <Text
          size={size}
          color={theme.color.basicBlack}
          letterSpacing="0.4px"
          lineHeight="20px"
        >
          {title}
        </Text>
        <Image src={iconSrc} alt="icon" width={14} height={14} />
      </AccordionWrapper>
    </>
  );
};

export default NotesRightPanelHeader;
