import Wrapper from "common/components/Wrapper/Wrapper";
import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface NoteProps {
  header?: string;
  text?: string;
  date?: string;
  category?: string;
}

interface ModalContentWrapperProps {
  width: string;
  height: string;
  alignItems: "center" | "flex-start";
  justifyContent: "space-around" | "flex-start";
  position?: string;
}

export const NoteWrapper = styled.div<NoteProps>`
  width: 180px;
  height: 180px;
  border-radius: ${theme.spacing.s}px;
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.bold};
  padding: ${theme.spacing.l}px;
  color: ${theme.color.basicBlack};
  background-color: ${theme.color.basicWhite};
  position: relative;
  cursor: pointer;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Category = styled.div`
  width: 92px;
  height: 32px;
  background: ${theme.color.lightGray};
  border-radius: 40px;
  font-size: 12px;
  font-weight: ${theme.fontWeight.normal};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  font-family: "Inter";
  color: ${theme.color.basicBlack};
`;

export const ImageWrapper = styled(Wrapper)`
  margin-right: 19px;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 65%;
  /* background-color: black; */
`;
export const ModalContentWrapper = styled.div<ModalContentWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: ${({ position }) => position};
  p {
    text-align: ${(props) => props.alignItems};
  }
`;
