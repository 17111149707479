import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

interface HeadingProps {
  align?: "center" | "inherit" | "justify" | "left" | "right";
  mt?: string;
  mb?: string;
  fontSize?: string;
}

const defaultProps: HeadingProps = {
  align: "inherit",
  mt: "0",
  mb: "0",
  fontSize: "30px",
};

const Heading = styled.h2<HeadingProps>`
  text-align: ${(props) => props.align};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  font-size: ${(props) => props.fontSize};
  font-family: "Inter", sans-serif;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.basicBlack};
`;

Heading.defaultProps = defaultProps;

export default Heading;
