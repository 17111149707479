import { imageBase64Decode } from "common/utils/imageBase64Decode";
import { GroupLogo, GroupResponseDto } from "services/api/group/groupApi";

import { groupConfig } from "../groupConfig";

export type GroupStatus = "ACTIVE" | "PASSIVE" | "BLOCKED" | "OTHER";

export type GroupType = "DISCUSS" | "PROJECT";

export type GroupAccess = "PUBLIC" | "PRIVATE";

export interface IGroup {
  groupStatus: GroupStatus;
  groupId: string;
  created: Date;
  name: string;
  description: string;
  information: string;
  logo: GroupLogo;
  groupAccess: GroupAccess;
  groupOwner: IGroupOwner;
  groupType: GroupType;
  createdBy: string;
  tags: string[];
  decodedAvatar: string;
}

enum IGroupOwnerType {
  USER = "USER",
  SYSTEM = "SYSTEM",
}

interface IGroupOwner {
  groupOwnerType: IGroupOwnerType;
  groupOwnerId: string;
}

export const convertGroupFromDto = (
  groupDto: GroupResponseDto
): IGroup | null => {
  try {
    const group: IGroup = {
      groupStatus: groupDto.group_status,
      groupId: groupDto.group_id,
      created: groupDto.created,
      name: groupDto.name,
      logo: { ...groupDto.logo },
      information: groupDto.information,
      description: groupDto.description,
      groupOwner: {
        groupOwnerType: IGroupOwnerType[groupDto.group_owner.group_owner_type],
        groupOwnerId: groupDto.group_owner.group_owner_id,
      },
      groupAccess: groupDto.group_access,
      groupType: groupDto.group_type,
      createdBy: groupDto.created_by,
      tags: groupDto.tags,
      decodedAvatar:
        groupDto.logo?.data !== undefined
          ? imageBase64Decode(groupDto?.logo?.data)
          : groupConfig.defaultImage,
    };
    return group;
  } catch (err) {
    console.log("Converting group response failed");
    return null;
  }
};
