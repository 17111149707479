import React from "react";

import Flex from "common/components/Flex";
import TaskPriorityButton from "common/components/Forms/Buttons/TaskPriorityButton/TaskPriorityButton";
import Text from "common/components/Text";
import { theme } from "common/theme/theme";
import { useAppSelector } from "store/hooks";

type Props = {
  priority: boolean;
  setPriorityFn: () => void;
};

export default function NewTaskHeader({ priority, setPriorityFn }: Props) {
  const editTaskData = useAppSelector((s) => s.tasker.taskerView.editTaskData);

  return (
    <Flex justify="space-between" width="100%" p="0 0 16px 0">
      <Text size="24px" weight="bold" color={theme.color.basicBlack}>
        {editTaskData === null ? "Nowe zadanie" : "Aktualizacja zadania"}
      </Text>
      <TaskPriorityButton priority={priority} setPriorityFn={setPriorityFn} />
    </Flex>
  );
}
