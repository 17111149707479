import React from "react";

import GroupPlainButton from "components/Group/Common/GroupPlainButton/GroupPlainButton";

import { IArchiveCheckboxes } from "../GroupCloseModal";
import {
  GroupCloseButtonsWrapper,
  GroupCloseModalHeader,
} from "../GroupCloseModal.styled";

import GroupCloseModalArchiveCheckbox from "./GroupCloseModalArchiveCheckbox/GroupCloseModalArchiveCheckbox";
import {
  GroupCloseArchiveModalWrapper,
  GroupCloseModalCheckboxes,
} from "./GroupCloseModalArchiveStage.styled";

interface GroupCloseModalArchiveStageProps {
  checkboxes: IArchiveCheckboxes;
  checkboxesHandler: (checkboxToHandle: keyof IArchiveCheckboxes) => void;
  handleAllCheckboxes: () => void;
  leaveArchiveStage: () => void;
  goToFinalStage: () => void;
}

const GroupCloseModalArchiveStage = ({
  checkboxes,
  checkboxesHandler,
  handleAllCheckboxes,
  leaveArchiveStage,
  goToFinalStage,
}: GroupCloseModalArchiveStageProps) => {
  return (
    <GroupCloseArchiveModalWrapper>
      <GroupCloseModalHeader>
        Które komponenty grupy chcesz archiwizować?
      </GroupCloseModalHeader>
      <GroupCloseModalCheckboxes>
        <GroupCloseModalArchiveCheckbox
          checked={checkboxes.allCheckboxes}
          onChecked={handleAllCheckboxes}
          data-testid="all-components-checkbox"
          textContent="Wszystkie komponenty"
        />
        <GroupCloseModalArchiveCheckbox
          checked={checkboxes.calendar}
          data-testid="calendar-checkbox"
          onChecked={() => checkboxesHandler("calendar")}
          textContent="Kalendarz"
        />
        <GroupCloseModalArchiveCheckbox
          checked={checkboxes.notes}
          data-testid="notes-checkbox"
          onChecked={() => checkboxesHandler("notes")}
          textContent="Notatki"
        />
      </GroupCloseModalCheckboxes>
      <GroupCloseButtonsWrapper>
        <GroupPlainButton
          width="50px"
          height="30px"
          testId="go-to-previous-stage-btn"
          onClick={leaveArchiveStage}
          isProceedButton={false}
          buttonText="Anuluj"
        />
        <GroupPlainButton
          width="101px"
          height="30px"
          testId="go-to-final-archive-stage"
          onClick={goToFinalStage}
          isProceedButton={true}
          buttonText=" Archiwizuj"
        />
      </GroupCloseButtonsWrapper>
    </GroupCloseArchiveModalWrapper>
  );
};

export default GroupCloseModalArchiveStage;
