import { GroupListMemberResponseDto } from "services/api/group/groupApi";
import { createTaskFromTaskResponse } from "services/api/tasker/tasker.mappers";
import {
  IListResponse,
  ITaskResponse,
} from "services/api/tasker/tasker.responses";

import { createTasksListFromTasksListResponse } from "./mappers/createTasksListFromTasksListResponse";
import { IGroup } from "./models/Group";
import { GroupUser } from "./models/GroupUser";
import { IList } from "./models/List";
import { Task } from "./models/Task";

export function buildListWithTasks(
  tasksResponse: ITaskResponse[],
  listsResponse: IListResponse[],
  usersResponse: GroupUser[]
): IList[] {
  if (tasksResponse.length > 0) {
    const allTasks: Task[] = [];
    tasksResponse.forEach((task: ITaskResponse) => {
      const newTask: Task = createTaskFromTaskResponse(task);
      if (task.assignee) {
        const user = usersResponse.find((m) => m?.user_id === task.assignee);
        if (user) {
          newTask.assignee = user;
        }
      }
      allTasks.push(newTask);
    });

    const allLists: IList[] = [];
    listsResponse.forEach((list: IListResponse) => {
      const newList = createTasksListFromTasksListResponse(list);
      allLists.push(newList);
    });

    allLists.forEach((list: IList) => {
      const tasksToAdd = allTasks.filter((t) => t.list_id === list.id);
      list.tasks = tasksToAdd;
    });

    return allLists;
  }

  return [];
}

export function buildGroupTasksByGroup(
  tasks: ITaskResponse[],
  availableGroups: GroupListMemberResponseDto[]
): IGroup[] {
  const groups: IGroup[] = [];
  tasks.forEach((t) => {
    const task: Task = createTaskFromTaskResponse(t);
    const groupAlreadyExists = groups.findIndex((g) => g.id === task.group_id);
    if (groupAlreadyExists === -1) {
      const newGroup: IGroup = {
        id: task.group_id,
        tasks: [task],
      };
      const groupName = availableGroups.find(
        (g) => g.group_id === task.group_id
      );
      if (!groupName) {
        console.error("NO SUCH GROUP FOR TASK !!");
      } else {
        newGroup.name = groupName.name;
      }

      groups.push(newGroup);
    } else {
      groups[groupAlreadyExists].tasks.push(task);
    }
  });
  return groups;
}
