import styled, { createGlobalStyle } from "styled-components/macro";

import { CloseButtonPosition } from "./types";

interface ModalContainerStyledProps {
  withPadding: boolean;
  closeButtonPosition: CloseButtonPosition;
  centerFixed: boolean;
}

export const CloseButtonStyled = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0;
  width: 12px;
  height: 12px;
`;

export const ModalContainerStyled = styled.div<ModalContainerStyledProps>`
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  padding: ${({ withPadding }) => (withPadding ? "20px" : "0px")};
  position: relative;
  min-width: 300px;
  overflow: visible; // to avoid displaying scrollbar when modal is closed

  ${CloseButtonStyled} {
    position: absolute;
    right: ${({ closeButtonPosition }) =>
      closeButtonPosition?.right ? closeButtonPosition.right : "24px"};
    top: ${({ closeButtonPosition }) =>
      closeButtonPosition?.top ? closeButtonPosition.top : "24px"};
    z-index: 10;
  }
  ${({ centerFixed }) => centerFixed && `height: fit-content;`}
`;

export const BackdropStyled = styled.div<{ centerFixed: boolean }>`
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  top: 0px;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  ${({ centerFixed }) =>
    centerFixed ? `padding-top: 30px;` : `align-items: center;`}
`;

export const BodyWithModalStyles = createGlobalStyle`
  body {
      position: fixed;
    }
`;
