import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { Paths } from "common/constants";
import { useAppSelector } from "store/hooks";

import { AddCategoryModalContent } from "./AddCategoryModalContent/AddCategoryModalContent";
import NoteKitContainer from "./NoteKit/NoteKitContainer";
import { AccordionWrapperUnderlined } from "./NotesRightPanel.styled";
import NotesRightPanelAddCategoryButton from "./NotesRightPanelAddCategoryButton";
import NotesRightPanelCategory from "./NotesRightPanelCategory";
import NotesRightPanelChip from "./NotesRightPanelChip";
import NotesRightPanelHeader from "./NotesRightPanelHeader";
import NotesRightPanelSubCategory from "./NotesRightPanelSubCategory";

enum NotesRightPanelChipType {
  id = "NotesRightPanelChipId",
}

const NotesRightPanel = () => {
  const location = useLocation();
  const { categories } = useAppSelector((state) => state.category);
  const [showAll, setShowAll] = useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | NotesRightPanelChipType
  >();

  const addCategoryModal = useModal();
  const handleAllCategoryClick = () => setShowAll((prevIsShow) => !prevIsShow);

  const closeModal = () => {
    addCategoryModal.modalProps.closeModal();
  };

  return (
    <AccordionWrapperUnderlined>
      <ModalPortal {...addCategoryModal.modalProps}>
        <AddCategoryModalContent onClose={closeModal} />
      </ModalPortal>
      {location.pathname.includes(Paths.CREATE_NOTE) ? (
        <>
          <NotesRightPanelHeader
            handleClick={handleAllCategoryClick}
            show={showAll}
            title="Przybornik"
            size="16px"
          />
          <NoteKitContainer></NoteKitContainer>
        </>
      ) : (
        <>
          <NotesRightPanelHeader
            handleClick={handleAllCategoryClick}
            show={showAll}
            title="Kategorie"
            size="16px"
          />
          <NotesRightPanelAddCategoryButton
            handleAddCategory={addCategoryModal.showModal}
          />
          {showAll ? (
            <>
              <NotesRightPanelChip
                isSelected={selectedCategoryId === NotesRightPanelChipType.id}
                selectCategory={() =>
                  setSelectedCategoryId(NotesRightPanelChipType.id)
                }
              />
              {categories?.map((category) => (
                <NotesRightPanelCategory
                  key={category.id}
                  title={category.categoryName}
                  isSelected={category.id === selectedCategoryId}
                  categoryId={category.id}
                  selectCategory={() => setSelectedCategoryId(category.id)}
                >
                  {category.subCategories?.length
                    ? category?.subCategories.map((subcategory) => (
                        <NotesRightPanelSubCategory
                          key={subcategory.id}
                          title={subcategory.categoryName}
                          isSelected={subcategory.id === selectedCategoryId}
                          selectCategory={() =>
                            setSelectedCategoryId(subcategory.id)
                          }
                        />
                      ))
                    : null}
                </NotesRightPanelCategory>
              ))}
              <NotesRightPanelAddCategoryButton
                handleAddCategory={addCategoryModal.showModal}
              />
            </>
          ) : null}
        </>
      )}
    </AccordionWrapperUnderlined>
  );
};

export default NotesRightPanel;
