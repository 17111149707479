import React from "react";

import Flex from "common/components/Flex";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Wrapper from "common/components/Wrapper/Wrapper";

import { NoteKitBorderWrapper } from "../shared/NoteKitStyles";

export default function TextBackgroundItem() {
  const iconSrc = `/icons/down.svg`;
  const iconBgColor = `/icons/noteKit/icon_bg_color.svg`;

  return (
    <Flex justify="space-between">
      <Wrapper>
        <Text ml="10px" size="14px">
          Tło tekstu
        </Text>
      </Wrapper>
      <Flex>
        <NoteKitBorderWrapper p="6px 10px">
          <Text size="14px">Brak</Text>
          <Text ml="10px">
            <Image src={iconSrc} alt="icon" width={13} height={13} />
          </Text>
        </NoteKitBorderWrapper>
      </Flex>
      <Flex>
        <NoteKitBorderWrapper p="5px 5px 1px 5px">
          <Text>
            <Image src={iconBgColor} alt="icon" width={22} height={22} />
          </Text>
        </NoteKitBorderWrapper>
      </Flex>
    </Flex>
  );
}
