import React, { memo } from "react";

import Flex from "common/components/Flex";
import TaskCompletedUserView from "common/components/Forms/Checkboxes/TaskCompleted/TaskCompletedUserView";
import TaskPriorityTag from "common/components/TagsAndLabels/Tags/TaskPriorityTag/TaskPriorityTag";
import TaskLabel from "common/components/TagsAndLabels/TaskCategory/TaskCategory";
import { theme } from "common/theme/theme";
import { randomNumber } from "common/utils/randomUtils";
import { useAppDispatch } from "store/hooks";
import { ICategory } from "store/tasker/models/Category";
import { Task } from "store/tasker/models/Task";
import { taskerAction } from "store/tasker/taskerSlice";

import Image from "../../Image";
import Text from "../../Text";

import { CardWrapper } from "./TaskCard.styled";

type TaskCardProps = {
  task: Task;
  category: ICategory | null;
};

function TaskCardUserViewMemo({ task, category }: TaskCardProps) {
  const dispatch = useAppDispatch();
  const date = new Date(task.date);
  return (
    <CardWrapper>
      <Flex direction="row" gap="8px" p="12px" align="flex-start">
        <Flex width="30px">
          <TaskCompletedUserView task={task} />
        </Flex>
        <Flex
          direction="column"
          width="100%"
          onClick={() =>
            dispatch(
              taskerAction.setOpenDetailsTaskView({
                task,
                listId: task.list_id,
              })
            )
          }
          cursor="pointer"
        >
          {category && (
            <TaskLabel
              text={category.name}
              p={"1px 13px"}
              h={"21px"}
              colorIdx={parseInt(randomNumber(2))}
            />
          )}
          <Text size="14px" weight="thin" lineHeight="20px" mb="10px">
            {task.task_title ?? "(Comment not added)"}
          </Text>
          <Flex direction="row" gap="4px">
            <Image
              src="/icons/calendar.svg"
              alt="Plus"
              width={16}
              height={16}
              opacity={"0.5"}
            />
            <Text
              ml="5px"
              size="12px"
              weight="normal"
              letterSpacing="0.2px"
              color={theme.color.darkerGray}
            >
              {`${date.getDate()} ${date.toLocaleString("default", {
                month: "long",
              })}  ${date.getFullYear()}`}
            </Text>
          </Flex>
          <Flex
            direction="row"
            align="center"
            justify="space-between"
            p="10px 0 0 0"
          >
            <TaskPriorityTag task={task} />
            <Flex>
              {task.assignee && (
                <Image
                  key={task.assignee.user_id}
                  src={
                    task.assignee.user?.avatarUrl ??
                    "/icons/profileIconExample.svg"
                  }
                  width={24}
                  height={24}
                  marginLeft={"-10px"}
                  radius="100%"
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CardWrapper>
  );
}

export const TaskCardUserView = memo(TaskCardUserViewMemo);
