import React from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { DecodedIdToken } from "common/types/IToken";
import { getJwtToken } from "common/utils/auth/getToken";
import jwtDecode from "jwt-decode";
import { IEditGroupStatusRequest } from "services/api/group/editGroup";
import { createGroupRequestHeader } from "services/api/group/groupApiUtils";
import { GroupApiRequest } from "services/api/shared/apiResponse";
import { useEditGroupMutation } from "store/group/groupApi";
import { getGroupInformation } from "store/groupSlice";
import { useAppDispatch } from "store/hooks";

import GroupRadioButtons from "../Common/GroupCreationRadioButtons/GroupRadioButtons";
import { GroupAccess, GroupStatus } from "../Types/group";

import {
  GroupSettingsModalHeader,
  GroupSettingsModalWrapper,
} from "./GroupSettings.styled";
import GroupSettingsButtons from "./GroupSettingsButtons/GroupSettingsButtons";

interface IGroupSettingsForm {
  access: GroupAccess;
  status: GroupStatus;
}

interface GroupSettingsProps {
  closeModal: () => void;
  groupAccess: GroupAccess;
  groupId: string;
  groupStatus: GroupStatus;
  groupType: string;
}

const GroupSettings = ({
  closeModal,
  groupAccess,
  groupId,
  groupStatus,
  groupType,
}: GroupSettingsProps) => {
  const dispatch = useAppDispatch();
  const [editGroup] = useEditGroupMutation();
  const { control, handleSubmit, setValue } = useForm<IGroupSettingsForm>({
    mode: "onTouched",
    defaultValues: {
      access: groupAccess,
      status: groupStatus,
    },
  });

  const onSubmit: SubmitHandler<IGroupSettingsForm> = async (data) => {
    const decodedToken = jwtDecode(await getJwtToken()) as DecodedIdToken;
    const userSub = decodedToken["cognito:username"];

    if (data.status !== groupStatus || data.access !== groupAccess) {
      const groupData: GroupApiRequest<IEditGroupStatusRequest> = {
        body: {
          header: createGroupRequestHeader(userSub),
          message: {
            group_id: groupId,
            group_type: groupType,
            group_status: "BLOCKED",
            group_access: data.access,
          },
        },
      };
      await editGroup({ editGroupData: groupData });
      dispatch(getGroupInformation({ groupId, groupType }));
    }
  };

  const onSubmitForm = () => {
    handleSubmit(onSubmit)();
    closeModal();
  };

  const formData = useWatch({ control });
  return (
    <GroupSettingsModalWrapper>
      <GroupSettingsModalHeader>
        Wybierz ustawienia grupy
      </GroupSettingsModalHeader>
      <GroupRadioButtons<IGroupSettingsForm>
        header="Dostępność grupy"
        inputName="access"
        firstButtonText="prywatna"
        secondButtonText="publiczna"
        firstButtonValue={"PRIVATE" as GroupAccess}
        secondButtonValue={"PUBLIC" as GroupAccess}
        setValue={setValue}
        selected={formData.access}
        firstDescription="Tylko członkowie grupy będą mogli zobaczyć jej zawartość, dodać post, skomentować oraz sprawdzić liczbę członków i kim są administratorzy."
        secondDescription="Każdy użytkownik będzie mógł zobaczyć jej zawartość, dodać post, skomentować oraz zobaczyć liczbę członków i kim są administratorzy."
      />
      <GroupRadioButtons<IGroupSettingsForm>
        header="Status grupy"
        inputName="status"
        firstButtonText="aktywna"
        secondButtonText="pasywna"
        firstButtonValue={"ACTIVE" as GroupStatus}
        secondButtonValue={"PASSIVE" as GroupStatus}
        setValue={setValue}
        selected={formData.status}
        firstDescription="Użytkownicy będą mieli możliwość dodawania postów, komentowania oraz dodawania członków grupy."
        secondDescription="Użytkownicy nie będą mieli możliwości dodawania postów, komentowania oraz dodawania członków grupy."
      />
      <GroupSettingsButtons
        closeModal={closeModal}
        onClickHandler={onSubmitForm}
      />
    </GroupSettingsModalWrapper>
  );
};

export default GroupSettings;
