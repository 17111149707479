import React from "react";

import { createCategoryFormConfig } from "../AddCategoryModalContent/helper";

import { NameLengthCounterWrapper } from "./NameCounter.styled";

interface NameLengthCounterProps {
  length: number;
  bottom?: string;
}
export const NameLengthCounter = ({
  length,
  bottom,
}: NameLengthCounterProps) => {
  return (
    <NameLengthCounterWrapper isValid={length <= 20} bottom={bottom}>
      {`${length}/
        ${createCategoryFormConfig.categoryNameMaxLength}`}
    </NameLengthCounterWrapper>
  );
};
