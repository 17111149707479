import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Gender } from "common/types/Gender";

import { RegistrationStepProvider } from "./context";
import RegisterFormBasic from "./RegisterFormBasic";
import RegisterFormDetails from "./RegisterFormDetails";
import { IRegistrationData, RegistrationStep } from "./types";
import validationSchema from "./validationSchema";

const RegisterForm = () => {
  const registrationForm = useForm<IRegistrationData>({
    resolver: yupResolver(validationSchema),
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: Gender.Female,
      dateOfBirth: null,
      language: { value: null, text: "" },
    },
  });
  const [currentStep, setCurrentStep] =
    useState<RegistrationStep>("emailAndPassword");
  return (
    <>
      <RegistrationStepProvider {...{ currentStep, setCurrentStep }}>
        <FormProvider {...registrationForm}>
          {currentStep === "emailAndPassword" && <RegisterFormBasic />}
          {currentStep === "details" && <RegisterFormDetails />}
        </FormProvider>
      </RegistrationStepProvider>
    </>
  );
};

export default RegisterForm;
