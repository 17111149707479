import { theme } from "common/theme/theme";
import styled from "styled-components/macro";

const Card = styled.div`
  box-sizing: border-box;
  background-color: ${theme.color.basicWhite};
  border-radius: 8px;
  padding: 2.5%;
`;

export default Card;
