import styled from "styled-components/macro";

export const Form = styled.form`
  margin-top: 140px;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 270px;
  height: 270px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LinkWrapper = styled.a`
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
`;
