import React, { useState } from "react";

import Avatar from "common/components/Avatar/Avatar";
import AvatarEdit from "common/components/AvatarChange/AvatarEdit";
import AvatarRemove from "common/components/AvatarChange/AvatarRemove";
import AvatarUpload from "common/components/AvatarChange/AvatarUpload";
import { ModalPortal } from "common/components/Modal/modal-portal";
import { s3BucketConfig } from "common/constants/AWS/s3BucketConfig";
import { modalConfig } from "common/constants/modalConfig";
import { imageBase64Decode } from "common/utils/imageBase64Decode";
import { groupConfig } from "components/Group/groupConfig";
import { uploadToBucket } from "services/api/group/groupApi";
import { getGroupInformation } from "store/groupSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { AvatarWrapper } from "./GroupAvatar.styled";

interface GroupAvatarProps {
  logo: string;
}

const GroupAvatar = ({ logo }: GroupAvatarProps) => {
  const appDispatch = useAppDispatch();
  const currentGroup = useAppSelector((state) => state.group.chosenGroup);
  const [editAvatar, setEditAvatar] = useState(false);
  const [files, setFiles] = useState<File[]>();
  const [imageUploadedUrl, setImageUploadedUrl] = useState(null);
  const [isImageRemoveConfirmed] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [imageUploadSubTitle, setImageUploadSubTitle] = useState(
    groupConfig.groupAvatarUpload.groupAvatarPreUploadStageSubtitle
  );

  const resetState = () => {
    setIsImageUploading(false);
    setFiles(null);
    setUploadError(null);
    setImageUploadedUrl(null);
    setLoadingProgress(0);
  };

  const turnOnEditAvatarPopup = () => {
    setEditAvatar(true);
  };

  const turnOffEditAvatarPopup = () => {
    setEditAvatar(false);
    setTimeout(() => {
      resetState();
    }, modalConfig.disappearTimeout);
  };

  const handleImageUpload = () => {
    setIsImageUploading(true);
  };

  const onImageUpload = (files: File[]) => {
    let counter = 0;
    const loadingInterval = setInterval(() => {
      counter++;
      if (
        counter ===
        groupConfig.groupAvatarUpload.groupAvatarUploadLoadingCounter
      )
        clearInterval(loadingInterval);
      setLoadingProgress(
        (prevLoadingProgress) =>
          prevLoadingProgress +
          groupConfig.groupAvatarUpload.groupAvatarUploadOneFrameProgress
      );
    }, groupConfig.groupAvatarUpload.groupAvatarUploadTimeWindow);
    setTimeout(() => {
      const firstFile = files[0];
      if (
        (firstFile.type !== "image/jpeg" && firstFile.type !== "image/png") ||
        firstFile.size > 1 << 21
      ) {
        setUploadError(
          groupConfig.groupAvatarUpload.error.wrongFormatAndExceededLimit
        );
        setLoadingProgress(0);
      } else if (
        firstFile.type !== "image/jpeg" &&
        firstFile.type !== "image/png"
      ) {
        setUploadError(groupConfig.groupAvatarUpload.error.wrongFormat);
        setLoadingProgress(0);
      } else if (firstFile.size > 1 << 21) {
        setUploadError(groupConfig.groupAvatarUpload.error.exceededLimit);
        setLoadingProgress(0);
      } else {
        setUploadError(null);
        setFiles(files);
        setImageUploadedUrl(URL.createObjectURL(firstFile));
        setImageUploadSubTitle(
          groupConfig.groupAvatarUpload.groupAvatarUplaodStageSubtitle
        );
      }
    }, groupConfig.groupAvatarUpload.groupAvatarUploadTimeWindow * (groupConfig.groupAvatarUpload.groupAvatarUploadLoadingCounter + 1));
  };

  const onImageUploadCancel = () => {
    setLoadingProgress(0);
    setImageUploadedUrl(null);
    setImageUploadSubTitle(
      groupConfig.groupAvatarUpload.groupAvatarPreUploadStageSubtitle
    );
    setUploadError(null);
  };

  const onImageSave = async () => {
    setEditAvatar(false);
    setIsImageUploading(false);
    await uploadToBucket(
      s3BucketConfig.GroupLogo.bucket,
      `g_${currentGroup.groupId}.jpg`,
      new Blob(files)
    );
    setTimeout(() => {
      appDispatch(
        getGroupInformation({
          groupId: currentGroup.groupId,
          groupType: currentGroup.groupType,
        })
      );
      resetState();
    }, s3BucketConfig.waitBeforeRefetchingS3);
  };

  return (
    <>
      <AvatarWrapper>
        <Avatar
          imageSrc={logo}
          editable={true}
          onEdit={turnOnEditAvatarPopup}
        />
      </AvatarWrapper>
      <ModalPortal
        withCloseButton={true}
        isVisible={editAvatar}
        closeModal={turnOffEditAvatarPopup}
      >
        {!isImageRemoveConfirmed && !isImageUploading && (
          <AvatarEdit
            height="160px"
            imageDefault={groupConfig.defaultImage}
            imageSrc={currentGroup?.decodedAvatar}
            onEdit={handleImageUpload}
            // eslint-disable-next-line
            onRemove={() => console.log("remove")}
            subtitle={
              logo !== groupConfig.defaultImage
                ? "Zdjęcie twojej grupy wygląda następująco:"
                : "Twoja grupa obecnie nie ma zdjęcia"
            }
            title="Zdjęcie grupy"
            width="160px"
          />
        )}
        {isImageRemoveConfirmed && (
          <AvatarRemove
            height="160px"
            imageSrc={groupConfig.defaultImage}
            onCancel={() => console.log("cancel")}
            onRemove={() => console.log("remove")}
            subtitle="Czy na pewno chcesz usunąć zdjęcie? Twój awatar będzie wyglądał tak:"
            title="Usuń zdjęcie"
            width="160px"
          />
        )}
        {isImageUploading && (
          <AvatarUpload
            error={uploadError}
            onImageUpload={onImageUpload}
            imageDefault={groupConfig.defaultImage}
            imageRequirements={[
              {
                key: "requirement",
                text: "Maksymalny rozmiar zdjęcia to: 2 MB",
              },
            ]}
            imageSrc={imageBase64Decode(currentGroup.logo.data)}
            imageUploadedUrl={imageUploadedUrl}
            onCancelUpload={onImageUploadCancel}
            onSaveImage={onImageSave}
            subtitle={imageUploadSubTitle}
            title="Zdjęcie grupy"
            uploadProgress={loadingProgress}
          />
        )}
      </ModalPortal>
    </>
  );
};

export default GroupAvatar;
