import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useCurrentPath = () => {
  const { pathname } = useLocation();

  const currentPath = useMemo(() => {
    return `/${pathname.split("/")[1]}`;
  }, [pathname]);

  return { currentPath };
};
