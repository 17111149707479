import React, { FocusEvent, useCallback, useState } from "react";
import { Controller } from "react-hook-form";

import IconButton from "common/components/IconButton/IconButton";
import { ValidationLabel } from "common/components/InputField";

import StyledFormDropdown, {
  DropdownField,
  FormSelectScrollbarWrapper,
  List,
  ListItem,
  PlaceholderLabel,
} from "./FormSelect.styled";
import { FormSelectProps } from "./Types";

function FormSelect({
  errorText,
  control,
  id,
  isValid,
  options,
  label,
  mt,
  mb,
  type,
  width,
}: FormSelectProps) {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOnMouse, setIsOnMouse] = useState(false);

  const handleBlur = (event: FocusEvent, onBlur) => {
    setIsFocus(false);
    isOnMouse || setIsActive(false);
    onBlur && onBlur(event);
  };

  const handleOnMouseLeave = () => {
    setIsOnMouse(false);
    isFocus || setIsActive(false);
  };

  function onClick(): void {
    if (!isClicked) setIsClicked(true);
    setIsActive(true);
  }

  const chooseItem = (item, onChange) => {
    onChange(item);
    setIsActive(false);
  };

  const checkIfPlaceholderLabelIsValid = useCallback(() => {
    if (isActive) {
      return true;
    } else {
      return isClicked ? isValid : null;
    }
  }, [isActive, isClicked, isValid]);

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <StyledFormDropdown mt={mt} mb={mb}>
          <IconButton
            width={12}
            height={21}
            icon="/icons/arrowDown.svg"
            position="absolute"
            right="10px"
            bottom="10px"
          />
          <DropdownField
            onClick={onClick}
            isClicked={isClicked}
            isActive={isActive}
            isValid={isValid}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={(e) => handleBlur(e, onBlur)}
            id={id}
            value={value?.text}
            onChange={(e) => onChange({ ...value, text: e.target.value })}
            name={id}
            type={type}
            width={width}
          />
          <PlaceholderLabel
            isValid={checkIfPlaceholderLabelIsValid()}
            htmlFor={id}
          >
            {label}
          </PlaceholderLabel>
          <label className="form__label"></label>
          {options?.filter((el) =>
            el.text.toLowerCase().includes(value?.text.toLowerCase())
          ).length > 0 && (
            <List
              isActive={isActive}
              onMouseOver={() => setIsOnMouse(true)}
              onMouseLeave={handleOnMouseLeave}
              rows={3}
              padding={"12px 6px 12px 12px"}
            >
              <FormSelectScrollbarWrapper scrollBarPadding="0px 8px 0px 0px">
                {options
                  .filter((el) =>
                    el.text.toLowerCase().includes(value.text.toLowerCase())
                  )
                  .map((el) => (
                    <ListItem
                      key={el.value}
                      onClick={() => chooseItem(el, onChange)}
                      data-value={el}
                    >
                      {el.text}
                    </ListItem>
                  ))}
              </FormSelectScrollbarWrapper>
            </List>
          )}
          {!isValid && errorText && isClicked && !isFocus && (
            <ValidationLabel isValid={checkIfPlaceholderLabelIsValid()}>
              {errorText}
            </ValidationLabel>
          )}
        </StyledFormDropdown>
      )}
    />
  );
}

export default FormSelect;
