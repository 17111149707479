import React, { useCallback, useState } from "react";
import { UseFormRegister } from "react-hook-form";

import FormInput from "common/components/FormInputHookForms";

import Checkbox from "../Forms/Checkboxes/Checkbox";

import {
  CheckboxContainer,
  PasswordInputWrapper,
} from "./PasswordInputField.styled";

interface PasswordInputFieldProps {
  id: string;
  label: string;
  placeholder: string;
  inputName: string;
  register: UseFormRegister<any>;
  passwordErrors: string;
  passwordTouched: boolean;
  mt?: string;
  mb?: string;
}

const PasswordInputField = ({
  id,
  label,
  placeholder,
  inputName,
  register,
  passwordErrors,
  passwordTouched,
  mt,
  mb,
}: PasswordInputFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const togglePasswordVisible = useCallback((): void => {
    setPasswordVisible(!passwordVisible);
  }, [setPasswordVisible, passwordVisible]);

  return (
    <PasswordInputWrapper>
      <FormInput
        id={id}
        placeholder={placeholder}
        label={label}
        inputName={inputName}
        type={passwordVisible ? "text" : "password"}
        isValid={!passwordErrors}
        register={register}
        errorText={passwordErrors}
        touched={passwordTouched}
        mt={mt}
        mb={mb}
      />
      <CheckboxContainer>
        <Checkbox
          checked={!passwordVisible}
          width={20}
          height={20}
          uncheckedIconSrc="/images/hide-password-icon.svg"
          checkedIconSrc="/images/show-password-icon.svg"
          onChecked={togglePasswordVisible}
        />
      </CheckboxContainer>
    </PasswordInputWrapper>
  );
};

export default PasswordInputField;
