import React, { useEffect, useMemo, useState } from "react";

import Flex from "common/components/Flex";
// import { FlexPart } from "common/components/Flex/FlexPart";
import CancelAcceptButtons from "common/components/Forms/Buttons/CancelAcceptButtons";
import { Container } from "common/components/Layout/Containers";
import { RelativeContainer } from "common/components/Layout/RelativeContainer";
import Text from "common/components/Text";
import UsersTableItem from "components/GroupCreation/GroupCreationSecondStage/UsersTables/UsersTableItem/UsersTableItem";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { GroupUser } from "store/tasker/models/GroupUser";

import { UsersScrollbarWrapper } from "./AddPersonsModal.styled";
import RightSidePanel from "./RightSidePanel/RightSidePanel";
import TopBar from "./TopBar/TopBar";

interface AddPersonsModalProps {
  assignee: GroupUser | null;
  handleCloseModalAndSetAssignee: (users: GroupUser | null) => void;
  handleCloseModalAndCancel: () => void;
}

export default function AddPersonsModal({
  handleCloseModalAndSetAssignee,
  handleCloseModalAndCancel,
  assignee: assigneeProp,
}: AddPersonsModalProps) {
  const dispatch = useAppDispatch();
  const allUsers = useAppSelector((state) => state.tasker.allUsers);
  const [Assignee, setAssignee] = useState<GroupUser | null>();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setAssignee(assigneeProp ?? null);
  }, [assigneeProp, dispatch]);

  const handleChangeInputValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const handleAddUser = (sub: string) => {
    const idx = allUsers.findIndex((u) => u.user_id === sub)!;
    setAssignee(allUsers[idx]);
  };

  const omitted = useMemo(() => {
    if (!Assignee) return allUsers;

    return allUsers.filter((user: GroupUser) => {
      return Assignee?.user_id !== user.user_id;
    });
  }, [allUsers, Assignee]);

  const handleRemoveUser = () => {
    setAssignee(null);
  };

  return (
    <RelativeContainer>
      <TopBar
        assignee={Assignee}
        handleChangeInputValue={handleChangeInputValue}
      />
      <Flex width="574px" height="411px" mt="24px" p="0 15px">
        <Flex>
          <UsersScrollbarWrapper>
            <Flex width="371px" direction="column" gap="11px">
              {allUsers.length !== 0 ? (
                <>
                  {omitted.map((user, i) => (
                    <UsersTableItem
                      key={i}
                      chooseHandler={() => handleAddUser(user.user_id)}
                      fullName={user?.user?.last_name ?? "No last name??"}
                      avatar={
                        user.user?.avatarUrl || "/icons/profileIconExample.svg"
                      }
                      occupation={user?.user?.email}
                      userSub={user.user_id}
                      localization={user.user?.gender}
                      showAddButton={Assignee === null}
                    />
                  ))}
                </>
              ) : (
                <Flex direction="column" justify="center">
                  <Text align="center">
                    <Text weight="bold" size="20px">
                      Brak wyników
                    </Text>
                    <Text
                      mt="16px"
                      size="14px"
                      data-testid="empty-state-message"
                    >
                      Nie udało nam się znaleźć użytkownika
                      {searchValue.trim()}. Sprawdź pisownię lub spróbuj
                      poszukać kogoś innego.
                    </Text>
                  </Text>
                </Flex>
              )}
            </Flex>
          </UsersScrollbarWrapper>
        </Flex>
        <RightSidePanel
          assignee={Assignee}
          handleRemoveUser={handleRemoveUser}
        />
      </Flex>
      <Container p="10px 20px">
        <CancelAcceptButtons
          flexJustifyType="space-between"
          cancelButton={{
            handleCloseModalAndCancel: handleCloseModalAndCancel,
            cancelButtonWidth: "160px",
          }}
          acceptButton={{
            handleCloseModalAndAccept: () =>
              handleCloseModalAndSetAssignee(Assignee),
            textAccept: "Dodaj osobę",
            acceptButtonPadding: "2px 15px",
            acceptButtonWidth: "160px",
          }}
        />
      </Container>
    </RelativeContainer>
  );
}
