import React, { useEffect, useState } from "react";

import { ModalPortal } from "common/components/Modal/modal-portal";
import { useModal } from "common/components/Modal/use-modal";
import { useCurrentPath } from "common/hooks/useCurrentPath";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { taskerAction } from "store/tasker/taskerSlice";

import GroupView from "./groupView/GroupView";
import GroupViewTaskDetailsModal from "./groupView/GroupViewTaskDetailsModal/GroupViewTaskDetailsModal";
import NewTaskView from "./NewTaskView/NewTaskView";
import UserView from "./userView/UserView";
import UserViewTaskDetailsModal from "./userView/UserViewTaskDetailsModal/UserViewTaskDetailsModal";

export default function TaskerPage() {
  const dispatch = useAppDispatch();
  const modal = useModal({ withCloseButton: true });
  const taskerSlice = useAppSelector((state) => state.tasker);
  const usePath = useCurrentPath();
  const [initModal, setInitModal] = useState(false);
  const [initTaskView, setInitTaskView] = useState(false);

  useEffect(() => {
    if (!initTaskView) {
      setInitTaskView(true);
      return;
    }

    if (
      taskerSlice.taskerView.taskView === "Details" &&
      !modal.modalProps.isVisible
    ) {
      dispatch(taskerAction.test("TaskerPage/just close modal"));
      modal.showModal();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskerSlice.taskerView.taskView]);

  useEffect(() => {
    if (!initModal) {
      setInitModal(true);
      return;
    }

    if (
      taskerSlice.taskerView.taskView === "Details" &&
      !modal.modalProps.isVisible
    ) {
      dispatch(taskerAction.test("TaskerPage/just close modal"));
      dispatch(taskerAction.setCloseDetailsTaskView());
      // !! modal.modalProps.closeModal(); is invoked by clicking on close modal !!
    } else if (
      taskerSlice.taskerView.taskView === "Edit" &&
      modal.modalProps.isVisible
    ) {
      modal.modalProps.closeModal();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.modalProps.isVisible]);

  if (
    taskerSlice.taskerView.taskView === "New" ||
    taskerSlice.taskerView.taskView === "Edit"
  ) {
    return <NewTaskView />;
  }

  return (
    <>
      <ModalPortal centerFixed={true} {...modal.modalProps}>
        {taskerSlice.MainView === "Group" ? (
          <GroupViewTaskDetailsModal closeModal={modal.modalProps.closeModal} />
        ) : (
          <UserViewTaskDetailsModal closeModal={modal.modalProps.closeModal} />
        )}
      </ModalPortal>
      {usePath.currentPath === "/user-task-board" ? (
        <UserView />
      ) : (
        <GroupView />
      )}
    </>
  );
}
