import * as yup from "yup";

const config = {
  taskNameMinLength: 3,
  taskNameMaxLength: 30,
  descriptionMaxLength: 500,
};

export const TaskCreationSchema = () =>
  yup.object().shape({
    task_title: yup
      .string()
      .required("Nazwa zadania jest wymagana")
      .min(
        config.taskNameMinLength,
        `Wpisana nazwa jest za krótka, musi zawierać minimum ${config.taskNameMinLength} znaki`
      )
      .max(
        config.taskNameMaxLength,
        `Wpisana nazwa jest za długa, musi zawierać maksymalnie ${config.taskNameMaxLength} znaków`
      )
      .matches(
        /^[a-zA-Z0-9-żźćńółęąśŻŹĆĄŚĘŁÓŃ ]*$/,
        "Nazwa zadania może zawierać tylko litery, cyfry i myślniki"
      ),
    listName: yup.string().required("Nazwa listy jest wymagana"),
    realisationDate: yup.string().required("Data jest wymagana"),
    assignee: yup.string().nullable(),
    reminder_date: yup.string().required(),
    description: yup
      .string()
      .required("Opis zadania jest wymagana")
      .max(
        config.descriptionMaxLength,
        `Opis jest zbyt długi, limit znaków ${config.descriptionMaxLength}`
      ),
  });
